import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineBars, AiOutlineUnorderedList } from 'react-icons/ai'
import { FiMoreHorizontal, FiSearch } from 'react-icons/fi'
import { IoIosArrowDown } from 'react-icons/io'
import { MdWindow } from 'react-icons/md'
import { TiThList } from 'react-icons/ti'
import IconDoc from "../../../../../assets/icon/Doc.svg"
import IconPdf from "../../../../../assets/icon/Pdf.svg"
import IconPpt from "../../../../../assets/icon/Ppt.svg"
import IconXlsx from "../../../../../assets/icon/IconXlsx.svg"
import Api from '../../../../../Api'
import { toast } from 'react-hot-toast'
import { CgTrashEmpty } from 'react-icons/cg'
import { ModalDelete } from '../../../../../components'
import { BiSearch } from 'react-icons/bi'

const File = () => {
    const [index, setIndex] = useState(0)
    const [dataFileWord, setDataFileWord] = useState('')
    const [dataFileExcel, setDataFileExcel] = useState('')
    const [dataFilePpt, setDataFilePpt] = useState('')
    const [dataFilePdf, setDataFilePdf] = useState('')
    const [dataRecentFile, setDataRecentFile] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [file, setFile] = useState()
    const [userId, setUserId] = useState()
    const [showModalDelete, setShowModalDelete] = useState()
    const [idFile, setIdFile] = useState()


    const Fetch = async () => {
        try {
          const response = await Api.Fetch(localStorage.getItem('hris-token'))
          setUserId(response.data.results.user.id)
        } catch (error) {
          console.log(error)
        }
    }

    const postFile = async (fileTarget) => {
        try {
            const formData = new FormData();
            formData.append('file', fileTarget);
            const response = await Api.PostFile(localStorage.getItem('hris-token'), formData, userId)
            setRefresh(true)
            toast.success('Success Upload File')
        } catch (error) {
            console.log(error)
            toast.error('Failed Upload File')
        }
    }

    const getDataFile = async () => {
        try {
            const response = await Api.GetFileByLogin(localStorage.getItem('hris-token'))
            setDataRecentFile(response.data.data)
            console.log(response, 'file')
            setDataFileWord(response.data.data.filter(item => item.type === 'docx' || item.type === 'doc' ))
            setDataFileExcel(response.data.data.filter(item => item.type === 'xlsx' || item.type === 'xls' ))
            setDataFilePdf(response.data.data.filter(item => item.type === 'pdf'))
            setDataFilePpt(response.data.data.filter(item => item.type === 'pptx' || item.type === 'ppt' ))
        } catch (error) {
            console.log(error)
        }
    }

    const deleteFile = async () => {
        try {
            const response = await Api.DeleteFile(localStorage.getItem('hris-token'), idFile)
            toast.success('Success Delete File')
            setShowModalDelete(!showModalDelete)
            setRefresh(true)
        } catch (error) {
            console.log(error)
            toast.error(error.response.message)
        }
    }
   
    const handleFile = async(e) => {
        const maxSize = 200 * 1024
        const fileTarget = e.target.files[0]
        if (fileTarget && fileTarget.size > maxSize){
            toast.error('file tidak boleh lebih dari 200KB')
        } else{
            postFile(fileTarget)
        }
    }

    const deleteFileModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setIdFile(id)
        setRefresh(true)
    }

    useEffect(() => {
        getDataFile()
        Fetch()
        setRefresh(false)
    }, [refresh])

  return (
    <div>
        <ModalDelete
            activeModal={showModalDelete}
            buttonClose={() => setShowModalDelete(!showModalDelete)}
            submitButton={deleteFile}
        />
        <div className="flex justify-between items-center mb-[20px]">
            <div className='mb-[8.5px]'>
                <h1 className='text-[20px] font-medium'>Files</h1>
                <p className='text-[10px] text-[#737373]'>List of employee file</p>
            </div>
            <label htmlFor='upload-file' className='bg-[#0E5073] px-[17px] py-[10px] text-white text-[14px] font-medium rounded-md'>
                <h1>Upload</h1>
                <input id='upload-file' onChange={(e) => handleFile(e)} type="file" className='hidden' />
            </label>
            {/* <div>
                <input type="file" className="hidden" onChange={(e) => handleChange(e)} />
                <button className="bg-[#0E5073] px-[17px] py-[10px] text-white text-[14px] font-medium rounded-md">Upload</button>
            </div> */}
        </div>
        <div className='flex text-xl items-center justify-end'>
            <div className='relative'>
                <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                <input placeholder='Search by File Name...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
            </div>
        </div>
        
        <h1 className='text-[20px] font-medium mb-[20px]'>Recent Files</h1>
        <div className="flex gap-[30px] mb-[41px] overflow-auto scrollbar-hide">
            {Object.values(dataRecentFile).map((item, idx) => (
                <div className='flex w-[176px] h-[48px] items-center border rounded-md shadow-md'>
                    <img src={
                        item.type === 'docx' || item.type === 'doc' ? IconDoc : 
                        item.type ===  'xlsx' || item.type === 'xls'? IconXlsx :
                        item.type === 'pptx' || item.type === 'ppt' ? IconPpt:
                        IconPdf} 
                        alt="Doc icon"  className={item.type ===  'xlsx' || item.type === 'xls'? 'w-[18.33px] h-[24.17px] mr-1 ml-2' :'w-[24px] h-[30px] mr-1 ml-2'}/>
                    <div className='truncate px-1'>
                        <h1 className='text-[13px] font-medium text-[#282828] text-ellipsis overflow-hidden'>{item.filename?? '-'}</h1>
                        <h2 className='text-[10px] text-[#A8A8A8]'>{item.size} KB</h2>
                    </div>
                </div>
            ))}
        </div>
        <div className='flex gap-[50px] mb-2'>
            <button onClick={() => setIndex(0)} className={index === 0? 'text-[13px] font-medium border-b-2 border-black' : 'text-[13px] text-[#A8A8A8]'}>Doc</button>
            <button onClick={() => setIndex(1)} className={index === 1? 'text-[13px] font-medium border-b-2 border-black' : 'text-[13px] text-[#A8A8A8]'}>Xlsx</button>
            <button onClick={() => setIndex(2)} className={index === 2? 'text-[13px] font-medium border-b-2 border-black' : 'text-[13px] text-[#A8A8A8]'}>Pdf</button>
            <button onClick={() => setIndex(3)} className={index === 3? 'text-[13px] font-medium border-b-2 border-black' : 'text-[13px] text-[#A8A8A8]'}>Ppt</button>
        </div>
        <hr className='border-1 mb-[20px]'/>
        {index === 0 ? (
            <div className='grid grid-cols-3 gap-x-[33px] gap-y-[38px]'>
                {Object.values(dataFileWord).map((item, idx) => (
                    <div key={idx}>
                        <div className='rounded-lg shadow-md w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                            <div className='flex justify-between items-start'>
                            <img src={IconDoc} alt="Doc icon" className='w-[60px] h-[70px]' />
                            <button onClick={() => deleteFileModal(item.id)}>
                                <CgTrashEmpty className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full text-sm ' />
                            </button>
                            </div>
                            <div>
                            <h1 className='text-[#282828] text-[13px] font-medium truncate'>{item.filename ? item.filename : '-'}</h1>
                            <p className='text-[12px] text-[#A8A8A8]'>{item.size ? (item.size) : '-'} Bytes</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        ) : index === 1 ? (
            <div className='grid grid-cols-3 gap-x-[33px] gap-y-[38px]'>
            {Object.values(dataFileExcel).map((item, idx) => (
                <div key={idx}>
                       <div className='rounded-lg shadow-md  w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                            <div className='flex justify-between items-start'>
                                <img src={IconXlsx} alt="Doc icon" className='w-[60px] h-[70px] p-2' />
                                <button onClick={() => deleteFileModal(item.id)} >
                                    <CgTrashEmpty className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full ' />
                                </button>
                            </div>
                            <div>
                                <h1 className='text-[#282828] text-[13px] font-medium truncate'>{item.filename ? item.filename : '-'}</h1>
                                <p className='text-[12px] text-[#A8A8A8]'>{item.size ? (item.size) : '-'} Bytes</p>
                            </div>
                        </div>
                </div>
            ))}
            
        </div>
        ) : index === 2 ? (
            <div className='grid grid-cols-3 gap-x-[33px] gap-y-[38px]'>
                {Object.values(dataFilePdf).map((item, idx) => (
                    <div key={idx}>
                        <div className='rounded-lg shadow-md w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                            <div className='flex justify-between items-start'>
                            <img src={IconPdf} alt="pdf icon" className='w-[60px] h-[70px]' />
                            <button onClick={() => deleteFileModal(item.id)}>
                                <CgTrashEmpty className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full ' />
                            </button>
                            </div>
                            <div>
                            <h1 className='text-[#282828] text-[13px] font-medium truncate'>{item.filename ? item.filename : '-'}</h1>
                            <p className='text-[12px] text-[#A8A8A8]'>{item.size ? (item.size) : '-'} Bytes</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        ) : index === 3 ? (
            <div className='grid grid-cols-3 gap-x-[33px] gap-y-[38px]'>
                {Object.values(dataFilePpt).map((item, idx) => (
                    <div key={idx}>
                        <div className='rounded-lg shadow-md w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                            <div className='flex justify-between items-start'>
                            <img src={IconPpt} alt="pdf icon" className='w-[60px] h-[70px]' />
                            <button onClick={() => deleteFileModal(item.id)}>
                                <CgTrashEmpty className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full ' />
                            </button>
                            </div>
                            <div>
                            <h1 className='text-[#282828] text-[13px] font-medium truncate'>{item.filename ? item.filename : '-'}</h1>
                            <p className='text-[12px] text-[#A8A8A8]'>{item.size ? (item.size) : '-'} Bytes</p>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
        ) : (
            ""
        )}
        {/* <div className='grid grid-cols-3 gap-x-[33px] gap-y-[38px]'>
            <div className='rounded-lg shadow-md  w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                <div className='flex justify-between items-start'>
                    <img src={IconDoc} alt="Doc icon" className='w-[60px] h-[70px]' />
                    <button>
                        <FiMoreHorizontal className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full ' />
                    </button>
                </div>
                <div>
                    <h1 className='text-[#282828] text-[15px] font-medium'>Contact_Bright</h1>
                    <p className='text-[12px]  text-[#A8A8A8]'>200 KB</p>
                </div>
            </div>
            <div className='rounded-lg shadow-md w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                <div className='flex justify-between items-start'>
                    <img src={IconDoc} alt="Doc icon" className='w-[60px] h-[70px]' />
                    <button>
                        <FiMoreHorizontal className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full ' />
                    </button>
                </div>
                <div>
                    <h1 className='text-[#282828] text-[15px] font-medium'>Contact_Bright</h1>
                    <p className='text-[12px]  text-[#A8A8A8]'>200 KB</p>
                </div>
            </div>
            <div className='rounded-lg shadow-md  w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                <div className='flex justify-between items-start'>
                    <img src={IconDoc} alt="Doc icon" className='w-[60px] h-[70px]' />
                    <button>
                        <FiMoreHorizontal className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full ' />
                    </button>
                </div>
                <div>
                    <h1 className='text-[#282828] text-[15px] font-medium'>Contact_Bright</h1>
                    <p className='text-[12px]  text-[#A8A8A8]'>200 KB</p>
                </div>
            </div>
            <div className='rounded-lg shadow-md  w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                <div className='flex justify-between items-start'>
                    <img src={IconDoc} alt="Doc icon" className='w-[60px] h-[70px]' />
                    <button>
                        <FiMoreHorizontal className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full ' />
                    </button>
                </div>
                <div>
                    <h1 className='text-[#282828] text-[15px] font-medium'>Contact_Bright</h1>
                    <p className='text-[12px]  text-[#A8A8A8]'>200 KB</p>
                </div>
            </div>
            <div className='rounded-lg shadow-md  w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                <div className='flex justify-between items-start'>
                    <img src={IconDoc} alt="Doc icon" className='w-[60px] h-[70px]' />
                    <button>
                        <FiMoreHorizontal className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full ' />
                    </button>
                </div>
                <div>
                    <h1 className='text-[#282828] text-[15px] font-medium'>Contact_Bright</h1>
                    <p className='text-[12px]  text-[#A8A8A8]'>200 KB</p>
                </div>
            </div>
            <div className='rounded-lg shadow-md  w-[219px] h-[133px] px-[20px] pt-[15px] bg-white'>
                <div className='flex justify-between items-start'>
                    <img src={IconDoc} alt="Doc icon" className='w-[60px] h-[70px]' />
                    <button>
                        <FiMoreHorizontal className='bg-[#F2F2F2] w-[30px] h-[30px] rounded-full ' />
                    </button>
                </div>
                <div>
                    <h1 className='text-[#282828] text-[15px] font-medium'>Contact_Bright</h1>
                    <p className='text-[12px]  text-[#A8A8A8]'>200 KB</p>
                </div>
            </div>
        </div> */}

    </div>
  )
}

export default File