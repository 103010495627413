import React, { useEffect, useState } from 'react'
import { IoMdArrowDropdown } from 'react-icons/io'
import { useLocation } from 'react-router-dom';
import Api from '../../../../../Api';
import moment from 'moment';
import { toast } from 'react-hot-toast';

const JobDetails = () => {
    const params = useLocation()
    const [enabled, setEnabled] = useState(false);
    const [openEdit, setOpenEdit] = useState(false)
    const [userId, setUserId] = useState('')
    const [dataJob, setDataJob] = useState('')
    const [refresh, setRefresh] = useState(false)

    const [dataJobGrade, setDataJobGrade] = useState('')
    const [dataJobLevel, setDataJobLevel] = useState('')
    const [dataJobPosition, setDataJobPosition] = useState('')

    const [jobLevel, setJobLevel] = useState()
    const [jobGrade, setJobGrade] = useState()
    const [jobPosition, setJobPosition] = useState()
    const [jobLocation, setJobLocation] = useState()
    const [isToggled, setIsToggled] = useState(false);
    const [nameAttachment, setNameAttachment] = useState()
    const [attachmentFile, setAttachmentFile] = useState()
    const [dataSubsidiary, setDataSubsidiary] = useState('')
    const [dataEmployeStatus, setDataEmployeStatus] = useState('')

    const GetDataEmployeeStatus = async() => {
        try {
            const response = await Api.GetEmployeeStatus(localStorage.getItem('hris-token'))
            console.log(response, 'emplStatus')
            setDataEmployeStatus(response.data.employe_status)
        } catch (error) {
            console.log(error)
        }
    }

    const getSubsidiary = async () => {
        try {
            const response = await Api.GetSubsidiary(localStorage.getItem('hris-token'))
            console.log(response, 'subsidiary')
            setDataSubsidiary(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    const Fetch = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserId(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeAttachment = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['png', 'pdf'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize){
            toast.error('file tidak boleh lebih dari 2 MB')
            setAttachmentFile(null)
        } else if (!allowedExtensions.includes(fileExtension)){
            toast.error('file harus berformat pdf atau png')
        } else{
            setNameAttachment(e.target.files[0].name)
            setAttachmentFile(e.target.files[0])
        }
    }

    const getJobDetail = async () => {
        try {
            if (!params.state){
                const response = await Api.GetJobDetail(localStorage.getItem('hris-token'), userId)
                console.log(response, 'dataJobDetail')
                setDataJob(response.data.data[0])
            } else{
                const response = await Api.GetJobDetail(localStorage.getItem('hris-token'), params.state.employeeId)
                setDataJob(response.data.data[0])
            }
        } catch (error) {
            console.log(error)
        }
    }
    


    const openEditJobDetail = async () => {
        setOpenEdit(!openEdit)
        try {
            const response = await Api.GetJobDetail(localStorage.getItem('hris-token'), userId)
            console.log(response, 'jobDetail')
            setJobLevel(response.data.data[0].job_level.level_name)
            setEmployeeId(response.data.data[0].employee_id)
            setContractStartDate(response.data.data[0].contract_start_date)
            setContractEndDate(response.data.data[0].contract_end_date)
            setJobGrade(response.data.data[0].job_grade.job_grade)
            setJobPosition(response.data.data[0].job_position.position_name)
            setJobLocation(response.data.data[0].location)
            setJobCategory(response.data.data[0].job_category.category_name)
            setEmployeeStatus(response.data.data[0].employe_status.employee_status)
            setJoinDate(response.data.data[0].join_date)
        } catch (error) {
            console.log(error)
        }
    }

    const [employeeId, setEmployeeId] = useState('')
    const [joinDate, setJoinDate] = useState('')
    const [dataJobCategory, setDataJobCategory] = useState('')
    const [jobCategory, setJobCategory] = useState('')
    const [subsidiary, setSubsidiary] = useState('')
    const [employeeStatus, setEmployeeStatus] = useState('')
    const [contractStartDate, setContractStartDate] = useState('')
    const [contractEndDate, setContractEndDate] = useState('')

    const postJobDetail = async () => {
        try {
            const data = {
                ...(employeeId && { employee_id: employeeId }),
                ...(joinDate && { join_date: joinDate }),
                ...(jobGrade && { job_grade: jobGrade }),
                ...(jobLevel && { job_level: jobLevel }),
                ...(jobPosition && { job_position: jobPosition }),
                ...(jobCategory && { job_category: jobCategory }),
                ...(subsidiary && { subsidiary: subsidiary }),
                ...(employeeStatus && { employe_status: employeeStatus }),
                ...(contractStartDate && { contract_start_date: contractStartDate }),
                ...(contractEndDate && { contract_end_date: contractEndDate }),
                ...(attachmentFile && { file_contract: attachmentFile }),
              };
              console.log(data, 'sendedData')
            if(!params.state){
                const response = await Api.UpdateJobDetail(localStorage.getItem('hris-token'), data,  userId)
                toast.success('Success Update')
                setOpenEdit(false)
            } else{
                const response = await Api.UpdateJobDetail(localStorage.getItem('hris-token'), data,  params.state.employeeId)
                console.log(response)
                toast.success('Success Update')
                setOpenEdit(false)
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!!!')
        }
    }

    

    const getJobLevel = async () => {
        try {
            const response = await Api.GetJobLevel(localStorage.getItem('hris-token'), 1, 100)
            setDataJobLevel(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getJobGrade = async () => {
        try {
            const response = await Api.GetJobGrade(localStorage.getItem('hris-token'), 1, 100)
            setDataJobGrade(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getJobPosition = async () => {
        try {
            const response = await Api.GetJobPosition(localStorage.getItem('hris-token'), 1, 100)
            setDataJobPosition(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getJobCategory = async () => {
        try {
            const response = await Api.GetJobCategory(localStorage.getItem('hris-token'))
            setDataJobCategory(response.data.jobCategory)
        } catch (error) {
            console.log(error)
        }
    }
    
    useEffect(() => {
        Fetch()
        getJobLevel()
        getJobCategory()
        getJobGrade()
        getJobPosition()
        getSubsidiary()
        setRefresh(false)
        GetDataEmployeeStatus()
    }, [refresh])

    useEffect(() => {
        getJobDetail()
    }, [userId])
    
    if (!dataJob) {
        return (
            <div className='text-center h-screen flex items-center justify-center'>Loading....</div>
        )
    }
  return (
    <div className='pb-[46px]'>
        <h1 className='text-[#000000] text-[20px] font-medium mb-[41px]'>Job Detail</h1>
        {localStorage.getItem('hris-role') === "ADMIN" && (
            <button onClick={openEditJobDetail} className='font-medium text-[14px] bg-[#0E5073] text-white py-[10px] px-[25.5px] rounded-md absolute right-0 mr-5'>Edit</button>
        )}

        {openEdit === true ? (
            <>
            <div className='space-y-[20px]'>
                <div className='w-1/2'>
                    <h1 className='text-[12px] text-[#737373] mb-[9px]'>Employee ID</h1>
                    <input type="text" onChange={(e) => setEmployeeId(e.target.value)} value={employeeId} className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-white border rounded-md w-full text-[#737373]'/>
                </div>
                <div className='w-1/2'>
                    <h1 className='text-[12px] text-[#737373] mb-[9px]'>Joined Date</h1>
                    <input type="date" onChange={(e) => setJoinDate(e.target.value)} value={moment(joinDate).format('YYYY-MM-DD')} className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-white border rounded-md w-full text-[#737373]'/>
                </div>
                <div className='flex gap-[20px]'>
                    <div className='w-full'>
                        <h2 className='text-[#737373] text-[12px] mb-[9px]'>Job Grade</h2>
                        <div className='relative w-full'>
                            <select onChange={(e) => setJobGrade(e.target.value)} value={jobGrade}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Job Grade...</option>
                                {Object.values(dataJobGrade).map((item) => (
                                    <option value={item.id}>{item.job_grade}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div> 
                    <div className='w-full'>
                        <h2 className='text-[#737373] text-[12px] mb-[9px]'>Job Level</h2>
                        <div className='relative w-full'>
                            <select onChange={(e) => setJobLevel(e.target.value)} value={jobLevel} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Job Level...</option>
                                {Object.values(dataJobLevel).map((item) => (
                                    <option value={item.id}>{item.level_name}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div> 
                </div>
                <div className='flex gap-[20px]'>
                    <div className='w-full'>
                        <h2 className='text-[#737373] text-[12px] mb-[9px]'>Job Category</h2>
                        <div className='relative w-full'>
                            <select onChange={(e) => setJobCategory(e.target.value)} value={jobCategory}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Job Category...</option>
                                {Object.values(dataJobCategory).map((item) => (
                                    <option value={item.id}>{item.category_name}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div> 
                    <div className='w-full'>
                        <h2 className='text-[#737373] text-[12px] mb-[9px]'>Job Position</h2>
                        <div className='relative w-full'>
                            <select onChange={(e) => setJobPosition(e.target.value)} value={jobPosition}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Job Position...</option>
                                {Object.values(dataJobPosition).map((item) => (
                                    <option value={item.id}>{item.position_name}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div> 
                </div>
                <div className='flex gap-[20px]'>
                    <div className='w-full'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Subsidiary</h1>
                        <div className='relative w-full'>
                            <select onChange={(e) => setSubsidiary(e.target.value)} value={subsidiary}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Subsidiary...</option>
                                {Object.values(dataSubsidiary).map((item) => (
                                    <option value={item.id}>{item.company_name}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Employee Status</h1>
                        <div className='relative w-full'>
                            <select onChange={(e) => setEmployeeStatus(e.target.value)} value={employeeStatus}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Employee Status...</option>
                                {Object.values(dataEmployeStatus).map((item) => (
                                    <option value={item.id}>{item.employee_status}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div> 
                </div>
                <button className='flex items-center gap-[20px]' onClick={handleToggle}>
                    {isToggled ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="15" viewBox="0 0 29 15" fill="none">
                            <rect width="29" height="15" rx="7.5" fill="#D8DBDF"/>
                            <circle cx="7.5" cy="7.5" r="5.5" fill="white"/>
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="15" viewBox="0 0 29 15" fill="none">
                            <rect width="29" height="15" rx="7.5" fill="#669BBC"/>
                            <circle cx="20.5" cy="7.5" r="5.5" fill="white"/>
                        </svg>
                    )}
                    <div className="text-neutral-500 text-xs font-medium">Include Employment Contract Details</div>
                </button>
            </div>

            {!isToggled && (
                <div>
                    <div className="mt-[24.25px] relative mb-[21px]">
                        
                        <div className="flex gap-[22px] mb-[37px]">
                            <div>
                                <h1 className="text-[12px] text-[#737373] mb-[9px]">Contract Start Date</h1>
                                <input type="date" onChange={(e) => setContractStartDate(e.target.value)} className="py-[10px] pl-[21.74px] pr-2 text-[12px] border rounded-md w-[188px]"/>
                            </div>
                            <div>
                                <h1 className="text-[12px] text-[#737373] mb-[9px]">Contract End Date</h1>
                                <input type="date" onChange={(e) => setContractEndDate(e.target.value)} className="py-[10px] pl-[21.74px] pr-2 text-[12px] border rounded-md w-[188px]"/>
                            </div>
                        </div>
                    </div>

                    <div className='mb-[28px]'>
                        <h1 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Contract Attachment  <span className='text-[#E00101]'>*</span></h1>
                        <div className='flex items-center gap-[13px]'>
                            <label htmlFor='upload-attachment' className='cursor-pointer'>
                                <div className='flex w-[337px] h-[35px] rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                    <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                        <div className="text-center text-sky-950 text-[9px] font-medium">Browse</div>
                                    </div>
                                    <input type='file' id='upload-attachment' accept=".pdf, .png" onChange={(e) => handleChangeAttachment(e)}  className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required/>
                                    <h1 className='text-zinc-700 text-xs font-medium truncate'>{nameAttachment? nameAttachment : 'No File Selected' }</h1>
                                </div>
                            </label>
                        </div>
                        <h1 className=" text-red-600 text-[8px] font-normal">* Maks 500 KB</h1>
                    </div>
                </div>
                
            )}

            <button onClick={postJobDetail} className='font-medium text-[14px] bg-[#0E5073] text-white py-[10px] px-[25.5px] rounded-md absolute right-0 mr-5'>Save</button>
            </>
        ) : (
            <>
            <div className='space-y-[20px]'>
                <div className='w-1/2'>
                    <h1 className='text-[12px] text-[#737373] mb-[9px]'>Employee ID</h1>
                    <input type="text" value={dataJob.employee_id?? '-'} className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-[#D8DBDF] rounded-md w-full text-[#737373]' readOnly/>
                </div>
                <div className='w-1/2'>
                    <h1 className='text-[12px] text-[#737373] mb-[9px]'>Joined Date</h1>
                    <input type="text" value={dataJob? moment(dataJob.join_date).format('DD-MM-YYYY') : '-'} className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-[#D8DBDF] rounded-md w-full text-[#737373]' readOnly/>
                </div>
                <div className='flex gap-[20px]'>
                    <div className='w-full'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Job Grade</h1>
                        <input type="text" value={dataJob.job_grade? dataJob.job_grade.job_grade : '-'}  className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-[#D8DBDF] rounded-md w-full text-[#737373]' readOnly/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Job Level</h1>
                        <input type="text" value={dataJob.job_level? dataJob.job_level.level_name : '-'}  className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-[#D8DBDF] rounded-md w-full text-[#737373]' readOnly/>
                    </div>
                </div>
                <div className='flex gap-[20px]'>
                    <div className='w-full'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Job Category</h1>
                        <input type="text" value={dataJob.job_grade? dataJob.job_grade.job_grade : '-'}  className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-[#D8DBDF] rounded-md w-full text-[#737373]' readOnly/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Job Position</h1>
                        <input type="text" value={dataJob.job_position? dataJob.job_position.position_name : '-'}  className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-[#D8DBDF] rounded-md w-full text-[#737373]' readOnly/>
                    </div> 
                </div>

                <div className='flex gap-[20px]'>
                    <div className='w-full'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Subsidiary</h1>
                        <input type="text" value={dataJob?.subsidiary?? '-'}  className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-[#D8DBDF] rounded-md w-full text-[#737373]' readOnly/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Employee Status</h1>
                        <input type="text" value={dataJob?.employe_status?.employee_status?? '-'}  className='py-[10px] pl-[21.74px] pr-2 text-[12px] font-medium bg-[#D8DBDF] rounded-md w-full text-[#737373]' readOnly/>
                    </div> 
                </div>

                <button className='flex items-center gap-[20px]' onClick={handleToggle}>
                    {isToggled ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="15" viewBox="0 0 29 15" fill="none">
                            <rect width="29" height="15" rx="7.5" fill="#D8DBDF"/>
                            <circle cx="7.5" cy="7.5" r="5.5" fill="white"/>
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="15" viewBox="0 0 29 15" fill="none">
                            <rect width="29" height="15" rx="7.5" fill="#669BBC"/>
                            <circle cx="20.5" cy="7.5" r="5.5" fill="white"/>
                        </svg>
                    )}
                    <div className="text-neutral-500 text-xs font-medium">Include Employment Contract Details</div>
                </button>
                
                {!isToggled && (
                    <div>
                        <div className="mt-[24.25px] relative mb-[21px]">
                            
                            <div className="flex gap-[22px] mb-[37px]">
                                <div>
                                    <h1 className="text-[12px] text-[#737373] mb-[9px]">Contract Start Date</h1>
                                    <input type="date" value={dataJob?.contract_start_date}  className="py-[10px] pl-[21.74px] pr-2 text-[12px] border rounded-md w-[188px] bg-[#D8DBDF]" readOnly/>
                                </div>
                                <div>
                                    <h1 className="text-[12px] text-[#737373] mb-[9px]">Contract End Date</h1>
                                    <input type="date" value={dataJob?.contract_end_date}  className="py-[10px] pl-[21.74px] pr-2 text-[12px] border rounded-md w-[188px] bg-[#D8DBDF]" readOnly/>
                                </div>
                            </div>
                            {/* <div className="flex border rounded-md w-[188px] items-center justify-between pl-[6px] pr-[22px] mb-[43px]">
                                <button className="text-[#003049] text-[9px] font-medium py-[8px] px-[10px] w-[54px] bg-[#ECECEC] rounded-md">Browse</button>
                                <h1 className="text-[12px] font-medium py-[10px] text-[#D8DBDF]">No file selected</h1>
                            </div>
                            <button className="p-[10px] text-[#003049] border rounded-md text-[12px] font-medium absolute right-0 top-0">Terminate Employment</button> */}
                        </div>
                    </div>
                )}
            </div>
            </>
        )}
        
    </div>
  )
}

export default JobDetails