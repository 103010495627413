import React, { useEffect, useState } from 'react'
import { Modal, Navbar, Pagination } from '../../../../components'
import { BiSearch } from 'react-icons/bi'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { FiEye } from 'react-icons/fi'
import { FaMapMarkerAlt } from 'react-icons/fa'
import Api from "../../../../Api"
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { Logo, LogoH } from '../../../../assets'
import { debounce } from 'lodash'
import L from 'leaflet'
import { useGeolocated } from "react-geolocated";
import { MapContainer, TileLayer, Marker, Popup, Circle, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import axios from 'axios'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { TbFileExport } from 'react-icons/tb'
import imageHandle from '../../../../utils/imageHandle'


const Attendance = () => {
    const [menu, setMenu] = useState('My Attendance')

    // My Attendance State
    const [showAddCheckIn, setShowAddCheckIn] = useState()
    const [showDetailAttendance, setShowDetailAttendance] = useState()
    const [dataAttendance, setDataAttendance] = useState('')
    const [latitudeAttendance, setLatitudeAttendance] = useState('')
    const [longitudeAttendance, setLongitudeAttendance] = useState('')
    const [geodata, setGeoData] = useState('')
    const [refresh, setRefresh] = useState('')

    // const limit = 10
    const [currentPageAttendance, setCurrentPageAttendance] = useState(1)
    const [totalPagesAttendance, setTotalPagesAttendance] = useState('')
    const [nameSearchAttendance, setNameSearchAttendance] = useState('')
    const [dateSearchAttendance, setDateSearchAttendance] = useState('')
    const [locationNameCheckoutAttendance, setLocationNameCheckoutAttendance] = useState('');
    const [locationNameCheckinAttendance, setLocationNameCheckinAttendance] = useState('');

    const [photoClockIn, setPhotoClockIn] = useState('')
    const [photoClockOut, setPhotoClockOut] = useState('')
    const [detailDataAttendance, setDetailDataAttendance] = useState('')

    
    const handlePageChangeAttendance = (page) => {
        setCurrentPageAttendance(page);
        setRefresh(true)
    };
    
    const handlePrevChangeAttendance = () => {
        if(currentPageAttendance === 1) {
            setCurrentPageAttendance(1)
        } else {
            setCurrentPageAttendance(currentPageAttendance - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChangeAttendance = () => {
        if(currentPageAttendance === totalPagesAttendance) {
            setCurrentPageAttendance(totalPagesAttendance)
        } else {
            setCurrentPageAttendance(currentPageAttendance + 1);
        }
        setRefresh(true)
    };

    const getDataAttendance = async () =>{
        try{
            const response = await Api.GetMyAttendance(localStorage.getItem('hris-token'), dateSearchAttendance, nameSearchAttendance, currentPageAttendance, limit)
            setDataAttendance(response.data.attendances)
            setCurrentPageAttendance(response.data.currentPage)
            setTotalPagesAttendance(response.data.totalPages)
            console.log(response,'atendance')
        } catch (error) {
            console.log(error)
        }
    }

    const openDetailAttendance = async (id) => {
        try {
            setShowDetailAttendance(!showDetailAttendance)
            const response = await Api.GetMyAttendanceById(localStorage.getItem('hris-token'), id)
            setDetailDataAttendance(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getGeoData = async() => {
        try {
            const response = await Api.GetGeoLocation(localStorage.getItem('hris-token'))
            setGeoData(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const { coords } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    })

    
    const CheckInDataAttendance = async () =>{

        const userLatLng = L.latLng([coords.latitude, coords.longitude]);
        setLatitudeAttendance(coords.latitude)
        setLongitudeAttendance(coords.longitude)
        let isOutsideRadius = true;
        geodata.forEach((data) => {
            const geocodeString = data.geocode;
            const geocodeArray = JSON.parse(geocodeString);
            const centerLatLng = L.latLng(geocodeArray[0], geocodeArray[1]);
            const distance = userLatLng.distanceTo(centerLatLng);
    
            if (distance <= data.georadius) {
                isOutsideRadius = false;
            }
        });
    
        if (isOutsideRadius) {
            toast.error('You are out of radius!')
        }else {
            try{
                const data = {
                    note: "ok",
                    longitude_checkin: JSON.stringify(longitudeAttendance) ,
                    latitude_checkin: JSON.stringify(latitudeAttendance)
                }
                const response = await Api.PostMyAttendanceCheckIn(localStorage.getItem('hris-token'), data)
                toast.success('Check in Success')
                setRefresh(true)
            } catch (error){
                console.log(error)
                toast.error(error.response.data.message)
            }
        }
    }

    const CheckOutDataAttendance = async (id) =>{
        const userLatLng = L.latLng([coords.latitude, coords.longitude]);
        setLatitudeAttendance(coords.latitude)
        setLongitudeAttendance(coords.longitude)
        let isOutsideRadius = true;
        geodata.forEach((data) => {
            const geocodeString = data.geocode;
            const geocodeArray = JSON.parse(geocodeString);
            const centerLatLng = L.latLng(geocodeArray[0], geocodeArray[1]);
            const distance = userLatLng.distanceTo(centerLatLng);
    
            if (distance <= data.georadius) {
                isOutsideRadius = false;
            }
        });
    
        if (isOutsideRadius) {
            toast.error('You are out of radius!')
        } else {
            try{
                const data = {
                    longitude_checkout: JSON.stringify(longitudeAttendance) ,
                    latitude_checkout: JSON.stringify(latitudeAttendance)
                }
                const response = await Api.PostMyAttendanceCheckOut(localStorage.getItem('hris-token'), data, id)
                setRefresh(true)
                toast.success('Check out Success')
            } catch (error){
                console.log(error)
                toast.error('Check out Failed')
            }
        }
    }

    const debouncedSearchNameAttendance = debounce(async(name) => {
        try {
            const response = await Api.GetMyAttendance(localStorage.getItem('hris-token'), dateSearchAttendance, name, currentPageAttendance, limit)
            setDataAttendance(response.data.attendances)
            setCurrentPageAttendance(response.data.currentPage)
            setTotalPagesAttendance(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchNameAttendance = (e) => {
        const searchName = e.target.value
        debouncedSearchNameAttendance(searchName)
    }
    
    const debouncedSearchDateAttendance = debounce(async(date) => {
        try {
            const response = await Api.GetMyAttendance(localStorage.getItem('hris-token'), date, nameSearchAttendance, currentPageAttendance, limit)
            setDataAttendance(response.data.attendances)
            setCurrentPageAttendance(response.data.currentPage)
            setTotalPagesAttendance(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const debouncedLimitAttendance = debounce(async(limit) => {
        try {
            const response = await Api.GetMyAttendance(localStorage.getItem('hris-token'), dateSearchAttendance, nameSearchAttendance, currentPageAttendance, limit)
            setDataAttendance(response.data.attendances)
            setCurrentPageAttendance(response.data.currentPage)
            setTotalPagesAttendance(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchDateAttendance = (e) => {
        const searchDate = e.target.value
        debouncedSearchDateAttendance(searchDate)
    }

    const handleLimitAttendance= (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimitAttendance(limit)
    }



    const duration = (endTime, startTime) => {
        const format = "YYYY-MM-DD HH:mm:ss"; // Include the date in the format as well
      
        const ms = moment(endTime, format).diff(moment(startTime, format));
        const d = moment.duration(ms).abs(); // Take the absolute value of the duration
        const h = d.hours();
        const m = d.minutes();
        const s = d.seconds();
      
        if (h !== 0) {
          return `${h} h ${m} m`;
        } else {
          return `${m} m`;
        }
    };

    const customIcon = new L.icon({
        iconUrl: LogoH ,
        iconSize: [17, 17],
      });

     
    
      const getLocationNameCheckinAttendance = () => {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${detailDataAttendance.latitude_checkin}&lon=${detailDataAttendance.longtitude_checkin}`
          )
          .then((response) => {
            const locationNameCheckin = response.data.display_name;
            setLocationNameCheckinAttendance(locationNameCheckin);
          })
          .catch((error) => {
          });
      };

      const getLocationNameCheckoutAttendance = () => {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${detailDataAttendance.latitude_checkout}&lon=${detailDataAttendance.longtitude_checkout}`
          )
          .then((response) => {
            const locationNameCheckout = response.data.display_name;
            setLocationNameCheckoutAttendance(locationNameCheckout);
          })
          .catch((error) => {
          });
      };

    //State Employee Record
    // const [showAddCheckIn, setShowAddCheckIn] = useState()
    // const [showDetailAttendance, setShowDetailAttendance] = useState(false)
    const [data, setData] = useState('')
    const [dataExport, setDataExport] = useState('')
    const [dataEmployee, setDataEmployee] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [dateSearch, setDateSearch] = useState('')
    const [detailData, setDetailData] = useState('')
    const [idUser, setIdUser] = useState('')
    const [limit, setLimit] = useState(10)


    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const getRecordAttendance = async () => {
        try{
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
            setData(response.data.response)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)

            console.log(response, 'record attendace')
        } catch (error) {
            console.log(error)
        }
    }

    const getRecordAttendanceForExport = async () => {
        try{
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), '', '', '', '')
            setDataExport(response.data.response)
        } catch (error) {
            console.log(error)
        }
    }

    const openDetailAttendanceRecord = async (id) => {
        setShowDetailAttendance(!showDetailAttendance)
        try {
            const response = await Api.GetAttendanceRecordById(localStorage.getItem('hris-token'), id)
            setDetailData(response.data)
            setIdUser(response.data.id)
            console.log(response, 'detailOpen')
        } catch (error) {
            console.log(error)
        }
    }

    const debouncedSearchName = debounce(async(name) => {
        try {
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), dateSearch, name, currentPage, limit)
            setData(response.data.response)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)

        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }

    const debouncedLimit = debounce(async(limit) => {
        setLimit(limit)
        try {
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
            setData(response.data.response)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)
            console.log(response, 'limit')
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleLimit= (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimit(limit)
    }

    const debouncedSearchDate = debounce(async(date) => {
        try {
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), date, nameSearch, currentPage, limit)
            setData(response.data.response)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)

        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchDate = (e) => {
        const searchDate = e.target.value
        debouncedSearchDate(searchDate)
    }

    const CheckOutDataAttendanceRecord = async (id) =>{
        // setShowAddCheckIn(!showAddCheckIn)
        try{
            const data = {
                userId: id,
            }
            console.log(data, 'dataPosted')
            const response = await Api.PostMyAttendanceCheckOut(localStorage.getItem('hris-token'), data, id)
            toast.success('Checkout success!')
            setRefresh(true)
        } catch (error){
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    const exportToExcel = () => {
        // Sample data array
        const dataEmployeeRecord = dataExport;

        // Define custom headers for each table
        const Headers = ['Employee Name', 'Date', 'Check In', 'Location Check In', 'Check Out', 'Location Check Out', 'Duration'];

        // Create modified data arrays with custom headers
        const jobGrade = dataEmployeeRecord.map(({ user, createdAt, time_checkin, time_checkout, latitude_checkin, longtitude_checkin, latitude_checkout, longtitude_checkout }) => ({
            'Employee Name': user ? user.fullname : '-',
            'Date': moment(createdAt).format('DD MMMM YYYY'),
            'Check In': time_checkin ? moment(time_checkin).format('hh:mm') : '-',
            'Location Check In': latitude_checkin && longtitude_checkin ? latitude_checkin + ',' + longtitude_checkin : '-',
            'Check Out': time_checkout ? moment(time_checkout).format('hh:mm') : '-',
            'Location Check Out': latitude_checkout && longtitude_checkout ? latitude_checkout + ',' + longtitude_checkout : '-',
            'Duration': time_checkin && time_checkout ? duration(time_checkin, time_checkout) : '-'
        }));

        // Create a new worksheet for each table
        const worksheetGrade = XLSX.utils.json_to_sheet(jobGrade, { header: Headers });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Employee Records');
        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, 'Employee Record.xlsx');
    };

    const [locationNameCheckout, setLocationNameCheckout] = useState('');
    const [locationNameCheckin, setLocationNameCheckin] = useState('');
    
      const getLocationNameCheckin = (dataLocation) => {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${detailData.latitude_checkin}&lon=${detailData.longtitude_checkin}`
          )
          .then((response) => {
            const locationNameCheckin = response.data.display_name;
            setLocationNameCheckin(locationNameCheckin);
          })
          .catch((error) => {
          });
      };

      const getLocationNameCheckout = () => {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${detailData.latitude_checkout}&lon=${detailData.longtitude_checkout}`
          )
          .then((response) => {
            const locationNameCheckout = response.data.display_name;
            setLocationNameCheckout(locationNameCheckout);
          })
          .catch((error) => {
          });
      };

      const [user, setUser] = useState('')

        const Fetch = async() => {
            try {
                const response = await Api.Fetch(localStorage.getItem('hris-token'))
                setUser(response.data.results.data.fullname)
                console.log(response, 'fetch')
            } catch (error) {
                console.log(error)
            }
        }
        
        const getEmployee = async () => {
            try {
                const response = await Api.GetEmployee(localStorage.getItem('hris-token'))
                setDataEmployee(response.data.data.filter(item => item.role !== 'ADMIN'))
            } catch (error) {
                console.log(error)
            }
        }

    // openIndex
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)
    const handleCollapse = (id) => {
            if(id){
                setCurrent(id)
                setActive(!Active)
            }
    }
    

    //EmployeeRecord
    useEffect(() => {
        getLocationNameCheckout()
        getLocationNameCheckin()
    } , []);

    useEffect(() => {
        getRecordAttendance()
        getRecordAttendanceForExport()
        getEmployee()
        Fetch()
        setRefresh(false)
    }, [refresh, limit])


    //MyAttendance
    useEffect(() => {
        getLocationNameCheckoutAttendance();
        getLocationNameCheckinAttendance()
    } , [detailDataAttendance]);

    useEffect(() => {
        getGeoData()
        getDataAttendance()
        setRefresh(false)

        // Get Geo Location User
        const getPosition = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        setLatitudeAttendance(position.coords.latitude);
                        setLongitudeAttendance(position.coords.longitude);
                    },
                    error => {
                        console.error(error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };
        getPosition();
    }, [refresh])
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Attendance'} LinkBack={'/dashboard'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='border-b-2 flex items-center text-[16px]'>
                            <button onClick={ () => setMenu('My Attendance') } className={`${menu === 'My Attendance' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>My Attendance</button>
                            <button onClick={ () => setMenu('Employee Record') } className={`${menu === 'Employee Record' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Employee Record</button>
                        </div>
                        {menu === 'My Attendance' ? (
                            <>
                                <div>
                                    <h1 className='text-[20px] text-[#454545] font-semibold'>My Attendace</h1>
                                    <h2 className='text-[#737373] text-[10px]'>List of Attendance </h2>
                                </div>

                                <div className="flex lg:flex-row flex-col gap-2 mt-[31px] relative justify-between">
                                    <div className='flex flex-col lg:flex-row items-center gap-[10px]'>
                                        <input onChange={handleSearchDateAttendance} type="date" className='w-full lg:w-[121px] p-[10px] bg-[#F5F8FA] rounded-md border-none text-[12px] text-[#7E8299]'/>
                                        <select onChange={handleLimitAttendance} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                            <option value="" selected disabled>Set Limit</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                        </select>
                                        {/* <button className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px] w-full lg:w-fit'>
                                            <TbFileExport className='text-[#003049]'/>
                                            <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                                        </button> */}
                                    </div>
                                    <div className="">
                                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                            <button onClick={CheckInDataAttendance} className='bg-[#003049] py-1.5 px-4 rounded-[10px] flex items-center gap-[16px] text-white w-full lg:w-auto'>
                                                <h1 className='truncate'>Check in!</h1>
                                            </button>
                                            <div onChange={handleSearchNameAttendance} className='relative w-full'>
                                                <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                                <input placeholder='Search by Employee Name or Employee ID...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                            </div>
                                            {/* <button onClick={() => setShowAddCheckIn(!showAddCheckIn)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                                <AiOutlinePlus  className='text-xl text-white'/>
                                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Check In</h1>
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            <div>
                                <div className='overflow-auto'>
                                    <table className='w-full space-y-[10px] text-[#737373]'>
                                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-[#737373] text-[11px] font-[500]'>Date</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                <h1 className='text-[#737373] text-[11px] font-[500]'>Check In</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                <h1 className='text-[#737373] text-[11px] font-[500]'>Check Out</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                <h1 className='text-[#737373] text-[11px] font-[500]'>Status</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                <h1 className='text-[#737373] text-[11px] font-[500]'>Duration</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center justify-center gap-[6px] w-full'>
                                                <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                        </div>
                                        {
                                            Object.values(dataAttendance).map((item, idx) =>(
                                            <div key={idx}>
                                                <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                                        <h1 className='text-[#737373] text-[11px] font-[500]'>{moment(item.date).format('DD/MM/YYYY')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                        <h1 className='text-[#737373] text-[11px] font-[500]'>{moment(item.time_checkin).format('HH:mm')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                        <h1 className='text-[#737373] text-[11px] font-[500]'>{!item.time_checkout ? '-' : moment(item.time_checkout).format('HH:mm')}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                        <h1 className='text-[#737373] text-[11px] font-[500]'>{!item.time_checkout ? '-' : duration(item.time_checkin, item.time_checkout)}</h1>
                                                    </div>
                                                    <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                        <h1 className='text-green-600 text-[11px] font-[500]'>Present</h1>
                                                    </div>
                                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                                        <button onClick={() => CheckOutDataAttendance(item.id)}  className=' bg-[#CEDFEA] rounded-[9px] w-[92px] flex items-center justify-center text-[#003049] py-[5px] px-[15px] text-[11px] font-semibold'>
                                                            Check Out
                                                        </button>
                                                        <button onClick={ () => handleCollapse(item.id) } className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                            { item.id === Current && !Active ?
                                                                <MdKeyboardArrowUp className='text-[#003049]'/>
                                                                :
                                                                <MdKeyboardArrowDown className='text-[#003049]'/>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${ item.id === Current && !Active  ? 'h-fit border' : 'h-0 border-0' }`}>
                                                        <div className='p-[16px] space-y-3'>
                                                            <h1 className="text-gray-500 text-sm font-semibold">Detail Employee Record</h1>
                                                            <div className='space-y-3'>
                                                                <div className='grid grid-cols-12'>
                                                                    <h1 className='col-span-1 text-gray-500 text-xs'>Name</h1>
                                                                    <h1 className='col-span-10 text-gray-500 text-xs'>: {item.user.fullname?? '-'}</h1>
                                                                </div>
                                                                <div className='grid grid-cols-12 '>
                                                                    <h1 className='col-span-1 text-gray-500 text-xs'>Date</h1>
                                                                    <h1 className='col-span-10 text-gray-500 text-xs'>: {moment(item.date).format('DD/MM/YYYY')}</h1>
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-[53px] items-start'>
                                                                <div>
                                                                    <h1 className="text-gray-500 text-sm font-medium mb-3">Check In</h1>
                                                                    <div className='w-[272px]'>
                                                                        <div className="grid grid-cols-3 text-gray-500 text-xs mb-3">
                                                                            <h1 className='col-span-1'>Check in</h1>
                                                                            <h1 className='col-span-2'>: 07:40</h1>
                                                                        </div>
                                                                        <div className='flex gap-[23px]'>
                                                                            <div>
                                                                                <h1 className='text-[#5C5C5C] text-[10px] font-medium '>Photo</h1>
                                                                                <div className='w-[77px] h-[76px] bg-[#EDEDED] rounded-[10px] flex items-center justify-center'>
                                                                                    <img className='object-contain' src={item.image_checkin? imageHandle(item.image_checkin) : LogoH}/>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <h1 className='text-[#5C5C5C] text-[10px] font-medium '>Location</h1>
                                                                                <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 text-base border'>
                                                                                {item.latitude_checkin && item.longtitude_checkin? (
                                                                                    <MapContainer
                                                                                        center={[item?.latitude_checkin, item?.longtitude_checkin]}
                                                                                        zoom={15}
                                                                                        style={{ height: '100%', width: '100%' }}
                                                                                    >
                                                                                        <TileLayer
                                                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                                            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                                                        />
                                                                                        <Marker icon={customIcon} position={[item?.latitude_checkin, item?.longtitude_checkin]}>
                                                                                            </Marker>
                                                                                    </MapContainer>
                                                                                ) : (
                                                                                    <h1 className='text-center text-[10px] font-medium'>Not Checkin Yet</h1>
                                                                                )}
                                                                                </div>

                                                                                <div className='flex gap-[9px]'>
                                                                                    <FaMapMarkerAlt className='text-[#757D8A]' />
                                                                                    <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                                                                        {locationNameCheckin ? locationNameCheckin : 'Not Checkout Yet'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <h1 className="text-gray-500 text-sm font-medium mb-3">Check out</h1>
                                                                    <div className='w-[272px]'>
                                                                        <div className="grid grid-cols-3 text-gray-500 text-xs mb-3">
                                                                            <h1 className='col-span-1'>Check out</h1>
                                                                            <h1 className='col-span-2'>: {!item.time_checkout ? '-' : moment(item.time_checkout).format('HH:mm')}</h1>
                                                                        </div>
                                                                        <div className='flex gap-[23px]'>
                                                                            <div>
                                                                                <h1 className='text-[#5C5C5C] text-[10px] font-medium '>Photo</h1>
                                                                                <div className='w-[77px] h-[76px] bg-[#EDEDED] rounded-[10px] flex items-center justify-center'>
                                                                                    <img className='object-contaout' src={item.image_checkout? imageHandle(item.image_checkout) : LogoH}/>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <h1 className='text-[#5C5C5C] text-[10px] font-medium '>Location</h1>
                                                                                <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 text-base border'>
                                                                                {item.latitude_checkout && item.longtitude_checkout? (
                                                                                    <MapContainer
                                                                                        center={[item?.latitude_checkout, item?.longtitude_checkout]}
                                                                                        zoom={15}
                                                                                        style={{ height: '100%', width: '100%' }}
                                                                                    >
                                                                                        <TileLayer
                                                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                                            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                                                        />
                                                                                        <Marker icon={customIcon} position={[item?.latitude_checkout, item?.longtitude_checkout]}>
                                                                                            </Marker>
                                                                                    </MapContainer>
                                                                                ) : (
                                                                                    <h1 className='text-center text-[10px] font-medium'>Not Checkout Yet</h1>
                                                                                )}
                                                                                </div>

                                                                                <div className='flex gap-[9px]'>
                                                                                    <FaMapMarkerAlt className='text-[#757D8A]' />
                                                                                    <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                                                                        {locationNameCheckout ? locationNameCheckout : 'Not Checkout Yet'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className='space-y-3'>
                                                                    <div className='grid grid-cols-12'>
                                                                        <h1 className='col-span-3 text-gray-500 text-xs'>Status</h1>
                                                                        <h1 className='col-span-1 text-gray-500 text-xs'>:</h1>
                                                                        <h1 className='col-span-8 text-green-600 text-xs'>Present</h1>
                                                                    </div>
                                                                    <div className='grid grid-cols-12 '>
                                                                        <h1 className='col-span-3 text-gray-500 text-xs'>Duration</h1>
                                                                        <h1 className='col-span-1 text-gray-500 text-xs'>:</h1>
                                                                        <h1 className='col-span-8 text-gray-500 text-xs'>{!item.time_checkout ? '-' : duration(item.time_checkin, item.time_checkout)}</h1>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            ))
                                        }
                                    </table>
                                </div>
                                <Pagination
                                    currentPage={currentPageAttendance} 
                                    totalPages={totalPagesAttendance} 
                                    onPageChange={handlePageChangeAttendance}
                                    onPrevChange={handlePrevChangeAttendance}
                                    onNextChange={handleNextChangeAttendance}
                                />
                            </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <h1 className='text-[20px] text-[#454545] font-semibold'>Employee Records</h1>
                                    <h2 className='text-[#737373] text-[10px]'>List of  record employee attendance</h2>
                                </div>

                                <div className="flex gap-2 mt-[31px] relative">
                                    <input onChange={handleSearchDate} type="date" className='w-[121px] p-[10px] bg-[#F5F8FA] rounded-md border-none text-[12px] text-[#7E8299]'/>
                                    <button onClick={exportToExcel} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                        <TbFileExport className='text-[#003049]'/>
                                        <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                                    </button>
                                    <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                        <option value="" selected disabled>Set Limit</option>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                    </select>
                                    <div className="absolute right-0">
                                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                            <div onChange={handleSearchName} className='relative'>
                                                <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                                <input placeholder='Search by Employee Name or Employee ID...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                            </div>
                                            
                                            {/* <button onClick={() => setShowAddCheckIn(!showAddCheckIn)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                                <AiOutlinePlus  className='text-xl text-white'/>
                                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Manual Check In</h1>
                                            </button> */}
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='overflow-auto scrollbar-hide'>
                                        <table className='space-y-[10px] text-[#737373]'>
                                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='flex items-center justify-start gap-[6px] min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Employee Name</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Date</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Check In</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Check Out</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Status</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Duration</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center justify-center gap-[6px] w-full'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                            </div>
                                            {Object.values(data).map((item ,idx) => (
                                                <div key={idx}>
                                                    <div className={`flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]`}>
                                                        <div className='flex items-center justify-start gap-[6px] min-w-[150px] max-w-[150px]'>
                                                            <h1 className='text-[#737373] text-[11px] font-[500]'>{item.user? item.user.fullname : '-'}</h1>
                                                        </div>
                                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                            <h1 className='text-[#737373] text-[11px] font-[500]'>{item.date? moment(item.date).format('DD/MM/YYYY') : '-'}</h1>
                                                        </div>
                                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                            <h1 className='text-[#737373] text-[11px] font-[500]'>{item.time_checkin? moment(item.time_checkin).format('HH:mm') : '-'}</h1>
                                                        </div>
                                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                            <h1 className='text-[#737373] text-[11px] font-[500]'>{!item.time_checkout ? '-' : moment(item.time_checkout).format('HH:mm')}</h1>
                                                        </div>
                                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                            <h1 className='text-green-600 text-[11px] font-medium'>Present</h1>
                                                        </div>
                                                        <div className='flex items-center justify-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                                        <h1 className='text-[#737373] text-[11px] font-[500]'>{!item.time_checkout ? '-' : duration(item.createdAt, item.updatedAt)}</h1>
                                                        </div>
                                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                                            <button onClick={ () => CheckOutDataAttendanceRecord(item.user.id) } className=' bg-[#CEDFEA] rounded-[9px] w-[92px] flex items-center justify-center text-[#003049] py-[5px] px-[15px] text-[11px] font-semibold'>
                                                                Check Out
                                                            </button>
                                                            <button onClick={ () => handleCollapse(item.id) } className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                                { item.id === Current && !Active ?
                                                                    <MdKeyboardArrowUp className='text-[#003049]'/>
                                                                    :
                                                                    <MdKeyboardArrowDown className='text-[#003049]'/>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${ item.id === Current && !Active  ? 'h-fit border' : 'h-0 border-0' }`}>
                                                        <div className='p-[16px] space-y-3'>
                                                            <h1 className="text-gray-500 text-sm font-semibold">Detail Employee Record</h1>
                                                            <div className='space-y-3'>
                                                                <div className='grid grid-cols-12'>
                                                                    <h1 className='col-span-1 text-gray-500 text-xs'>Name</h1>
                                                                    <h1 className='col-span-10 text-gray-500 text-xs'>: {item.user.fullname?? '-'}</h1>
                                                                </div>
                                                                <div className='grid grid-cols-12 '>
                                                                    <h1 className='col-span-1 text-gray-500 text-xs'>Date</h1>
                                                                    <h1 className='col-span-10 text-gray-500 text-xs'>: {item.date? moment(item.date).format('DD/MM/YY') : '-'}</h1>
                                                                </div>
                                                            </div>
                                                            <div className='flex gap-[53px] items-start'>
                                                                <div>
                                                                    <h1 className="text-gray-500 text-sm font-medium mb-3">Check In</h1>
                                                                    <div className='w-[272px]'>
                                                                        <div className="grid grid-cols-3 text-gray-500 text-xs mb-3">
                                                                            <h1 className='col-span-1'>Check in</h1>
                                                                            <h1 className='col-span-2'>: 07:40</h1>
                                                                        </div>
                                                                        <div className='flex gap-[23px]'>
                                                                            <div>
                                                                                <h1 className='text-[#5C5C5C] text-[10px] font-medium '>Photo</h1>
                                                                                <div className='w-[77px] h-[76px] bg-[#EDEDED] rounded-[10px] flex items-center justify-center'>
                                                                                    <img className='object-contain' src={item.image_checkin? imageHandle(item.image_checkin) : LogoH}/>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <h1 className='text-[#5C5C5C] text-[10px] font-medium '>Location</h1>
                                                                                <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 text-base border'>
                                                                                {item.latitude_checkin && item.longtitude_checkin? (
                                                                                    <MapContainer
                                                                                        center={[item?.latitude_checkin, item?.longtitude_checkin]}
                                                                                        zoom={15}
                                                                                        style={{ height: '100%', width: '100%' }}
                                                                                    >
                                                                                        <TileLayer
                                                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                                            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                                                        />
                                                                                        <Marker icon={customIcon} position={[item?.latitude_checkin, item?.longtitude_checkin]}>
                                                                                            </Marker>
                                                                                    </MapContainer>
                                                                                ) : (
                                                                                    <h1 className='text-center text-[10px] font-medium'>Not Checkin Yet</h1>
                                                                                )}
                                                                                </div>

                                                                                <div className='flex gap-[9px]'>
                                                                                    <FaMapMarkerAlt className='text-[#757D8A]' />
                                                                                    <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                                                                        {locationNameCheckin ? locationNameCheckin : 'Not Checkout Yet'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <h1 className="text-gray-500 text-sm font-medium mb-3">Check out</h1>
                                                                    <div className='w-[272px]'>
                                                                        <div className="grid grid-cols-3 text-gray-500 text-xs mb-3">
                                                                            <h1 className='col-span-1'>Check out</h1>
                                                                            <h1 className='col-span-2'>: {!item.time_checkout ? '-' : moment(item.time_checkout).format('HH:mm')}</h1>
                                                                        </div>
                                                                        <div className='flex gap-[23px]'>
                                                                            <div>
                                                                                <h1 className='text-[#5C5C5C] text-[10px] font-medium '>Photo</h1>
                                                                                <div className='w-[77px] h-[76px] bg-[#EDEDED] rounded-[10px] flex items-center justify-center'>
                                                                                    <img className='object-contain' src={item.image_checkout? imageHandle(item.image_checkout) : LogoH}/>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <h1 className='text-[#5C5C5C] text-[10px] font-medium '>Location</h1>
                                                                                <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 text-base border'>
                                                                                {item.latitude_checkout && item.longtitude_checkout? (
                                                                                    <MapContainer
                                                                                        center={[item?.latitude_checkout, item?.longtitude_checkout]}
                                                                                        zoom={15}
                                                                                        style={{ height: '100%', width: '100%' }}
                                                                                    >
                                                                                        <TileLayer
                                                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                                            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                                                        />
                                                                                        <Marker icon={customIcon} position={[item?.latitude_checkout, item?.longtitude_checkout]}>
                                                                                            </Marker>
                                                                                    </MapContainer>
                                                                                ) : (
                                                                                    <h1 className='text-center text-[10px] font-medium'>Not Checkout Yet</h1>
                                                                                )}
                                                                                </div>

                                                                                <div className='flex gap-[9px]'>
                                                                                    <FaMapMarkerAlt className='text-[#757D8A]' />
                                                                                    <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                                                                        {locationNameCheckout ? locationNameCheckout : 'Not Checkout Yet'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className='space-y-3'>
                                                                    <div className='grid grid-cols-12'>
                                                                        <h1 className='col-span-3 text-gray-500 text-xs'>Status</h1>
                                                                        <h1 className='col-span-1 text-gray-500 text-xs'>:</h1>
                                                                        <h1 className='col-span-8 text-green-600 text-xs'>Present</h1>
                                                                    </div>
                                                                    <div className='grid grid-cols-12 '>
                                                                        <h1 className='col-span-3 text-gray-500 text-xs'>Duration</h1>
                                                                        <h1 className='col-span-1 text-gray-500 text-xs'>:</h1>
                                                                        <h1 className='col-span-8 text-gray-500 text-xs'>{!item.time_checkout ? '-' : duration(item.createdAt, item.updatedAt)}</h1>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            ))}
                                        </table>
                                    </div>
                                    <Pagination
                                        currentPage={currentPage} 
                                        totalPages={totalPages} 
                                        lengthData={totalCount}
                                        limitData={limit}
                                        onPageChange={handlePageChange}
                                        onPrevChange={handlePrevChange}
                                        onNextChange={handleNextChange}
                                    />
                            </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Attendance


