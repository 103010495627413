import React, { useEffect, useState } from 'react'
import { Modal, Navbar, Sidebar } from '../../../../components'
import { BsFilter } from 'react-icons/bs'
import { TbFileExport } from 'react-icons/tb'
import { BiSearch } from 'react-icons/bi'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { AiOutlineMinus } from 'react-icons/ai'
import Api from '../../../../Api'
import moment from 'moment'

const AllStage = () => {
    const [showModalExport, setShowModalExport] = useState()
    const [showFilter, setShowFilter] = useState()

    const [dataRecruitmentStage, setDataRecruitmentStage] = useState('')

    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const getRecruitmentStage = async () => {
        try {
            const response = await Api.GetRecruitmentStage(localStorage.getItem('hris-token'))
            setDataRecruitmentStage(response.data)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() =>{
        getRecruitmentStage()
    }, [])

  return (
    <div>
        <Modal 
            activeModal={showFilter}
            buttonClose={ () => setShowFilter(!showFilter)}
            width={'450px'}
            content= {
                <div className='space-y-[20px]'>
                    <div className='flex gap-[27px] items-center justify-center'>
                        <BsFilter className='text-[#282828] text-2xl'/>
                        <h1 className='text-[#282828] text-[20px] font-semibold'>Filter</h1>
                    </div>
                    <div className='text-[#5C5C5C] text-[12px] font-medium'>
                        <h1 className='mb-[6px]'>Position</h1>
                        <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Designer</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Programmer</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Manager</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Sales Marketing</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Advertiser</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Admin Inputer</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Human Resources</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Finance</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Secretary</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>General Affair</h1>
                            </div>
                        </div>
                    </div>
                    <div className='text-[#5C5C5C] text-[12px] font-medium'>
                        <h1 className='mb-[6px]'>Recuitment Stage</h1>
                        <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Administration </h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Psikotes</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Test Penerimaan </h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Pemeriksaan Referensi</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Medical Check</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Interview HR</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Interview User</h1>
                            </div>
                            <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                <h1>Interview Department</h1>
                            </div>
                        </div>
                    </div>
                    <button className='py-[10px] px-[150px] bg-[#0E5073] text-white text-[14px] font-medium flex justify-center rounded-md'>Apply Filter</button>
                </div>
            }
        />
        <Modal 
                activeModal={showModalExport}
                title={'Range Export'}
                buttonClose={ () => setShowModalExport(!showModalExport)}
                width={'421px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className='flex gap-2'>
                            <div>
                                <h1 className='text-[#A8A8A8] text-[12px] mb-[6px]'>Publish Date</h1>
                                <input type="date" className='h-[35px] py-[11px] pl-[8.7px] border rounded-md w-[174px] pr-[11px] text-[#5C5C5C] bg-[#EEEEEE80] text-[12px]' />
                            </div>
                            <div className='pt-9'>
                                <AiOutlineMinus  className='text-[#A8A8A8]'/>
                            </div>
                            <div>
                                <h1 className='text-[#A8A8A8] text-[12px] mb-[6px]'>Expired Date</h1>
                                <input type="date" className='h-[35px] py-[11px] pl-[8.7px] border rounded-md w-[174px] pr-[11px] text-[#5C5C5C] bg-[#EEEEEE80] text-[12px]' />
                            </div>
                        </div>

                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Export</button>
                        </div>
                    </div>
                }
            />
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'All Stages Recruitment'} LinkBack={'/recruitment/entry-application'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div>
                        <h1 className='text-[#454545] font-semibold text-[20px]'>All Stages  Recruitment</h1>
                        <p className='text-[#737373] text-[10px]'>List of  stage recruitment</p>
                    </div>

                    <div className="flex gap-[20px] mt-[31px] relative">
                        <input type="date" className='bg-[#F5F8FA] text-[#7E8299] py-[11.5px] text-[12px] px-[10px] font-medium' />
                        <button onClick={() => setShowFilter(!showFilter)} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                            <BsFilter className='text-[#003049]'/>
                            <h1 className='text-[#003049] text-sm font-[500]'>Filter</h1>
                        </button>
                        <button onClick={() => setShowModalExport(!showModalExport)} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                            <TbFileExport className='text-[#003049]'/>
                            <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                        </button>
                        <div className="absolute right-0">
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input placeholder='Search by Employee Name...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px] text-[#737373]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[220px] max-w-[220px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Full Name</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[220px] max-w-[220px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Position</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Apply Date</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Phone Number</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Recruitment Stage</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                </div>
                                {Object.values(dataRecruitmentStage).map((item, idx) => (
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[220px] max-w-[220px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.applicant? item.applicant.name : '-'}</h1>
                                        </div>
                                        <div className='min-w-[220px] max-w-[220px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.recruitment? item.recruitment.position : '-'}</h1>
                                        </div>
                                        <div className='min-w-[140px] max-w-[140px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item? moment(item.createdAt).format('DD-MM-YYYY') : '-'}</h1>
                                        </div>
                                        <div className='min-w-[140px] max-w-[140px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.applicant? item.applicant.phone : '-'}</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px] flex'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.stage_name? item.stage_name : '-'}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            {item.applicant.status === 'Success' ?
                                            <div className=' bg-[#BDFFD778] rounded-[9px] flex items-center text-[#00AE46] justify-center p-[10px] text-[12px] font-medium w-[71px]'>
                                                Success
                                            </div>
                                            : item.applicant.status === 'Failed' ?
                                            <div className=' bg-[#FFBDBD78] rounded-[9px] flex items-center text-[#AE0000] justify-center p-[10px] text-[12px] font-medium w-[71px]'>
                                                Failed
                                            </div>
                                            : <div className=' bg-[#FFF7B278] rounded-[9px] flex items-center text-[#AE9200] justify-center p-[10px] text-[12px] font-medium w-1/2'>
                                                On Progress
                                            </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                                {/* <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[220px] max-w-[220px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Muh Rizieq Fazlulrahman</h1>
                                    </div>
                                    <div className='min-w-[220px] max-w-[220px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Machine Learning Engineering</h1>
                                    </div>
                                    <div className='min-w-[140px] max-w-[140px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>30/11/2022</h1>
                                    </div>
                                    <div className='min-w-[140px] max-w-[140px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>081234567890</h1>
                                    </div>
                                    <div className='min-w-[120px] max-w-[120px] flex'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Administration</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <button className=' bg-[#FFBDBD78] rounded-[9px] flex items-center text-[#AE0000] justify-center p-[10px] text-[12px] font-medium w-[71px]'>
                                                Failed
                                        </button>
                                    </div>
                                </div> */}
                            </table>
                        </div>
                        <div className='flex items-center justify-between mt-[30px]'>
                            <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                            <div className='flex items-center gap-[8px]'>
                                <MdKeyboardArrowLeft className='text-[#A098AE]'/>
                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                    <h1 className='text-[#780000] text-[10px]'>1</h1>
                                </div>
                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                    <h1 className='text-white text-[10px]'>2</h1>
                                </div>
                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                    <h1 className='text-[#780000] text-[10px]'>3</h1>
                                </div>
                                <MdKeyboardArrowRight className='text-[#A098AE]'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AllStage