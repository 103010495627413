import React, { useEffect, useState } from 'react'
import { IconAdmin, IconDashboard, IconDocument, IconEmployee, IconFinance, IconLogout, IconPayroll, IconRecruitment, IconTimeManagement, LogoH } from '../../assets'
import { RiArrowRightSFill } from 'react-icons/ri';
import { MdMenuOpen } from 'react-icons/md'
import { BsArrowLeftShort } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';
import Api from '../../Api';
import imageHandle from '../../utils/imageHandle';

const Navbar = ({SubNavbar, NameSubNavbar, LinkBack}) => {

    const navigate = useNavigate()
    const [mobileMenu, setMobileMenu] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [job, setJob] = useState(false)
    const [organization, setOrganization] = useState(false)
    const [employee, setEmployee] = useState(false)
    const [configuration, setConfiguration] = useState(false)
    const [timeManagement, setTimeManagement] = useState(false)
    const [attendance, setAttendance] = useState(false)
    const [user, setUser] = useState('')
    const [role, setRole] = useState('')
    const [display, setDisplay] = useState(true)
    const [inbox, setInbox] = useState('')
    const [image, setImage] = useState('')


    const Fetch = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUser(response.data.results.user)
            setRole(response.data.results.user.role)
            setImage(response.data?.results?.user_detail?.image)
        } catch (error) {
            navigate('/login')
        }
    }

    // const getDataCompany = async() => {
    //     try {
    //         const response = await Api.GetGeneralInformation(localStorage.getItem('hris-token'))
    //         setImage(response.data.company.image)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const GetInbox = async() => {
        try {
            const response = await Api.GetInbox(localStorage.getItem('hris-token'))
            setInbox(response.data.data.filter(item => item.isRead === false))
        } catch (error) {   
            console.log(error)
        }
    }

    const Logout = async() => {
        localStorage.removeItem('hris-token')
        localStorage.removeItem('hris-role')
        navigate('/')
    }

    useEffect(() => {
        Fetch()
        GetInbox()
    },[])

    return (
        <div>
            <div className={`bg-white border shadow-sm rounded-[6px] py-[16px] px-[25px] mt-[30px] w-full flex items-center justify-between`}>
                <div className={`${ SubNavbar ? 'hidden' : 'block' } space-y-[5px]`}>
                    <h1 className='text-[#C1121F] text-sm font-semibold'>Hello, {user.fullname}</h1>
                    <h1 className='text-[#AAAAAA] text-[10px] font-[500]'>{user.role}</h1>
                </div>
                <div className={`${ SubNavbar ? 'block' : 'hidden' } flex items-center gap-[16px]`}>
                    <Link to={LinkBack}>
                        <BsArrowLeftShort className='text-[#DC1717] text-2xl'/>
                    </Link>
                    <h1 className='text-[#DC1717] text-sm font-[500]'>{NameSubNavbar}</h1>
                </div>
                <div className='hidden lg:flex items-center gap-[20px]'>
                    <Link to={'/inbox'} className={`${SubNavbar ? '' : 'border'} w-[41px] h-[41px] rounded-full flex items-center justify-center relative`}>
                        <FaEnvelope className={`${inbox.length > 0 ? 'text-[#DC1717] animate-pulse' : 'text-[#E00101] animate-none'} text-2xl relative`}/>
                    </Link>
                    {/* <button className={`${SubNavbar ? '' : 'border'} w-[41px] h-[41px] rounded-full flex items-center justify-center`}>
                        <img src={IconNotification} alt='Humanusia Notification'/>
                    </button> */}
                    <Link to='/profile'>
                        <button className={`${SubNavbar ? 'hidden' : 'block'} border w-[41px] h-[41px] rounded-full overflow-hidden`}>
                            <img src={!image ? LogoH : imageHandle(image)} className='object-cover' alt='Humanusia Logo'/>
                        </button>
                        <div className={`${SubNavbar ? 'flex items-center gap-[13px]' : 'hidden'} py-[8px] px-[18px] rounded-full`}>
                            <img src={!image ? LogoH : imageHandle(image)} className='object-cover border w-[41px] h-[41px] rounded-full' alt='Humanusia Logo'/>
                            {/* <h1 className='text-[#EDEDED] text-sm font-[500]'>{user.fullname}</h1> */}
                        </div>
                    </Link>
                </div>
                <button onClick={()=>setMobileMenu(!mobileMenu)} className='flex lg:hidden border w-[41px] h-[41px] rounded-full items-center justify-center'>
                    <MdMenuOpen className='text-2xl text-[#C1121F]'/>
                </button>
            </div>
            <div className={`${mobileMenu ? 'h-[730px] border' : 'h-0 border-0'} mt-2 bg-white rounded-[6px] w-full shadow-sm transition-all duration-1000 ease-in-out overflow-hidden`}>
                <div className='my-[16px] mx-[25px]'>
                    <div className='border-b-2 pt-[20px] pb-[30px]'>
                        <div className='flex items-center justify-center gap-[20px]'>
                            <button className='border w-[41px] h-[41px] rounded-full flex items-center justify-center'>
                                <FaEnvelope className='text-2xl text-[#A8A8A8]'/>
                            </button>
                            <button className='border w-[41px] h-[41px] rounded-full overflow-hidden'>
                                <img src={!image ? LogoH : imageHandle(image)} className='object-cover' alt='Humanusia Logo'/>
                            </button>
                        </div>
                        <h1 className='text-sm text-[#A8A8A8] hover:text-[#780000] font-[500] mt-6 text-center'>{user.fullname}</h1>
                    </div>
                    {role === 'ADMIN' ?
                        <div className={`my-[40px] h-[410px] overflow-auto scrollbar-hide flex flex-col ${display ? 'items-start' : 'items-center'}`}>
                            <Link to={'/dashboard'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconDashboard} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Dashboard</h1>
                                }
                            </Link>
                                
                            <button onClick={() => {setAdmin(!admin); setJob(false); setOrganization(false); setDisplay(true); setEmployee(false); setTimeManagement(false)}} className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} gap-[16px] text-sm ${admin ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500]`}>
                                <div className='flex items-center gap-[16px]'>
                                    <img src={IconAdmin} alt='Humanusia Icon'/>
                                    {display &&
                                        <h1>Admin</h1>
                                    }
                                </div>
                                {display && 
                                    <RiArrowRightSFill className='text-xl'/>
                                }
                            </button>
                            <div className={`${admin ? 'min-h-[240px]' : 'min-h-0'} transition-all duration-1000 ease-in-out w-full mb-[40px] pl-[39px] overflow-auto scrollbar-hide`}>
                                <Link to={'/user-management'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>User Management</h1>
                                </Link>
                                
                                <Link to={'/job-management'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Job Management</h1>
                                </Link>
                                <Link to={'/job-management/employee-status'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Employee Status</h1>
                                </Link>
                                <Link to={'/attendance-setting'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Attendance Setting</h1>
                                </Link>
                                <Link to={'/job-management/work-shift'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Work Shift</h1>
                                </Link>
                                {/* <button onClick={()=> setJob(!job)} className={`text-sm ${job ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[20px]`}>
                                    Job
                                    <RiArrowRightSFill className='text-xl'/>
                                </button>
                                <div className={`${job ? 'h-[120px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                                </div> */}
                                
                                <Link to={'/general-information'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>General Information</h1>
                                </Link>
                                <Link to={'/general-information/cost-profit'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Cost And Profit</h1>
                                </Link>


                                {/* <button onClick={ () => setOrganization(!organization) } className={`text-sm ${organization ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[20px]`}>
                                    Organization
                                    <RiArrowRightSFill className='text-xl'/>
                                </button>
                                <div className={`${organization ? 'h-[80px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                                    <Link to={''}>
                                        <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Location</h1>
                                    </Link>
                                    <Link to={'/structure'}>
                                        <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Structure</h1>
                                    </Link>
                                </div> */}

                                {/* <Link to={'/subsidiary'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Subsidiary</h1>
                                </Link> */}

                            </div>

                            <button onClick={ () => {setEmployee(!employee); setConfiguration(false); setDisplay(true); setAdmin(false); setTimeManagement(false)} } className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} gap-[16px] text-sm ${employee ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500]`}>
                                <div className='flex items-center gap-[16px]'>
                                    <img src={IconEmployee} alt='Humanusia Icon'/>
                                    {display &&
                                        <h1>Employee</h1>
                                    }
                                </div>
                                {display && 
                                    <RiArrowRightSFill className='text-xl'/>
                                }
                            </button>
                            <div className={`${employee ? 'min-h-[120px]' : 'min-h-0'} transition-all duration-1000 ease-in-out w-full mb-[40px] pl-[39px] overflow-auto scrollbar-hide`}>        
                                <Link to={'/employee/data-import'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Data Import</h1>
                                </Link>
                                <Link to={'/employee/termination'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Termination Reasons</h1>
                                </Link>

                                {/* <button onClick={()=> setConfiguration(!configuration)} className={`text-sm ${configuration ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[20px]`}>
                                    Configuration
                                    <RiArrowRightSFill className='text-xl'/>
                                </button>
                                <div className={`${configuration ? 'h-[80px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                                    <Link to={''}>
                                        <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Reporting Methods</h1>
                                    </Link>
                                </div> */}

                                <Link to={'/employee'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Employee List</h1>
                                </Link>

                                {/* <Link to={'/employee/employee-transfer'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Employee Transfer</h1>
                                </Link>
                                <Link to={'/employee/employee-report'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Report</h1>
                                </Link> */}
                            </div>
                            

                            <Link to={'/document'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconDocument} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Document</h1>
                                }
                            </Link>

                            <button onClick={ () => {setTimeManagement(!timeManagement); setAttendance(false); setDisplay(true); setAdmin(false); setEmployee(false)}} className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} text-sm ${timeManagement ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] gap-[16px]`}>
                                <div className='flex items-center gap-[16px]'>
                                    <img src={IconTimeManagement} alt='Humanusia Icon'/>
                                    {display &&
                                        <h1>Time Mgmt.</h1>
                                    }
                                </div>
                                {display && 
                                    <RiArrowRightSFill className='text-black text-xl'/>
                                }
                            </button>
                            <div className={`${timeManagement ? 'min-h-[200px]' : 'min-h-0'} transition-all duration-1000 ease-in-out w-full mb-[40px] pl-[39px] overflow-auto scrollbar-hide`}>        
                                {/* <button onClick={()=> setAttendance(!attendance)} className={`${attendance ? 'text-[#780000]' : 'text-black'} text-sm hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[20px]`}>
                                    Attendance
                                    <RiArrowRightSFill className='text-xl'/>
                                </button>
                                <div className={`${attendance ? 'h-[80px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                                </div> */}
                                <Link to={'/time-management/my-attendance'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>My Attendance</h1>
                                </Link>
                                <Link to={'/time-management/employee-record'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Employee Records</h1>
                                </Link>

                                <Link to={'/time-management/time-of'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Time Of</h1>
                                </Link>
                                <Link to={'/time-management/schedule'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Schedule</h1>
                                </Link>
                                <Link to={'/time-management/calendar'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Calendar</h1>
                                </Link>
                            </div>

                            <Link to={'/payroll'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconPayroll} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Payroll</h1>
                                }
                            </Link>

                            <Link to={'/finance'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconFinance} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Finance</h1>
                                }
                            </Link>

                            <Link to={'/recruitment'} className='flex items-center gap-[16px]'>
                                <img src={IconRecruitment} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Recruitment</h1>
                                }
                            </Link>

                            {/* <Link to={'/myprofile'} className='flex items-center gap-[16px]'>
                                <img src={IconRecruitment} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>My Profile</h1>
                                }
                            </Link> */}
                        </div>
                        :
                        <div className={`my-[40px] h-[410px] overflow-auto scrollbar-hide flex flex-col ${display ? 'items-start' : 'items-center'}`}>
                            <Link to={'/dashboard'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconDashboard} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Dashboard</h1>
                                }
                            </Link>

                            {/* <Link to={'/document'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconDocument} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Document</h1>
                                }
                            </Link> */}

                            <button onClick={ () => {setTimeManagement(!timeManagement); setAttendance(false); setDisplay(true); setAdmin(false); setEmployee(false)}} className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} text-sm ${timeManagement ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] gap-[16px]`}>
                                <div className='flex items-center gap-[16px]'>
                                    <img src={IconTimeManagement} alt='Humanusia Icon'/>
                                    {display &&
                                        <h1>Time Mgmt.</h1>
                                    }
                                </div>
                                {display && 
                                    <RiArrowRightSFill className='text-black text-xl'/>
                                }
                            </button>
                            <div className={`${timeManagement ? 'h-[160px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full mb-[40px] pl-[39px] overflow-auto scrollbar-hide`}>        
                                <button onClick={()=> setAttendance(!attendance)} className={`${attendance ? 'text-[#780000]' : 'text-black'} text-sm hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[20px]`}>
                                    Attendance
                                    <RiArrowRightSFill className='text-xl'/>
                                </button>
                                <div className={`${attendance ? 'h-[45px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                                    <Link to={'/time-management/my-attendance'}>
                                        <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>My Attendance</h1>
                                    </Link>
                                </div>
                                <Link to={'/time-management/time-of'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Time Of</h1>
                                </Link>
                                <Link to={'/time-management/schedule'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Schedule</h1>
                                </Link>
                                <Link to={'/time-management/calendar'}>
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500] mt-[20px]'>Calendar</h1>
                                </Link>
                            </div>

                            <Link to={'/finance'} className='flex items-center gap-[16px] mb-[40px]'>
                                <img src={IconFinance} alt='Humanusia Icon'/>
                                {display &&
                                    <h1 className='text-sm text-black hover:text-[#780000] font-[500]'>Finance</h1>
                                }
                            </Link>
                        </div>
                    }

                    <div className={`border-t-2 w-full pt-[40px] flex ${display ? 'justify-start' : 'justify-center'}`}>
                        <button onClick={Logout} className='flex items-center gap-[16px]'>
                            <img src={IconLogout} alt='Humanusia Icon'/>
                            {display &&
                                <h1 className='text-sm text-[#780000] font-[500]'>Sign Out</h1>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar