import React from 'react'
import { Navbar } from '../../../../components'
import { IoMdArrowDropdown } from 'react-icons/io'
import { FiSearch } from 'react-icons/fi'
import { MdImportExport } from 'react-icons/md'
import { CgTrashEmpty } from 'react-icons/cg'

const MassResign = () => {
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Mass Resign'} LinkBack={'/dashboard'}/>
                    <h1 className="text-zinc-800 text-xl font-semibold">Mass Resign Employee</h1>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                          <h1 className="text-zinc-700 text-xl font-semibold">List of Mass Resign</h1>
                          <h1 className="text-gray-500 text-sm font-normal">List of  employee in the company</h1>
                        </div>
                        <div className='flex items-end gap-[20px]'>
                          <div className='w-[227px]'>
                              <h1 className='text-gray-500 text-xs font-[500] mb-[7px]'>Direct To<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                              <div className='relative'>
                                  <select className='bg-white border rounded-[6px] text-gray-500 text-xs  px-[8px] py-[10px] w-full appearance-none'>
                                      <option selected value={''}>Select Reason...</option>
                                      <option value={'Anggota Ombudsman'}>Anggota Ombudsman</option>
                                      <option value={'Kepala Perwakilan'}>Kepala Perwakilan</option>
                                      <option value={'Kepala Keasistenan Utama'}>Kepala Keasistenan Utama</option>
                                      <option value={'Sekretaris Jenderal'}>Sekretaris Jenderal</option>
                                      <option value={'Asisten Kantor Perwakilan'}>Asisten Kantor Perwakilan</option>
                                      <option value={'Asisten Pusat'}>Asisten Pusat</option>
                                      <option value={'PNS'}>PNS</option>
                                      <option value={'Tenaga Pendukung'}>Tenaga Pendukung</option>
                                  </select>
                                  <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#737373] text-xl'/>
                              </div>
                          </div>
                          <input type="date" className='px-[10px] py-[8px] rounded-lg bg-[#EDEEF1] text-[#8E95A2]' />
                        </div>

                        <button className='flex items-center justify-center py-[8.5px] px-[30px] rounded-lg bg-[#015995] text-white font-medium'>Add Employee</button>

                        <div className="flex items-center justify-end">
                          <div className='relative'>
                              <input placeholder='Search ...' className='bg-white border rounded-[6px] text-[#A8A8A8] text-sm font-medium pl-10 w-full lg:w-[300px] flex items-center gap-[12px] px-[10px] py-[8px]'/>
                              <FiSearch className='text-[#A8A8A8] absolute top-[11px] left-3'/>
                          </div>
                        </div>

                        <div>
                                <div className='overflow-auto scrollbar-hide'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Employee</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Job Position</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Resign Date</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Merit Pay</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Reason</h1>
                                            </div>
                                            <div className='flex items-center justify-center gap-[15px] w-full'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Action</h1>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>data</h1>
                                            </div>
                                            <div className='min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>data</h1>
                                            </div>
                                            <div className='min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>data</h1>
                                            </div>
                                            <div className='min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>data</h1>
                                            </div>
                                            <div className='min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>data</h1>
                                            </div>
                                            <div className='w-full flex items-center justify-center gap-[12px]'>
                                                <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]'/>
                                                </button>
                                            </div>
                                        </div>
                                    </table>
                                </div>
                                {/* <Pagination 
                                    currentPage={currentPage} 
                                    totalPages={totalPages} 
                                    onPageChange={handlePageChange}
                                    onPrevChange={handlePrevChange}
                                    onNextChange={handleNextChange}
                                /> */}
                            </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default MassResign