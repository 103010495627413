import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ExampleNews, LogoH } from '../../../assets'
import { DCardEmploye, DCardNews, Modal, Navbar, TinyMce } from '../../../components'
import { IoSend } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import Api from '../../../Api'
import imageHandle from '../../../utils/imageHandle'
import { toast } from 'react-hot-toast'
import { BsThreeDotsVertical } from 'react-icons/bs'

const Dashboard = () => {
    const [dataNews, setDataNews] = useState('')
    const [textForum, setTextForum] = useState('')
    const [getForum, setGetForum] = useState('')
    const [getStatistict, setGetStatistict] = useState('')
    const [getBirthdate, setGetBirthdate] = useState('')
    const [getNewEmployee, setGetNewEmployee] = useState('')
    const [appliedEmployee, setAppliedEmployee] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [image, setImage] = useState('')
    const [imageProfile, setImageProfile] = useState('')
    const [seeMoreForum, setSeeMoreForum] = useState(false)
    const [modalAddAnnouncement, setModalAddAnnouncement] = useState()
    const [announcement, setAnnouncement] = useState()
    const [triggerOption, setTriggerOption] = useState({})


    // const Fetch = async () => {
    //     try {
    //       const response = await Api.Fetch(localStorage.getItem('hris-token'))
    //     } catch (error) {
    //       console.log(error)
    //     }
    // }


    const getData = async() => {
        try {
            const resNews = await Api.GetNews(localStorage.getItem('hris-token'), '', '', '')
            // const resForum = await Api.GetForum(localStorage.getItem('hris-token'))
            const resStat = await Api.GetStatistict(localStorage.getItem('hris-token'))
            const resBirth = await Api.GetBirthdateByMonth(localStorage.getItem('hris-token'))
            const resNewEmp = await Api.GetNewEmployee(localStorage.getItem('hris-token'))
            const resAppliedEmployee = await Api.GetRecruitmentApplicant(localStorage.getItem('hris-token'))
            setGetBirthdate(resBirth.data.results.data)
            setGetStatistict(resStat.data)
            // setGetForum(resForum.data.data)
            setDataNews(resNews.data.news)
            setGetNewEmployee(resNewEmp.data)
            setAppliedEmployee(resAppliedEmployee.data.entryAplicant)
        } catch (error) {
            console.log(error)
        }
    }

    const postForum = async() => {
        try {
            const data = {
                text : textForum
            }
            const response = await Api.PostForum(localStorage.getItem('hris-token'), data)
            toast.success('Success Post Forum')
            setRefresh(true)
        } catch (error) {
            console.log(error)
            toast.success(error)
        }
    }

    const getContentTinyMce = (data) => {
        setAnnouncement(data);
    };

    const [dataAnnouncement, setDataAnnouncement] = useState('')
    const handlePopUpOption = (itemId) => {
        setTriggerOption((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };
    const getAnnouncement = async () => {
        try {
            const response = await Api.GetAnnouncement(localStorage.getItem('hris-token'))
            setDataAnnouncement(response.data.announcements)
            console.log(response, 'announcement')
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getData()
        getAnnouncement()
        setRefresh(false)
    }, [refresh])

    return (
        <div className='relative'>
            <Modal
                activeModal={modalAddAnnouncement}
                title={'Create Announcement'}
                buttonClose={ () => setModalAddAnnouncement(!modalAddAnnouncement)}
                width={'850px'}
                content= {
                    <div className='space-y-6'>
                        <TinyMce
                        getContentTinyMce={getContentTinyMce}
                        setDataTinyMce={announcement}
                        />
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={() => setModalAddAnnouncement(!modalAddAnnouncement)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button  className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden '>
                <Navbar/>
                <div className='flex items-center gap-[15px] overflow-auto '>
                    <DCardEmploye
                        CardName={'Total Employees'}
                        Value={getStatistict.total_employee}
                    />
                    <DCardEmploye
                        CardName={'New Employees'}
                        Value={getNewEmployee.totalEmployeeNew}
                    />
                    <DCardEmploye
                        CardName={'Entry Applicant'}
                        Value={appliedEmployee}
                    />
                    <DCardEmploye
                        CardName={'Resigned Employees'}
                        Value={'0'}
                    />
                </div>
                <div className='space-y-[24px] '>
                    <div className='flex items-center justify-between'>
                        <h1 className='text-[#454545] font-[600]'>Company News</h1>
                        {localStorage.getItem('hris-role') === 'ADMIN' &&
                            <Link to={'/news'} className='text-[#A8A8A8] text-[13px] font-[500] hover:text-[#780000]'>See More</Link>
                        }
                    </div>
                    {dataNews.length === 0 ?
                        <div className='h-52 flex items-center justify-center border rounded-[12px]'>
                            <h1 className='text-[#737373] text-sm'>News is empty, What do you want to convey ?</h1>
                        </div>
                    :
                        <div className='flex items-center gap-[15px] overflow-auto scrollbar-hide'>
                            {Object.values(dataNews).map((data, index) => {
                                return (
                                    <DCardNews
                                        key={index}
                                        id={data.id}
                                        NewsTitle={data.title}
                                        NewsDate={moment(data.createdAt).format('MMMM DD, YYYY')}
                                        image={!data.image ? ExampleNews : imageHandle(data.image)}
                                    />
                                )
                            })}
                        </div>
                    }
                </div>
                <div className='flex flex-col lg:grid grid-cols-12 gap-[20px] pb-[232px]'>
                    <div className='col-span-9 space-y-[24px] order-2 lg:order-1'>
                        <div className='bg-white border shadow-sm rounded-[12px] py-[23px] px-[21px] flex items-center gap-[20px]'>
                            <img src={image? imageHandle(image): LogoH} className='w-[34px] h-[34px] rounded-full shadow-sm border object-cover' alt='Humanusia Logo'/>
                            <input onChange={ (e) => setTextForum(e.target.value) } placeholder='What do you want to post...' className='text-xs bg-white rounded-[6px] px-[17px] border w-full py-[10px]'/>
                            <button onClick={postForum} className='py-[7px] px-[15px] rounded-[6px] bg-[#003049] flex items-center gap-[10px]'>
                                <IoSend className='text-white'/>
                                <h1 className='hidden lg:block text-white text-[13px] font-semibold'>Send</h1>
                            </button>
                        </div>

                        <div className='flex border rounded-xl w-full justify-center'>
                            <Link to={'history-announcement'} className='flex gap-[10px] items-center justify-center py-[11px] px-[147px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8857 4.08203V7.08203H21.8857C22.151 7.08203 22.4053 7.18739 22.5928 7.37492C22.7804 7.56246 22.8857 7.81681 22.8857 8.08203V18.082C22.8857 18.8777 22.5697 19.6407 22.0071 20.2034C21.4445 20.766 20.6814 21.082 19.8857 21.082H5.88574C5.09009 21.082 4.32703 20.766 3.76442 20.2034C3.20181 19.6407 2.88574 18.8777 2.88574 18.082V4.08203C2.88574 3.81681 2.9911 3.56246 3.17864 3.37492C3.36617 3.18739 3.62053 3.08203 3.88574 3.08203H17.8857C18.151 3.08203 18.4053 3.18739 18.5928 3.37492C18.7804 3.56246 18.8857 3.81681 18.8857 4.08203ZM20.8857 18.082C20.8857 18.3472 20.7804 18.6016 20.5928 18.7891C20.4053 18.9767 20.151 19.082 19.8857 19.082C19.6205 19.082 19.3662 18.9767 19.1786 18.7891C18.9911 18.6016 18.8857 18.3472 18.8857 18.082V9.08203H20.8857V18.082ZM6.88574 8.08203C6.88574 7.81681 6.9911 7.56246 7.17864 7.37492C7.36617 7.18739 7.62053 7.08203 7.88574 7.08203H13.8857C14.151 7.08203 14.4053 7.18739 14.5928 7.37492C14.7804 7.56246 14.8857 7.81681 14.8857 8.08203C14.8857 8.34725 14.7804 8.6016 14.5928 8.78914C14.4053 8.97667 14.151 9.08203 13.8857 9.08203H7.88574C7.62053 9.08203 7.36617 8.97667 7.17864 8.78914C6.9911 8.6016 6.88574 8.34725 6.88574 8.08203ZM8.88574 12.082C8.88574 11.8168 8.9911 11.5625 9.17864 11.3749C9.36617 11.1874 9.62053 11.082 9.88574 11.082H13.8857C14.151 11.082 14.4053 11.1874 14.5928 11.3749C14.7804 11.5625 14.8857 11.8168 14.8857 12.082C14.8857 12.3472 14.7804 12.6016 14.5928 12.7891C14.4053 12.9767 14.151 13.082 13.8857 13.082H9.88574C9.62053 13.082 9.36617 12.9767 9.17864 12.7891C8.9911 12.6016 8.88574 12.3472 8.88574 12.082Z" fill="#C1121F"/>
                                </svg>
                                <button className="text-zinc-700 text-sm font-medium ">History Announcement</button>
                            </Link>
                            <Link to={'polling'} className='flex gap-[10px] items-center justify-center py-[11px] border-l px-[147px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M22.1276 14.5537L22.1486 14.6157L22.1716 14.7157L22.1816 14.8157V21.3316C22.1815 21.5132 22.1156 21.6885 21.9961 21.8252C21.8766 21.9618 21.7115 22.0504 21.5316 22.0746L21.4316 22.0816H2.93262C2.75138 22.0816 2.57628 22.016 2.43969 21.8969C2.3031 21.7778 2.21427 21.6132 2.18962 21.4336L2.18262 21.3316V14.8317L2.18462 14.7797L2.19462 14.6937C2.20477 14.6411 2.22052 14.5898 2.24162 14.5407L5.00162 8.52165C5.05368 8.40787 5.13338 8.30892 5.23346 8.23381C5.33354 8.15871 5.45082 8.10984 5.57462 8.09165L5.68262 8.08465L8.22262 8.08365L7.43262 9.45365L7.36562 9.58365H6.16262L4.10062 14.0817H20.2526L18.2256 9.73565L19.0876 8.23865C19.1546 8.28865 19.2126 8.35165 19.2596 8.42265L19.3126 8.51765L22.1276 14.5537ZM13.5476 2.13765L13.6396 2.18265L18.8276 5.18565C19.1556 5.37565 19.2856 5.77665 19.1466 6.11865L19.1016 6.21065L16.2946 11.0817H17.4326C17.624 11.0797 17.8089 11.151 17.9494 11.2809C18.0899 11.4108 18.1755 11.5896 18.1885 11.7805C18.2015 11.9715 18.1411 12.1602 18.0195 12.308C17.898 12.4558 17.7245 12.5516 17.5346 12.5757L17.4326 12.5827L15.4306 12.5817V12.5847H11.3516L11.3486 12.5817H6.93262C6.74378 12.5799 6.56256 12.5069 6.42516 12.3774C6.28775 12.2479 6.20427 12.0712 6.19139 11.8828C6.17851 11.6944 6.23718 11.5081 6.35568 11.3611C6.47417 11.214 6.64378 11.1171 6.83062 11.0897L6.93262 11.0827L8.75562 11.0817L8.57362 10.9767C8.41716 10.8855 8.29885 10.7409 8.24044 10.5695C8.18202 10.3981 8.18741 10.2114 8.25562 10.0437L8.29962 9.95165L12.6166 2.45565C12.8066 2.12665 13.2066 1.99565 13.5476 2.13565V2.13765ZM13.5376 3.85765L9.97162 10.0517L11.7496 11.0817H14.5666L17.4316 6.10865L13.5396 3.85565L13.5376 3.85765Z" fill="#003049"/>
                                </svg>
                                <div className="text-zinc-700 text-sm font-medium">Polling</div>
                            </Link>
                        </div>

                        <div className='space-y-[12px]'>
                            {Object.values(dataAnnouncement).map((item, idx) => (
                                <div className='py-[28px] px-[36px] border rounded-xl shadow'>
                                    <div className='flex items-center justify-between mb-[16px]'>
                                        <div className='flex items-center gap-[20px] w-full'>
                                            <img className="w-[38.24px] h-[41.13px] rounded-full shadow" src={item.user.user_detail.image? imageHandle(item.user.user_detail.image) : LogoH} />
                                            <div className='flex items-center justify-between w-full'>
                                                <div>
                                                    <h1 className="text-zinc-700 text-sm font-semibold">{item.user.fullname?? '-'}</h1>
                                                    <h1 className="text-gray-500 text-xs font-semibold">{item.user.user_detail.job_position? item.user.user_detail.job_position.position_name : '-'}</h1>
                                                </div>
                                                <h1 className="text-gray-400 text-[11px] font-light">{moment(item.createdAt).format('DD-MM-YYYY')} ; {moment(item.createdAt).format('HH:MM')}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-zinc-700 text-sm font-normal" dangerouslySetInnerHTML={{__html: item?.description}}></div>
                                </div>
                            ))}
                        </div>
                        
                        {seeMoreForum === false ?
                            Object.values(getForum.slice(0,5)).map((data, index) => {
                                return (
                                    <div key={index} className='bg-white border shadow-sm rounded-[12px] py-[23px] px-[21px] space-y-[14px]'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex items-center gap-[17px]'>
                                                <img src={data.user.user_detail.image? imageHandle(data.user.user_detail.image) : LogoH} className='w-[34px] h-[34px] rounded-full shadow-sm border object-cover' alt='Humanusia Logo'/>
                                                <div className='space-y-1'>
                                                    <h1 className='text-[#333333] text-sm font-semibold'>{data?.user?.fullname}</h1>
                                                    <h1 className='text-[#767676] text-xs font-semibold'>{data?.user?.role}</h1>
                                                </div>
                                            </div>
                                            <h1 className='text-[#767676] text-[9px] font-[300]'>{moment().format('DD MMMM YYYY hh.mm A')}</h1>
                                        </div>
                                        <h1 className='text-[#737373] text-sm'>{data.text}</h1>
                                    </div>
                                )
                            })
                    
                        : 
                            Object.values(getForum).map((data, index) => {
                                return (
                                    <div key={index} className='bg-white border shadow-sm rounded-[12px] py-[23px] px-[21px] space-y-[14px]'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex items-center gap-[17px]'>
                                                <img src={data.user.user_detail.image? imageHandle(data.user.user_detail.image) : LogoH} className='w-[34px] h-[34px] rounded-full shadow-sm border object-cover' alt='Humanusia Logo'/>
                                                <div className='space-y-1'>
                                                    <h1 className='text-[#333333] text-sm font-semibold'>{data?.user?.fullname}</h1>
                                                    <h1 className='text-[#767676] text-xs font-semibold'>{data?.user?.role}</h1>
                                                </div>
                                            </div>
                                            <h1 className='text-[#767676] text-[9px] font-[300]'>{moment().format('DD MMMM YYYY hh.mm A')}</h1>
                                        </div>
                                        <h1 className='text-[#737373] text-sm'>{data.text}</h1>
                                    </div>
                                )
                            })
                        }
                        <div className='flex justify-center items-center px-3'>
                            <button className='text-[#A8A8A8] text-[16px] font-[500] hover:text-[#780000]' onClick={() => setSeeMoreForum(!seeMoreForum)}>{seeMoreForum === false && getForum.length < 6 ? '' : seeMoreForum === false && getForum.length > 5 ? 'Load More...' :  'Show Less...'}</button>
                        </div>
                    </div>
                    <div className='col-span-3 order-1 lg:order-2'>
                        <div className='bg-white border shadow-sm rounded-[12px] p-[21px]'>
                            <h1 className='text-[#454545] text-[13px] font-semibold'>Who’s Birthday In this Month</h1>
                            {Object.values(getBirthdate).map((data, index) => {
                                return (
                                    <div key={index} className='mt-[16px] space-y-[17px]'>
                                        <h1 className='text-[#A8A8A8] text-[10px] font-[500] uppercase'>{moment(data.user_detail.birthdate).format('MMMM')}</h1>
                                        <div className='flex items-center gap-[14px]'>
                                            <img src={data.user_detail.image? imageHandle(data.user_detail.image) : LogoH} className='w-[33px] h-[33px] rounded-[3px] shadow-sm border' alt='Humanusia Logo'/>
                                            <div className='space-y-1'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>{data.fullname}</h1>
                                                <h1 className='text-[#CACACA] text-[10px] font-[500]'>{data.role}</h1>
                                            </div>
                                        </div>  
                                    </div>
                                )
                            })}
                        </div>  
                    </div>

                </div>
                
            </div>
        </div>
    )
}

export default Dashboard