import React, { useState } from 'react'
import { Navbar } from '../../../../components'
import Leave from '../Leave'
import Permission from '../Permission'
import Overtime from '../Overtime'

const TimeOf = () => {

    const [menu, setMenu] = useState('Leave')

    return (
        <div>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Time Of'} LinkBack={'/dashboard'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border w-full'>
                        <div className='border-b-2 flex items-center text-[16px]'>
                            <button onClick={ () => setMenu('Leave') } className={`${menu === 'Leave' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Leave</button>
                            <button onClick={ () => setMenu('Permission') } className={`${menu === 'Permission' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Permission</button>
                            <button onClick={ () => setMenu('Overtime') } className={`${menu === 'Overtime' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Overtime</button>
                        </div>

                        {menu === 'Leave' ? 
                        (
                            <Leave />
                        ) : menu === 'Permission' ? (
                            <Permission />
                        ) : menu === 'Overtime' ?(
                            <Overtime />
                        ) : null}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeOf