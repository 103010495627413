import React, { useEffect, useState } from 'react'
import { Modal, Navbar, Pagination, Sidebar } from '../../../../components'
import { IoMdArrowDropdown } from 'react-icons/io'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { TbFileExport } from 'react-icons/tb'
import { BiSearch } from 'react-icons/bi'
import { MdImportExport} from 'react-icons/md'
import { FiEye } from 'react-icons/fi'
import Api from '../../../../Api'
import moment from 'moment'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { debounce } from 'lodash'
import { LogoH } from '../../../../assets'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import imageHandle from '../../../../utils/imageHandle'
import { toast } from 'react-hot-toast'
import axios from 'axios'


const EmployeeRecord = () => {
    const [showAddCheckIn, setShowAddCheckIn] = useState()
    const [showDetailAttendance, setShowDetailAttendance] = useState(false)
    const [data, setData] = useState('')
    const [dataExport, setDataExport] = useState('')
    const [dataEmployee, setDataEmployee] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [refresh, setRefresh] = useState(false)

    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [dateSearch, setDateSearch] = useState('')

    const [detailData, setDetailData] = useState('')
    const [idUser, setIdUser] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const duration = (endTime, startTime) => {
        const format = "YYYY-MM-DD HH:mm:ss"; // Include the date in the format as well
      
        const ms = moment(endTime, format).diff(moment(startTime, format));
        const d = moment.duration(ms).abs(); // Take the absolute value of the duration
        const h = d.hours();
        const m = d.minutes();
        const s = d.seconds();
      
        if (h !== 0) {
          return `${h} h ${m} m`;
        } else {
          return `${m} m`;
        }
    };

    const getRecordAttendance = async () => {
        try{
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
            setData(response.data.response)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)

            console.log(response, 'record attendace')
        } catch (error) {
            console.log(error)
        }
    }

    const getRecordAttendanceForExport = async () => {
        try{
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), '', '', '', '')
            setDataExport(response.data.response)
        } catch (error) {
            console.log(error)
        }
    }

    const [user, setUser] = useState('')

    const Fetch = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUser(response.data.results.data.fullname)
            console.log(response, 'fetch')
        } catch (error) {
            console.log(error)
        }
    }


    

    const openDetailAttendanceRecord = async (id) => {
        setShowDetailAttendance(!showDetailAttendance)
        try {
            const response = await Api.GetAttendanceRecordById(localStorage.getItem('hris-token'), id)
            setDetailData(response.data)
            setIdUser(response.data.id)
            console.log(response, 'detailOpen')
        } catch (error) {
            console.log(error)
        }
    }

    const debouncedSearchName = debounce(async(name) => {
        try {
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), dateSearch, name, currentPage, limit)
            setData(response.data.response)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)

        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }
    
    const debouncedSearchDate = debounce(async(date) => {
        try {
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), date, nameSearch, currentPage, limit)
            setData(response.data.response)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)

        } catch (error) {
            console.log(error)
        }
    }, 300)

    const debouncedLimit = debounce(async(limit) => {
        setLimit(limit)
        try {
            const response = await Api.GetAttendanceRecord(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
            setData(response.data.response)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)
            console.log(response, 'limit')
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleLimit= (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimit(limit)
    }

    const handleSearchDate = (e) => {
        const searchDate = e.target.value
        debouncedSearchDate(searchDate)
    }

    const getEmployee = async () => {
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'))
            setDataEmployee(response.data.data.filter(item => item.role !== 'ADMIN'))
        } catch (error) {
            console.log(error)
        }
    }



    // Post Check In
    const [fullname, setFullname] = useState('')
    const [note, setNote] = useState('')
    const [time, setTime] = useState('')

    const postCheckin = async () => {
        try{
            const data ={
                fullname: fullname,
                note: note,
                time: time,
            }
            const resonse = await Api.PostMyAttendanceCheckIn(localStorage.getItem('hris-token'), data)
            setShowAddCheckIn(!showAddCheckIn)
            setRefresh(true)
        } catch(error){
            console.log(error)
        }
    }

    const CheckOutDataAttendanceRecord = async (id) =>{
        // setShowAddCheckIn(!showAddCheckIn)
        try{
            const data = {
                userId: id,
            }
            console.log(data, 'dataPosted')
            const response = await Api.PostMyAttendanceCheckOut(localStorage.getItem('hris-token'), data, id)
            toast.success('Checkout success!')
            setRefresh(true)
        } catch (error){
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    // Export Employee
    const exportToExcel = () => {
        // Sample data array
        const dataEmployeeRecord = dataExport;

        // Define custom headers for each table
        const Headers = ['Employee Name', 'Date', 'Check In', 'Location Check In', 'Check Out', 'Location Check Out', 'Duration'];

        // Create modified data arrays with custom headers
        const jobGrade = dataEmployeeRecord.map(({ user, createdAt, time_checkin, time_checkout, latitude_checkin, longtitude_checkin, latitude_checkout, longtitude_checkout }) => ({
            'Employee Name': user ? user.fullname : '-',
            'Date': moment(createdAt).format('DD MMMM YYYY'),
            'Check In': time_checkin ? moment(time_checkin).format('hh:mm') : '-',
            'Location Check In': latitude_checkin && longtitude_checkin ? latitude_checkin + ',' + longtitude_checkin : '-',
            'Check Out': time_checkout ? moment(time_checkout).format('hh:mm') : '-',
            'Location Check Out': latitude_checkout && longtitude_checkout ? latitude_checkout + ',' + longtitude_checkout : '-',
            'Duration': time_checkin && time_checkout ? duration(time_checkin, time_checkout) : '-'
        }));

        // Create a new worksheet for each table
        const worksheetGrade = XLSX.utils.json_to_sheet(jobGrade, { header: Headers });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Employee Records');
        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, 'Employee Record.xlsx');
    };

    const customIcon = new L.icon({
    iconUrl: LogoH ,
    iconSize: [17, 17],
    });

      const [locationNameCheckout, setLocationNameCheckout] = useState('');
      const [locationNameCheckin, setLocationNameCheckin] = useState('');
    
      const getLocationNameCheckin = () => {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${detailData.latitude_checkin}&lon=${detailData.longtitude_checkin}`
          )
          .then((response) => {
            const locationNameCheckin = response.data.display_name;
            setLocationNameCheckin(locationNameCheckin);
          })
          .catch((error) => {
          });
      };

      const getLocationNameCheckout = () => {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${detailData.latitude_checkout}&lon=${detailData.longtitude_checkout}`
          )
          .then((response) => {
            const locationNameCheckout = response.data.display_name;
            setLocationNameCheckout(locationNameCheckout);
          })
          .catch((error) => {
          });
      };

    useEffect(() => {
        getLocationNameCheckout()
        getLocationNameCheckin()
    } , [detailData]);

    useEffect(() => {
        getRecordAttendance()
        getRecordAttendanceForExport()
        getEmployee()
        Fetch()
        setRefresh(false)
    }, [refresh, limit])
    
  return (
    <div>
        <Modal 
            activeModal={showAddCheckIn}
            title={'Manual Check In'}
            buttonClose={ () => setShowAddCheckIn(!showAddCheckIn)}
            width={'615px'}
            content= {
                <div className='space-y-[20px] w-[full]'>
                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Employee Name <span className='text-[#780000]'>*</span></h2>
                        <div className='relative'>
                            <select onChange={(e) => setFullname(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                <option >Select Employee</option>
                                {Object.values(dataEmployee).map((item) =>(
                                    <option value={item.fullname} >{item.fullname}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl text-[#A8A8A8]'/>
                        </div>
                    </div>
                    <div className='relative'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Time</h1>
                        <input type="time" onChange={(e) => setTime(e.target.value)} className='py-[10px] border rounded-md  pl-[13px] text-[12px] text-[#737373] w-full appearance-none pr-5' placeholder='Your License name...'/>
                        
                    </div>
                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Note</h1>
                        <textarea onChange={(e) => setNote(e.target.value)} rows={4} placeholder="Note check in here..." className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full resize-none'/>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postCheckin} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showDetailAttendance}
            title={'Detail Attendance'}
            buttonClose={ () => setShowDetailAttendance(!showDetailAttendance)}
            width={'715px'}
            content= {
                <div className='space-y-[20px] w-[full]'>
                    <div className='bg-[#F8F8F8] w-[623px] rounded-2xl mb-[30px]'>
                        <div className='py-[20px] px-[20px]'>
                            <h1 className=' text-[#5C5C5C] text-[13px] font-semibold mb-[22px]'>Attendance Information</h1>
                            <div className='text-[11px] text-[#737373] font-semibold space-y-2'>
                                <div className='grid grid-cols-12'>
                                    <div className='col-span-2'>
                                        <h1>Employee Name</h1>
                                    </div>
                                    <div className='col-span-10'>
                                        <h1>: {detailData.user?.fullname}</h1>
                                    </div>
                                </div>
                                <div className='grid grid-cols-12'>
                                    <div className='col-span-2'>
                                        <h1>Company Location</h1>
                                    </div>
                                    <div className='col-span-10'>
                                        <h1>: {detailData.company?.address}</h1>
                                    </div>
                                </div>
                                <div className='grid grid-cols-12'>
                                    <div className='col-span-2'>
                                        <h1>Duration</h1>
                                    </div>
                                    <div className='col-span-10'>
                                        <h1>: {detailData.time_checkin && detailData.time_checkout ? duration(detailData.time_checkin, detailData.time_checkout) : '-'}</h1>
                                    </div>
                                </div>
                                <div className='grid grid-cols-12'>
                                    <div className='col-span-2'>
                                        <h1>Work Shift</h1>
                                    </div>
                                    <div className='col-span-10'>
                                        <h1>: -</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        <div>
                            <div className='flex text-[14px] text-[#5C5C5C] font-semibold gap-[43px] mb-[11px]'>
                                <h1>Check in</h1>
                                <h2>{moment(detailData.time_checkin).format('hh:mm')}</h2>
                            </div>
                            <div className='flex gap-[23px]'>
                                <div>
                                    <h1 className='text-[#5C5C5C] text-[10px] font-medium mb-[1px]'>Photo</h1>
                                    <div className='w-[77px] h-[76px] bg-[#EDEDED] rounded-[10px] flex items-center justify-center'>
                                        <img className='object-contain' src={detailData.image_checkin? imageHandle(detailData.image_checkin) : LogoH}/>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='text-[#5C5C5C] text-[10px] font-medium mb-[1px]'>Location</h1>
                                    <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 text-base border'>
                                    {detailData.latitude_checkin && detailData.longtitude_checkin? (
                                        <MapContainer
                                            center={[detailData?.latitude_checkin, detailData?.longtitude_checkin]}
                                            zoom={15}
                                            style={{ height: '100%', width: '100%' }}
                                        >
                                            <TileLayer
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                            />
                                            <Marker icon={customIcon} position={[detailData?.latitude_checkin, detailData?.longtitude_checkin]}>
                                                </Marker>
                                        </MapContainer>
                                    ) : (
                                        <h1 className='text-center text-[10px] font-medium'>Not Checkin Yet</h1>
                                    )}
                                    </div>

                                    <div className='flex gap-[9px]'>
                                        <FaMapMarkerAlt className='text-[#757D8A]' />
                                        <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                            {locationNameCheckin ? locationNameCheckin : 'Not Checkout Yet'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex text-[14px] text-[#5C5C5C] font-semibold gap-[43px] mb-[11px]'>
                                <h1>Check Out</h1>
                                <h2>{detailData.time_checkout? moment(detailData.time_checkout).format('hh:mm') : '-'}</h2>

                            </div>
                            <div className='flex gap-[23px]'>
                                <div>
                                    <h1 className='text-[#5C5C5C] text-[10px] font-medium mb-[1px]'>Photo</h1>
                                    <div className='w-[77px] h-[76px] bg-[#EDEDED] rounded-[10px] flex items-center justify-center'>
                                    <img className='object-contain' src={detailData.image_checkout? imageHandle(detailData.image_checkout) : LogoH}/>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='text-[#5C5C5C] text-[10px] font-medium mb-[1px]'>Location</h1>
                                    <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 text-base border'>
                                        {detailData.latitude_checkout && detailData.longtitude_checkout ? (
                                        <MapContainer
                                            center={[detailData.latitude_checkout, detailData.longtitude_checkout]}
                                            zoom={15}
                                            style={{ height: '100%', width: '100%' }}
                                        >
                                            <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                            />
                                            <Marker icon={customIcon} position={[detailData.latitude_checkout, detailData.longtitude_checkout]}>
                                            </Marker>
                                        </MapContainer>
                                        ) : (
                                        <h1 className='text-center text-[10px] font-medium'>Not Checkout Yet</h1>
                                        )}
                                    </div>
                                    <div className='flex gap-[9px]'>
                                        <FaMapMarkerAlt className='text-[#757D8A]' />
                                        <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                            {locationNameCheckout ? locationNameCheckout : 'Not Checkout Yet'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
         <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Employee Records'} LinkBack={'/dashboard'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div>
                        <h1 className='text-[20px] text-[#454545] font-semibold'>Employee Records</h1>
                        <h2 className='text-[#737373] text-[10px]'>List of  record employee attendance</h2>
                    </div>

                    <div className="flex gap-2 mt-[31px] relative">
                        <input onChange={handleSearchDate} type="date" className='w-[121px] p-[10px] bg-[#F5F8FA] rounded-md border-none text-[12px] text-[#7E8299]'/>
                        <button onClick={exportToExcel} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                            <TbFileExport className='text-[#003049]'/>
                            <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                        </button>
                        <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                            <option value="" selected disabled>Set Limit</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                        </select>
                        <div className="absolute right-0">
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div onChange={handleSearchName} className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input placeholder='Search by Employee Name or Employee ID...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                
                                {/* <button onClick={() => setShowAddCheckIn(!showAddCheckIn)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Manual Check In</h1>
                                </button> */}
                            </div>
                        </div>
                    </div>

                <div>
            <div className='overflow-auto scrollbar-hide'>
                <table className='w-full space-y-[10px] text-[#737373]'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Employee Name</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Date</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Check In</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Location</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Check Out</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Location</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Duration</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center justify-center gap-[6px] w-full'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                    </div>
                    {Object.values(data).map((item ,idx) => (
                        <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>{item.user? item.user.fullname : '-'}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>{item.date? moment(item.date).format('DD MMMM YYYY') : '-'}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>{item.time_checkin? moment(item.time_checkin).format('HH:mm') : '-'}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                                <a href={`https://www.google.com/maps/?q=${item.latitude_checkin},${item.longtitude_checkin}`} target='_blank' className='text-blue-500 text-[11px] font-[500] underline underline-offset-2'>Location</a>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>{!item.time_checkout ? '-' : moment(item.time_checkout).format('HH:mm')}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                                <a href={`https://www.google.com/maps/?q=${item.latitude_checkout},${item.longtitude_checkout}`} target='_blank' className='text-blue-500 text-[11px] font-[500] underline underline-offset-2'>Location</a>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>{!item.time_checkout ? '-' : duration(item.createdAt, item.updatedAt)}</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='w-full flex items-center justify-center gap-[12px]'>
                                <button onClick={() => openDetailAttendanceRecord(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                    <FiEye className='text-[#003049]'/>
                                </button>
                                <button onClick={ () => CheckOutDataAttendanceRecord(item.user.id) } className=' bg-[#CEDFEA] rounded-[9px] w-[92px] flex items-center justify-center text-[#003049] py-[5px] px-[15px] text-[11px] font-semibold'>
                                    Check Out
                                </button>
                            </div>
                        </div>
                    ))}
                </table>
            </div>
            <Pagination
                currentPage={currentPage} 
                totalPages={totalPages} 
                lengthData={totalCount}
                limitData={limit}
                onPageChange={handlePageChange}
                onPrevChange={handlePrevChange}
                onNextChange={handleNextChange}
            />
        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EmployeeRecord