import React from 'react'
import { Navbar } from '../../../../components'
import { MdImportExport } from 'react-icons/md'
import { HiOutlinePencil } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

const DetailSKP = () => {
  const status = 'Rejected'
  const rhkType = 'Utama'
  const navigate = useNavigate()
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
        <div className='flex px-[30px] py-[35px] gap-[30px]'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Performance'} LinkBack={'/performance'}/>
                <h1 className="text-zinc-800 text-xl font-semibold">Detail SKP</h1>

                <div className='space-y-[24px] bg-white px-[40px] py-[40px] rounded-[12px] border'>
                    <div className="flex items-center justify-between">
                      <div className='flex gap-[14px] items-center'>
                        <div className={`py-[8px] px-[12px] text-[#40444C] text-xs font-semibold rounded-lg ${status === 'Rejected' ? 'bg-[#FF9F9F]' : status === 'Draft' ? 'bg-[#CBEAFF]' : status === 'Under Review' ? 'bg-[#FFED86]' : status === 'Approved' ? 'bg-[#7EFFB2]' : status === 'Evaluation' ? 'bg-[#FFDDCB]' : status === 'Completed' ? 'bg-[#CFCBFF]' : null}`}>Status Cek</div>
                        <h1 className='text-[#B6BAC3]'>|</h1>
                        <div className='px-[8px] py-[2px] bg-[#7E0000] text-white text-[8px] flex items-center justify-center rounded-lg font-medium'>Period 1 January 2023 - 31 December 2023</div>
                      </div>

                      <div className='flex items-center gap-[10px]'>
                        {status === 'Rejected' && (
                          <button className='px-[8px] py-[7px] text-[14px] border border-gray-400 rounded-lg bg-[#015995] text-white flex items-center gap-[4px]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                              <g clip-path="url(#clip0_1305_30661)">
                                <path d="M15.0116 4.46424L12.534 1.96963C12.3703 1.80645 12.1487 1.71484 11.9178 1.71484C11.6868 1.71484 11.4653 1.80645 11.3016 1.96963L2.51829 10.7558L1.71636 14.2228C1.68869 14.3496 1.68964 14.4809 1.71913 14.6072C1.74862 14.7336 1.80591 14.8517 1.88682 14.953C1.96772 15.0543 2.07019 15.1362 2.18674 15.1928C2.3033 15.2493 2.43099 15.2791 2.5605 15.2799C2.62084 15.286 2.68165 15.286 2.74199 15.2799L6.24095 14.4765L15.0116 5.69887C15.1745 5.53486 15.2659 5.31291 15.2659 5.08156C15.2659 4.8502 15.1745 4.62825 15.0116 4.46424ZM5.81888 13.7155L2.53939 14.4047L3.28646 11.1828L9.8581 4.62491L12.3905 7.16181L5.81888 13.7155ZM12.9561 6.54873L10.4237 4.01183L11.8925 2.54889L14.3827 5.08578L12.9561 6.54873Z" fill="white" stroke="white" stroke-width="0.3"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_1305_30661">
                                  <rect width="16.962" height="16.962" fill="white" transform="translate(0 0.0185547)"/>
                                </clipPath>
                              </defs>
                            </svg>
                            <h1>Reapply</h1>
                          </button>

                        )}
                        <button onClick={() => navigate('/performance/detail-skp/outcome-role-matrix')} className='px-[8px] py-[7px] border border-gray-400 rounded-lg text-[#113B61] text-[14px]'>Outcome Role Matrix</button>
                      </div>
                    </div>

                    <div className='flex items-center justify-center gap-[14px]'>

                        <div className='w-full px-[34px] py-[24px] space-y-[14px] border border-gray-500 rounded-xl'>
                          <h1 className="text-zinc-700 text-sm font-medium">Employees Assessed</h1>
                          <div className='grid grid-cols-11'>
                            <div className='col-span-3 text-gray-500 text-xs font-normal'>
                              <h1>Nama</h1>
                              <h1>Employee ID</h1>
                              <h1>Job Position</h1>
                              <h1>Subsidiary</h1>
                            </div>
                            <div className='col-span-1 text-gray-500 text-xs font-normal justify-end'>
                              <h1>:</h1>
                              <h1>:</h1>
                              <h1>:</h1>
                              <h1>:</h1>
                            </div>
                            <div className='col-span-7 text-gray-500 text-xs font-normal'>
                              <h1 className='truncate'>Muh Rizieq Fazlulrahman Djafar</h1>
                              <h1 className='truncate'>20102027</h1>
                              <h1 className='truncate'>Frontend Web Developer</h1>
                              <h1 className='truncate'>Kranji</h1>
                            </div>
                          </div>
                          <h1 className="text-green-600 text-xs font-medium">Qualitative</h1>
                        </div>

                        <div className='w-full px-[34px] py-[24px] space-y-[14px] border border-gray-500 rounded-xl'>
                          <h1 className="text-zinc-700 text-sm font-medium">Appraisal Employee</h1>
                          <div className='grid grid-cols-11'>
                            <div className='col-span-3 text-gray-500 text-xs font-normal'>
                              <h1>Nama</h1>
                              <h1>Employee ID</h1>
                              <h1>Job Position</h1>
                              <h1>Subsidiary</h1>
                            </div>
                            <div className='col-span-1 text-gray-500 text-xs font-normal justify-end'>
                              <h1>:</h1>
                              <h1>:</h1>
                              <h1>:</h1>
                              <h1>:</h1>
                            </div>
                            <div className='col-span-7 text-gray-500 text-xs font-normal'>
                              <h1 className='truncate'>Jahfal</h1>
                              <h1 className='truncate'>20102027</h1>
                              <h1 className='truncate'>Project Manager</h1>
                              <h1 className='truncate'>Kranji</h1>
                            </div>
                          </div>
                          <h1 className="text-green-600 text-xs font-medium">Qualitative</h1>
                        </div>

                    </div>

                    <div className='w-full lg:w-auto'>
                        <h1 className='text-[#454545] text-[20px] font-[600]'>Detail SKP</h1>
                        <h1 className='text-[#A8A8A8] text-xs font-[400]'>List of Detail Sasaran Kinerja Pegawai</h1>
                    </div>

                    <div className='mt-[44px] mb-[44px] overflow-auto scrollbar-hide'>
                      <table className='w-full space-y-[10px]'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>No.</h1>
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>RHK Intervening Leaders</h1>
                                <MdImportExport className='text-[#40444C] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>RHK Type</h1>
                                <MdImportExport className='text-[#40444C] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>RHK</h1>
                                <MdImportExport className='text-[#40444C] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>Aspect</h1>
                                <MdImportExport className='text-[#40444C] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>Individual Work Indicators</h1>
                                <MdImportExport className='text-[#40444C] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[15px] min-w-[110px] max-w-[110px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>Annual Target</h1>
                                <MdImportExport className='text-[#40444C] text-xs'/>
                            </div>
                            {/* <div className='flex items-center justify-center gap-[15px] w-full'>
                                <h1 className='text-[#40444C] text-xs font-medium'>Action</h1>
                            </div> */}
                        </div>

                        <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='min-w-[50px] max-w-[50px]'>
                                <h1 className='text-[#40444C] text-xs font-medium '>1. </h1>
                            </div>
                            <div className='min-w-[200px] max-w-[200px]'>
                                <h1 className='text-[#40444C] text-xs font-medium '>Terlaksananya program dan pengelolaan sumber daya manusia dalam pencapaian standar nasional </h1>
                            </div>
                            <div className='min-w-[100px] max-w-[100px]'>
                                <h1 className={`w-fit text-xs font-medium py-[4px] px-[8px] rounded-lg ${rhkType === 'Utama'? 'bg-[#CFDEF8] text-[#113B61]' : 'bg-[#FFC9B4] text-[#7E0000]'}`}>Utama</h1>
                            </div>
                            <div className='min-w-[200px] max-w-[200px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>Tersusunnya perangkat pendukung pegawai pada tahap perencanaan</h1>
                            </div>
                            <div className='min-w-[100px] max-w-[100px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>Kualitas</h1>
                            </div>
                            <div className='min-w-[200px] max-w-[200px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>Jumlah perangkat pembelajaran mata pelajaran Produktif Otomatisasi Tata Kelola Perkantoran</h1>
                            </div>
                            <div className='min-w-[110px] max-w-[110px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>4 dokumen</h1>
                            </div>
                        </div>
                      </table>
                    </div>

                    <div className='w-full lg:w-auto'>
                        <h1 className='text-[#454545] text-[20px] font-[600]'>Perilaku Kerja</h1>
                    </div>

                    <div className='mt-[44px] mb-[44px] overflow-auto scrollbar-hide'>
                      <table className='w-full space-y-[10px]'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                <h1 className='text-[#40444C] text-xs font-medium'>No.</h1>
                            </div>
                            <div className='flex items-center gap-[15px] w-full'>
                                <h1 className='text-[#40444C] text-xs font-medium'>RHK Intervening Leaders</h1>
                                <MdImportExport className='text-[#40444C] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[15px] w-full'>
                                <h1 className='text-[#40444C] text-xs font-medium'>RHK Type</h1>
                                <MdImportExport className='text-[#40444C] text-xs'/>
                            </div>
                        </div>

                        <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='min-w-[100px] max-w-[100px]'>
                                <h1 className='text-[#40444C] text-xs font-medium '>1. </h1>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-xs font-medium '>Terlaksananya program dan pengelolaan sumber daya manusia dalam pencapaian standar nasional </h1>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-xs font-medium'>Tersusunnya perangkat pendukung pegawai pada tahap perencanaan</h1>
                            </div>
                        </div>
                      </table>
                    </div>


                </div>
                
            </div>
        </div>
    </div>
  )
}

export default DetailSKP