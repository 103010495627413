import React, { useEffect, useState } from 'react'
import { AiOutlineFileSearch, AiOutlinePlus } from 'react-icons/ai'
import { BsFilter } from 'react-icons/bs'
import { Modal, Navbar } from '../../../../components'
import CardDocument from '../../../../components/Card/CardDocument'
import Api from '../../../../Api'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { debounce } from 'lodash'


const Document = () => {
    const navigate = useNavigate()
    const [showModalCreateDocument, setShowModalCreateDocument] = useState (false)
    const [refresh, setRefresh] = useState(false)
    // Data
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    // Single Choice Repeater
    const [inputFieldsSingleChoice, setInputFieldsSingleChoice] = useState([{
        titleSingleChoice: '' 
    }])

    const addFieldSingleChoices = () => {
        let newfield = {
            titleSingleChoice: ''
        }
        setInputFieldsSingleChoice([...inputFieldsSingleChoice, newfield])
    }

    const removeFieldsSingleChoice = (index) => {
        let data = [...inputFieldsSingleChoice];
        data.splice(index, 1);
        setInputFieldsSingleChoice(data);
    };

    const handleFormChangeSingleChoice = (index, event) => {
        let data = [...inputFieldsSingleChoice];
        data[index][event.target.name] = event.target.value;
        setInputFieldsSingleChoice(data);
    }
    
    // Multiple Choice Repeater
    const [inputFieldsMultipleChoice, setInputFieldsMultipleChoice] = useState([{
        titleMultipleChoice: '' 
    }])

    const addFieldMultipleChoices = () => {
        let newfield = {
            titleMultipleChoice: ''
        }
        setInputFieldsMultipleChoice([...inputFieldsMultipleChoice, newfield])
    }

    const removeFieldsMultipleChoice = (index) => {
        let data = [...inputFieldsMultipleChoice];
        data.splice(index, 1);
        setInputFieldsMultipleChoice(data);
    };

    const handleFormChangeMultipleChoice = (index, event) => {
        let data = [...inputFieldsMultipleChoice];
        data[index][event.target.name] = event.target.value;
        setInputFieldsMultipleChoice(data);
    }
    
    // Dropdown Repeater
    const [inputFieldsDropdown, setInputFieldsDropdown] = useState([{
        titleDropdown: '' 
    }])

    const addFieldDropdowns = () => {
        let newfield = {
            titleDropdown: ''
        }
        setInputFieldsDropdown([...inputFieldsDropdown, newfield])
    }

    const removeFieldsDropdown = (index) => {
        let data = [...inputFieldsDropdown];
        data.splice(index, 1);
        setInputFieldsDropdown(data);
    };

    const handleFormChangeDropdown = (index, event) => {
        let data = [...inputFieldsDropdown];
        data[index][event.target.name] = event.target.value;
        setInputFieldsDropdown(data);
    }

    // Form Repeater
    const [inputFields, setInputFields] = useState([{
        field_name: '',
        field_type: '',
        data_type: '',
        option_single_choice: inputFieldsSingleChoice, 
        option_multiple_choice: inputFieldsMultipleChoice, 
        dropdown: inputFieldsDropdown, 
    }])

    const addFields = () => {
        let newfield = {
            field_name: '',
            field_type: '',
            data_type: '',
            option_single_choice: inputFieldsSingleChoice, 
            option_multiple_choice: inputFieldsMultipleChoice, 
            dropdown: inputFieldsDropdown, 
        }
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    }

    // Document State

    const [dataDocument, setDataDocument] = useState('')

    const GetDocument = async() => {
        try {
            const response = await Api.GetDocument(localStorage.getItem('hris-token'), '')
            setDataDocument(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const debouncedSearchTitle = debounce(async(title) => {
        try {
            const response = await Api.GetDocument(localStorage.getItem('hris-token'), title)
            setDataDocument(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchTitle(searchName)
    }

    const PostDocument = async() => {
        const newDocument = {
            title: title,
            description: description,
            delegated_to: "delegated-to-user-id",
            newDocumentDetails: inputFields.map((input) => {
                const field = {
                    field_name: input.field_name,
                    field_type: input.field_type,
                    data_type: input.data_type,
                };
                if (input.field_type === "single-choice") {
                    field.option_single_choice = inputFieldsSingleChoice.map(
                        (choice) => choice.titleSingleChoice
                    );
                } else if (input.field_type === "check-box") {
                    field.option_multiple_choice = inputFieldsMultipleChoice.map(
                        (choice) => choice.titleMultipleChoice
                    );
                } else if (input.field_type === "dropdown") {
                    field.dropdown = inputFieldsDropdown.map(
                        (option) => option.titleDropdown
                    );
                }
                return field;
            }),
        };

        try {
            const response = await Api.CreateDocument(localStorage.getItem('hris-token'), newDocument)
            toast.success('Success Create Document')
            setShowModalCreateDocument(!showModalCreateDocument)
            setRefresh(true)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetDocument()
        setRefresh(false)
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal 
                activeModal={showModalCreateDocument}
                title={'Create Document Management System'}
                buttonClose={ () => setShowModalCreateDocument(!showModalCreateDocument)}
                width={'715px'}
                content={
                    <div className='space-y-4'>
                        <div className='border rounded-md flex items-center shadow-md'>
                            <div className='h-[100px] min-w-[10px] rounded-l-md bg-[#780000]'/>
                            <div className='px-[15px] w-full space-y-1'>
                                <input name='title' type='text' onChange={(e) => setTitle(e.target.value)} className='border-b border-[#EDEDED] min-w-full outline-none text-[#454545] py-[6px] text-[20px]' placeholder='Document Title'/>
                                <input name='description' type='text' onChange={(e) => setDescription(e.target.value)} className='border-b border-[#EDEDED] min-w-full outline-none text-[#454545] py-[6px] text-[12px]' placeholder='Title Description'/>
                            </div>
                        </div>
                        <div className='border rounded-md shadow-md px-[10px] py-[12px]'>
                            {inputFields.map((input, index) => {
                                return (
                                    <div>
                                        <div className='flex items-center gap-2'>
                                            <input type="text" name='field_name' onChange={(e) => { handleFormChange(index, e) }} className="w-full h-[35px] outline-none border px-4 py-2 rounded-lg mt-3 shadow text-[12px] placeholder:text-[##A8A8A8]" placeholder="Field Name"/>
                                            <div className="w-full h-[35px] px-4 py-2 shadow rounded-lg mt-3 text-[12px] border placeholder:text-[#A8A8A8] ">
                                                <select name='field_type' onChange={(e) => { handleFormChange(index, e) }} className="w-full text-gray-500 outline-none" >
                                                    <option selected disabled value=''> Field Type </option>
                                                    <option value="short-answer">Short Answer</option>
                                                    <option value="paragraph">Paragraph</option>
                                                    <option value="single-choice">Single Choice</option>
                                                    <option value="check-box">Multiple Choice</option>
                                                    <option value="dropdown">Dropdown</option>
                                                    {/* <option value="upload-file">Upload File</option> */}
                                                    <option value="date">Date</option>
                                                    <option value="time">Time</option>
                                                </select>
                                            </div>
                                            <div className="w-full h-[35px] px-4 py-2 shadow rounded-lg mt-3 text-[12px] border placeholder:text-[#A8A8A8] outline-none">
                                                <select name='data_type' onChange={(e) => { handleFormChange(index, e) }} className="w-full text-gray-500">
                                                    <option selected disabled value=''> Data Type</option>
                                                    <option value="string">Alphabet</option>
                                                    <option value="integer">Number</option>
                                                    {/* <option value="file">File</option> */}
                                                </select>
                                            </div>
                                            {index >= 0 &&
                                                <button onClick={ () => removeFields(index)}>
                                                    <BiMinus className="mt-[14px] ml-[6px] text-[#780000] text-3xl rounded-lg" />
                                                </button>
                                            }
                                        </div>
                                        {input.field_type === "check-box" && (
                                            <div>
                                                {inputFieldsMultipleChoice.map((input, index) => {
                                                    return (
                                                        <div className='w-full flex items-center gap-10'>
                                                            <label className="flex items-center gap-[10px] ml-[16px] my-[18px]">
                                                                <input type="checkbox" className="h-[15px] w-[15px] text-[#A8A8A8]" id="myRadio"/>
                                                                <input type="text" value={input.titleMultipleChoice} name='titleMultipleChoice' onChange={(e) => { handleFormChangeMultipleChoice(index, e) }} className="text-[12px] outline-none py-2 placeholder:text-[#A8A8A8] w-full appearance-none border-b" placeholder="Option Name" />
                                                            </label> 
                                                            {index >= 0 &&
                                                                <button onClick={removeFieldsMultipleChoice}>
                                                                    <BiMinus className="mt-[14px] ml-[6px] text-[#780000] text-3xl rounded-lg" />
                                                                </button>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                                <button onClick={addFieldMultipleChoices} className='h-[35px] border shadow-md rounded-lg mt-3 ml-[6px] text-[12px] placeholder:text-[#A8A8A8] w-full flex items-center justify-center gap-2'>
                                                    <BiPlus className="text-[#669BBC] text-xl" />
                                                    Multiple Choice
                                                </button>
                                            </div>
                                        )}
                                        {input.field_type === "dropdown" && (
                                            <div>
                                                {inputFieldsDropdown.map((input, index) => {
                                                    return (
                                                        <div className='w-full flex items-center gap-10'>
                                                            <label className="flex items-center gap-[10px] ml-[16px] my-[18px]">
                                                                <input type="text" value={input.titleDropdown} name='titleDropdown' onChange={(e) => { handleFormChangeDropdown(index, e) }} className="text-[12px] outline-none py-2 placeholder:text-[#A8A8A8] w-full appearance-none border-b" placeholder="Option Name" />
                                                            </label> 
                                                            {index >= 0 &&
                                                                <button onClick={removeFieldsDropdown}>
                                                                    <BiMinus className="mt-[14px] ml-[6px] text-[#780000] text-3xl rounded-lg" />
                                                                </button>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                                <button onClick={addFieldDropdowns} className='h-[35px] border shadow-md rounded-lg mt-3 ml-[6px] text-[12px] placeholder:text-[#A8A8A8] w-full flex items-center justify-center gap-2'>
                                                    <BiPlus className="text-[#669BBC] text-xl" />
                                                    Dropdown
                                                </button>
                                            </div>
                                        )}
                                        {input.field_type === "single-choice" && (
                                            <div>
                                                {inputFieldsSingleChoice.map((input, index) => {
                                                    return (
                                                        <div className='w-full flex items-center gap-10'>
                                                            <label className="flex items-center gap-[10px] ml-[16px] my-[18px]">
                                                                <input type="radio" value={input.titleSingleChoice} className="form-radio h-[15px] w-[15px] text-[#A8A8A8]" id="myRadio"/>
                                                                <input type="text" name='titleSingleChoice' onChange={(e) => { handleFormChangeSingleChoice(index, e) }} className="text-[12px] outline-none py-2 placeholder:text-[#A8A8A8] w-full appearance-none border-b" placeholder="Option Name" />
                                                            </label> 
                                                            {index >= 0 &&
                                                                <button onClick={removeFieldsSingleChoice}>
                                                                    <BiMinus className="mt-[14px] ml-[6px] text-[#780000] text-3xl rounded-lg" />
                                                                </button>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                                <button onClick={addFieldSingleChoices} className='h-[35px] border shadow-md rounded-lg mt-3 ml-[6px] text-[12px] placeholder:text-[#A8A8A8] w-full flex items-center justify-center gap-2'>
                                                    <BiPlus className="text-[#669BBC] text-xl" />
                                                    Single Choice
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        <button onClick={addFields} className='h-[35px] bg-[#669BBC] border shadow-md rounded-lg mt-3 ml-[6px] text-[12px] placeholder:text-[#A8A8A8] w-full flex items-center justify-center'>
                            <BiPlus className="text-white text-xl" />
                        </button>
                        <div className="flex items-center justify-end gap-4">
                            <button onClick={ () => setShowModalCreateDocument(!showModalCreateDocument)} className="w-[100px] h-[38px] bg-[#ECECEC] rounded-lg font-medium text-[14px] mr-[10px]">Cancel</button>
                            <button onClick={PostDocument} className="w-[100px] h-[38px] text-white bg-[#0E5073] rounded-lg font-medium text-[14px]">Create</button>
                        </div>
                    </div>
                    
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Document Management System'} LinkBack={'/dashboard'}/>

                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='flex flex-col lg:flex-row items-center justify-between'>
                        <div>
                            <h1 className='text-[20px]'>Document Management System</h1>
                            <h2 className='text-[10px] text-[#737373]'>List of document</h2>
                        </div>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative'>
                                    <AiOutlineFileSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input onChange={handleSearchName}  placeholder='Search Document...' className='text-[#A8A8A8] text-sm font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                <button onClick={()=>setShowModalCreateDocument(!showModalCreateDocument )} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Create Document</h1>
                                </button>
                        </div>
                    </div>
                </div>

                {/* <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                    <div className='px-[42px]'>
                        <h1 className='text-[#C1121F] text-[13px] font-[600]'>TOTAL RESPONDENT</h1>
                        <h2 className='text-[#A8A8A8] text-[10px]'>Respondent data in one year</h2>
                    </div>
                    <div><Verticalbar /></div>
                </div> */}

                <div className=' bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px] w-full h-[599px]'>
                    <div className='relative mb-[52px]'>
                        <div className='space-y-2'>
                            <h1 className='text-[20px]'>Document</h1>
                            <h2 className='text-[10px] text-[#737373]'>{dataDocument.length} document</h2>
                        </div>
                        <button className='absolute top-0 right-0 w-[81px] h-[36px]  bg-white border rounded-lg flex'>
                            <h1 className='text-[13px] mr-[12px] my-[10px] ml-[10px] text-[#003049] font-medium'>Filter</h1>
                            <BsFilter className='my-[10px] text-xl'/>
                        </button>
                    </div>
                    <div className='grid grid-cols-12 gap-x-[20px] gap-y-[20px]'>
                        {Object.values(dataDocument).map((data, index) => {
                            return (
                                <div className='col-span-3' key={index}>
                                    <CardDocument
                                        title={data.title}
                                        description={data.description}
                                        onClick={() => navigate('/document/open-document', {state: {documentId : data.id}})}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Document