import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination, Sidebar } from '../../../../components'
import { IoMdArrowDropdown } from 'react-icons/io'
import { TbFileExport } from 'react-icons/tb'
import { BiSearch } from 'react-icons/bi'
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle, AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import Api from '../../../../Api'
import moment from 'moment'
import { FaTimesCircle } from 'react-icons/fa'
import { toast } from 'react-hot-toast'
import { debounce } from 'lodash'

const Overtime = () => {
    const [showDetailOvertime, setShowDetailOvertime] = useState()
    const [showAddOvertime, setShowAddOvertime] = useState()
    const [showEditOvertime, setShowEditOvertime] = useState()
    const [showModalDelete, setShowModalDelete] = useState()

    const [data, setData] = useState('')
    const [dataById, setDataById] = useState('')
    const [dataEmployee, setDataEmployee] = useState('')
    const [refresh, setRefresh] = useState(false)

    //Post Overtime
    const [jobPosition, setJobPosition] = useState('')
    const [fullName, setFullName] = useState('')
    const [overtSubmissionDate, setOvertSubmissionDate] = useState('')
    const [overtOnDate, setOvertOnDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [backToWork, setBackToWork] = useState('')
    const [backToWorkTime, setBackToWorkTime] = useState('')
    const [reason, setReason] = useState('')
    const [user, setUser] = useState('')

    const [userName, setUserName] = useState('')
    const [userId, setUserId] = useState('')
    const [overtimeId, setOvertimeId] = useState()
    const [dataJobPosition, setDataJobPosition] = useState()
    const [isLoading, setIsLoading] = useState(false)
    //Pagination
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [dateSearch, setDateSearch] = useState('')
    // Repeater Employee
    const [searchTerm, setSearchTerm] = useState('');
    const [employeeName, setEmployeeName] = useState([])
    const [idEmployee, setIdEmployee] = useState([])

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const FetchUser = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserName(response.data.results.user.fullname)
            setUserId(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }
    const getEmployee = async () => {
        try {
            const response = await Api.getAllEmployee(localStorage.getItem('hris-token'))
            setDataEmployee(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getEmployeeById = async () => {
        try {
            const response = await Api.GetAllEmployeeById(localStorage.getItem('hris-token'), user)
            setFullName(response.data[0].fullname)
        } catch (error) {
            console.log(error)
        }
    }

    const openDetailOvertime = async (id) => {
        setShowDetailOvertime(!showDetailOvertime)
        try{
            const response = await Api.GetOvertimeById(localStorage.getItem('hris-token'), id)
            console.log(response, 'byId')
            setDataById(response.data)
        } catch(error){
            console.log(error)
        }
    }

    const deleteOvertimeModal = (id) => {
        setShowModalDelete(!showModalDelete)
        setOvertimeId(id)
        setRefresh(true)
    }

    const deleteOvertime = async () => {
        try {
            const response = await Api.DeleteOvertime(localStorage.getItem('hris-token'), overtimeId)
            setShowModalDelete(!showModalDelete)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }

    const openEditOvertime = async (id) => {
        setShowEditOvertime(!showEditOvertime)
        setOvertimeId(id)
        try{
            const response = await Api.GetOvertimeById(localStorage.getItem('hris-token'), id)
            setJobPosition(response.data.job_position)
            setFullName(response.data.fullname)
            setOvertSubmissionDate(response.data.overt_submission_date)
            setOvertOnDate(response.data.overt_ondate)
            setStartTime(response.data.start_time)
            setEndTime(response.data.end_time)
            setReason(response.data.reason)
            setBackToWork(response.data.back_to_work)
            setBackToWorkTime(response.data.back_to_work_time)
        } catch(error){
            console.log(error)
        }
    }

    const editOVertime = async (id) => {
        try{
            const data ={
                // job_position: 'jobPosition',
                fullname: fullName,
                overt_submission_date: overtSubmissionDate,
                overt_ondate: overtOnDate,
                start_time: startTime,
                back_to_work:backToWork,
                back_to_work_time: backToWorkTime,
                end_time: endTime,
                reason: reason,
                reportToId: idEmployee
            }
            console.log(data, 'sendedData')
            setIsLoading(true)
            const response = await Api.UpdateOvertime(localStorage.getItem('hris-token'), data, overtimeId)
            setIsLoading(false)
            resetForm()
            setShowEditOvertime(!showEditOvertime)
            setRefresh(true)
            toast.success('Success Edit Overtime')
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Failed Edit Overtime')
        }
    }

    const resetForm = () => {
        setJobPosition('')
        setFullName('')
        setOvertSubmissionDate('')
        setOvertOnDate('')
        setStartTime('')
        setEndTime('')
        setBackToWork('')
        setBackToWorkTime('')
        setReason('')
        setUser('')
    }

    const postOvertime = async() => {
        try {
            const data ={
                // job_position: 'jobPosition',
                fullname: localStorage.getItem('hris-role') === 'ADMIN'? fullName : userName,
                overt_submission_date: overtSubmissionDate,
                overt_ondate: overtOnDate,
                start_time: startTime,
                back_to_work:backToWork,
                back_to_work_time: backToWorkTime,
                end_time: endTime,
                reason: reason,
                userId: localStorage.getItem('hris-role') === 'ADMIN'? user : userId,
                reportToId: idEmployee

            }
            setIsLoading(true)
            const response = await Api.PostOvertime(localStorage.getItem('hris-token'), data)
            setIsLoading(false)
            resetForm()
            setShowAddOvertime(!showAddOvertime)
            setRefresh(true)
            console.log(response)
            toast.success('Success Add Overtime')
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Failed Add Overtime')
        }
    }

    const getOvertime = async () => {
        try {
            if(localStorage.getItem('hris-role') === "ADMIN"){
                const response = await Api.GetOvertimeByAdmin(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
                console.log(response, 'overtime')
            } else{
                const response = await Api.GetOvertime(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const debouncedSearchName = debounce(async(name) => {
        try {
            if(localStorage.getItem('hris-role') === 'ADMIN'){
                const response = await Api.GetOvertimeByAdmin(localStorage.getItem('hris-token'), dateSearch, name, currentPage, limit)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            } else {
                const response = await Api.GetOvertime(localStorage.getItem('hris-token'), dateSearch, name, currentPage, limit)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }
    const debouncedSearchDate = debounce(async(date) => {
        try {
            if(localStorage.getItem('hris-role') === 'ADMIN'){
                const response = await Api.GetOvertimeByAdmin(localStorage.getItem('hris-token'), date, nameSearch, currentPage, limit)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            } else {
                const response = await Api.GetOvertime(localStorage.getItem('hris-token'), date, nameSearch, currentPage, limit)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const debouncedLimit = debounce(async(limit) => {
        try {
            if(localStorage.getItem('hris-role') === 'ADMIN'){
                const response = await Api.GetOvertimeByAdmin(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            } else {
                const response = await Api.GetOvertime(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
                setData(response.data.overtime)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleLimit= (e) => {
        const limit = e.target.value
        debouncedLimit(limit)
    }

    const handleSearchDate = (e) => {
        const searchDate = e.target.value
        debouncedSearchDate(searchDate)
    }

    

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleRemoveClickedEmployee = (name) => {
        const index = employeeName.indexOf(name);
        if (index !== -1) {
          const newIdEmployee = [...idEmployee];
          const newEmployeeName = [...employeeName];
      
          newIdEmployee.splice(index, 1);
          newEmployeeName.splice(index, 1);
      
          setIdEmployee(newIdEmployee);
          setEmployeeName(newEmployeeName);
        }
    };

    const handleNameClick = (id) => {
        const selectedOption = dataEmployee.find (
            (data) => data.id === id
        );
        if (selectedOption && !idEmployee.includes(id)) {
            setIdEmployee([...idEmployee, selectedOption.id]);
            setEmployeeName([...employeeName, selectedOption.fullname]);
        }
    };

    useEffect(() => {
        getEmployeeById()
    }, [user])
    
    useEffect(() => {
        getOvertime()
        getEmployee()
        FetchUser()
        setRefresh(false)
    }, [refresh, limit])

    

  return (
    <div>
        <Modal 
            activeModal={showDetailOvertime}
            title={'Detail Overtime'}
            buttonClose={ () => setShowDetailOvertime(!showDetailOvertime)}
            width={'832px'}
            content= {
                <div className='space-y-[40px] w-[full]'>
                    <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-[773px]'>
                        <div className='w-[669px]'>
                            <div className="grid grid-cols-2 text-[11px] text-[#737373] gap-y-[20px] ">
                                <h1 className='font-semibold'>Employee Name</h1>
                                <h1 className='font-medium'>: {dataById.fullname} </h1>
                                <h1 className='font-semibold'>Job Position</h1>
                                <h1 className='font-medium'>: {dataById.user? dataById.user.buat[0].job_position.position_name : '-'} </h1>
                                <h1 className='font-semibold'>Overtime submission date</h1>
                                <h1 className='font-medium'>: {dataById.overt_submission_date? moment(dataById.overt_submission_date).format('DD-MM-YYYY') : '-'}</h1>
                                <h1 className='font-semibold'>Overtime on date </h1>
                                <h1 className='font-medium'>: {dataById.overt_ondate? moment(dataById.overt_ondate).format('DD-MM-YYYY') : '-'}</h1>
                                <h1 className='font-semibold'>Start Time</h1>
                                <h1 className='font-medium'>: {dataById.start_time? dataById.start_time : '-'}</h1>
                                <h1 className='font-semibold'>End Time </h1>
                                <h1 className='font-medium'>: {dataById.end_time ? dataById.end_time: '-'}</h1>
                                <h1 className='font-semibold'>Description</h1>
                                <h1 className='font-medium'>: {dataById.reason ? dataById.reason : '-'}.</h1>
                            </div>
                        </div>

                        <h1 className='text-[#454545] mt-[21px] mb-[11px] font-semibold'>Back to Work</h1>

                        <div className='w-[260px]'>
                            <div className="grid grid-cols-2 text-[11px] text-[#737373] gap-y-[20px]">
                                <h1 className='font-medium'>Date</h1>
                                <h1>: {dataById.back_to_work? dataById.back_to_work : '-'}</h1>
                                <h1 className='font-medium'>Time</h1>
                                <h1>: {dataById.back_to_work_time? dataById.back_to_work_time : '-'}</h1>
                            </div>
                        </div>
                    </div>
                    
                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowDetailOvertime(!showDetailOvertime)} className=' bg-[#0E5073] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[white] text-sm font-[500]'>Close</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />

        <Modal 
            activeModal={showAddOvertime}
            title={'Add Overtime'}
            buttonClose={ () => setShowAddOvertime(!showAddOvertime)}
            width={'553px'}
            content= {
                <div className='space-y-[24px] w-[full]'>
                    {localStorage.getItem('hris-role') === 'ADMIN' ? 
                    (
                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[5px]'>Employee Name  <span className='text-[#780000]'>*</span></h2>
                        <div className='relative'>
                            <select onChange={(e) => setUser(e.target.value)} value={user} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[473px] appearance-none'>
                                <option selected value={''}>Select Employee...</option>
                                {Object.values(dataEmployee).map((item) => {
                                    if (item.role === 'EMPLOYEE'){
                                        return (
                                            <option value={item.id}>{item.fullname}</option>
                                        )
                                    } else{
                                        return ''
                                    }
                                })}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div>
                    ) : 
                    (
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input value={userName} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-[#454545] text-[13px] px-[8px] py-[10px] w-full appearance-none' readOnly/>
                            </div>
                        </div>
                    )}

                    {/* <div>
                        <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Job Position</h1>
                        <input value={jobPosition? jobPosition : 'default'} type='text' className='bg-[#F2F2F2] border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Job Position' readOnly/>
                    </div> */}

                    <div className="flex gap-[19px]">
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Overtime submission date <span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setOvertSubmissionDate(e.target.value)} value={overtSubmissionDate}  type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Overtime on date <span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setOvertOnDate(e.target.value)} value={overtOnDate}  type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                    </div>

                    <div className="flex gap-[19px]">
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Start Time  <span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setStartTime(e.target.value)} value={startTime}  type="time" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>End Time  <span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setEndTime(e.target.value)} value={endTime}  type="time" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]' placeholder='Overtime reason...'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Description<span className='text-[#780000]'>*</span></h1>
                        <textarea onChange={(e) => setReason(e.target.value)} value={reason}  rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px]  w-full text-[#A8A8A8] resize-none' placeholder='Overtime reason...'/>
                    </div>

                    <h1 className='text-[#454545] text-[13px] font-medium'>Back to Work</h1>

                    <div className="flex gap-[19px] ">
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Date<span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setBackToWork(e.target.value)} value={backToWork}  type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Time<span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setBackToWorkTime(e.target.value)} value={backToWorkTime}  type="time" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Approval <span className='text-[#780000]'>*</span></h1>

                        <div className='relative py-[12px] pl-[12px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-full mb-3'>
                            <BiSearch className='absolute right-[12px] text-lg' />
                            <input value={searchTerm} onChange={handleSearchChange} type="text" placeholder='Search Employee name...' className='outline-none text-[12px] w-full' />
                        </div>

                        {searchTerm.trim() !== '' && (
                            <div className={`bg-white rounded-md mb-3 shadow overflow-auto min-h-fit max-h-[120px]`}>
                                {dataEmployee.map((item, index) => (
                                    <h1 key={index} className='flex text-[12px] font-medium items-center p-[10px]' onClick={() => handleNameClick(item.id)}>
                                        {item.fullname}
                                    </h1>   
                                ))}
                            </div>
                        )}
                        
                        {employeeName.length > 0 && (
                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                {employeeName.map((name, index) => (
                                    <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                        <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                            <h1>{name}</h1>
                                            <button onClick={() => handleRemoveClickedEmployee(name)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                    <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className='relative pb-[37px] pt-[40px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddOvertime(!showAddOvertime)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={isLoading ? null : postOvertime} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditOvertime}
            title={'Edit Overtime'}
            buttonClose={ () => setShowEditOvertime(!showEditOvertime)}
            width={'553px'}
            content= {
                <div className='space-y-[24px] w-[full]'>
                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[5px]'>Employee Name  <span className='text-[#780000]'>*</span></h2>
                        <input value={fullName} type='text' className='bg-[#F2F2F2] border rounded-[6px] text-[#A8A8A8] text-[12px] px-[8px] py-[10px] w-full' placeholder={fullName} readOnly/>
                    </div>

                    {/* <div>
                        <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Job Position</h1>
                        <input value={jobPosition? jobPosition : 'default'} type='text' className='bg-[#F2F2F2] border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Job Position' readOnly/>
                    </div> */}

                    

                    <div className="flex gap-[19px]">
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Overtime submission date <span className='text-[#780000]'>*</span></h1>
                            <input type="date" value={moment(overtSubmissionDate).format('YYYY-MM-DD')} onChange={(e) => setOvertSubmissionDate(e.target.value)} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Overtime on date <span className='text-[#780000]'>*</span></h1>
                            <input type="date" value={moment(overtOnDate).format('YYYY-MM-DD')} onChange={(e) => setOvertOnDate(e.target.value)} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                    </div>

                    <div className="flex gap-[19px]">
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Start Time  <span className='text-[#780000]'>*</span></h1>
                            <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>End Time  <span className='text-[#780000]'>*</span></h1>
                            <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Description<span className='text-[#780000]'>*</span></h1>
                        <textarea rows={3} value={reason} onChange={(e) => setReason(e.target.value)} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] w-full text-[#A8A8A8] resize-none'/>
                    </div>

                    <h1 className='text-[#454545] text-[13px] font-medium'>Back to Work</h1>

                    <div className="flex gap-[19px] ">
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Date<span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setBackToWork(e.target.value)} value={moment(backToWork).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Time<span className='text-[#780000]'>*</span></h1>
                            <input onChange={(e) => setBackToWorkTime(e.target.value)} value={backToWorkTime} type="time" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[227px]'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Approval <span className='text-[#780000]'>*</span></h1>

                        <div className='relative py-[12px] pl-[12px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-full mb-3'>
                            <BiSearch className='absolute right-[12px] text-lg' />
                            <input value={searchTerm} onChange={handleSearchChange} type="text" placeholder='Search Employee name...' className='outline-none text-[12px] w-full' />
                        </div>

                        {searchTerm.trim() !== '' && (
                            <div className={`bg-white rounded-md mb-3 shadow overflow-auto min-h-fit max-h-[120px]`}>
                                {dataEmployee.map((item, index) => (
                                    <h1 key={index} className='flex text-[12px] font-medium items-center p-[10px]' onClick={() => handleNameClick(item.id)}>
                                        {item.fullname}
                                    </h1>   
                                ))}
                            </div>
                        )}
                        
                        {employeeName.length > 0 && (
                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                {employeeName.map((name, index) => (
                                    <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                        <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                            <h1>{name}</h1>
                                            <button onClick={() => handleRemoveClickedEmployee(name)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                    <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    

                    <div className='relative pb-[37px] pt-[40px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowEditOvertime(!showEditOvertime)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={isLoading ? null : editOVertime} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <ModalDelete
            activeModal={showModalDelete}
            buttonClose={() => setShowModalDelete(!showModalDelete)}
            submitButton={deleteOvertime}
        />
        
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    
                    <div>
                        <h1 className='text-[20px]'>Overtime</h1>
                        <h2 className='text-[10px] text-[#737373]'>List of  Overtime Employee</h2>
                    </div>

                    <div className="flex gap-2 mt-[31px] relative">
                        <input onChange={handleSearchDate} type="date" className='bg-[#F5F8FA] py-[11.5px] px-[10px] text-[#7E8299] text-[12px] font-medium rounded-md' />

                        <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                            <option value="" selected disabled>Set Limit</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                        </select>

                        {/* <button className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[11px]'>
                            <TbFileExport className='text-[#003049]'/>
                            <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                        </button> */}
                        <div className="absolute right-0">
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input onChange={handleSearchName} placeholder='Search by Employee Name...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                <button onClick={() => setShowAddOvertime(!showAddOvertime)} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add Overtime</h1>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Employee Name</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Job Position</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Date of Filling</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Reason</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Approved Leader</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Action </h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                </div>
                                {Object.values(data).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.fullname? item.fullname : '-'}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.user.user_detail? item.user.user_detail.job_position.position_name : '-'}</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.overt_ondate ? moment(item.overt_ondate).format('DD-MM-YYYY') : '-'}</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.reason? item.reason : '-'}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <div className='rounded-[9px] flex flex-col items-center justify-center'>
                                                    {item.status === 'pending' ? 
                                                        <AiFillClockCircle className='text-[#FFD900] w-[29px] h-[29px]'/>
                                                    :item.status === 'approved' ?
                                                        <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]'/>
                                                    :
                                                        <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]'/>
                                                    }
                                            </div>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <AiOutlineEye onClick={() => openDetailOvertime(item.id)} className='text-[#003049]'/>
                                            </button>
                                            {item.status === 'approved' ? 
                                                <button disabled onClick={() => openEditOvertime(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]'/>
                                                </button> 
                                                :
                                                <button onClick={() => openEditOvertime(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]'/>
                                                </button>
                                            }
                                            
                                            <button onClick={() => deleteOvertimeModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty className='text-[#003049]'/>
                                            </button>
                                            
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage} 
                            totalPages={totalPages} 
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>

                </div>
    </div>
  )
}

export default Overtime