import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { LogoH } from '../../../../assets'
import { InboxDetailCashAdvance, InboxDetailLeave, InboxDetailLoan, InboxDetailOvertime, InboxDetailPermission, InboxDetailReimburstment, Navbar, Sidebar } from '../../../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import imageHandle from '../../../../utils/imageHandle'

const InboxDetail = () => {
    const navigate = useNavigate()
    const params = useLocation()
    const type = params.state.type
    

    const [data, setData] = useState('')
    const [settingData, setSettingData] = useState('')
    const [dataJobPosition, setDataJobPosition] = useState('')
    const [userData, setUserData] = useState('')
    const [toUser, setToUser] = useState('')
    const [emergencyContact, setEmergencyContact] = useState('')

    // Recruitment
    const [recruitmentData, setRecruitmentData] = useState('')

    const GetDetail = async() => {
        try {
            if(params.state.type === 'Reimbursment' || params.state.type === 'Reimbursement') {
                const response = await Api.GetFinanceReimbursmentById(localStorage.getItem('hris-token'), params.state.applicantId)
                console.log(response, 'reim')
                setData(response.data[0])
                setSettingData(response.data[0].rbSetting)
                setUserData(response.data[0].user)
                setToUser(response.data[0].user.id)
                setDataJobPosition(response.data[0].user.buat[0].job_position.position_name)
            }else if(params.state.type ==='Loan') {
                const response = await Api.GetFinanceLoanById(localStorage.getItem('hris-token'), params.state.applicantId)
                setData(response.data[0])
                setSettingData(response.data[0].loSetting)
                setUserData(response.data[0].user)
                setToUser(response.data[0].user.id)
                setDataJobPosition(response.data[0].user.buat[0].job_position.position_name)
                console.log(response, 'loan')


            }else if(params.state.type ==='Cash Advance') {
                const response = await Api.GetFinanceCashAdvanceById(localStorage.getItem('hris-token'), params.state.applicantId)
                console.log(response, 'ca')
                setData(response.data[0])
                setSettingData(response.data[0].caSetting)
                setUserData(response.data[0].user)
                setToUser(response.data[0].user.id)


            }else if (params.state.type === 'Leave'){
                const response = await Api.getLeaveById(localStorage.getItem('hris-token'), params.state.applicantId)
                setData(response.data.response)
                setEmergencyContact(response.data.response.emergency_contact)
                setSettingData(response.data.response.leavetype)
                setUserData(response.data.response.user)
                setToUser(response.data.response.user.id)
                setDataJobPosition(response.data.response.user.buat[0].job_position.position_name)

            }else if (params.state.type === 'Permission'){
                const response = await Api.GetPermissionById(localStorage.getItem('hris-token'), params.state.applicantId)
                setData(response.data)
                setUserData(response.data.user)
                setToUser(response.data.user.id)
                setDataJobPosition(response.data.user.buat[0].job_position.position_name)

            }else if (params.state.type === 'Overtime'){
                const response = await Api.GetOvertimeById(localStorage.getItem('hris-token'), params.state.applicantId)
                setData(response.data[0])
                setUserData(response.data[0].user)
                setToUser(response.data[0].user.id)
                setDataJobPosition(response.data[0].user.buat[0].job_position.position_name)

            }else if (params.state.type === 'Aplicant Recruitment') {
                const response = await Api.GetRecruitmentApplicantById(localStorage.getItem('hris-token'), params.state.applicantId)
                console.log(response, 'aplicant')
                setRecruitmentData(response.data.applicant)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const Approve = async(id) => {
        try {
            const data = {
                status: 'approved',
                toUser: toUser
            }
            if(params.state.type === 'Reimbursment' || params.state.type === 'Reimbursement') {
                const response = await Api.PostInboxApprovallReimbursment(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Loan') {
                const response = await Api.PostInboxApprovallLoan(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Cash Advance') {
                const response = await Api.PostInboxApprovallCashAdvance(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Leave') {
                const response = await Api.PostInboxApprovallLeave(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='Permission') {
                const response = await Api.PostInboxApprovallPermission(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='Overtime') {
                const response = await Api.PostInboxApprovallOvertime(localStorage.getItem('hris-token'), data, id)
            }  
            navigate(-1)
        } catch (error) {
            console.log(error)
        }
    }
    
    const Decline = async(id) => {
        try {
            const data = {
                status: 'decline',
                toUser: toUser
            }
            if(params.state.type === 'Reimbursment' || params.state.type === 'Reimbursement') {
                const response = await Api.PostInboxApprovallReimbursment(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Loan') {
                const response = await Api.PostInboxApprovallLoan(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Cash Advance') {
                const response = await Api.PostInboxApprovallCashAdvance(localStorage.getItem('hris-token'), data, id)
            }  else if(params.state.type ==='Leave') {
                const response = await Api.PostInboxApprovallLeave(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Permission') {
                const response = await Api.PostInboxApprovallPermission(localStorage.getItem('hris-token'), data, id)
            } else if(params.state.type ==='Overtime') {
                const response = await Api.PostInboxApprovallOvertime(localStorage.getItem('hris-token'), data, id)
            } 
            navigate(-1)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetDetail()
    }, [])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Inbox'} LinkBack={'/inbox'}/>
                <div className='space-y-[24px] bg-white border px-[36px] py-[26px] rounded-[12px]'>
                    <div className='border-b-2 border-[#737373] py-2 space-y-[24px]'>
                        {type === 'Reimbursement' || type === 'Reimbursment' ?
                            <h1 className='text-[#454545] text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Reimbursement need approved for ${userData.fullname}` : `Reimbursment has been ${data.status}`}</h1>
                        : type === 'Loan' ?
                            <h1 className='text-[#454545] text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Loan need approved for ${userData.fullname}` : `Loan has been ${data.status}`}</h1>
                        : type === 'Cash Advance' ?
                            <h1 className='text-[#454545] text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Cash Advance need approved for ${userData.fullname}` : `Cash Advance has been ${data.status}`}</h1>
                        : type === 'Leave' ?
                            <h1 className='text-[#454545] text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Leave need approved for ${userData.fullname}` : `Leave has been ${data.status}`}</h1>
                        : type === 'Permission' ?
                            <h1 className='text-[#454545] text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Permission need approved for ${userData.fullname}` : `Permission has been ${data.status}`}</h1>
                        : type === 'Overtime' ?
                            <h1 className='text-[#454545] text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Overtime need approved for ${userData.fullname}` : `Overtime has been ${data.status}`}</h1>
                        :
                        <h1 className='text-[#454545] text-[20px] font-bold'>{localStorage.getItem('hris-role') === 'ADMIN' ? `Someone has applied for a job from ${recruitmentData.name}` : null}</h1>
                        }
                        <div className='flex flex-col lg:flex-row lg:items-center  justify-between'>
                            <div className='flex items-center gap-[8px]'>
                                <img src={LogoH} className='w-[41px] h-[41px] rounded-full object-cover border' alt='Humanusia'/>
                                <div className='space-y-0.5'>
                                    {type === 'Reimbursement' || type === 'Reimbursment' ?
                                        <h1 className='text-[#454545] text-sm font-[500]'>{userData.fullname}</h1>
                                    : type === 'Loan' ?
                                        <h1 className='text-[#454545] text-sm font-[500]'>{userData.fullname}</h1>
                                    : type === 'Cash Advance' ?
                                        <h1 className='text-[#454545] text-sm font-[500]'>{userData.fullname}</h1>
                                    : type === 'Leave' ?
                                        <h1 className='text-[#454545] text-sm font-[500]'>{userData.fullname}</h1>
                                    : type === 'Permission' ?
                                        <h1 className='text-[#454545] text-sm font-[500]'>{userData.fullname}</h1>
                                    : type === 'Overtime' ?
                                        <h1 className='text-[#454545] text-sm font-[500]'>{userData.fullname}</h1>
                                    :
                                        <h1 className='text-[#454545] text-sm font-[500]'>{recruitmentData.name}</h1>
                                    }
                                    {localStorage.getItem('hris-role') === 'ADMIN' &&
                                        <h1 className='text-[#5C5C5C] text-[11px]'>to me</h1>
                                    }
                                </div>
                            </div>
                            {/* <h1 className='text-[#454545] text-xs font-semibold mt-3 lg:mt-0'>{moment(data.createdAt).format('DD MMMM YYYY hh.mm')}</h1> */}
                        </div>
                    </div>
                    {/* <h1 className='text-[#454545] text-sm'>Reimbursement of funds has been approved on August 30, 2022 amounting to Rp. 350,000 and has been sent to your bank account.</h1> */}
                    { 
                        type === 'Reimbursement' || type === 'Reimbursment' ?
                            <InboxDetailReimburstment
                                EmployeeName={userData?.fullname}
                                jobPosition={dataJobPosition?? '-'}
                                Policy={settingData?.name}
                                Amount={data?.amount}
                                UseDate={moment(data?.use_date).format('DD/MM/YYYY')}
                                Notes={data?.note}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={localStorage.getItem('hris-role') === 'ADMIN' ? true : false}
                            />
                        : type === 'Loan' ?
                            <InboxDetailLoan
                                EmployeeName={userData?.fullname}
                                jobPosition={dataJobPosition?? '-'}
                                Policy={settingData?.name}
                                UseDate={moment(data?.use_date).format('DD/MM/YYYY')}
                                Interest={data?.interest}
                                Installment={data?.max_installment}
                                Amount={data?.amount}
                                Notes={data?.note}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={localStorage.getItem('hris-role') === 'ADMIN' ? true : false}
                            />
                        : type === 'Cash Advance' ?
                            <InboxDetailCashAdvance
                                EmployeeName={userData?.fullname}
                                jobPosition={dataJobPosition?? '-'}
                                Policy={settingData?.name}
                                RequestDate={moment(data?.request).format('DD/MM/YYYY')}
                                DateUse={moment(data?.use_date).format('DD/MM/YYYY')}
                                Amount={data?.amount}
                                Notes={data?.note}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={localStorage.getItem('hris-role') === 'ADMIN' ? true : false}
                            />
                        : type === 'Leave' ?
                            <InboxDetailLeave
                                EmployeeName={userData?.fullname}
                                JobPosition={dataJobPosition?? '-'}
                                StartDate={moment(data?.start_date).format('DD/MM/YYYY')}
                                EndDate={moment(data?.end_date).format('DD/MM/YYYY')}
                                Type={settingData?.type_name}
                                LeaveDuration={data?.leave_duration}
                                RemainingDayOff={data?.remaining_daysof}
                                Notes={data?.notes}
                                DelegatedEmployee={data.delegated_employee}
                                DelegatedTask={data?.delegated_task}
                                EmergencyContact={emergencyContact.name}
                                EmergencyPhone={emergencyContact.telephone}
                                EmergencyAddress={emergencyContact.addres}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={localStorage.getItem('hris-role') === 'ADMIN' ? true : false}
                            />
                        : type === 'Permission' ?
                            <InboxDetailPermission
                                EmployeeName={userData?.fullname}
                                JobPosition={dataJobPosition?? '-'}
                                PermissionReason={data?.permission_reason}
                                DateFilling={data?.dateof_filling}
                                SubmissionDate={data?.submission_time}
                                NumberOfDays={data?.num_ofdays}
                                StartTime={data?.start_time}
                                EndTime={data?.end_time}
                                Date={data?.backto_work}
                                Time={data?.backto_work_time}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={localStorage.getItem('hris-role') === 'ADMIN' ? true : false}
                            />
                        : type === 'Overtime' ?
                            <InboxDetailOvertime
                                EmployeeName={userData?.fullname}
                                JobPosition={dataJobPosition?? '-'}
                                OvertimeSubDate={data?.overt_submission_date}
                                OvertimeOnDate={data?.overt_ondate}
                                StartTime={data?.start_time}
                                EndTIme={data?.end_time}
                                Description={data?.reason}
                                BackToWork={data?.back_to_work}
                                BackToWorkTime={data?.back_to_work_time}
                                onClickDecline={() => Decline(data.id)}
                                onClickApprove={() => Approve(data.id)}
                                buttonHide={localStorage.getItem('hris-role') === 'ADMIN' ? true : false}
                            />
                        :
                        <div className='space-y-[24px] bg-[#F8F8F8] px-[28px] py-[40px] rounded-[12px] border'>
                            <div className='pb-[39px]'>
                                <h1 className='text-[#5C5C5C] text-[15px] font-semibold mb-[28px]'>Personal Detail</h1>
                                <div className='flex gap-[87px] text-[#737373] text-[11px]'>
                                    <div className='font-medium space-y-[27px]'>
                                        <h1>Full Name</h1>
                                        <h1>Birthdate</h1>
                                        <h1>Gender</h1>
                                        <h1>Age</h1>
                                        <h1>Email</h1>
                                        <h1>Sumber Lowongan</h1>
                                        <h1>Tanggal Melamar</h1>
                                        <h1>Position</h1>
                                        <h1>Nomor Telepon</h1>
                                        <h1>Alamat KTP</h1>
                                        <h1>Alamat Domisili</h1>
                                    </div>
                                    <div className='space-y-[27px]'>
                                        <h1>: {recruitmentData.name}</h1>
                                        <h1>: {recruitmentData.birthDate}</h1>
                                        <h1>: {recruitmentData.gender}</h1>
                                        <h1>: {recruitmentData.age}</h1>
                                        <h1>: {recruitmentData.email}</h1>
                                        <h1>: {recruitmentData.source}</h1>
                                        <h1>: {moment(recruitmentData.createdAt).format('DD MMMM YYYY')}</h1>
                                        <h1>: {!recruitmentData.title ? '-' : recruitmentData.title}</h1>
                                        <h1>: {recruitmentData.phone}</h1>
                                        <h1>: {recruitmentData.identityAddress}</h1>
                                        <h1>: {recruitmentData.address}</h1>
                                    </div>
                                </div>
                            </div>

                            <div className='pb-[34px]'>
                                <h1 className='mb-[30px] text-[13px] font-semibold text-[#5C5C5C]'>Education</h1>
                                <div className='text-[#A8A8A8] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>SMK N 1 Purwokerto</h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>IPA</h3>
                                    <p className='mb-[9px] font-light text-[10px]'>95,0</p>
                                    <hr />
                                </div>
                                <div className='text-[#737373] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>Universitas Gajah Mada </h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>Teknik Fisika</h3>
                                    <p className='mb-[9px] font-light text-[10px] text-[#A8A8A8]'>3,89</p>
                                    <hr />
                                </div>
                            </div>

                            <div className='mb-[49px]'>
                                <h1 className='mb-[30px] text-[13px] font-semibold text-[#5C5C5C]'>Experience details</h1>
                                <div className='text-[#737373] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>Machine Learning Engineering </h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>Tokopedia - <span className='text-[#A8A8A8]'>Fulltime</span></h3>
                                    <p className='mb-[9px] font-light text-[10px] text-[#A8A8A8]'>Jan 2021 - Sep 2021 · 9 bln</p>
                                    <hr />
                                </div>
                                <div className='text-[#737373] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>Machine Learning Engineering </h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>Tokopedia - <span className='text-[#A8A8A8]'>Partime</span></h3>
                                    <p className='mb-[9px] font-light text-[10px] text-[#A8A8A8]'>Okt 2021 - Jun 2022 · 9 bln</p>
                                    <hr />
                                </div>
                                <div className='text-[#737373] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>Data Analyst</h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>Traveloka-<span className='text-[#A8A8A8]'>Contract</span></h3>
                                    <p className='mb-[9px] font-light text-[10px] text-[#A8A8A8]'>Jan 2022 - Saat ini · 9 bln</p>
                                    <hr />
                                </div>
                            </div>
                            
                            <div className='grid grid-cols-4 gap-y-[49px]'>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Portofolio</h1>
                                    <a href={imageHandle(recruitmentData.portofolio)} target='_blank' className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</a>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>ID Card</h1>
                                    <a href={imageHandle(recruitmentData.ktp)} target='_blank' className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</a>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Family Card</h1>
                                    <a href={imageHandle(recruitmentData.kartuKeluarga)} target='_blank' className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</a>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Last Diploma</h1>
                                    <a href={imageHandle(recruitmentData.ijazah)} target='_blank' className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</a>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Transcripts</h1>
                                    <a href={imageHandle(recruitmentData.transkipNilai)} target='_blank' className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</a>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Final Vaccine Certificate</h1>
                                    <a href={imageHandle(recruitmentData.vaccince)} target='_blank' className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</a>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Applicant File</h1>
                                    <a href={imageHandle(recruitmentData.applicantFile)} target='_blank' className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</a>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default InboxDetail