import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IconInsertPhoto } from '../../../../assets'
import { Navbar, Sidebar } from '../../../../components'
import Api from '../../../../Api'
import { toast } from 'react-hot-toast'

const NewsCreate = () => {
    const navigate = useNavigate()
    const [viewImage, setViewImage] = useState('')
    const [uploadImage, setUploadImage] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const handleViewImage = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize){
            toast.error('gambar harus < 2MB')
            setUploadImage(null)
        } else if (!allowedExtensions.includes(fileExtension)){
            toast.error('file harus jpg, jpeg, atau png')
        } else {
            setViewImage(URL.createObjectURL(e.target.files[0]))
            const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                setUploadImage(reader.result)
            };
        }
    }

    const postData = async() => {
        try {
            const data = {
                title: title,
                description: description,
                image: uploadImage,
            }
            await Api.CreateNews(localStorage.getItem('hris-token'), data)
            toast.success('Success create news')
            navigate(-1)
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Add Company News'} LinkBack={'/news'}/>
                <div className='space-y-[24px]'>
                    <div className='border-b-2 py-2'>
                        <h1 className='text-[#454545] text-[24px] font-bold'>Add Company News</h1>
                    </div>
                    <div className='space-y-[30px]'>
                        <div>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Photo<span className='text-[#780000] ml-[2px]'>*</span></h1>
                            <label htmlFor='upload-image'>
                                <div className='w-full lg:w-[404px] h-[267px] rounded-[3px] bg-[#D9D9D9] bg-cover shadow-md border' style={{ backgroundImage: `url(${viewImage})` }}>
                                    {!viewImage &&
                                        <div className='flex flex-col justify-center items-center space-y-3 h-full'>
                                            <img src={IconInsertPhoto} alt='Insert Humanusia'/>
                                            <h1 className='text-[#A8A8A8] text-[8px] w-1/2 text-center'>*Acceptable file types are JPG, JPEG and PNG. Max file size is 2 MB.</h1>
                                        </div>
                                    }
                                </div>
                                <input type='file' className='hidden' id='upload-image' onChange={ (e) => handleViewImage(e) }/>
                            </label>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Title<span className='text-[#780000] ml-[2px]'>*</span></h1>
                            <input className='bg-white rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' value={title} onChange={ (e) => setTitle(e.target.value) } placeholder='ex: Kegiatan Outing bersama '/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Description <span className='text-[#780000] ml-[2px]'>*</span></h1>
                            <textarea rows={8} className='bg-white rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' value={description} onChange={ (e) => setDescription(e.target.value) } placeholder='Description... '/>
                        </div>
                    </div>
                    <div className='flex items-center justify-end gap-[12px]'>
                        <Link to={'/news'} className='bg-[#ECECEC] w-[100px] h-[38px] py-[10px] px-[25px] rounded-[6px] text-[#003049] text-sm'>Cancel</Link>
                        <button className='bg-[#0E5073] w-[100px] h-[38px] py-[10px] px-[25px] rounded-[6px] text-white text-sm' onClick={postData} >Add</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsCreate