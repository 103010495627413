import React, { useState } from 'react'
import { Navbar } from '../../../../components'
import { CgTrashEmpty } from 'react-icons/cg'
import { Link } from 'react-router-dom';

const PerformanceSetting = () => {
  const [performanceSettingNames, setPerformanceSettingNames] = useState([]);

  // Fungsi untuk menangani perubahan input Performance Setting Name
  const handleSettingNameChange = (e, index) => {
    const updatedNames = [...performanceSettingNames];
    updatedNames[index] = e.target.value;
    setPerformanceSettingNames(updatedNames);
  };

  // Fungsi untuk menghapus Performance Setting Name berdasarkan indeks
  const handleDeleteSettingName = (index) => {
    const updatedNames = [...performanceSettingNames];
    updatedNames.splice(index, 1);
    setPerformanceSettingNames(updatedNames);
  };

  // Fungsi untuk menambahkan Performance Setting Name baru
  const handleAddSettingName = () => {
    const updatedNames = [...performanceSettingNames, '']; // Tambahkan string kosong untuk item baru
    setPerformanceSettingNames(updatedNames);
  };

   // State untuk menyimpan data Performance Setting Points
   const [performanceSettingPoints, setPerformanceSettingPoints] = useState([]);

   // Fungsi untuk menangani perubahan input Performance Setting Point
   const handleSettingPointChange = (e, index) => {
     const updatedPoints = [...performanceSettingPoints];
     updatedPoints[index] = e.target.value;
     setPerformanceSettingPoints(updatedPoints);
   };
 
   // Fungsi untuk menghapus Performance Setting Point berdasarkan indeks
   const handleDeleteSettingPoint = (index) => {
     const updatedPoints = [...performanceSettingPoints];
     updatedPoints.splice(index, 1);
     setPerformanceSettingPoints(updatedPoints);
   };
 
   // Fungsi untuk menambahkan Performance Setting Point baru
   const handleAddSettingPoint = () => {
     const updatedPoints = [...performanceSettingPoints, '']; // Tambahkan string kosong untuk item baru
     setPerformanceSettingPoints(updatedPoints);
   };
   
   console.log(performanceSettingPoints)
   console.log(performanceSettingNames)



  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
        <div className='flex px-[30px] py-[35px] gap-[30px]'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Performance Setting'} LinkBack={'/dashboard'}/>
                <div className='flex items-center justify-between'>
                  <div>
                    <h1 className="text-zinc-800 text-xl font-semibold">Performance Setting</h1>
                    <h1 className="text-gray-500 text-sm font-normal">Add performance setting for employee performance</h1>
                  </div>
                  <Link to={'preview'} className='flex gap-[10px] items-center p-[10px] rounded-md border-[#113B61] border bg-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                      <path d="M16.7141 6.1363C17.1091 6.65297 17.1091 7.35047 16.7141 7.8663C15.4699 9.49047 12.4849 12.8346 8.99993 12.8346C5.51493 12.8346 2.52993 9.49047 1.28576 7.8663C1.09358 7.61891 0.989258 7.31457 0.989258 7.0013C0.989258 6.68804 1.09358 6.38369 1.28576 6.1363C2.52993 4.51214 5.51493 1.16797 8.99993 1.16797C12.4849 1.16797 15.4699 4.51214 16.7141 6.1363V6.1363Z" stroke="#113B61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9 9.5C10.3807 9.5 11.5 8.38071 11.5 7C11.5 5.61929 10.3807 4.5 9 4.5C7.61929 4.5 6.5 5.61929 6.5 7C6.5 8.38071 7.61929 9.5 9 9.5Z" stroke="#113B61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <h1 className="text-center text-cyan-900 text-sm font-medium">Preview</h1>
                  </Link>
                </div>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    {/* Performance Evaluation Repeater */}
                    <div className='rounded-xl border shadow p-[24px]'>
                        <div className='mb-[14px]'>
                          <h1 className="text-zinc-700 text-sm font-semibold mb-[12px]">Performance Evaluation 1</h1>
                            <div className='flex items-center gap-[12px]'>
                              <input type="text" className='text-gray-500 text-xs font-normal px-[18px] py-[10px] border rounded-md w-full' placeholder='Input Performance Evaluation'/>
                              <button className='p-[12px] bg-[#FFE1E1] rounded-[9px] flex items-center justify-center'>
                                  <CgTrashEmpty className='text-[#780000]'/>
                              </button>
                            </div>
                        </div>

                        {/* Performance Setting Name Repeater */}
                        {performanceSettingNames.map((settingName, index) => (
                          <div className='border shadow rounded-xl px-[27px] py-[20px] mb-[24px]' key={index}>
                            <h1 className="text-zinc-700 text-sm font-semibold">Performance Setting Name {index + 1}</h1>
                            <h1 className="text-gray-400 text-xs mb-[14px]">This will be displayed as the name of the employee's performance appraisal from the title</h1>
                            <div className='flex items-center gap-[12px] mb-[19px]'>
                              <input type="text" className='text-gray-500 text-xs font-normal px-[18px] py-[10px] border rounded-md w-full' placeholder='Input Performance Evaluation' value={settingName} onChange={(e) => handleSettingNameChange(e, index)} />
                              <button onClick={() => handleDeleteSettingName(index)} className='p-[12px] bg-[#FFE1E1] rounded-[9px] flex items-center justify-center'>
                                    <CgTrashEmpty className='text-[#4b2d2d]'/>
                                </button>
                            </div>
                            <div>
                            <h1 className="text-zinc-700 text-sm font-semibold">Performance Setting Point</h1>
                            <h1 className="text-gray-400 text-xs mb-[12px]">This will be displayed as the point of the employee's performance appraisal from the name setting</h1>
                            {/* Performance Setting Point Repeater */}
                            {performanceSettingPoints.map((settingPoint, index) => (
                              <div className='flex items-center gap-[12px] mb-[19px]' key={index}>
                                <input type="text" className='text-gray-500 text-xs font-normal px-[18px] py-[10px] border rounded-md w-full' placeholder='Input Performance Evaluation' value={settingPoint} onChange={(e) => handleSettingPointChange(e, index)} />
                                <button onClick={() => handleDeleteSettingPoint(index)} className='p-[12px] bg-[#FFE1E1] rounded-[9px] flex items-center justify-center'>
                                    <CgTrashEmpty className='text-[#4b2d2d]'/>
                                </button>
                              </div>
                            ))}
                            <button onClick={handleAddSettingPoint} className='flex items-center text-zinc-800 text-xs gap-[10px]'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M12 2.14844C6.49 2.14844 2 6.63844 2 12.1484C2 17.6584 6.49 22.1484 12 22.1484C17.51 22.1484 22 17.6584 22 12.1484C22 6.63844 17.51 2.14844 12 2.14844ZM16 12.8984H12.75V16.1484C12.75 16.5584 12.41 16.8984 12 16.8984C11.59 16.8984 11.25 16.5584 11.25 16.1484V12.8984H8C7.59 12.8984 7.25 12.5584 7.25 12.1484C7.25 11.7384 7.59 11.3984 8 11.3984H11.25V8.14844C11.25 7.73844 11.59 7.39844 12 7.39844C12.41 7.39844 12.75 7.73844 12.75 8.14844V11.3984H16C16.41 11.3984 16.75 11.7384 16.75 12.1484C16.75 12.5584 16.41 12.8984 16 12.8984Z" fill="#113B61"/>
                              </svg>
                              <h1 className="text-zinc-800 text-xs font-normal">Add point </h1>
                            </button>
                          </div>


                          </div>
                        ))}

                        <button onClick={handleAddSettingName} className='flex items-center text-zinc-800 text-xs gap-[10px]'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M12 2.14844C6.49 2.14844 2 6.63844 2 12.1484C2 17.6584 6.49 22.1484 12 22.1484C17.51 22.1484 22 17.6584 22 12.1484C22 6.63844 17.51 2.14844 12 2.14844ZM16 12.8984H12.75V16.1484C12.75 16.5584 12.41 16.8984 12 16.8984C11.59 16.8984 11.25 16.5584 11.25 16.1484V12.8984H8C7.59 12.8984 7.25 12.5584 7.25 12.1484C7.25 11.7384 7.59 11.3984 8 11.3984H11.25V8.14844C11.25 7.73844 11.59 7.39844 12 7.39844C12.41 7.39844 12.75 7.73844 12.75 8.14844V11.3984H16C16.41 11.3984 16.75 11.7384 16.75 12.1484C16.75 12.5584 16.41 12.8984 16 12.8984Z" fill="#113B61"/>
                          </svg>
                          <h1 className="text-zinc-800 text-xs font-normal">Add Performance Setting Name</h1>
                        </button>
                    </div>


                    <div className='flex items-center justify-end '>
                      <button className='flex items-center text-zinc-800 text-xs gap-[10px] rounded-xl border border-[#7E0000] py-[6px] px-[10px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" fill="#7E0000"/>
                        </svg>
                          <h1 className="text-[#7E0000] text-xs font-normal">Add Performance Evaluation</h1>
                      </button>
                    </div>
                </div>
            </div>
        </div>  
    </div>
  )
}

export default PerformanceSetting