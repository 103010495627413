import React, { useEffect, useState } from 'react'
import { BsImage } from 'react-icons/bs'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdModeEditOutline } from 'react-icons/md'
import { LogoH } from '../../../../../assets'
import { Navbar, Sidebar } from '../../../../../components'
import Api from '../../../../../Api'
import { toast } from 'react-hot-toast'
import axios from 'axios'

const GeneralInformation = () => {

    const [editActivated, setEditActivvated] = useState(false)
    
    // Send Data
    const [companyID, setCompanyID] = useState('')
    const [image, setImage] = useState('')
    const [imageBase64, setImageBase64] = useState('')
    const [organizationName, setOrganizationName] = useState('')
    const [numberOfEmploye, setNumberOfEmploye] = useState('')
    const [registrationNumber, setRegistrationNumber] = useState('')
    const [taxId, setTaxId] = useState('')
    const [phone, setPhone] = useState('')
    const [fax, setFax] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [country, setCountry] = useState('')
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [notes, setNotes] = useState('')
    const [dataCountry, setDataCountry] = useState([])
    const [dataProvince, setDataProvince] = useState([])
    const [dataCity, setDataCity] = useState()
    const [idProvince, setIdProvince] = useState()

    const handleChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageBase64(reader.result);
        };
        reader.readAsDataURL(file);
        setImage(URL.createObjectURL(file));
    }
    
    const GetData = async() => {
        try {
            const response = await Api.GetGeneralInformation(localStorage.getItem('hris-token'))
            setCompanyID(response.data.companyId)
            setImage(response.data.company.image)
            setOrganizationName(response.data.company.company_name)
            setNumberOfEmploye(response.data.company.number_of_employee)
            setRegistrationNumber(response.data.company.registration_number)
            setTaxId(response.data.company.tax_id)
            setPhone(response.data.company.phone)
            setFax(response.data.company.fax)
            setEmail(response.data.company.email)
            setAddress(response.data.company.address)
            setCountry(response.data.company.country)
            setProvince(response.data.company.province)
            setCity(response.data.company.city)
            setPostalCode(response.data.company.postal_code)
            setNotes(response.data.company.notes)   
        } catch (error) {
            console.log(error)
        }
    }

    const getCountry = async () => {
        try {
            const response = await Api.GetCountry(localStorage.getItem('hris-token'));
            const sortedData = response.data.sort((a, b) => {
            return a.name.common.localeCompare(b.name.common);
            });
            setDataCountry(sortedData);
        } catch (error) {
            console.log(error);
        }
    }

    const getProvince = () => {
        axios
          .get(
            `https://api.goapi.id/v1/regional/provinsi?api_key=9haY98mRpFbb8f3lAqgWsjIHzYrXoS`
          )
          .then((response) => {
            setDataProvince(response.data.data)
          })
          .catch((error) => {
          });
    };

    const handleProvinceChange = (e) => {
        const selectedProvince = e.target.value;
        const selectedIdProvince = e.target.options[e.target.selectedIndex].getAttribute('data-id');
        
        setProvince(selectedProvince);
        setIdProvince(selectedIdProvince);
      };

    const getCity = () => {
        axios
          .get(
            `https://api.goapi.id/v1/regional/kota?provinsi_id=${idProvince}&api_key=9haY98mRpFbb8f3lAqgWsjIHzYrXoS`
          )
          .then((response) => {
            setDataCity(response.data.data)
          })
          .catch((error) => {
          });
      };

    const updateData = async() => {
        try {
            if(!imageBase64) {
                const data ={
                    company_name : organizationName,
                    number_of_employee : numberOfEmploye,
                    registration_number : registrationNumber,
                    tax_id : taxId,
                    phone : phone,
                    fax : fax,
                    email : email,
                    address : address,
                    city : city,
                    province : province,
                    postal_code : postalCode,
                    country : country,
                    notes : notes,
                }
                const response = await Api.UpdateGeneralInformation(localStorage.getItem('hris-token'), data, companyID)
                toast.success('Success Update')
                setEditActivvated(!editActivated)

            } else {
                const data ={
                    company_name : organizationName,
                    number_of_employee : numberOfEmploye,
                    registration_number : registrationNumber,
                    tax_id : taxId,
                    phone : phone,
                    fax : fax,
                    email : email,
                    address : address,
                    city : city,
                    province : province,
                    postal_code : postalCode,
                    country : country,
                    notes : notes,
                    image: imageBase64
                }
                const response = await Api.UpdateGeneralInformation(localStorage.getItem('hris-token'), data, companyID)
                toast.success('Success Update')
                setEditActivvated(!editActivated)
            }
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    useEffect(() => {
        GetData()
        getCountry()
        getProvince()
    }, [])

    useEffect(() => {
        getCity()
      }, [province, dataProvince])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'General Information'} LinkBack={'/dashboard'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='flex items-center justify-between border-b py-[16px]'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-[#454545] text-[16px] font-[500]'>
                                General Information
                            </h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                General company information
                            </h1>
                        </div>
                        <button onClick={ () => setEditActivvated(!editActivated) } className={`${editActivated ? 'bg-[#A8A8A8]' : 'bg-[#0E5073]'} text-white text-sm rounded-[6px] py-[10px] px-[16px] flex items-center gap-[11px]`}>
                            <HiOutlinePencil className='text-white'/>
                            Edit
                        </button>
                    </div>
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Company Logo</h1>
                            <label htmlFor='upload-image' className={`${editActivated ? 'cursor-pointer' : 'cursor-default'}`}>
                                <div className='w-[105px] h-[105px] border rounded-[12px] bg-[#C6C6C6] flex flex-col items-center justify-center bg-cover relative' style={{ backgroundImage: `url(${!image ? LogoH : image})` }}>
                                    <BsImage className='text-2xl text-[#EDEDED]'/>
                                    <div className='absolute bg-[#F9F9F9] border border-[#EBEBEB] w-[38px] h-[38px] rounded-full -bottom-3 -right-4 flex items-center justify-center'>
                                        <MdModeEditOutline/>
                                    </div>
                                </div>
                                <input disabled={editActivated ? false : true} type='file' id='upload-image' onChange={(e) => handleChange(e)}  className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required/>
                            </label>
                        </div>
                        <div className='flex items-center gap-[30px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Organization Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Organization Name...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Number of Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input value={numberOfEmploye} onChange={(e) => setNumberOfEmploye(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Number Of Employee...'/>
                            </div>
                        </div>
                        <div className='flex items-center gap-[30px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Registration Number<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input value={registrationNumber} onChange={(e) => setRegistrationNumber(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Registration Number...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Tax ID</h1>
                                <input value={taxId} onChange={(e) => setTaxId(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='123-345-5678'/>
                            </div>
                        </div>
                        <div className='flex items-center gap-[30px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Phone Number<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input value={phone} onChange={(e) => setPhone(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='0281-xxx-xxx'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Fax</h1>
                                <input value={fax} onChange={(e) => setFax(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='0224203450'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Email</h1>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='humanusia@example.com'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Address<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <textarea value={address} onChange={(e) => setAddress(e.target.value)} disabled={editActivated ? false : true} type='text' rows={4}  className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Your Address...'/>
                        </div>
                        <div className='flex items-center gap-[30px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Country</h1>
                                    <div className='relative'>
                                        <select  onChange={(e) => setCountry(e.target.value)} disabled={editActivated ? false : true} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                            <option selected value={''}>Select Country</option>
                                            {Object.values(dataCountry).map((item, idx) => (
                                            <option key={idx} value={item.name.common}>{item.name.common}</option>
                                            ))}
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                    </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Province<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select value={province} onChange={handleProvinceChange} disabled={editActivated ? false : true} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                        <option selected value={''}>Select Province</option>
                                        {Object.values(dataProvince).map((item, idx) => (
                                        <option data-id={item.id} key={idx} value={item.name}>{item.name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center gap-[30px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>City<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select value={city} onChange={(e) => setCity(e.target.value)} disabled={editActivated ? false : true} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                        <option selected value={''}>Select City</option>
                                        {Object.values(dataCity).map((item, idx) => (
                                            <option key={idx} value={item.name}>{item.name}</option>
                                            ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Postal Code<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input value={postalCode} onChange={(e) => setPostalCode(e.target.value)} disabled={editActivated ? false : true} type={'text'} className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Postal Code...'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Notes<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <textarea value={notes} onChange={(e) => setNotes(e.target.value)} disabled={editActivated ? false : true} type='text' rows={4}  className={`${editActivated ? 'bg-white' : 'bg-[#EDEDED]'} border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full`} placeholder='Notes...'/>
                        </div>
                        { editActivated && 
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button  onClick={ () => setEditActivvated(!editActivated) } className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                                <button onClick={updateData} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralInformation