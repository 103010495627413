import React, { useState } from 'react'
import { Navbar, Sidebar } from '../../../../../components'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { BiCloudUpload, BiDownload } from 'react-icons/bi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { RxCross2 } from 'react-icons/rx'
import Api from '../../../../../Api'

const DataImport = () => {
    const navigate = useNavigate()
    const [namepath, setNamePath] = useState('')
    const [file, setFile] = useState('')

    const handleChangeFile = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['xlsx', 'xls'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize){
            toast.error('file tidak boleh lebih dari 10 MB')
            setFile(null)
        } else if (!allowedExtensions.includes(fileExtension)){
            toast.error('file harus berformat excel')
        } else{
            setNamePath(e.target.files[0].name)
            setFile(e.target.files[0])
        }
    }

    const ImportData = async() => {
        const data = {
            file: file
        }
        try {
            const response = await Api.UploadEmployee(localStorage.getItem('hris-token'), data)
            toast.success('Import Data Employee Successfully')
            navigate('/employee')
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Import Data'} LinkBack={'/dashboard'}/>
                <div className='space-y-[32px] bg-white px-[46px] py-[40px] rounded-[12px] border'>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] font-[600] text-[20px]'>Import Job and Employee Data</h1>
                        <h1 className='text-[#737373] text-[14px]'>Import a file to update external user data </h1>
                    </div>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] text-[16px] font-[600]'>Step :</h1>
                        <h1 className='text-[#464E5F] text-[14px]'>Follow these steps to perform the correct import</h1>
                        <ol className='list-decimal text-[#464E5F] text-[14px] list-outside ml-3'>
                            <li>
                            In the download template section, select the template type according to the correct filling order, Make sure you follow the correct document import sequence, namely:
                            </li>
                        </ol>
                        <ul className='list-disc text-[#464E5F] text-[14px] list-inside ml-3'>
                            <li>
                                Import Job Management
                            </li>
                            <li>
                                Import Employee Data
                            </li>
                        </ul>
                        <h1 className='text-[#464E5F] text-[14px]'>then click <span className='font-bold'>'Download Template'</span></h1>
                        <ol className='list-decimal text-[#464E5F] text-[14px] list-outside ml-3' start={2}>
                            <li>
                                Fill in data according to the available template
                            </li>
                            <li>
                                Make sure the data  you fill is correct
                            </li>
                            <li>
                                After all the data is filled in,Choose File to upload, then click <span className='font-bold'>‘Upload File’</span>
                            </li>
                        </ol>
                    </div>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] text-[16px] font-[600]'>Download Template :</h1>
                        <div className='flex items-center gap-[8px]'>
                            <div className='relative'>
                                <select className='text-[#6B7280] text-[11px] outline-none font-medium rounded-md w-[227px] border py-[11px] pl-[9px] pr-[16px] shadow-sm appearance-none'>
                                    <option selected className='text-[#6B7280] font-normal '>Select template type</option>
                                    <option className='text-[#25272C]' value="Job Management">Job Management</option>
                                    <option className='text-[#25272C]' value="Employee Data">Employee Data</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                            <button className='flex bg-[#015995] items-center justify-center px-[8px] py-[7px] gap-[4px] rounded-lg text-white font-medium text-[14px]'>
                                <BiDownload className='text-xl'/>
                                <h1>Download Template</h1>
                            </button>
                        </div>
                    </div>
                    <div className='space-y-1'>
                        <h1 className='text-[#272B30] text-[16px] font-[600]'>Upload File <span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <h1 className='text-[#C1121F] text-[8px]'>*Upload File max 10 MB</h1>
                        <div className='flex items-center gap-[13px]'>
                            <label htmlFor='upload-file' className='cursor-pointer'>
                                <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-[227px]'>
                                    <h1 className='text-[#8E95A2] text-[12px] font-medium'>Choose File</h1>
                                </div>
                                <input type='file' id='upload-file' accept=".xlsx, .xls" onChange={(e) => handleChangeFile(e)}  className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required/>
                            </label>
                            <button className='flex bg-[#015995] items-center justify-center px-[8px] py-[7px] gap-[4px] rounded-lg text-white font-medium text-[14px]'>
                                <BiCloudUpload className='text-xl'/>
                                <h1>Upload File</h1>
                            </button>
                        </div>
                        {(namepath && file) && 
                            <div className='py-[6px] px-[8px] bg-[#D8DBDF] rounded flex gap-[8px] justify-between w-fit'>
                                <h1 className='text-[10px] text-[#737373] truncate'>{namepath}</h1>
                                <button onClick={() => setFile('')}> <RxCross2 className='text-sm'/></button>
                            </div>
                        }
                    </div>
                    
                    <button onClick={ImportData} className='bg-[#015995] rounded-[6px] flex items-center gap-[12px] px-[17px] py-[10px]'>
                        <h1 className='text-white text-sm font-[500]'>Upload</h1>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DataImport