import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import { BiFilter, BiPlus } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowUp, MdModeEditOutline } from 'react-icons/md'
import {  Link, useNavigate } from 'react-router-dom'
import { TbFileExport } from 'react-icons/tb'
import { FiSearch } from 'react-icons/fi'
import { VscSignOut } from 'react-icons/vsc'
import moment from 'moment'
import { BsFilter, BsImage } from 'react-icons/bs'
import { LogoH } from '../../../../assets'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import { toast } from 'react-hot-toast'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { debounce } from 'lodash'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import imageHandle from '../../../../utils/imageHandle'

const EmployeeList = () => {
    const navigate = useNavigate()
    const [showDetail, setShowDetail] = useState(false)
    const [showFilter, setShowFilter] = useState()
    const [showModal, setShowModal] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [valueSwitch, setvalueSwitch] = useState(true)
    const [image, setImage] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [passwordType, setPasswordType] = useState('password')
    const [confirmPasswordType, setConfirmPasswordType] = useState('password')


    // Employe State
    const [employeeStatusData, setEmployeeStatusData] = useState('')
    const [jobGradeData, setJobGradeData] = useState('')
    const [jobLevelData, setJobLevelData] = useState('')
    const [jobPositionData, setJobPositionData] = useState('')
    const [employeeData, setEmployeeData] = useState('')
    const [employeeDataExport, setEmployeeDataExport] = useState('')
    const [fullname, setFullname] = useState('')
    const [employeeId, setEmployeeId] = useState('')
    const [isVerified, setIsVerified] = useState()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [role, setRole] = useState('')
    const [joinDate, setJoinDate] = useState('')
    const [uploadImage, setUploadImage] = useState('')
    const [jobLevel, setJobLevel] = useState('')
    const [jobGrade, setJobGrade] = useState('')
    const [jobPosition, setJobPosition] = useState('')
    const [employeeStatus, setEmployeeStatus] = useState('')
    const [page, setPage] = useState('')
    const [nameSearch, setNameSearch] = useState('')

    // Filter State
    const [jobGradeFilter, setJobGradeFilter] = useState('')
    const [jobLevelFilter, setJobLevelFilter] = useState('')
    const [jobPositionFilter, setJobPositionFilter] = useState('')
    const [companyLocationFilter, setCompanyLocationFilter] = useState('')
    const [joinDateFilter, setJoinDateFilter] = useState('')
    const [employeeStatusFilter, setEmployeeStatusFilter] = useState('')
    const [currentFilter, setCurrentFilter] = useState('')

    // openIndex
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)

    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalCount, setTotalCount] = useState('')
    const [dataPackage, setDataPackage] = useState('')
    const [idPackage, setIdPackage] = useState('')

    const handleCollapse = (id) => {
        if(id){
            setCurrent(id)
            setActive(!Active)
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const ChangePasswordType = () => {
        if(passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }
    const ChangeConfirmPasswordType = () => {
        if(confirmPasswordType === 'password') {
            setConfirmPasswordType('text')
        } else {
            setConfirmPasswordType('password')
        }
    }
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    // const handleChange = (e) => {
    //     const maxSize = 2 * 1024 * 1024
    //     const allowedExtensions = ['jpg', 'jpeg', 'png'];
    //     const fileExtension = file.name.split('.').pop().toLowerCase();
    //     setImage(URL.createObjectURL(e.target.files[0]))
    //     const file = e.target.files[0]
    //     const reader = new FileReader()
    //         reader.readAsDataURL(file)
    //         reader.onload = () => {
    //         setUploadImage(reader.result)
    //     };
    // }

    const handleChange = (e) => {
        const maxSize = 5 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize){
            toast.error('gambar harus < 5MB')
            setUploadImage(null)
        } else if (!allowedExtensions.includes(fileExtension)){
            toast.error('file harus jpg, jpeg, atau png')
        } else {
            setImage(URL.createObjectURL(e.target.files[0]))
            const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                setUploadImage(reader.result)
            };
        }
    }

    const handleSwitch = (e) => {
        setvalueSwitch(e)
    }

    const GetPackage = async() => {
        try {
            const response = await Api.GetAllPackage(localStorage.getItem('hris-token'))
            setDataPackage(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const GetEmployeeStatus = async() => {
        try {
            const response = await Api.GetEmployeeStatus(localStorage.getItem('hris-token'))
            setEmployeeStatusData(response.data.employe_status)
        } catch (error) {
            console.log(error)
        }
    }

    const GetJobData = async() => {
        try {
            const resGrade = await Api.GetJobGrade(localStorage.getItem('hris-token'), 1, 100)
            const resLevel = await Api.GetJobLevel(localStorage.getItem('hris-token'), 1, 100)
            const resPosition = await Api.GetJobPosition(localStorage.getItem('hris-token'), 1, 100)
            setJobGradeData(resGrade.data.data)
            setJobLevelData(resLevel.data.data)
            setJobPositionData(resPosition.data.data)
        } catch (error) {
            console.log(error)   
        }
    }

    const GetEmployee = async() => {
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), nameSearch, currentPage, limit , jobGradeFilter, jobLevelFilter, jobPositionFilter, companyLocationFilter, joinDateFilter, employeeStatusFilter)
            setEmployeeData(response.data.data.filter(item => item.role === 'USER'))
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalCount(response.data.totalCount)
            console.log(response, 'employeeo')
        } catch (error) {
            console.log(error)
        }
    }
    
    const GetEmployeeForExport = async() => {
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), '','', '','','','','','','')
            setEmployeeDataExport(response.data.data.filter(item => item.role === 'USER'))
            console.log(response, 'export')
        } catch (error) {
            console.log(error)
        }
    }
      
    const [filter, setFilter] = useState({
        jobGradeFilter: '',
        jobLevelFilter: '',
        jobPositionFilter: '',
        companyLocationFilter: '',
        joinDateFilter: '',
        employeeStatusFilter: ''
    });

    const debouncedFilter = debounce(async (filterValues) => {
        try {
            const response = await Api.GetEmployee(
                localStorage.getItem('hris-token'),
                fullname,
                currentPage,
                limit,
                filterValues.jobGradeFilter,
                filterValues.jobLevelFilter,
                filterValues.jobPositionFilter,
                filterValues.companyLocationFilter,
                filterValues.joinDateFilter,
                filterValues.employeeStatusFilter
            );
            console.log(response, 'filtered')
            setEmployeeData(response.data.data.filter((item) => item.role === 'USER'));
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.log(error);
        }
    }, 300);
      
    const handleFilter = (e) => {
        const { name, value } = e.target;
        const updatedFilter = {
          ...filter,
          [name]: value,
        };
        setFilter(updatedFilter);
        debouncedFilter(updatedFilter);
    };

    const handleResetFilter = () => {
        setFilter({
            jobGradeFilter: '',
            jobLevelFilter: '',
            jobPositionFilter: '',
            companyLocationFilter: '',
            joinDateFilter: '',
            employeeStatusFilter: '' 
        })
        setShowFilter(!showFilter)
        window.location.reload()
    }
    const debouncedSearchName = debounce(async(name) => {
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), name, currentPage, limit, jobGradeFilter, jobLevelFilter, jobPositionFilter, companyLocationFilter, joinDateFilter, employeeStatusFilter)
            setEmployeeData(response.data.data.filter(item => item.role === 'USER'))
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            console.log(response, 'filtered')
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }
    
    const debouncedLimit = debounce(async(limit) => {
        setLimit(limit)
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), nameSearch, currentPage, limit, jobGradeFilter, jobLevelFilter, jobPositionFilter, companyLocationFilter, joinDateFilter, employeeStatusFilter)
            setEmployeeData(response.data.data.filter(item => item.role === 'USER'))
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            console.log(response, 'filtered')
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleLimit= (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimit(limit)
    }

    const resetForm = () =>{
        setFullname('')
        setEmployeeId('')
        setIsVerified('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setJoinDate('')
        setUploadImage('')
        setJobLevel('')
        setJobGrade('')
        setJobPosition('')
        setEmployeeStatus('')
        setUploadImage('')
    }

    const CreateEmployee = async() => {
        try {
            const data = {
                fullname : fullname,
                isVerified : JSON.parse(isVerified),
                email : email,
                password : password,
                role: 'EMPLOYEE',
                employee_id : employeeId,
                join_date : joinDate,
                job_grade : jobGrade,
                job_level : jobLevel,
                job_position : jobPosition,
                employe_status : employeeStatus,
                image : uploadImage, 
            }
            if (password !== confirmPassword) {
                toast.error("Password doesn't match!!!")
            } else {
                const response = await Api.CreateEmployee(localStorage.getItem('hris-token'), data)
                resetForm()
                setRefresh(true)
                toast.success('Success Create')
                setShowModal(!showModal)
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed create, pastikan semua field terisi!')

        }
    }

    // const OpenModalUpdateEmployee = async(id) => {
    //     setShowModal(!showModal)
    //     setEmployeeId(id)
    //     try {
    //         const response = await Api.GetEmployeeById(localStorage.getItem('hris-token'), id)
    //         console.log(response)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const UpdateEmployee = async() => {
    //     const data = {

    //     }
    //     try {
    //         const response = await Api.UpdateEmployee(localStorage.getItem('hris-token'), data)
    //         console.log(response)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const DeleteEmployeeModal = async(id) => {
        setShowModalDelete(!showModalDelete)
        setEmployeeId(id)
    }

    const DeleteEmployee = async(id) => {
        try {
            const response = await Api.DeleteEmployee(localStorage.getItem('hris-token'), employeeId)
            setRefresh(true)
            setShowModalDelete(!showModalDelete)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')

        }
    }


    // Export Employee
    const exportToExcel = () => {
        // Sample data array
        const data = employeeDataExport;

        // Define custom headers for each table
        const Headers = ['Fullname', 'Gender', 'Email', 'Other Email', 'Phone', 'Telephone', 'Address', 'Birthdate', 'Country', 'Province', 'City', 'Postal Code', 'Martial Status', 'Nationalites', 'Driver License', 'License Expired', 'Employee Status', 'Employee ID', 'Other ID', 'Start Date', 'End Date', 'Job Grade', 'Job Level', 'Job Position', 'Location'];

        // Create modified data arrays with custom headers
        const jobGrade = data.map(({ fullname, user_detail, email, phone, isVerified }) => ({
            'Fullname': fullname || '-',
            'Gender': user_detail?.gender || '-',
            'Email': email || '-',
            'Other Email': user_detail?.other_email || '-',
            'Phone': phone || '-',
            'Telephone': user_detail?.telephone || '-',
            'Address': user_detail?.address || '-',
            'Birthdate': user_detail?.birthdate || '-',
            'Country': user_detail?.country || '-',
            'Province': user_detail?.province || '-',
            'City': user_detail?.city || '-',
            'Postal Code': user_detail?.postal_code || '-',
            'Martial Status': user_detail?.marital_status || '-',
            'Nationalites': user_detail?.nationalites || '-',
            'Driver License': user_detail?.driver_license || '-',
            'License Expired': user_detail?.license_expired || '-',
            'Employee Status': isVerified || '-',
            'Employee ID': user_detail?.employee_id || '-',
            'Other ID': user_detail?.other_id || '-',
            'Start Date': user_detail?.start_date || '-',
            'End Date': user_detail?.end_date || '-',
            'Job Grade': user_detail?.job_grade?.job_grade || '-',
            'Job Level': user_detail?.job_level?.level_name || '-',
            'Job Position': user_detail?.job_position?.position_name || '-',
            'Location': user_detail?.location || '-'
        }));

        // Create a new worksheet for each table
        const worksheetGrade = XLSX.utils.json_to_sheet(jobGrade, { header: Headers });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Employee List');
        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, 'Employee.xlsx');
    };

    useEffect(() => {
        debouncedFilter(filter);
        GetEmployeeStatus()
        GetJobData()
        GetEmployee()
        GetPackage()
        GetEmployeeForExport()
        setRefresh(false)
        return () => {
            debouncedFilter.cancel();
          };
    }, [refresh, limit, filter])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModal}
                buttonClose={() => setShowModal(!showModal)}
                width={'550px'}
                title={'Add Employee'}
                content={
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <label htmlFor='upload-image'>
                                <div className='w-[105px] cursor-pointer h-[105px] border rounded-[12px] bg-[#C6C6C6] flex flex-col items-center justify-center bg-cover relative' style={{ backgroundImage: `url(${!image ? LogoH : image})` }}>
                                    <BsImage className='text-2xl text-[#EDEDED]'/>
                                    <div className='absolute bg-[#F9F9F9] border border-[#EBEBEB] w-[38px] h-[38px] rounded-full -bottom-3 -right-4 flex items-center justify-center'>
                                        <MdModeEditOutline/>
                                    </div>
                                </div>
                                <input type='file' id='upload-image' onChange={(e) => handleChange(e)}  className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required/>
                            </label>
                            <h1 className='text-[#A8A8A8] text-[10px] font-[300] mt-[15px] italic'>*Accepts jpg, .png, .gif up to 5MB.</h1>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee Full Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input onChange={(e) => setFullname(e.target.value)} value={fullname} type={'text'} className= 'border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Employee Full Name'/>
                        </div>
                        <div className='flex items-center gap-3'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee ID<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setEmployeeId(e.target.value)} value={employeeId} type={'text'} className= 'border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Employee ID'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Join Date <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={(e) => setJoinDate(e.target.value)} value={joinDate} type={'date'} className= 'border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Employee Full Name'/>
                            </div>
                        </div>
                        <div className='flex items-center gap-3'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee Status <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setEmployeeStatus(e.target.value)} value={employeeStatus} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                        <option selected value={''} disabled>Select Employee Status</option>
                                        {Object.values(employeeStatusData).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.employee_status}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Job Grade <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setJobGrade(e.target.value)} value={jobGrade} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                        <option selected value={''} disabled>Select Job Grade</option>
                                        {Object.values(jobGradeData).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.job_grade}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center gap-3'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Job Level <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                     <select onChange={(e) => setJobLevel(e.target.value)} value={jobLevel} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                        <option selected value={''} disabled>Select Job Level</option>
                                        {Object.values(jobLevelData).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.level_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Job Position <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setJobPosition(e.target.value)} value={jobPosition} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                        <option selected value={''} disabled>Select Job Position</option>
                                        {Object.values(jobPositionData).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.position_name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                        </div>
                        {/* <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Company Location <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select value={''} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                    <option selected value={''}>Select Company Location</option>
                                    <option value={'Option 1'}>Option 1</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div> */}

                        {/* <div className='w-full'>
                            <label className='relative inline-flex items-center cursor-pointer'>
                                <input type='checkbox' onChange={ (e) =>  handleSwitch(e.target.checked) } className='sr-only peer'/>
                                <div className='w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600'/>
                            </label>
                        </div> */}
                        {valueSwitch && 
                            <div className='space-y-[25px]'>
                                <h1 className='text-[13px] font-medium text-[#5C5C5C] underline'>Login Details</h1>
                                <div className='flex items-center gap-3'>
                                    <div className='w-full'>
                                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Email<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                        <input type={'text'} onChange={(e) => setEmail(e.target.value)} value={email} className= 'border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Email'/>
                                    </div>
                                    <div className='w-full'>
                                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Status <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                        <div className='relative'>
                                            <select onChange={(e) => setIsVerified(e.target.value)} value={isVerified} className={`border rounded-[6px] text-[#A8A8A8] text-[12px] px-[8px] py-[10px] w-full appearance-none`}>
                                                <option selected value={''} disabled>Select Status</option>
                                                <option value={true}>Enable</option>
                                                <option value={false}>Disable</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <div className='w-full'>
                                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Password<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                        <div className='relative'>
                                            <input onChange={(e) => setPassword(e.target.value)} value={password} type={passwordType} className= 'border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Password'/>
                                            <button onClick={ChangePasswordType} className='absolute right-5 top-3 text-xl text-[#A8A8A8]'>
                                                {passwordType === 'text' ?
                                                    <AiOutlineEye/>
                                                :
                                                    <AiOutlineEyeInvisible/>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Confirm Password <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                        <div className='relative'>
                                            <input onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} type={confirmPasswordType} className= 'border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Confirm Password'/>
                                            <button onClick={ChangeConfirmPasswordType} className='absolute right-5 top-3 text-xl text-[#A8A8A8]'>
                                                {confirmPasswordType === 'text' ?
                                                    <AiOutlineEye/>
                                                :
                                                    <AiOutlineEyeInvisible/>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModal(!showModal)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={CreateEmployee} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={DeleteEmployee}
            />
            <Modal 
                activeModal={showFilter}
                buttonClose={ () => setShowFilter(!showFilter)}
                width={'450px'}
                title={
                    <div className='flex gap-[27px] items-center'>
                        <BsFilter className='text-[#282828] text-2xl'/>
                        <h1 className='text-[#282828] text-[20px] font-semibold'>Filter</h1>
                    </div>
                }
                content= {
                    <div className='space-y-[20px]'>
                        <div className='text-[#5C5C5C] text-[12px] font-medium'>
                            <h1 className='mb-[6px] font-semibold'>Job Grade</h1>
                            <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                                {Object.values(jobGradeData).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-[19.5px] mb-[3px]'>
                                        <input type="radio" name='jobGradeFilter' id={'radioGrade'+idx} onChange={handleFilter} value={item.job_grade} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                        <label htmlFor={'radioGrade'+idx}>{item.job_grade}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='text-[#5C5C5C] text-[12px] font-medium'>
                            <h1 className='mb-[6px] font-semibold'>Job Level</h1>
                            <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                                {Object.values(jobLevelData).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-[19.5px] mb-[3px]'>
                                        <input type="radio" name='jobLevelFilter' id={'radioLevel'+idx} onChange={handleFilter} value={item.level_name} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                        <label htmlFor={'radioLevel'+idx}>{item.level_name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='text-[#5C5C5C] text-[12px] font-medium'>
                            <h1 className='mb-[6px] font-semibold'>Position</h1>
                            <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                                {Object.values(jobPositionData).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-[19.5px] mb-[3px]'>
                                        <input type="radio" name='jobPositionFilter' id={'radioPosition'+idx} onChange={handleFilter} value={item.position_name} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                        <label htmlFor={'radioPosition'+idx}>{item.position_name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='text-[#5C5C5C] text-[12px] font-medium'>
                            <h1 className='mb-[6px] font-semibold'>Employee Status</h1>
                            <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                                {Object.values(employeeStatusData).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-[19.5px] mb-[3px]'>
                                        <input type="radio" name='employeeStatusFilter' id={'radioStatus'+idx} onChange={handleFilter} value={item.employee_status} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                        <label htmlFor={'radioStatus'+idx}>{item.employee_status}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <div className='text-[#5C5C5C] text-[12px] font-medium'>
                            <h1 className='mb-[6px] font-semibold'>Company Location</h1>
                            <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Company Location 1</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Company Location 2</h1>
                                </div>
                            </div>
                        </div> */}
                        <div className='text-[#5C5C5C] text-[12px] font-medium w-full'>
                            <h1 className='mb-[6px] font-semibold'>Join Date</h1>
                            <input onChange={handleFilter} name='joinDateFilter' type="date" className='py-[10px] px-[12px] text-[12px] border rounded-md text-[#A8A8A8] w-full' />
                        </div>
                        <div className='flex items-center justify-between'>
                            <button onClick={handleResetFilter} className='py-[10px] px-[15px] text-[#0E5073] text-[14px] font-medium flex justify-center rounded-md items-center border'>Reset Filter</button>
                            <button onClick={() => setShowFilter(!showFilter)} className='py-[10px] px-[15px] bg-[#0E5073] text-white text-[14px] font-medium flex justify-center rounded-md items-center'>Apply Filter</button>
                        </div>
                    </div>
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Employee'} LinkBack={'/dashboard'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-[#25272C] text-[20px] font-[600]'>
                                List of Employee
                            </h1>
                            <h1 className='text-[#40444C] text-[14px] font-[400]'>
                                List of employees in the company
                            </h1>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                        <div className='flex items-center gap-[13px] mt-3 lg:mt-0 w-full lg:w-auto'>
                            <button onClick={() => setShowFilter(!showFilter)} className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <BiFilter className='text-[#0E5073]'/>
                                <h1 className='text-[#0E5073] text-sm font-[500]'>Filter</h1>
                            </button>
                            <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                <option value="" selected disabled>Set Limit</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                            </select>
                            <button onClick={exportToExcel} className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <TbFileExport className='text-[#0E5073]'/>
                                <h1 className='text-[#0E5073] text-sm font-[500]'>Export</h1>
                            </button>
                            <Link to={'mass-resign'} className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <VscSignOut className='text-[#8E95A2]'/>
                                <h1 className='text-[#8E95A2] text-sm font-[500]'>Mass Resign</h1>
                            </Link>
                        </div>
                        <div className='flex flex-col lg:flex-row lg:items-center gap-[13px] mt-3 lg:mt-0 w-full lg:w-auto'>
                            <div className='relative'>
                                <input onChange={handleSearchName} placeholder='Search by Employee Name...' className='bg-white border rounded-[6px] text-[#A8A8A8] text-sm pl-10 w-full lg:w-[300px] flex items-center gap-[12px] px-[10px] py-[8px]'/>
                                <FiSearch className='text-[#A8A8A8] absolute top-[11px] left-3'/>
                            </div>
                            <Link to={'add-employee'} className='bg-[#0E5073] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <BiPlus className='text-white'/>
                                <h1 className='text-white text-sm font-[500]'>Add Employee</h1>
                            </Link>
                        </div>
                    </div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Employee</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px] justify-center'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Job Grade</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px] justify-center'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Job Level</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px] justify-center'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Job Position</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px] justify-center'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Employee Status</h1>
                                </div>
                                <div className='flex items-center gap-[15px] justify-center w-full'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Action</h1>
                                </div>
                            </div>
                            {employeeData.length === 0 ?
                            <div>
                                <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] ${showDetail ? 'rounded-t-[3px]' : 'rounded-[3px]'}`}>
                                    <div className='min-w-[300px] max-w-[300px]'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                    </div>
                                    <div className='min-w-[100px] max-w-[100px]'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                    </div>
                                    <div className='min-w-[100px] max-w-[100px]'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                    </div>
                                    <div className='flex items-center justify-center w-full gap-[12px]'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                    </div>
                                </div>
                            </div>
                            :
                            Object.values(employeeData).map((data, index) => {
                                return (
                                    <div key={index}>
                                        <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] ${showDetail ? 'rounded-t-[3px]' : 'rounded-[3px]'}`}>
                                            <div className='min-w-[300px] max-w-[300px]'>
                                                <div className='flex gap-[15px]'>
                                                    <div className='w-[34px] h-[34px] bg-white border'>
                                                        <img className='w-full h-full' src={data.user_detail.image? imageHandle(data.user_detail.image) : LogoH} />
                                                    </div>
                                                    <div>
                                                        <button onClick={() => navigate('/employee/detail-employee', {state: {employeeId: data.id}})} className='text-[#6B7280] text-xs font-[500] truncate hover:underline'>{!data.fullname ? '-' : data.fullname}</button>
                                                        <h1 className='text-[#B6BAC3] text-xs font-[500] truncate'>M001M01NJF002</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate text-center'>{!data.user_detail.job_grade ? '-' : data.user_detail.job_grade.job_grade}</h1>
                                            </div>
                                            <div className='min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate text-center'>{!data.user_detail.job_level ? '-' : data.user_detail.job_level.level_name}</h1>
                                            </div>
                                            <div className='min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate text-center'>{!data.user_detail.job_position ? '-' : data.user_detail.job_position.position_name}</h1>
                                            </div>
                                            <div className='min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate text-center'>{!data.user_detail.employe_status ? '-' : data.user_detail.employe_status.employee_status}</h1>
                                            </div>
                                            <div className='flex items-center justify-center w-full gap-[12px]'>
                                                <button onClick={() => navigate('/profile', {state: {employeeId: data.id}})} className='w-[29px] h-[29px] bg-[#CFDEF8] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]'/>
                                                </button>
                                                <button onClick={() => DeleteEmployeeModal(data.id)} className='w-[29px] h-[29px] bg-[#CFDEF8] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]'/>
                                                </button>
                                                <button onClick={ () => handleCollapse(data.id) } className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                    { data.id === Current && !Active ?
                                                        <MdKeyboardArrowUp className='text-[#003049]'/>
                                                        :
                                                        <MdKeyboardArrowDown className='text-[#003049]'/>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${ data.id === Current && !Active  ? 'h-52 border' : 'h-0 border-0' }`}>
                                            <div className='grid grid-cols-12 gap-5 px-4 py-4'>
                                                <h1 className='text-[#737373] text-xs font-[500] col-span-6 underline underline-offset-3'>Detail Employee Information</h1>
                                                <h1 className='text-[#737373] text-xs font-[500] col-span-6 underline underline-offset-3'>Supervisor</h1>
                                            </div>
                                            <div className='grid grid-cols-12 gap-5 px-4 py-4'>
                                                <div className='flex flex-col gap-3 col-span-2'>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Join Date</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Country</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Province</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>City</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Address</h1>
                                                </div>
                                                <div className='flex flex-col gap-3 col-span-1'>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                </div>
                                                <div className='flex flex-col gap-3 col-span-3'>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{moment(data.user_detail.createdAt).format('DD MMMM YYYY')}</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{data.user_detail.country ? data.user_detail.country : '-'}</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{data.user_detail.province ? data.user_detail.province : '-'}</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{data.user_detail.city ? data.user_detail.city : '-'}</h1>
                                                    <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{data.user_detail.address ? data.user_detail.address : '-'}</h1>
                                                </div>
                                                <div className='flex flex-col gap-3 col-span-2'>
                                                    <ul className='list-disc text-[#A8A8A8] text-[10px] font-[500]'>
                                                        <li>{data.report_to_employee[0].report_to_employee.fullname?? '-'}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage} 
                        totalPages={totalPages} 
                        lengthData={totalCount}
                        limitData={limit}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmployeeList