import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import { BiSearch, BiShareAlt } from 'react-icons/bi'
import {AiFillFileText, AiOutlineEye, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { TbRepeat } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { debounce } from 'lodash'

const Recruitment = () => {
    const navigate = useNavigate()
    const [showModalShare, setShowModalShare] = useState()
    const [showModalRepost, setShowModalRepost] = useState()
    const [showModalDelete, setShowModalDelete] = useState()

    const [publishDate, setPublishDate] = useState('')
    const [expiredDate, setExpiredDate] = useState('')

    const [recruitmentId, setRecruitmentId] = useState('')
    const [dataRecruitment, setDataRecruitment] = useState('')
    const [refresh, setRefresh] = useState(false)

    const limit = 5
    
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const GetRecruitment = async() => {
        try {
            const response = await Api.GetRecruitment(localStorage.getItem('hris-token'), '', currentPage, limit)
            setDataRecruitment(response.data.recruitments)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }

    const debouncedSearchTitle = debounce(async(title) => {
        try {
            const response = await Api.GetRecruitment(localStorage.getItem('hris-token'), title, currentPage, limit)
            setDataRecruitment(response.data.recruitments)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchTitle = (e) => {
        const searchTitle = e.target.value
        debouncedSearchTitle(searchTitle)
    }

    const DeleteRecruitmentModal = async(id) => {
        setShowModalDelete(!showModalDelete)
        setRecruitmentId(id)
    }

    const DeleteRecruitment = async(id) => {
        try {
            const response = await Api.DeleteRecruitment(localStorage.getItem('hris-token'), recruitmentId)
            setShowModalDelete(!showModalDelete)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)  
             toast.error('Failed Delete')         
        }
    }

    const openRepost = async (id) => {
        setShowModalRepost(!showModalRepost)
        setRecruitmentId(id)
        try {
            const response = await Api.GetRecruitmentById(localStorage.getItem('hris-token'), id)
            setPublishDate(response.data.recruitment.publishDate)
            setExpiredDate(response.data.recruitment.expiredDate)
            console.log(response)
        } catch (error) {
            
        }
    }

    const editRepost = async () => {
        try {
            const data = {
                publishDate: publishDate,
                expiredDate: expiredDate
            }
            const response = await Api.UpdateRecruitment(localStorage.getItem('hris-token'), data, recruitmentId)
            setRefresh(true)
            setShowModalRepost(!showModalRepost)
            console.log(response)
            toast.success('Success Repost Recruitment!')
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    useEffect(() => {
        GetRecruitment()
        setRefresh(false)
    }, [refresh])

    return (
        <div>
            <Modal 
                activeModal={showModalShare}
                title={'Share Vacancy'}
                buttonClose={ () => setShowModalShare(!showModalShare)}
                width={'582px'}
                content= {
                    <div className='space-y-[20px] w-[473px]'>
                        <hr />
                        <div className='w-full'>
                            <h1 className='text-[12px] font-semibold text-[#494949] mb-[7px]'>Link</h1>
                            <input type="text" className='border rounded-xl w-full h-[40px] py-[11px] pl-[15px] pr-[21px]' placeholder='https://forms.gle/2BdbDDD7TALpw4Gt8' />
                        </div>

                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalShare(!showModalShare)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Copy</button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showModalRepost}
                title={'Repost Job Vacancies'}
                buttonClose={ () => setShowModalRepost(!showModalRepost)}
                width={'430px'}
                content= {
                    <div className='space-y-[20px] w-full'>
                        <hr />
                        
                        <div className='flex gap-2 justify-center'>
                            <div>
                                <h1 className='text-[#A8A8A8] text-[12px] mb-[6px]'>Publish Date</h1>
                                <input type="date" onChange={(e) => setPublishDate(e.target.value)} value={moment(publishDate).format('YYYY-MM-DD')} className='h-[35px] py-[11px] pl-[8.7px] border rounded-md w-[174px] pr-[11px]' />
                            </div>
                            <div className='pt-9'>
                                <AiOutlineMinus  className='text-[#A8A8A8]'/>
                            </div>
                            <div>
                                <h1 className='text-[#A8A8A8] text-[12px] mb-[6px]'>Expired Date</h1>
                                <input type="date" onChange={(e) => setExpiredDate(e.target.value)} value={moment(expiredDate).format('YYYY-MM-DD')} className='h-[35px] py-[11px] pl-[8.7px] border rounded-md w-[174px] pr-[11px]' />
                            </div>
                        </div>

                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={editRepost} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Repost</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={DeleteRecruitment}
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Recruitment'} LinkBack={'/dashboard'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className='font-semibold text-[20px] text-[#454545]'>Recruitment</h1>
                            <h2 className='text-[10px] text-[#737373]'>list of recruitment </h2>
                        </div>

                        
                        <div className="flex justify-end">
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input onChange={handleSearchTitle} placeholder='Search...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                <button onClick={() => navigate('/recruitment/entry-application')} className='py-2 px-4 rounded-[10px] flex items-center gap-[16px] border'>
                                    <AiOutlineEye  className='text-xl text-[#003049]'/>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Entry Application</h1>
                                </button>
                                <button onClick={() => navigate('/recruitment/create-recruitment')} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Recruitment</h1>
                                </button>
                            </div>
                        </div>
                        

                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-zinc-700 text-[11px] font-semibold'>Recruitment Name</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-zinc-700 text-[11px] font-semibold'>Description</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-zinc-700 text-[11px] font-semibold'>Expired</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-zinc-700 text-[11px] font-semibold'>Actions</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                    </div>
                                    { dataRecruitment.length === 0 ?
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[100px] max-w-[100px] '>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[300px] max-w-[300px] '>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>-</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px] '>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className=' text-[#5C5C5C] w-full flex items-center justify-center gap-[12px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                    </div>
                                    :
                                    Object.values(dataRecruitment).map((data, index) => {
                                        return (
                                            <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-zinc-700 text-xs font-medium mb-1'>{data.title}</h1>
                                                    <p className="text-gray-500 text-[10px] font-medium">{moment(data.createdAt).format('DD/MM/YYYY')}</p>
                                                </div>
                                                <div className='min-w-[300px] max-w-[300px] '>
                                                    <h1 className='text-zinc-700 text-xs font-medium'>{data.description}</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px] '>
                                                    <h1 className='text-zinc-700 text-xs font-medium truncate'>{data.expiredDate}</h1>
                                                </div>
                                                <div className=' text-[#5C5C5C] w-full flex items-center justify-center gap-[12px]'>
                                                        <button className='p-[6px] bg-slate-300 rounded-[9px] flex items-center justify-between' onClick={() => navigate('/recruitment/edit-recruitment', {state: {recruitmenId: data.id}})}>
                                                            <HiOutlinePencil className='text-[#003049]'/>
                                                        </button>
                                                        <button className='p-[6px] bg-slate-300 rounded-[9px] flex items-center justify-between' onClick={() => DeleteRecruitmentModal(data.id)}>
                                                            <CgTrashEmpty className='text-[#003049]'/>
                                                        </button>
                                                        <button className='p-[6px] bg-slate-300 rounded-[9px] flex items-center justify-between' onClick={ () => openRepost(data.id)}>
                                                            <TbRepeat className='text-[#003049]' />
                                                        </button>
                                                        <button className='p-[6px] bg-slate-300 rounded-[9px] flex items-center justify-between' onClick={ () => setShowModalShare(!showModalShare)}>
                                                            <BiShareAlt className='text-[#003049]' />
                                                        </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPage} 
                                totalPages={totalPages} 
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Recruitment