import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { AddSubsidiary, Approval, AttendanceSetting, CostProfit, Dashboard, DataImport, DetailBlog1, DetailBlog2, DetailBlog3, DetailSubsidiary, Document, EditSubsidiary, EmployeeList, EmployeeTransfer, GeneralInformation, Inbox, InboxDetail, JobBulkUpload, JobManagement, Login, MainBlog, Nationalities, News, NewsCreate, NewsEdit, NewsView, Register, Structure, Subsidiary, Template, TerminationReasons, UserManagement, Workshift } from "./pages";
import Answer from "./pages/Main/Document/Answer";
import OpenDocument from "./pages/Main/Document/Open Document";
import StatisticAnswer from "./pages/Main/Document/Statistic Answer";
import Finance from "./pages/Main/Finance/Finance";
import Reimbursement from "./pages/Main/Finance/Reimbursement";
import CashAdvance from "./pages/Main/Finance/Cash Advance";
import Loan from "./pages/Main/Finance/Loan";
import FinanceSetting from "./pages/Main/Finance/Finance Setting";
import DetailLoan from "./pages/Main/Finance/Detail Loan";
import Recruitment from "./pages/Main/Recruitment/Recruitment";
import CreateRecruitment from "./pages/Main/Recruitment/Create Recruitment";
import EditRecruitment from "./pages/Main/Recruitment/Edit Recruitment";
import EntryApplication from "./pages/Main/Recruitment/Entry Application";
import DetailApplicant from "./pages/Main/Recruitment/Detail Applicant";
import ArchiveApplicant from "./pages/Main/Recruitment/Archive Applicant";
import RecruitmentStage from "./pages/Main/Recruitment/Recruitment Stage";
import AllStage from "./pages/Main/Recruitment/All Stage";
import MyProfile from "./pages/Main/My Profile/My Profile";
import MyAttendance from "./pages/Main/Time Management/My Attendance";
import EmployeeRecord from "./pages/Main/Time Management/Employee Record";
import TimeOf from "./pages/Main/Time Management/Time Of";
import Leave from "./pages/Main/Time Management/Leave";
import LeaveSetting from "./pages/Main/Time Management/Leave Setting";
import Permission from "./pages/Main/Time Management/Permission";
import Overtime from "./pages/Main/Time Management/Overtime";
import Schedule from "./pages/Main/Time Management/Schedule";
import Calendar from "./pages/Main/Time Management/Calendar";
import Payroll from "./pages/Main/Payroll/Payroll";
import Aboutus from "./pages/Aboutus";
import { Toaster } from "react-hot-toast";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import EmployeeConfiguration from "./pages/Main/Configuration/EmployeeConfiguration";
import Polling from "./pages/Main/Dashboard/Polling";
import CreatePolling from "./pages/Main/Dashboard/CreatePolling";
import DetailPolling from "./pages/Main/Dashboard/Polling/DetailPolling";
import PollingForm from "./pages/Main/Dashboard/Polling/PollingForm";
import TimeManagementSetting from "./pages/Main/Configuration/TimeManagementSetting";
import OfficialWorkTravelSetting from "./pages/Main/Configuration/TimeManagementSetting/OfficialWorkTravelSetting";
import PayrollSetting from "./pages/Main/Configuration/PayrollSetting";
import Attendance from "./pages/Main/Time Management/Attendance";
import OfficialWorkTravel from "./pages/Main/Time Management/OfficialWorkTravel";
import ImportDataWorkTravel from "./pages/Main/Time Management/OfficialWorkTravel/ImportDataWorkTravel";
import PayrollHistory from "./pages/Main/Payroll/PayrollHistory";
import DetailPayrollHistory from "./pages/Main/Payroll/PayrollHistory/DetailPayrollHistory";
import DetailEmployee from "./pages/Main/Employee/DetailEmployee";
import AddEmployee from "./pages/Main/Employee/AddEmployee";
import MassResign from "./pages/Main/Employee/MassResign";
import Punishment from "./pages/Main/Configuration/Punishment";
import PerformanceSetting from "./pages/Main/Configuration/PerformanceSetting";
import PreviewPerformanceSetting from "./pages/Main/Configuration/PerformanceSetting/PreviewPerformance";
import Performance from "./pages/Main/Performance";
import AddSasaranKinerjaPegawai from "./pages/Main/Performance/AddSasaranKinerjaPegawai";
import DetailSKP from "./pages/Main/Performance/DetailSKP";
import OutcomeRoleMatrix from "./pages/Main/Performance/OutcomeRoleMatrix";
import DetailEvaluation from "./pages/Main/Performance/DetailEvaluation";
import AddEvaluation from "./pages/Main/Performance/AddEvaluation";
import HistoryAnnouncement from "./pages/Main/Dashboard/HistoryAnnouncement";
import EditWorkTravel from "./pages/Main/Time Management/OfficialWorkTravel/EditWorkTravel";
import DetailAnswerPolling from "./pages/Main/Dashboard/Polling/DetailAnswerPolling";

function App() {
    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={true}
            />
            <Routes>
                {/* Auth */}
                <Route name='Login' path="/login" element={<Login/>}/>
                <Route name='Register' path="/register" element={<Register/>}/>
                <Route name='Home' path="/" element={<Home/>}/>
                <Route name='Aboutus' path="/about-us" element={<Aboutus/>}/>
                <Route name='PrivacyPolicy' path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route name='MainBlog' path="/blogs" element={<MainBlog/>}/>
                <Route name='DetailBlog1' path="/detail-blog/menciptakan-pengalaman-pengelolaan-sdm-yang-berfokus-pada-karyawan" element={<DetailBlog1/>}/>
                <Route name='DetailBlog2' path="/detail-blog/maksimalkan-efisiensi-sdm-dengan-humansia-yang-unggul-dengan-fitur-fitur-hris-terbaik" element={<DetailBlog2/>}/>
                <Route name='DetailBlog3' path="/detail-blog/humanusia-sebagai-rekomendasi-sistem-manajemen-hr-dengan-harga-terjangkau" element={<DetailBlog3/>}/>

                <Route path="/dashboard" element={<Template/>}>
                    <Route index element={<Dashboard/>}/>
                    <Route name='Polling' path="/dashboard/polling" element={<Polling/>}/>
                    <Route name='CreatePolling' path="/dashboard/create-polling" element={<CreatePolling/>}/>
                    <Route name='DetailPolling' path="/dashboard/polling/detail-polling" element={<DetailPolling/>}/>
                    <Route name='DetailPollingAnswer' path="/dashboard/polling/detail-polling/answer" element={<DetailAnswerPolling/>}/>
                    <Route name='PollingForm' path="/dashboard/polling/polling-form" element={<PollingForm/>}/>
                    <Route name='HistoryAnnouncement' path="/dashboard/history-announcement" element={<HistoryAnnouncement/>}/>

                </Route>
                <Route path="/inbox" element={<Template/>}>
                    <Route index element={<Inbox/>}/>
                    <Route name='InboxDetail' path="/inbox/inbox-detail" element={<InboxDetail/>}/>
                    <Route name='Approval' path="/inbox/approval" element={<Approval/>}/>
                </Route>
                <Route path="/news" element={<Template/>}>
                    <Route index element={<News/>}/>
                    <Route name='NewsCreate' path="/news/news-create" element={<NewsCreate/>}/>
                    <Route name='NewsEdit' path="/news/news-edit" element={<NewsEdit/>}/>
                    <Route name='NewsView' path="/news/news-view" element={<NewsView/>}/>
                </Route>
                <Route path="/user-management" element={<Template/>}>
                    <Route index element={<UserManagement/>}/>
                </Route>
                <Route path="/attendance-setting" element={<Template/>}>
                    <Route index element={<AttendanceSetting/>}/>
                </Route>
                <Route  path="/data-import" element={<Template/>}>
                    <Route index element ={<DataImport/>} />
                </Route>
                <Route path="/time-management-setting" element={<Template/>}>
                    <Route index element ={<TimeManagementSetting/>} />
                </Route>
                <Route path="/employee-configuration" element={<Template/>}>
                    <Route index element ={<EmployeeConfiguration/>} />
                </Route>
                <Route path="/official-work-travel" element={<Template/>}>
                    <Route index element ={<OfficialWorkTravelSetting/>} />
                </Route>
                <Route path="/payroll-setting" element={<Template/>}>
                    <Route index element ={<PayrollSetting/>} />
                </Route>
                <Route path="/punishment" element={<Template/>}>
                    <Route index element ={<Punishment/>} />
                </Route>

                <Route path="/performance" element={<Template/>}>
                    <Route index element ={<Performance/>} />
                    <Route name='AddSasaranKinerjaPegawai' path="/performance/add-sasaran-kinerja-pegawai" element={<AddSasaranKinerjaPegawai/>}/>
                    <Route name='DetailSkp' path="/performance/detail-skp" element={<DetailSKP/>}/>
                    <Route name='DetailEvaluation' path="/performance/detail-evaluation" element={<DetailEvaluation/>}/>
                    <Route name='AddEvaluation' path="/performance/detail-evaluation/add-evaluation" element={<AddEvaluation/>}/>
                    <Route name='OutcomeRoleMatrix' path="/performance/detail-skp/outcome-role-matrix" element={<OutcomeRoleMatrix/>}/>
                </Route>

                <Route path="/performance-setting" element={<Template/>}>
                    <Route index element ={<PerformanceSetting/>} />
                    <Route name='PreviewPerformanceSetting' path="/performance-setting/preview" element={<PreviewPerformanceSetting/>}/>

                </Route>

                <Route path="/job-management" element={<Template/>}>
                    <Route index element={<JobManagement/>}/>
                    <Route name='JobBulkUpload' path="/job-management/bulk-upload" element={<JobBulkUpload/>}/>
                </Route>
                <Route path="/subsidiary" element={<Template/>}>
                    <Route index element={<Subsidiary/>}/>
                    <Route name='AddSubsidiary' path="/subsidiary/add-subsidiary" element={<AddSubsidiary/>}/>
                    <Route name='EditSubsidiary' path="/subsidiary/edit-subsidiary" element={<EditSubsidiary/>}/>
                    <Route name='DetailSubsidiary' path="/subsidiary/detail-subsidiary" element={<DetailSubsidiary/>}/>
                </Route>
                <Route path="/general-information" element={<Template/>}>
                    <Route index element={<GeneralInformation/>}/>
                    <Route name='Structure' path="/general-information/structure" element={<Structure/>}/>
                    <Route name='CostProfit' path="/general-information/cost-profit" element={<CostProfit/>}/>
                    <Route name='Nationalities' path="/general-information/nationalities" element={<Nationalities/>}/>
                </Route>
                <Route path="/employee" element={<Template/>}>
                    <Route index element={<EmployeeList/>}/>
                    <Route name='EmployeeTransfer' path="/employee/employee-transfer" element={<EmployeeTransfer/>}/>
                    <Route name='DetailEmployee' path="/employee/detail-employee" element={<DetailEmployee/>}/>
                    <Route name='AddEmployee' path="/employee/add-employee" element={<AddEmployee/>}/>
                    <Route name='MassResign' path="/employee/mass-resign" element={<MassResign/>}/>
                </Route>
                <Route path="/document" element={<Template/>}>
                    <Route index element={<Document/>}/>
                    <Route name='OpenDocument' path="/document/open-document" element={<OpenDocument/>}/>
                    <Route name='Answer' path="/document/answer-document" element={<Answer/>}/>
                    <Route name='StatisticAnswer' path="/document/statistic-answer" element={<StatisticAnswer/>}/>
                </Route>
                <Route path="/finance" element={<Template/>}>
                    <Route index element={<Finance/>}/>
                    <Route name='Reimbursement' path="/finance/reimbursement" element={<Reimbursement/>}/>
                    <Route name='CashAdvance' path="/finance/cash-advance" element={<CashAdvance/>}/>
                    <Route name='Loan' path="/finance/loan" element={<Loan/>}/>
                    <Route name='DetailLoan' path="/finance/detail-loan" element={<DetailLoan/>}/>
                    <Route name='FinanceSetting' path="/finance/setting" element={<FinanceSetting/>}/>
                </Route>
                <Route path="/recruitment" element={<Template/>}>
                    <Route index element={<Recruitment/>}/>
                    <Route name='CreateRecruitment' path="/recruitment/create-recruitment" element={<CreateRecruitment/>}/>
                    <Route name='EditRecruitment' path="/recruitment/edit-recruitment" element={<EditRecruitment/>}/>
                    <Route name='EntryApplication' path="/recruitment/entry-application" element={<EntryApplication/>}/>
                    <Route name='DetailApplicant' path="/recruitment/detail-applicant" element={<DetailApplicant/>}/>
                    <Route name='ArchiveApplicant' path="/recruitment/archive-applicant" element={<ArchiveApplicant/>}/>
                    <Route name='RecruitmentStage' path="/recruitment/recruitment-stage" element={<RecruitmentStage/>}/>
                    <Route name='AllStage' path="/recruitment/all-stage" element={<AllStage/>}/>
                </Route>
                <Route path="/profile" element={<Template/>}>
                    <Route index element={<MyProfile/>}/>
                </Route>
                <Route path="/time-management" element={<Template/>}>
                    <Route name='MyAttendance' path="/time-management/my-attendance" element={<MyAttendance/>}/>
                    <Route name='Attendance' path="/time-management/attendance" element={<Attendance/>}/>
                    <Route name='EmployeeRecord' path="/time-management/employee-record" element={<EmployeeRecord/>}/>
                    <Route name='TimeOf' path="/time-management/time-of" element={<TimeOf/>}/>
                    <Route name='Leave' path="/time-management/leave" element={<Leave/>}/>
                    <Route name='LeaveSetting' path="/time-management/leave-setting" element={<LeaveSetting/>}/>
                    <Route name='Permission' path="/time-management/permission" element={<Permission/>}/>
                    <Route name='Overtime' path="/time-management/overtime" element={<Overtime/>}/>
                    <Route name='Schedule' path="/time-management/schedule" element={<Schedule/>}/>
                    <Route name='Calendar' path="/time-management/calendar" element={<Calendar/>}/>
                    <Route name='OfficialWorkTravel' path="/time-management/official-work-travel" element={<OfficialWorkTravel/>}/>
                    <Route name='ImportDataWorkTravel' path="/time-management/official-work-travel/import-data" element={<ImportDataWorkTravel/>}/>
                    <Route name='EditWorkTravel' path="/time-management/official-work-travel/edit" element={<EditWorkTravel/>}/>
                </Route>
                <Route path="/payroll" element={<Template/>}>
                    <Route index element={<Payroll/>}/>
                    <Route name='PayrollHistory' path="/payroll/payroll-history" element={<PayrollHistory/>}/>
                    <Route name='DetailPayrollHistory' path="/payroll/payroll-history/detail" element={<DetailPayrollHistory/>}/>

                </Route>
            </Routes>
        </div>
    )
}

export default App