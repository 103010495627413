import React, { useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { Modal, ModalDelete, Navbar, Pagination } from '../../../../components'
import { MdImportExport} from 'react-icons/md'
import { AiFillCheckCircle, AiFillClockCircle, AiOutlinePlus } from 'react-icons/ai'
import { IoMdArrowDropdown } from 'react-icons/io'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import Api from '../../../../Api'
import moment from 'moment'
import { FaTimesCircle } from 'react-icons/fa'
import { toast } from 'react-hot-toast'
import { debounce } from 'lodash'

const Reimbursement = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [showModalAddReimbursement, setShowModalAddReimbursement] = useState()
    const [showModalEditReimbursement, setShowModalEditReimbursement] = useState()
    const [showModalDelete, setShowModalDelete] = useState()
    
    const [reimbursementId, setReimbursmentId] = useState('')
    const [reimbursmentData, setReimbursmentData] = useState('')
    const [reimbursmentDataSetting, setReimbursmentDataSetting] = useState('')
    const [reimbursmentDataSettingById, setReimbursmentDataSettingById] = useState('')
    const [amount, setAmount] = useState('')
    const [file, setFile] = useState('')
    const [useDate, setUseDate] = useState('')
    const [note, setNote] = useState('')
    const [userName, setUserName] = useState('')
    const [userId, setUserId] = useState('')
    const [rbSettingId, setRbSettingId] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [limit, setLimit] = useState(5)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [nameSearch, setNameSearch] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };


    const FetchUser = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserName(response.data.results.data.fullname)
            setUserId(response.data.results.data.id)
        } catch (error) {
            console.log(error)
        }
    }

const GetDataReimbursmentSetting = async() => {
        try {
            const response = await Api.GetFinanceReimbursmentSetting(localStorage.getItem('hris-token'), 1 , 100)
            setReimbursmentDataSetting(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

   

    const GetDataReimbursment = async() => {
        try {
            const response = await Api.GetFinanceReimbursment(localStorage.getItem('hris-token'), nameSearch,  currentPage, limit)
            setReimbursmentData(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }
    const debouncedSearchName = debounce(async(name) => {
        try {
            const response = await Api.GetFinanceReimbursment(localStorage.getItem('hris-token'), name, currentPage, limit)
                setReimbursmentData(response.data.data)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const debouncedLimit = debounce(async(limit) => {
        try {
            const response = await Api.GetFinanceReimbursment(localStorage.getItem('hris-token'), nameSearch, currentPage, limit)
                setReimbursmentData(response.data.data)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleLimit= (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimit(limit)
    }

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }

    const resetForm = () => {
        setAmount('')
        setUseDate('')
        setNote('')
        setRbSettingId('')
        setUseDate('')
    }

    const CreateReibursment = async() => {
        try {
            const data = {
                amount: amount,
                use_date: useDate,
                note: note,
                user: userId,
                rbSettingId: rbSettingId,
            }
            if (amount < 0){
                toast.error('Amount cannot be less than zero')
            } else{
                setIsLoading(true)
                const response = await Api.CreateFinanceReimbursment(localStorage.getItem('hris-token'), data)
                setIsLoading(false)
                resetForm()
                setRefresh(true)
                toast.success('Success Create Reimburstment')
                setShowModalAddReimbursement(!showModalAddReimbursement)
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }


    const UpdateReimbursmentModalOpen = async(id) => {
        setShowModalEditReimbursement(!showModalEditReimbursement)
        setReimbursmentId(id)
        try {
            const response = await Api.GetFinanceReimbursmentById(localStorage.getItem('hris-token'), id)
            setAmount(response.data[0].amount)
            setUseDate(response.data[0].use_date)
            setNote(response.data[0].note)
            setRbSettingId(response.data[0].rbSetting.id)
        } catch (error) {
            console.log(error)
        }
    }

    const GetDataReimbursmentSettingById = async() => {
        try {
            const response = await Api.GetFinanceReimbursmentSettingById(localStorage.getItem('hris-token'), rbSettingId)
            setReimbursmentDataSettingById(response.data[0])
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateReimbursment = async() => {
        try {
            const data = {
                amount: amount,
                use_date: useDate,
                note: note,
                user: userId,
                rbSettingId: rbSettingId,
            }
            if (amount < 0) {
                toast.error('Amount cannot be less than zero')
            } else {
                setIsLoading(true)
                const response = await Api.UpdateFinanceReimbursment(localStorage.getItem('hris-token'), data, reimbursementId)
                setIsLoading(false)
                resetForm()
                setRefresh(true)
                toast.success('Success Update Reimbursment')
                setShowModalEditReimbursement(!showModalEditReimbursement)
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const DeleteReimbursmentModal = async(id) => {
        setShowModalDelete(!showModalDelete)
        setReimbursmentId(id)
    }

    const DeleteReimbursment = async(id) => {
        try {
            const response = await Api.DeleteFinanceReimbursment(localStorage.getItem('hris-token'), reimbursementId)
            setRefresh(true)
            setShowModalDelete(!showModalDelete)
            toast.success('Success Delete Reimbursment')
        } catch (error) {
            console.log(error)
            toast.error('Failed delete!')
        }
    }

    useEffect(() => {
        FetchUser()
        GetDataReimbursment()
        setRefresh(false)
    }, [refresh, limit])
    
    useEffect(() => {
        GetDataReimbursmentSetting()
        GetDataReimbursmentSettingById()
    }, [rbSettingId])

    return (
        <div>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <Modal 
                    activeModal={showModalAddReimbursement}
                    title={'Add Reimbursement'}
                    buttonClose={ () => setShowModalAddReimbursement(!showModalAddReimbursement)}
                    width={'553px'}
                    content= {
                        <div className='space-y-[20px] w-[473px]'>
                            {/* <div className='flex items-center gap-[20px]'> */}
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Reimbursement Policy<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setRbSettingId(e.target.value)} value={rbSettingId} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''} disabled>Select reimbursement policy</option>
                                        {Object.values(reimbursmentDataSetting).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                                {reimbursmentDataSettingById.length === 0 ?
                                null
                                :
                                <div>
                                    <h1 className='text-[#B70000] text-[10px] italic'>*The policy you choose has a limit of Rp. {reimbursmentDataSettingById.limit_amount}</h1>
                                    <h1 className='text-[#B70000] text-[10px] italic'>*The policy you choose has a Min Next Claim for {reimbursmentDataSettingById.min_next_claim} days</h1>
                                </div>
                                }
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <input value={userName} type='text' className='h-[35px] w-full bg-[#DCDCDC] border rounded-[6px] text-[#454545] text-[13px] px-[8px] py-[10px] appearance-none' placeholder='Employee Name' readOnly/>
                                </div>
                            </div>
                            {/* </div> */}
                            <div className='flex items-center gap-[20px]'>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[8px] font-[500] mb-[7px]'>Amount<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <input onChange={ (e) => setAmount(e.target.value) } min={0} value={amount} type='number' className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='Rp' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[8px] font-[500] mb-[7px]'>Use Date<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <input onChange={ (e) => setUseDate(e.target.value) } value={useDate} type={'date'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='dd/mm/yyyy'/>
                                        {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                    </div>
                                </div>
                            </div>
                            
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Note<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <textarea onChange={ (e) => setNote(e.target.value) } value={note} rows={'3'} className='resize-none bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Note...'>
                                </textarea>
                            </div>

                            {/* <div className='w-full'>
                                <h1 className='text-[#737373] text-[8px] font-[500] mb-[7px]'>Upload File<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <input onChange={ (e) => setFile(e.target.files[0]) } type={'file'} className='bg-white border w-full rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='dd/mm/yyyy'/>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div> */}

                            {/* <div className='w-full'>
                                <h1 className='text-[#454545] text-[12px] mb-[14px] font-semibold'>Upload File</h1>
                                <button className='w-[92px] h-[35px] text-[#CACACA] text-[12px] p-[10px] border rounded-lg'>Choose file</button>
                                <p className='text-[#C1121F] text-[8px] font-normal'>*max 10 MB</p>
                            </div> */}
                                
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={ () => setShowModalAddReimbursement(!showModalAddReimbursement)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                                <button onClick={isLoading ? null : CreateReibursment} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                            </div>
                        </div>
                    }
                />
                <Modal 
                    activeModal={showModalEditReimbursement}
                    title={'Edit Reimbursement'}
                    buttonClose={ () => setShowModalEditReimbursement(!showModalEditReimbursement)}
                    width={'553px'}
                    content= {
                        <div className='space-y-[20px] w-[473px]'>
                            {/* <div className='flex items-center gap-[20px]'> */}
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Reimbursement Policy<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={(e) => setRbSettingId(e.target.value)} value={rbSettingId} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''} disabled>Select reimbursement policy</option>
                                        {Object.values(reimbursmentDataSetting).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                                {reimbursmentDataSettingById.length === 0 ?
                                null
                                :
                                <div>
                                    <h1 className='text-[#B70000] text-[10px] italic'>*The policy you choose has a limit of Rp. {reimbursmentDataSettingById.limit_amount}</h1>
                                    <h1 className='text-[#B70000] text-[10px] italic'>*The policy you choose has a Min Next Claim for {reimbursmentDataSettingById.min_next_claim} days</h1>
                                </div>
                                }
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <input value={userName} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-[#454545] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='Employee Name' readOnly/>
                                </div>
                            </div>
                            {/* </div> */}
                            <div className='flex items-center gap-[20px]'>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[8px] font-[500] mb-[7px]'>Amount<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <input type='number' value={amount} onChange={(e)=>setAmount(e.target.value)} min={0} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='Rp' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[8px] font-[500] mb-[7px]'>Use Date<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <input type={'date'} value={moment(useDate).format('YYYY-MM-DD')} onChange={(e)=>setUseDate(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-[227px] appearance-none' placeholder='dd/mm/yyyy'/>
                                        {/* <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/> */}
                                    </div>
                                </div>
                            </div>
                            
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Note<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <textarea rows={'3'} value={note} onChange={(e)=>setNote(e.target.value)} className='resize-none bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Note...'>
                                </textarea>
                            </div>

                            {/* <div className='w-full'>
                                <h1 className='text-[#454545] text-[12px] mb-[14px] font-semibold'>Upload File</h1>
                                <button className='w-[92px] h-[35px] text-[#CACACA] text-[12px] p-[10px] border rounded-lg'>Choose file</button>
                                <p className='text-[#C1121F] text-[8px] font-normal'>*max 10 MB</p>
                            </div> */}
                                
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={ () => setShowModalEditReimbursement(!showModalEditReimbursement) } className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                                <button onClick={isLoading ? null : UpdateReimbursment} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                            </div>
                        </div>
                    }
                />
                <ModalDelete
                    activeModal={showModalDelete}
                    buttonClose={() => setShowModalDelete(!showModalDelete)}
                    submitButton={DeleteReimbursment}
                />
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Reimbursement'} LinkBack={'/finance'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className='text-[20px]'>Reimbursment</h1>
                            <h2 className='text-[10px] text-[#737373]'>list of Reimbursment </h2>
                        </div>
                        <div className="flex gap-2 mt-[31px] justify-between">
                            <div className='flex gap-2'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input onChange={handleSearchName} placeholder='Search by Employee Name or Employee ID...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                    <option value="" selected disabled>Set Limit</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                </select>
                            </div>
                            <button onClick={()=>setShowModalAddReimbursement(!showModalAddReimbursement )} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus  className='text-xl text-white'/>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add Reimbursment</h1>
                            </button>
                        </div>
                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Employee Name</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Reimbursement Policy</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Amount</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Use Date</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Note</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Approval</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                    </div>
                                    {reimbursmentData.length === 0 ?
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className=' min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px] flex items-center justify-center gap-[6px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>-</h1>
                                        </div>
                                    </div>
                                    :
                                    Object.values(reimbursmentData).map((data, index) => {
                                        return (
                                            <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[250px] max-w-[250px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.user ? '-' : data.user.fullname}</h1>
                                                </div>
                                                <div className='min-w-[250px] max-w-[250px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{data.rbSetting.name}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{data.amount}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{moment(data.use_date).format('DD MMMM YYYY')}</h1>
                                                </div>
                                                <div className=' min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{data.note}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px] flex items-center justify-center gap-[6px]'>
                                                    {data.status === 'pending' ? 
                                                        <AiFillClockCircle className='text-[#FFD900] w-[29px] h-[29px]'/>
                                                    :data.status === 'approved' ?
                                                        <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]'/>
                                                    :
                                                        <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]'/>
                                                    }
                                                    {/* <button className='rounded-[9px]'>
                                                        <span className='block text-[7px] font-medium text-[#737373] underline'>Detail</span>
                                                    </button> */}
                                                </div>
                                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                                    {data.status === 'approved' ?
                                                    <button disabled onClick={() => UpdateReimbursmentModalOpen(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                     </button>
                                                    :
                                                    <button onClick={() => UpdateReimbursmentModalOpen(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                    </button>
                                                    }
                                                    
                                                    <button onClick={() => DeleteReimbursmentModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]'/>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPage} 
                                totalPages={totalPages} 
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reimbursement