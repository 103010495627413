import React from 'react'
import { Footer, NavbarLandingPage } from '../../components'

const PrivacyPolicy = () => {
  return (
    <div>

      <NavbarLandingPage
          features={'#features'}
          solution={'#solution'}
          partnership={'#partnership'}
          getStarted={'#pricing'}
      />

      <div className='px-[250px] py-14'>
          <h1 className='text-[#2B2A35] font-bold text-[30px] text-center mb-8'>Privacy Policy for the Humanusia.id Application</h1>

          <div className='space-y-2 mb-7'>
            <h2 className='text-[#2B2A35] text-[20px] font-medium'>Effective Date: 1 July 2023</h2>
            <p className='text-sm'>At Humanusia.id, we value the privacy of our users, and our commitment is to protect and safeguard the confidentiality of the personal information you provide to us when using our application. This Privacy Policy explains how we collect, use, disclose, and protect your personal information. Please read this policy carefully.</p>
          </div>

          <div className='space-y-5 text-sm'>
              <h1 className='font-medium text-[#2B2A35] text-[20px]'>1. Information We Collect:</h1>
              <ol className='space-y-1 ml-10 list-[lower-latin]'>
                <li>Personal Information: When you use the Humanusia.id application, we may collect personal information such as your name, email address, phone number, address, and other information you voluntarily provide.</li>
                <li>Non-Personal Information: We may also collect non-personal information such as the type of device you use, operating system, language preferences, and anonymous statistical data generated by the use of the application.</li>
              </ol>

              <h1 className='font-medium text-[#2B2A35] text-[20px]'>2. Use of Information:</h1>
              <ol className='space-y-1 ml-10 list-[lower-latin]'>
                <li>Use of Personal Information: We use the personal information you provide to operate, maintain, and improve our services. This information is also used to contact you, provide information related to our services, and fulfill your requests.</li>
                <li>Use of Non-Personal Information: Non-personal information is used for statistical analysis, product development, and overall enhancement of the user experience.</li>
              </ol>

              <h1 className='font-medium text-[#2B2A35] text-[20px]'>3. Information Protection:</h1>
              <ol className='space-y-1 ml-10 list-[lower-latin]'>
                <li>Data Security: We implement appropriate technical and organizational security measures to protect your personal information from unauthorized access, unauthorized use, or unauthorized alteration. However, please note that no method of data transmission over the internet or electronic storage is 100% secure.</li>
                <li>Data Storage: Your personal information will be stored only for as long as necessary for the purposes described in this policy, unless otherwise required or permitted by applicable law.</li>
              </ol>

              <h1 className='font-medium text-[#2B2A35] text-[20px]'>4. Disclosure to Third Parties:</h1>
              <ol className='space-y-1 ml-10 list-[lower-latin]'>
                <li>Third Parties: We will not sell, rent, or exchange your personal information to third parties without your permission, except when necessary to provide the services you request or as required by law.</li>
                <li>Service Providers: We may use third-party service providers to assist in the operation of our application, such as data storage, analytics, and payment processing. These third parties will have limited access to your personal information and will only use it for the specified purposes.</li>
              </ol>

              <h1 className='font-medium text-[#2B2A35] text-[20px]'>5. Changes to the Privacy Policy:</h1>
              <ol className='space-y-1 ml-10 list-[lower-latin]'>
                <li>This Privacy Policy may be updated from time to time. If there are material changes to this policy, we will provide appropriate notice to you through the application or via the contact information you have provided to us.</li>
                <li>Please check this policy periodically to ensure you understand how your information is protected and used.</li>
              </ol>

              <h1 className='font-medium text-[#2B2A35] text-[20px]'>6. Contact:</h1>
              <p className='ml-5'>If you have any questions, comments, or concerns regarding privacy or this policy, please contact us at info@humanusia.id or 08112935992.</p>
              <p>By using the Humanusia.id application, you signify your agreement to this Privacy Policy. Please note that if you do not agree with this policy, we may not be able to provide our services to you fully.</p>
          </div>
      </div>

      <Footer/>
      <div className='px-[16px] lg:px-[150px] py-[41px] space-y-[50px]'>
          <h1 className='text-[#5C5C5C] text-center'>@2022 Humanusia. All rights reserved.</h1>
      </div>

    </div>
  )
}

export default PrivacyPolicy