import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar } from '../../../../components'
import { toast } from 'react-hot-toast'
import Api from '../../../../Api'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { BiPlus } from 'react-icons/bi'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdModeEditOutline } from 'react-icons/md'
import { TbNotes } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import { IoMdArrowDropdown } from 'react-icons/io'
import { BsImage } from 'react-icons/bs'
import { LogoH } from '../../../../assets'

const EmployeeConfiguration = () => {
    const [configuration, setConfiguration] = useState('Employee Status')

    //Employee Status State
    const [showModalAddEmployeeStatus, setShowModalAddEmployeeStatus] = useState(false)
    const [showModalEditEmployeeStatus, setShowModalEditEmployeeStatus] = useState(false)
    const [showModalDeleteEmployeeStatus, setShowModalDeleteEmployeeStatus] = useState(false)
    const [showModalAddSupervisor, setShowModalAddSupervisor] = useState()
    const [showModalAddSubordinate, setShowModalAddSubordinate] = useState()
    const [getIdEmployeeStatus, setGetIdEmployeeStatus] = useState('')
    const [dataEmployeStatus, setDataEmployeStatus] = useState('')
    const [employeStatus, setEmployeStatus] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [dataEmployee, setDataEmployee] = useState('')
    const [idEmployee , setIdEmployee] = useState('')
    const [idSupervisor , setIdSupervisor] = useState('')
    const [idUser, setIdUser] = useState('')

   
    
    const openModalUpdateEmployeeStatus = async(id) => {
        setShowModalEditEmployeeStatus(!showModalEditEmployeeStatus)
        setGetIdEmployeeStatus(id)
        try {
            const response = await Api.GetEmployeeStatusById(localStorage.getItem('hris-token'), id)
            setEmployeStatus(response.data[0].employee_status)
        } catch (error) {
            console.log(error)
        }
    }

    const GetDataEmployeeStatus = async() => {
        try {
            const response = await Api.GetEmployeeStatus(localStorage.getItem('hris-token'))
            setDataEmployeStatus(response.data.employe_status)
        } catch (error) {
            console.log(error)
        }
    }

    const PostDataEmplyeeStatus = async() => {
        try {
            const data = {
                employee_status: employeStatus
            }
            const response = await Api.CreateEmployeeStatus(localStorage.getItem('hris-token'), data)
            setEmployeStatus('')
            setShowModalAddEmployeeStatus(!showModalAddEmployeeStatus)
            setRefresh(true)
            toast.success('Success Create')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }
    
    const UpdateDataEmployeeStatus = async(id) => {
        try {
            const data = { 
                employee_status : employeStatus
            }
            const response = await Api.UpdateEmployeeStatus(localStorage.getItem('hris-token'), data, getIdEmployeeStatus)
            setEmployeStatus('')
            setShowModalEditEmployeeStatus(!showModalEditEmployeeStatus)
            setRefresh(true)
            toast.success('Success Update')
        } catch (error) {
            console.log(error)
            toast.error('Failed Update')
        }
    }

    const DeleteDataEmployeeStatusModal = async(id) => {
        setShowModalDeleteEmployeeStatus(!showModalDeleteEmployeeStatus)
        setGetIdEmployeeStatus(id)
    }

    const DeleteDataEmployeeStatus = async(id) => {
        try {
            const response = await Api.DeleteEmployeeStatus(localStorage.getItem('hris-token'), getIdEmployeeStatus)
            setShowModalDeleteEmployeeStatus(!showModalDeleteEmployeeStatus)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }

    //Work Shift State
    const [showModalAddWorkShift, setShowModalAddWorkShift] = useState(false)
    const [showModalEditWorkShift, setShowModalEditWorkShift] = useState(false)
    const [showModalDeleteWorkShift, setShowModalDeleteWorkShift] = useState(false)
    const [getIdWorkShift, setGetIdWorkShift] = useState('')
    const [dataWorkShift, setDataWorkShift] = useState('')

    const [workShift, setWorkShift] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [workingHours, setWorkingHours] = useState('')

    const openModalUpdateWorkShift = async(id) => {
        setShowModalEditWorkShift(!showModalEditWorkShift)
        setGetIdWorkShift(id)
        try {
            const response = await Api.GetWorkShiftById(localStorage.getItem('hris-token'), id)
            setWorkShift(response.data[0].work_shift_name)
            setStartTime(response.data[0].start_time)
            setEndTime(response.data[0].end_time)
            setWorkingHours(response.data[0].working_hours)
        } catch (error) {
            console.log(error)
        }
    }

    const GetDataWorkShift = async() => {
        try {
            const response = await Api.GetWorkShift(localStorage.getItem('hris-token'))
            setDataWorkShift(response.data.workShift)
        } catch (error) {
            console.log(error)
        }
    }


    const PostWorkShift = async() => {
        try {
            const data = {
                work_shift_name: workShift,
                working_hours: workingHours,
                start_time: startTime,
                end_time: endTime,
            }

            setWorkShift('')
            setWorkingHours('')
            setStartTime('')
            setEndTime('')

            const response = await Api.CreateWorkShift(localStorage.getItem('hris-token'), data)
            setRefresh(true)
            toast.success('Success Create')
            setShowModalAddWorkShift(!showModalAddWorkShift)
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }
    
    const UpdateDataWorkShift = async(id) => {
        try {
            const data = { 
                work_shift_name: workShift,
                working_hours: workingHours,
                start_time: startTime,
                end_time: endTime,
            }
            const response = await Api.UpdateWorkShift(localStorage.getItem('hris-token'), data, getIdWorkShift)
            setRefresh(true)
            toast.success('Success Update')
            setShowModalEditWorkShift(!showModalEditWorkShift)
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const DeleteDataWorkShiftModal = async(id) => {
        setShowModalDeleteWorkShift(!showModalDeleteWorkShift)
        setGetIdWorkShift(id)
    }

    const DeleteDataWorkShift = async(id) => {
        try {
            const response = await Api.DeleteWorkShift(localStorage.getItem('hris-token'), getIdWorkShift)
            setRefresh(true)
            setShowModalDeleteWorkShift(!showModalDeleteWorkShift)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }

    
    // Termination Reason
    const [showModalAddTermination, setShowModalAddTermination] = useState(false)
    const [showModalEditTermination, setShowModalEditTermination] = useState(false)
    const [showModalDeleteTermination, setShowModalDeleteTermination] = useState(false)
    const [terminationId, setTerminationId] = useState('')
    const [terminationData, setTerminationData] = useState('')
    const [title, setTitle] = useState('')
    const [up, setUP] = useState('')
    const [umpk, setUMPK] = useState('')
    const [uph, setUPH] = useState('')
    const [totalCount, setTotalCount] = useState('')

    const GetTermimnationData = async() => {
        try {
            const response = await Api.GetTermination(localStorage.getItem('hris-token'))
            setTerminationData(response.data.response)
            setTotalCount(response.data.totalCount)
            console.log(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const resetForm = () => {
        setTitle('')
        setUP('')
        setUMPK('')
        setUPH('')
    }

    const PostTermimnationData = async() => {
        try {
            const data = {
                title: title,
                up: up,
                umpk: umpk,
                uph: uph
            }
            if (up < 0 || umpk < 0 || uph < 0){
                toast.error('Data cannot be less than zero')
            } else {
                const response = await Api.CreateTermination(localStorage.getItem('hris-token'), data)
                resetForm()
                setShowModalAddTermination(!showModalAddTermination)
                setRefresh(true)
                toast.success('Success Create')
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const OpenModalTerminationUpdate = async(id) => {
        setShowModalEditTermination(!showModalEditTermination)
        setTerminationId(id)
        try {
            const response = await Api.GetTerminationById(localStorage.getItem('hris-token'), id)
            setTitle(response.data[0].title)
            setUP(response.data[0].up)
            setUMPK(response.data[0].umpk)
            setUPH(response.data[0].uph)
        } catch (error) {
            console.log(error)
        }
    }

    const UpdateTermimnationData = async() => {
        try {
            const data = {
                title: title,
                up: up,
                umpk: umpk,
                uph: uph
            }
            if (up < 0 || umpk < 0 || uph < 0){
                toast.error('Data cannot be less than zero')
            } else{
                const response = await Api.UpdateTermination(localStorage.getItem('hris-token'), data, terminationId)
                resetForm()
                setShowModalEditTermination(!showModalEditTermination)
                setRefresh(true)
                toast.success('Success Update')
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const DeleteTermimnationData = async(id) => {
        try {
            const response = await Api.DeleteTermination(localStorage.getItem('hris-token'), terminationId)
            setShowModalDeleteTermination(!showModalDeleteTermination)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed delete')
        }
    }

    const DeleteTerminationModal = async(id) => {
        setShowModalDeleteTermination(!showModalDeleteTermination)
        setTerminationId(id)
    }

    //State Report To
    const [showModalReportTo, setShowModalReportTo] = useState(false)
    const [valueSwitchReportTo, setvalueSwitchReportTo] = useState(false)
    const [imageReportTo, setImageReportTo] = useState('')
    const [reportMethod, setReportMethod] = useState('')
    const [dataReportTo, setDataReportTo] = useState('')

    const createSupervisor = async () => {
        const data = {
            report_to_employee: idEmployee ,
            report_method: reportMethod,
            structureId: idSupervisor ,
            list_of_report: 'supervisor' 
        }
        try {
            const response = await Api.PostReportToSupervisor(localStorage.getItem('hris-token'), data, idUser)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeReportTo = (e) => {
        setImageReportTo(URL.createObjectURL(e.target.files[0]))
    }

    const handleSwitchReportTo = (e) => {
        setvalueSwitchReportTo(e)
    }

    const getEmployee = async () => {
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), '', '', '', '', '', '', '', '', '')
            setDataEmployee(response.data.data.filter(item => item.role === 'USER'))
        } catch (error) {
            console.log(error)
        }
    }

    const getEmployeeById = async () => {
        try {
            const response = await Api.GetEmployeeById(localStorage.getItem('hris-token'), idEmployee)
            console.log(response, 'employeById')
            setIdSupervisor(response.data[0].user_detail.job_position.superiorId)
        } catch (error) {
            console.log(error)
        }
    }

    const getAllReportTo = async () => {
        try {
            const response = await Api.GetAllReportTo(localStorage.getItem('hris-token'))
            console.log(response, 'reportTo')
            setDataReportTo(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
   
    const [dataReportToById, setDataReportToById] = useState('')
    const openDetailReportTo = async (id) => {
        setShowModalReportTo(!showModalReportTo)
        setIdUser(id)
        try {
            const response = await Api.GetReportToById(localStorage.getItem('hris-token'), id)
            setDataReportToById(response.data.data)
            console.log(response, 'reporttoById')
        } catch (error) {
            
        }
    }

    


    useEffect(() => {
        GetDataEmployeeStatus()
        GetTermimnationData()
        GetDataWorkShift()
        setRefresh(false)
        getAllReportTo()
    }, [refresh])

    useEffect(() => {
        getEmployee()
    }, [showModalReportTo])

    useEffect(() => {
        getEmployeeById()
    }, [idEmployee])

  return (
    <div>
        <Modal
            activeModal={showModalAddEmployeeStatus}
            title={'Add Employee Status'}
            buttonClose={ () => setShowModalAddEmployeeStatus(!showModalAddEmployeeStatus)}
            width={'550px'}
            content= {
                <div className='space-y-[25px]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee Status<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input onChange={(e) => setEmployeStatus(e.target.value)} value={employeStatus} type={'text'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Employee Status...'/>
                    </div>
                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowModalAddEmployeeStatus(!showModalAddEmployeeStatus)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={PostDataEmplyeeStatus} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                    </div>
                </div>
            }
        />
        <Modal
            activeModal={showModalEditEmployeeStatus}
            title={'Edit Employee Status'}
            buttonClose={ () => setShowModalEditEmployeeStatus(!showModalEditEmployeeStatus)}
            width={'550px'}
            content= {
                <div className='space-y-[25px]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee Status<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={employeStatus} onChange={(e) => setEmployeStatus(e.target.value)} type={'text'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Employee Status...'/>
                    </div>
                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowModalEditEmployeeStatus(!showModalEditEmployeeStatus)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={UpdateDataEmployeeStatus} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                    </div>
                </div>
            }
        />
        <ModalDelete
            activeModal={showModalDeleteEmployeeStatus}
            buttonClose={ () => setShowModalDeleteEmployeeStatus(!showModalDeleteEmployeeStatus)}
            submitButton={DeleteDataEmployeeStatus}
        />
        <Modal
            activeModal={showModalAddWorkShift}
        title={'Add Work Shift'}
        buttonClose={ () => setShowModalAddWorkShift(!showModalAddWorkShift)}
        width={'550px'}
        content= {
            <div className='space-y-[25px]'>
                <div className='w-full'>
                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Work Shift<span className='text-[#780000] ml-[4px]'>*</span></h1>
                    <input onChange={(e) => setWorkShift(e.target.value)} value={workShift} type={'text'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Work Shift...'/>
                </div>
                <div className='flex items-center justify-between border-b-2'>
                    <h1 className='text-[#737373] text-sm font-[500]'>Working Hours</h1>
                    <div className='flex items-center gap-2'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Duration : </h1>
                        <select onChange={ (e) => setWorkingHours(e.target.value) } value={workingHours} className='text-[#737373] text-[13px] font-[500] mb-[7px] w-fit border rounded'>
                            <option value={'0'}>0</option>
                            <option value={'1'}>1</option>
                            <option value={'2'}>2</option>
                            <option value={'3'}>3</option>
                            <option value={'4'}>4</option>
                            <option value={'5'}>5</option>
                            <option value={'6'}>6</option>
                            <option value={'7'}>7</option>
                            <option value={'8'}>8</option>
                            <option value={'9'}>9</option>
                            <option value={'10'}>10</option>
                            <option value={'11'}>11</option>
                            <option value={'12'}>12</option>
                        </select>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Hours/Day </h1>
                    </div>
                </div>
                <div className='flex items-center gap-[11px]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Start Time<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input onChange={(e) => setStartTime(e.target.value)} value={startTime} type={'time'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Start Time...'/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>End Time<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input onChange={(e) => setEndTime(e.target.value)} value={endTime} type={'time'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='End Time...'/>
                    </div>
                </div>
                <div className='flex items-center justify-end gap-[12px] mt-5'>
                    <button onClick={ () => setShowModalAddWorkShift(!showModalAddWorkShift)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                    <button onClick={PostWorkShift} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                </div>
            </div>
        }
        />
        <Modal
            activeModal={showModalEditWorkShift}
            title={'Edit Work Shift'}
            buttonClose={ () => setShowModalEditWorkShift(!showModalEditWorkShift)}
            width={'550px'}
            content= {
                <div className='space-y-[25px]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Work Shift<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={workShift} onChange={ (e) => setWorkShift(e.target.value) } type={'text'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Work Shift...'/>
                    </div>
                    <div className='flex items-center justify-between border-b-2'>
                        <h1 className='text-[#737373] text-sm font-[500]'>Working Hours</h1>
                        <div className='flex items-center gap-2'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Duration : </h1>
                            <select value={workingHours} onChange={ (e) => setWorkingHours(e.target.value) } className='text-[#737373] text-[13px] font-[500] mb-[7px] w-fit border rounded'>
                                <option value={'0'}>0</option>
                                <option value={'1'}>1</option>
                                <option value={'2'}>2</option>
                                <option value={'3'}>3</option>
                                <option value={'4'}>4</option>
                                <option value={'5'}>5</option>
                                <option value={'6'}>6</option>
                                <option value={'7'}>7</option>
                                <option value={'8'}>8</option>
                                <option value={'9'}>9</option>
                                <option value={'10'}>10</option>
                                <option value={'11'}>11</option>
                                <option value={'12'}>12</option>
                            </select>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Hours/Day </h1>
                        </div>
                    </div>
                    <div className='flex items-center gap-[11px]'>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Start Time<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input value={startTime} onChange={(e) => setStartTime(e.target.value)} type={'time'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Work Shift...'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>End Time<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input value={endTime} onChange={(e) => setEndTime(e.target.value)} type={'time'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Work Shift...'/>
                        </div>
                    </div>
                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowModalEditWorkShift(!showModalEditWorkShift)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={UpdateDataWorkShift} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                    </div>
                </div>
            }
        />
        <ModalDelete
            activeModal={showModalDeleteWorkShift}
            buttonClose={ () => setShowModalDeleteWorkShift(!showModalDeleteWorkShift)}
            submitButton={DeleteDataWorkShift}
        />
        <Modal
            activeModal={showModalAddTermination}
            title={'Add Termination Reasons'}
            buttonClose={ () => setShowModalAddTermination(!showModalAddTermination)}
            width={'550px'}
            content= {
                <div className='space-y-[25px]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Reason Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input onChange={ (e) => setTitle(e.target.value)} min={0} value={title} type={'text'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Reason Name...'/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Uang Pesangon (UP)<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input onChange={ (e) => setUP(e.target.value) }  min={0} value={up}  type={'number'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='0'/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Uang Penghargaan Masa Kerja (UMPK)<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input onChange={ (e) => setUMPK(e.target.value) } min={0} value={umpk}  type={'number'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='0'/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Uang Penggantian Hak (UPH)<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input onChange={ (e) => setUPH(e.target.value) } min={0} value={uph} type={'number'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='0'/>
                    </div>
                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowModalAddTermination(!showModalAddTermination)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={PostTermimnationData} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Add</button>
                    </div>
                </div>
            }
        />
        <ModalDelete
            activeModal={showModalDeleteTermination}
            buttonClose={ () => setShowModalDeleteTermination(!showModalDeleteTermination)}
            submitButton={DeleteTermimnationData}
        />
        <Modal
            activeModal={showModalEditTermination}
            title={'Edit Termination Reasons'}
            buttonClose={ () => setShowModalEditTermination(!showModalEditTermination)}
            width={'550px'}
            content= {
                <div className='space-y-[25px]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Reason Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={title} onChange={ (e) => setTitle(e.target.value) } min={0} type={'text'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Reason Name...'/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Uang Pesangon (UP)<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={up} onChange={ (e) => setUP(e.target.value) } min={0} type={'number'} className='bg-white border w-full rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px]' placeholder='0'/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Uang Penghargaan Masa Kerja (UMPK)<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={umpk} onChange={ (e) => setUMPK(e.target.value) } min={0} type={'number'} className='bg-white border w-full rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px]' placeholder='0'/>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Uang Penggantian Hak (UPH)<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input value={uph} onChange={ (e) => setUPH(e.target.value) } min={0} type={'number'} className='bg-white border w-full rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px]' placeholder='0'/>
                    </div>
                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowModalEditTermination(!showModalEditTermination)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={UpdateTermimnationData} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                    </div>
                </div>
            }
        />
        <Modal
            activeModal={showModalReportTo}
            buttonClose={() => setShowModalReportTo(!showModalReportTo)}
            width={'550px'}
            title={'Add Report To'}
            content={
                <div className='space-y-[25px]'>
                    <h1 className="text-black text-base font-medium">Supervisor</h1>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] w-full justify-start'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Name</h1>
                                </div>
                                <div className='flex items-center gap-[15px] w-full justify-start'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Reporting Method</h1>
                                </div>
                                <div className='flex items-center gap-[15px] w-full justify-center'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Action</h1>
                                </div>
                            </div>
                            {Object.values(dataReportToById).map((item, idx) => (
                            <div>
                                <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] rounded-[3px]`}>
                                    <div className='w-full flex items-center justify-start'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{item.user.fullname}</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-start'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{item.report_method}</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center'>
                                        <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M6.74823 3.83327H6.61572C6.6886 3.83327 6.74823 3.77364 6.74823 3.70075V3.83327H11.7838V3.70075C11.7838 3.77364 11.8435 3.83327 11.9163 3.83327H11.7838V5.02591H12.9765V3.70075C12.9765 3.11603 12.5011 2.64062 11.9163 2.64062H6.61572C6.03099 2.64062 5.55559 3.11603 5.55559 3.70075V5.02591H6.74823V3.83327ZM15.0967 5.02591H3.43534C3.14215 5.02591 2.90527 5.26278 2.90527 5.55597V6.08604C2.90527 6.15892 2.96491 6.21855 3.03779 6.21855H4.03828L4.44743 14.8818C4.47393 15.4466 4.94105 15.8922 5.5059 15.8922H13.0262C13.5927 15.8922 14.0581 15.4483 14.0846 14.8818L14.4938 6.21855H15.4943C15.5672 6.21855 15.6268 6.15892 15.6268 6.08604V5.55597C15.6268 5.26278 15.3899 5.02591 15.0967 5.02591ZM12.8986 14.6996H5.63344L5.23258 6.21855H13.2995L12.8986 14.6996Z" fill="#003049"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </table>
                    </div>
                    <button onClick={() => setShowModalAddSupervisor(!showModalAddSupervisor)} className='flex items-center py-[7px] px-[8px] gap-[4px] border rounded-lg border-[#8E95A2]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M12.75 11.9336V6.68359H11.25V11.9336H6V13.4336H11.25V18.6836H12.75V13.4336H18V11.9336H12.75Z" fill="#113B61"/>
                        </svg>
                        <h1 className="text-cyan-900 text-sm font-medium">Add Supervisor</h1>
                    </button>

                    <h1 className="text-black text-base font-medium">Subordinate</h1>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] w-full justify-start'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Name</h1>
                                </div>
                                <div className='flex items-center gap-[15px] w-full justify-start'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Reporting Method</h1>
                                </div>
                                <div className='flex items-center gap-[15px] w-full justify-center'>
                                    <h1 className='text-[#737373] text-xs font-[500]'>Action</h1>
                                </div>
                            </div>
                            <div>
                                <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] rounded-[3px]`}>
                                    <div className='w-full flex items-center justify-start'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>PT Humanusia.id</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-start'>
                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>PT Humanusia.id</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center'>
                                        <button   className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M6.74823 3.83327H6.61572C6.6886 3.83327 6.74823 3.77364 6.74823 3.70075V3.83327H11.7838V3.70075C11.7838 3.77364 11.8435 3.83327 11.9163 3.83327H11.7838V5.02591H12.9765V3.70075C12.9765 3.11603 12.5011 2.64062 11.9163 2.64062H6.61572C6.03099 2.64062 5.55559 3.11603 5.55559 3.70075V5.02591H6.74823V3.83327ZM15.0967 5.02591H3.43534C3.14215 5.02591 2.90527 5.26278 2.90527 5.55597V6.08604C2.90527 6.15892 2.96491 6.21855 3.03779 6.21855H4.03828L4.44743 14.8818C4.47393 15.4466 4.94105 15.8922 5.5059 15.8922H13.0262C13.5927 15.8922 14.0581 15.4483 14.0846 14.8818L14.4938 6.21855H15.4943C15.5672 6.21855 15.6268 6.15892 15.6268 6.08604V5.55597C15.6268 5.26278 15.3899 5.02591 15.0967 5.02591ZM12.8986 14.6996H5.63344L5.23258 6.21855H13.2995L12.8986 14.6996Z" fill="#003049"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </table>
                    </div>
                    <button onClick={() => setShowModalAddSubordinate(!showModalAddSubordinate)} className='flex items-center py-[7px] px-[8px] gap-[4px] border rounded-lg border-[#8E95A2]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M12.75 11.9336V6.68359H11.25V11.9336H6V13.4336H11.25V18.6836H12.75V13.4336H18V11.9336H12.75Z" fill="#113B61"/>
                        </svg>
                        <h1 className="text-cyan-900 text-sm font-medium">Add Subordinate</h1>
                    </button>

                </div>
            }
        />
        <Modal
            activeModal={showModalAddSupervisor}
            buttonClose={() => setShowModalAddSupervisor(!showModalAddSupervisor)}
            width={'550px'}
            title={'Add Supervisor'}
            content={
                <div className='space-y-[25px]'>
                    <div className='flex items-center justify-between gap-[23px]'>
                        <div className='w-full'>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-1'>Nama <span className='text-red-600'>*</span></h1>
                            <div className='w-full'>
                                <div className='relative'>
                                    <select onChange={(e) => setIdEmployee(e.target.value)} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] px-[20px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select supervisor name...</option>
                                        {Object.values(dataEmployee).map((item, idx) => (
                                            <option key={idx} value={item.id}>{item.fullname}</option>

                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-1'>Superior Title/ID <span className='text-red-600'>*</span></h1>
                            <div className="w-[252px] h-[35px] flex items-center line-clamp-1 text-[#6B7280] text-xs bg-gray-300 rounded-md border border-stone-300 py-[6px] px-[10px]">{idSupervisor}</div>
                        </div>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-neutral-500 text-[11px] font-medium mb-1'>Reporting Method <span className='text-red-600'>*</span></h1>
                        <div className='w-full'>
                            <div className='relative'>
                                <select onChange={(e) => setReportMethod(e.target.value)}  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Reporting Method...</option>
                                    <option value={'Direct'}>Direct</option>
                                    <option value={'Indirect'}>Indirect</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowModalAddSupervisor(!showModalAddSupervisor)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button onClick={createSupervisor} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                    </div>
                </div>
            }
        />
        <Modal
            activeModal={showModalAddSubordinate}
            buttonClose={() => setShowModalAddSubordinate(!showModalAddSubordinate)}
            width={'550px'}
            title={'Add Subordinate'}
            content={
                <div className='space-y-[25px]'>
                    <div className='flex items-center justify-between gap-[23px]'>
                        <div className='w-full'>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-1'>Nama <span className='text-red-600'>*</span></h1>
                            <div className='w-full'>
                                <div className='relative'>
                                    <select  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select supervisor name...</option>
                                        <option value={'Option'}>Option</option>
                                        <option value={'Option'}>Option</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-neutral-500 text-[11px] font-medium mb-1'>Superior Title/ID <span className='text-red-600'>*</span></h1>
                            <div className="w-full h-[35px] flex items-center justify-start text-[#6B7280] text-xs bg-gray-300 rounded-md border border-stone-300 py-[6px] px-[10px]">Superior Id...</div>
                        </div>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-neutral-500 text-[11px] font-medium mb-1'>Reporting Method <span className='text-red-600'>*</span></h1>
                        <div className='w-full'>
                            <div className='relative'>
                                <select  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Reporting Method...</option>
                                    <option value={'Option'}>Option</option>
                                    <option value={'Option'}>Option</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowModalAddSubordinate(!showModalAddSubordinate)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button  className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                    </div>
                </div>
            }
        />
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Employee Configuration'} LinkBack={'/dashboard'}/>
                        <div className='space-y-[24px] bg-white px-[11px] py-[31px] rounded-[12px] border'>
                            <div className='border-b-2 flex items-center text-[16px] '>
                                <button onClick={ () => setConfiguration('Employee Status') } className={`${configuration === 'Employee Status' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[28px] py-[10px]`}>Employee Status</button>
                                <button onClick={ () => setConfiguration('Work Shift') } className={`${configuration === 'Work Shift' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[28px] py-[10px]`}>Work Shift</button>
                                <button onClick={ () => setConfiguration('Termination Reason') } className={`${configuration === 'Termination Reason' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[28px] py-[10px]`}>Termination Reason</button>
                                <button onClick={ () => setConfiguration('Report To') } className={`${configuration === 'Report To' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[28px] py-[10px]`}>Report To</button>
                            </div>
                        

                        <div className='border rounded-lg px-[27px] py-[41px]'>
                            <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                                <div className='w-full lg:w-auto'>
                                    <h1 className='text-[#454545] text-[20px] font-[600]'>
                                        {configuration === 'Employee Status' ? 'Employee Status'
                                        : configuration === 'Work Shift' ? 'Work Shift'
                                        : configuration === 'Termination Reason' ? 'Termination Reason'
                                        : configuration === 'Report To' ? 'Report To'
                                        : null}
                                    </h1>
                                    <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                        {configuration === 'Employee Status' ? 'List of Employee Status'
                                        : configuration === 'Work Shift' ? 'List of Work Shift'
                                        : configuration === 'Termination Reason' ? 'List of Termination Reason'
                                        : configuration === 'Report To' ? 'List of Report To'
                                        : null}
                                    </h1>
                                </div>
                                <div className='flex items-center gap-[13px]'>
                                    {configuration !== 'Report To' &&(
                                        <button 
                                        onClick={
                                                configuration === 'Employee Status' ? () => setShowModalAddEmployeeStatus(!showModalAddEmployeeStatus)
                                                : configuration === 'Work Shift' ? () => setShowModalAddWorkShift(!showModalAddWorkShift)
                                                : configuration === 'Termination Reason' ? () => setShowModalAddTermination(!showModalAddTermination)
                                                : null
                                            } 
                                        className='bg-[#015995] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                            <BiPlus className='text-white'/>
                                            <h1 className='text-white text-sm font-[500]'>
                                                {configuration === 'Employee Status' ? 'Add Employee Status'
                                                : configuration === 'Work Shift' ? 'Add Work Shift'
                                                : configuration === 'Termination Reason' ? 'Add Termination Reason'
                                                : null}
                                            </h1>
                                        </button>
                                    ) }
                                </div>
                            </div>

                            {
                                configuration === 'Employee Status' ? 
                                <>
                                    <div className='overflow-auto scrollbar-hide'>
                                        <table className='w-full space-y-[10px]'>
                                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='flex items-center gap-[15px] w-full'>
                                                    <h1 className='text-[#737373] text-xs font-[500]'>Employee Status</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center gap-[15px] w-[200px]'>
                                                    <h1 className='text-[#737373] text-xs font-[500]'>Action</h1>
                                                </div>
                                            </div>
                                            { dataEmployeStatus.length === 0 ?
                                            <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='w-full'>
                                                    <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                                </div>
                                                <div className='w-[200px] flex items-center gap-[12px]'>
                                                    <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                                </div>
                                            </div>
                                            
                                            :
                                            Object.values(dataEmployeStatus).map((data, index) => {
                                                return (
                                                    <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                        <div className='w-full'>
                                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>{data.employee_status}</h1>
                                                        </div>
                                                        <div className='w-[200px] flex items-center gap-[12px]'>
                                                            <button onClick={ () => openModalUpdateEmployeeStatus(data.id) } className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                <HiOutlinePencil className='text-[#003049]'/>
                                                            </button>
                                                            <button onClick={ () => DeleteDataEmployeeStatusModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                <CgTrashEmpty className='text-[#003049]'/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </table>
                                    </div>
                                </>
                                : configuration === 'Work Shift'?
                                <>
                                <div className='overflow-auto scrollbar-hide'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[15px] w-[300px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Work Shift</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[15px] w-[200px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Start Time</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[15px] w-[200px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>End Time</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[15px] w-[200px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Duration</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[15px] w-[200px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Action</h1>
                                            </div>
                                        </div>
                                        {dataWorkShift.length === 0 ?
                                        <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='w-[300px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                            </div>
                                            <div className='w-[200px] flex items-center gap-[12px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                            </div>
                                            <div className='w-[200px] flex items-center gap-[12px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                            </div>
                                            <div className='w-[200px] flex items-center gap-[12px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                            </div>
                                            <div className='w-[200px] flex items-center gap-[12px]'>
                                                <h1 className='text-[#737373] text-xs font-[500] truncate'>-</h1>
                                            </div>
                                        </div>
                                        :
                                        Object.values(dataWorkShift).map((data, index) => {
                                            return (
                                                <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='w-[300px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{data.work_shift_name}</h1>
                                                    </div>
                                                    <div className='w-[200px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{data.start_time}</h1>
                                                    </div>
                                                    <div className='w-[200px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{data.end_time}</h1>
                                                    </div>
                                                    <div className='w-[200px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{data.working_hours} Hours</h1>
                                                    </div>
                                                    <div className='w-[200px] flex items-center gap-[12px]'>
                                                        <button onClick={ () => openModalUpdateWorkShift(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <HiOutlinePencil className='text-[#003049]'/>
                                                        </button>
                                                        <button onClick={ () => DeleteDataWorkShiftModal(data.id) } className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <CgTrashEmpty className='text-[#003049]'/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </table>
                                </div>
                                </>
                                :configuration === 'Termination Reason'?
                                <div className='overflow-auto scrollbar-hide'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Name</h1>
                                                <MdImportExport className='text-[#737373] text-xs'/>
                                            </div>
                                            <div className='flex items-center justify-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Uang Pesangon (UP)</h1>
                                            </div>
                                            <div className='flex items-center justify-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Uang Penghargaan Masa Kerja (UMPK)</h1>
                                            </div>
                                            <div className='flex items-center justify-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Uang Penggantian Hak (UPH)</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] w-full justify-center'>
                                                <h1 className='text-[#737373] text-xs font-[500]'>Action</h1>
                                            </div>
                                        </div>
                                        {Object.values(terminationData).map((data, index) => {
                                            return (
                                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{data.title}</h1>
                                                    </div>
                                                    <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate text-center'>{data.up}</h1>
                                                    </div>
                                                    <div className='min-w-[250px] max-w-[250px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate text-center'>{data.umpk}</h1>
                                                    </div>
                                                    <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate text-center'>{data.uph}</h1>
                                                    </div>
                                                    <div className='w-full justify-center flex items-center gap-[12px]'>
                                                        <button onClick={ () => OpenModalTerminationUpdate(data.id) } className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <HiOutlinePencil className='text-[#003049]'/>
                                                        </button>
                                                        <button onClick={ () => DeleteTerminationModal(data.id) } className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <CgTrashEmpty className='text-[#003049]'/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </table>
                                </div>
                                : configuration === 'Report To'?
                                <>
                                    <div className='overflow-auto scrollbar-hide'>
                                        <table className='w-full space-y-[10px]'>
                                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-xs font-[500]'>Employee Name</h1>
                                                </div>
                                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-xs font-[500]'>Job Position</h1>
                                                </div>
                                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-xs font-[500]'>Supervisor</h1>
                                                </div>
                                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-xs font-[500]'>Subordinate</h1>
                                                </div>
                                                <div className='flex items-center gap-[15px] w-full'>
                                                    <h1 className='text-[#737373] text-xs font-[500]'>Action</h1>
                                                </div>
                                            </div>
                                            {Object.values(dataReportTo).map((item, idx) => (
                                                <div key={idx}>
                                                    <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] rounded-[3px]`}>
                                                        <div className='min-w-[200px] max-w-[200px]'>
                                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>{item?.user?.fullname}</h1>
                                                        </div>
                                                        <div className='min-w-[200px] max-w-[200px]'>
                                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>{item?.user?.job_position}</h1>
                                                        </div>
                                                        <div className='min-w-[200px] max-w-[200px]'>
                                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>{item.total_supervisor}</h1>
                                                        </div>
                                                        <div className='min-w-[200px] max-w-[200px]'>
                                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>{item.total_subordinate}</h1>
                                                        </div>
                                                        <div className='w-full flex items-center gap-[12px]'>
                                                            <button onClick={() => openDetailReportTo(item.user.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                    <g clip-path="url(#clip0_1677_11854)">
                                                                        <path d="M15.0217 7.7483C15.3566 8.18644 15.3566 8.77792 15.0217 9.21534C13.9666 10.5926 11.4353 13.4285 8.48006 13.4285C5.52478 13.4285 2.9935 10.5926 1.93845 9.21534C1.77548 9.00556 1.68701 8.74747 1.68701 8.48182C1.68701 8.21617 1.77548 7.95809 1.93845 7.7483C2.9935 6.37101 5.52478 3.53516 8.48006 3.53516C11.4353 3.53516 13.9666 6.37101 15.0217 7.7483V7.7483Z" stroke="#003049" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M8.47986 10.6033C9.65071 10.6033 10.5999 9.65412 10.5999 8.48328C10.5999 7.31244 9.65071 6.36328 8.47986 6.36328C7.30902 6.36328 6.35986 7.31244 6.35986 8.48328C6.35986 9.65412 7.30902 10.6033 8.47986 10.6033Z" stroke="#003049" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_1677_11854">
                                                                        <rect width="16.96" height="16.96" fill="white"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </table>
                                    </div>
                                    <div className='flex items-center justify-between mt-[30px]'>
                                        <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                                        <div className='flex items-center gap-[8px]'>
                                            <MdKeyboardArrowLeft className='text-[#A098AE]'/>
                                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                                <h1 className='text-[#780000] text-[10px]'>1</h1>
                                            </div>
                                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                                <h1 className='text-white text-[10px]'>2</h1>
                                            </div>
                                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                                <h1 className='text-[#780000] text-[10px]'>3</h1>
                                            </div>
                                            <MdKeyboardArrowRight className='text-[#A098AE]'/>
                                        </div>
                                    </div>
                                </>
                                :null
                            }
                        </div>

                    </div>

                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default EmployeeConfiguration