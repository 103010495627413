import React from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'

const InboxDetailReimburstment = ({EmployeeName, Policy, Amount, UseDate, Notes, onClickDecline, onClickApprove, buttonHide, jobPosition}) => {
    return (
        <div className='space-y-10'>
            <div className='bg-[#F8F9FB] space-y-[16px] rounded-[15px] px-[33px] py-[31px]'>
                <div className='border-b-2'>
                    <h1 className='text-[#2E2E2E] text-sm font-[500]'>Detail Reimbursment</h1>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Employee Name</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!EmployeeName ? ': Empty' : `: ${EmployeeName}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Job Position</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!jobPosition ? ': Empty' : `: ${jobPosition}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Reimbursment Policy</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!Policy ? ': Empty' : `: ${Policy}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Amount</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!Amount ? ': Rp. 0' : `: Rp. ${Amount}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Use Date</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!UseDate ? ': Empty' : `: ${UseDate}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Notes</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!Notes ? ': Empty' : `: ${Notes}`}</h1>
                    </div>
                </div>
                {/* <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Attechment</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <button className='p-[10px] rounded-[15px] border flex items-center gap-[10px]'>
                            <MdOutlineFileDownload className='text-[#A8A8A8]'/>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>Download File</h1>
                        </button>
                    </div>
                </div> */}
            </div>
            {buttonHide &&
                <div className='flex items-center justify-end gap-[20px]'>
                    <button onClick={onClickDecline} className='bg-[#C1121F] text-[#EDEDED] text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Decline
                    </button>
                    <button onClick={onClickApprove} className='bg-[#04BB00] text-[#EDEDED] text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Approve
                    </button>
                </div>
            }
        </div>   
    )
}

export default InboxDetailReimburstment