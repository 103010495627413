import React, { useState } from 'react'
import { Modal, Navbar } from '../../../../components'
import { BiSearch } from 'react-icons/bi'
import { AiFillCheckCircle, AiFillClockCircle, AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { FaTimesCircle } from 'react-icons/fa'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { button } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import { useEffect } from 'react'
import toast from 'react-hot-toast'

const OfficialWorkTravel = () => {
    const navigate = useNavigate()
    const status = 'approve'
    const id = 1
    const [showDetailApproval, setShowDetailApproval] = useState('')
    const [dataWorkTravel, setDataWorkTravel] = useState('')
    const [refresh, setRefresh] = useState(false)

    // openIndex
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)
    const handleCollapse = (id) => {
            if(id){
                setCurrent(id)
                setActive(!Active)
            }
    }

    const getDataWorkTravel = async () => {
        try {
            const response = await Api.GetWorkTravel(localStorage.getItem('hris-token'))
            setDataWorkTravel(response.data.workTravels)
            console.log(response, 'getTravel')
        } catch (error) {
            console.log(error)
        }
    }

    const deleteWorkTravel = async (id) => {
        try {
            const response = await Api.DeleteWorkTravel(localStorage.getItem('hris-token'), id)
            setRefresh(true)
            toast.success('Success Delete Work Travel')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete Work Travel')
        }
    }

    useEffect(() => {
        getDataWorkTravel()
        setRefresh(false)
    }, [refresh])
    

  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
    <Modal 
        activeModal={showDetailApproval}
        title={'Detail Approval'}
        buttonClose={ () => setShowDetailApproval(!showDetailApproval)}
        width={'500px'}
        content= {
            <div className='overflow-auto scrollbar-hide'>
                <table className='w-full space-y-[10px]'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center justify-start gap-[6px] min-w-[200px] max-w-[200px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Leader Name</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center justify-end gap-[6px] w-full pr-5'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Status</h1>
                        </div>
                    </div>
                    
                        <div className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                            <div className='min-w-[200px] max-w-[200px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500] truncate text-start'>Esther Howard</h1>
                            </div>
                            <div className='w-full flex items-center justify-end gap-[12px] pr-5'>
                                <div className='rounded-[9px] flex flex-col items-center justify-center'>
                                        {status === 'pending' ? 
                                            <>
                                                <button onClick={() => setShowDetailApproval(!showDetailApproval)}>
                                                    <AiFillClockCircle className='text-[#FFD900] w-[29px] h-[29px]'/>
                                                </button>
                                            </>
                                        :status === 'approved' ?
                                            <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]'/>
                                        :
                                            <>
                                                <button onClick={() => setShowDetailApproval(!showDetailApproval)}>
                                                    <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]'/>
                                                </button>
                                            </>
                                        }
                                </div>
                            </div>
                        </div>
                </table>
            </div>
        }/>
        <div className='flex px-[30px] py-[35px] gap-[30px]'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Official Work Travel'} LinkBack={'/dashboard'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div>
                        <h1 className='text-[20px]'>Official Work Travel</h1>
                        <h2 className='text-[10px] text-[#737373]'>List of  employee work trip</h2>
                    </div>
                    <div className="flex gap-2 mt-[31px] relative">
                        <input  type="date" className='bg-[#F5F8FA] py-[11.5px] px-[10px] text-[#7E8299] text-[12px] font-medium rounded-md' />

                        {/* <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                            <option value="" selected disabled>Set Limit</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                        </select> */}

                        {/* <button className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[11px]'>
                            <TbFileExport className='text-[#003049]'/>
                            <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                        </button> */}
                        <div className="absolute right-0">
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input  placeholder='Search by Employee Name...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                <button onClick={() => navigate('/time-management/official-work-travel/import-data')} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Official Work Trip</h1>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center justify-start gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Employee Name</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-start gap-[6px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Job Position</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-start gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Name of Trip</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-start gap-[6px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Duration</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center  gap-[15px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Approved Leader</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Action </h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                </div>
                                {Object.values(dataWorkTravel).map((item, idx) => (
                                    <div key={idx}>
                                        <div className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-[#737373] text-[11px] font-[500] truncate text-start'>{item.user?.fullname?? '-'}</h1>
                                            </div>
                                            <div className='min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-[#737373] text-[11px] font-[500] truncate text-start'>{item.user?.user_detail.job_position.position_name?? '-'}</h1>
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-[#737373] text-[11px] font-[500] truncate text-start'>{item.office_work_travel.type_name?? '-'}</h1>
                                            </div>
                                            <div className='min-w-[150px] max-w-[150px]'>
                                                <h1 className='text-[#737373] text-[11px] font-[500] truncate text-start'>{item.duration?? '-'}</h1>
                                            </div>
                                            <div className='w-full flex items-center justify-center gap-[12px]'>
                                                <div className='rounded-[9px] flex flex-col items-center justify-center'>
                                                    {item.status === 'pending' ? 
                                                        <>
                                                            <button onClick={() => setShowDetailApproval(!showDetailApproval)}>
                                                                <AiFillClockCircle className='text-[#FFD900] w-[29px] h-[29px]'/>
                                                            </button>
                                                        </>
                                                    :item.status === 'approved' ?
                                                        <>
                                                            <button onClick={() => setShowDetailApproval(!showDetailApproval)}>
                                                                <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]'/>
                                                            </button>
                                                        </>
                                                    :
                                                        <>
                                                            <button onClick={() => setShowDetailApproval(!showDetailApproval)}>
                                                                <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]'/>
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className='w-full flex items-center justify-center gap-[12px]'>
                                                {status === 'approved' ? 
                                                    <button disabled className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                    </button> 
                                                    :
                                                    <button onClick={() => navigate('/time-management/official-work-travel/edit', {state: {workTravelId: item.id}})}  className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                    </button>
                                                }
                                                
                                                <button onClick={() => deleteWorkTravel(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#003049]'/>
                                                </button>

                                                <button onClick={ () => handleCollapse(item.id)} className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                    { item.id === Current && !Active ?
                                                        <MdKeyboardArrowUp className='text-[#003049]'/>
                                                        :
                                                        <MdKeyboardArrowDown className='text-[#003049]'/>
                                                    }
                                                </button>
                                            </div>

                                        </div>
                                        <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${ item.id === Current && !Active  ? 'h-fit border' : 'h-0 border-0' }`}>
                                            <div className='p-[16px] space-y-3'>
                                                <h1 className="text-gray-500 text-sm font-semibold ">Detail Official Work Travel</h1>
                                                <div className='space-y-3'>
                                                    <div className='flex items-center gap-[118px] w-full'>
                                                        <h1 className='col-span-6 text-gray-500 text-xs'>Date</h1>
                                                        <h1 className='col-span-6 text-gray-500 text-xs'>: {item.duration?? '-'}</h1>
                                                    </div>

                                                    <div className='flex w-full items-start gap-[100px]'>
                                                        <div className='w-fit space-y-1'>
                                                            <div className='grid grid-cols-12 '>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>Name</h1>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>: {item.user?.fullname}</h1>
                                                            </div>
                                                            <div className='grid grid-cols-12 '>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>Job Position</h1>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>: {item.user?.user_detail.job_position.position_name}</h1>
                                                            </div>
                                                            <div className='grid grid-cols-12 '>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>Name of Trip</h1>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>: {item.office_work_travel.type_name?? '-'}</h1>
                                                            </div>
                                                            <div className='grid grid-cols-12 '>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>Location</h1>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>: {item.location?? '-'}</h1>
                                                            </div>
                                                        </div>
                                                        <div className='w-fit space-y-1'>
                                                            <div className='grid grid-cols-12 '>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>Duration</h1>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>: 3 Day</h1>
                                                            </div>
                                                            <div className='grid grid-cols-12 '>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>Attachment</h1>
                                                                <div className='col-span-6 text-gray-500 text-xs flex gap-1 items-start'>
                                                                    <h1 className=' text-gray-500 text-xs'>:</h1>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                                        <path d="M5 12.8711V7.87109C5 6.54501 5.52678 5.27324 6.46447 4.33556C7.40215 3.39788 8.67392 2.87109 10 2.87109C11.3261 2.87109 12.5979 3.39788 13.5355 4.33556C14.4732 5.27324 15 6.54501 15 7.87109V14.5378C15 15.4218 14.6488 16.2697 14.0237 16.8948C13.3986 17.5199 12.5507 17.8711 11.6667 17.8711C10.7826 17.8711 9.93477 17.5199 9.30964 16.8948C8.68452 16.2697 8.33333 15.4218 8.33333 14.5378V7.87109C8.33333 7.42907 8.50893 7.00514 8.82149 6.69258C9.13405 6.38002 9.55797 6.20443 10 6.20443C10.442 6.20443 10.866 6.38002 11.1785 6.69258C11.4911 7.00514 11.6667 7.42907 11.6667 7.87109V14.5378" stroke="#015995" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                    <div className="text-cyan-900 text-xs font-medium">Download Surat Tugas</div>
                                                                </div>
                                                            </div>
                                                            <div className='grid grid-cols-12 '>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>Purpose</h1>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>: {item.purpose?? '-'}</h1>
                                                            </div>
                                                            <div className='grid grid-cols-12 '>
                                                                <h1 className='col-span-6 text-gray-500 text-xs'>Approval Leader</h1>
                                                                <div className='col-span-6 text-gray-500 text-xs flex gap-1'>
                                                                    <h1 className=' text-gray-500 text-xs'>:</h1>
                                                                    {item.status === 'pending' ? (
                                                                        <>
                                                                            <AiFillClockCircle className='text-[#FFD900] w-[18px] h-[18px] text-sm'/>
                                                                            <h1 className="text-neutral-500 text-[7px] font-medium">Pending</h1>
                                                                        </>
                                                                    ) : item.status === 'decline' ? (
                                                                        <>
                                                                            <FaTimesCircle className='text-[#C1121F] w-[18px] h-[18px] text-sm'/>
                                                                            <h1 className="text-neutral-500 text-[7px] font-medium">Decline</h1>
                                                                        </>
                                                                    ):(
                                                                        <>
                                                                            <AiFillCheckCircle className='text-[#00AB9A] w-[18px] h-[18px] text-sm'/>
                                                                            <h1 className="text-neutral-500 text-[7px] font-medium">Approved</h1>
                                                                        </>
                                                                    )}
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        {/* <Pagination
                            currentPage={currentPage} 
                            totalPages={totalPages} 
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OfficialWorkTravel