import React, { useState } from 'react'
import { Navbar } from '../../../../components'
import PersonalDetail from '../../My Profile/My Profile/components/PersonalDetail'
import EmployeeData from './components/EmployeeData'
import PayrollData from './components/PayrollData'

const DetailEmployee = () => {
    const [index, setIndex] = useState(0)
  return (
    <div>
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Detail Employee'} LinkBack={'/employee'}/>
                    <h1 className="text-zinc-800 text-xl font-semibold">Detail Employee</h1>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className="flex relative gap-2">
                            <div className='w-[262px] h-[389px] shadow-2xl py-[18px] rounded-bl-xl  relative px-[18px] bg-white'>
                                <button onClick={() => setIndex(0)} className={index === 0 ? 'flex items-center justify-start bg-[#EFF9FF] w-[226px] h-[38px] rounded-md border-r-[5px] border-[#709EDA]' : 'flex items-center justify-start w-[226px] h-[38px] rounded-md'}>
                                    <h1 className={index === 0 ? 'text-[14px] font-semibold text-[#40444C] py-[10px] px-[10px]' : 'text-[14px] font-semibold text-[#6B7280] py-[10px] px-[10px]'}>
                                        Personal
                                    </h1>
                                </button>

                                <button onClick={() => setIndex(1)} className={index === 1 ? 'flex items-center justify-start bg-[#EFF9FF]  h-[38px] w-[226px] rounded-md border-r-[5px] border-[#709EDA]' : 'flex items-center justify-start  h-[38px] w-[226px] rounded-md'}>
                                    <h1 className={index === 1 ? 'text-[14px] font-semibold text-[#40444C] py-[10px] px-[10px]' : 'text-[14px] font-semibold text-[#6B7280] py-[10px] px-[10px]'}>
                                        Employee Data
                                    </h1>
                                
                                </button>

                                <button onClick={() => setIndex(2)} className={index === 2 ? 'flex items-center justify-start bg-[#EFF9FF]  h-[38px] w-[226px] rounded-md border-r-[5px] border-[#709EDA]' : 'flex items-center justify-start  h-[38px] w-[226px] rounded-md'}>
                                    <h1 className={index === 2 ? 'text-[14px] font-semibold text-[#40444C] py-[10px] px-[10px]' : 'text-[14px] font-semibold text-[#6B7280] py-[10px] px-[10px]'}>
                                        Payroll
                                    </h1>
                                </button>
                            </div>
                            <div className="ml-auto w-full p-5 space-y-5 bg-white rounded-r-xl h-[762px] overflow-y-auto scrollbar-default">
                            {index === 0 ? (
                                <PersonalDetail/>
                            ) : index === 1 ? (
                                <EmployeeData />
                            ) : index === 2?(
                                <PayrollData />
                            ) : null }
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DetailEmployee