import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import Api from '../../../../../Api'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { ModalDelete, Pagination } from '../../../../../components'

const Imigration = ({modalAddImigration, modalEditImigration, refreshPage}) => {
    const [data, setData] = useState('')
    const params = useLocation()
    const [showModalDelete, setShowModalDelete] = useState()
    const [idImigration, setIdImigration] = useState()
    const [refresh, setRefresh] = useState(false)
    // console.log(params.state.employeeId)
    const limit = 5
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const getData = async () => {
        try {
            if(!params.state){
                const response = await Api.GetImmigrationByLogin(localStorage.getItem('hris-token'), currentPage, limit)
                setData(response.data.data)
                setCurrentPage(response.data.pagination.page)
                setTotalPages(response.data.pagination.totalPages)
                console.log(response.data, 'notparam')
            } else {
                const response = await Api.GetImmigrationByParam(localStorage.getItem('hris-token'), params.state.employeeId, currentPage, limit)
                setData(response.data.data)
                setCurrentPage(response.data.pagination.page)
                setTotalPages(response.data.pagination.totalPages)
                console.log(response, 'byparam')
            }
        } catch (error) {
            
        }
    }

    const deleteImigrationModal = (id) =>{
        setShowModalDelete(!showModalDelete)
        setIdImigration(id)
        setRefresh(true)
    }

    const deleteImigration = async (id) => {
        try{
            const response = await Api.DeleteImmigration(localStorage.getItem('hris-token'), idImigration)
            setShowModalDelete(!showModalDelete)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }
    
    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh, refreshPage])
    return (
        <div>
        <ModalDelete
            activeModal={showModalDelete}
            buttonClose={() => setShowModalDelete(!showModalDelete)}
            submitButton={deleteImigration}
        />
        <div className='shadow rounded-xl px-[19px] py-[19px]'>
            <div className='flex relative'>
                <div>
                    <h1 className='text-[#000000] text-[20px] font-medium'>Imigration</h1>
                    <h2 className='text-[#737373] text-[10px] mb-[35px]'>List of Imigration</h2>
                </div>
                <div className='absolute right-0'>
                    <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                        <button onClick={modalAddImigration}  className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                            <AiOutlinePlus  className='text-xl text-white'/>
                            <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <div className='overflow-auto scrollbar-hide'>
                    <table className='w-full space-y-[10px] text-[#737373]'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Document</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Number</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Issue Date</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Expiry Date</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Issue By</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Eligible Review Date</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Note</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            
                            <div className='flex items-center justify-center gap-[6px] w-full'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                        </div>
                        {Object.values(data).map((item, idx) => (
                            <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.documentType}</h1>
                                </div>
                                <div className='min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.number}</h1>
                                </div>
                                <div className='min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{moment(item.issueDate).format('DD-MM-YYYY')}</h1>
                                </div>
                                <div className='min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{moment(item.expiryDate).format('DD-MM-YYYY')}</h1>
                                </div>
                                <div className='min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.issuedby}</h1>
                                </div>
                                <div className='min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{moment(item.eligileIssueDate).format('DD-MM-YYYY')}</h1>
                                </div>
                                <div className='min-w-[150px] max-w-[150px]'>
                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.note}</h1>
                                </div>
                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                    <button onClick={() => deleteImigrationModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <CgTrashEmpty className='text-[#003049]'/>
                                    </button>
                                    <button onClick={() => modalEditImigration(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                        <HiOutlinePencil className='text-[#003049]'/>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage} 
                    totalPages={totalPages} 
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>
        </div>
    </div>
  )
}

export default Imigration