import request from "./utils/request";

class Api {

    static urlAPI() {
        return process.env.REACT_APP_BACKEND_URL
    }

    // Begin :: Auth
    static Login(email, password) {
        let path = 'auth/login';
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data: {
                email,
                password,
            },
        })
    }

    static Register(data) {
        let path = 'register';
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
        })
    }

    static Fetch(token) {
        let path = `auth/fetch`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // static GetCountry(token) {
    //     let path = `api/regions/Indonesia/province`;
    //     return request(`${this.urlAPI()}${path}`, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${token}`
    //         }
    //     })
    // }
    
    // Dashboard
    static GetNews(token, title, page, limit) {
        let path = `news/get?title=${title}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetNewsDetail(token, id) {
        let path = `news/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateNews(token, data) {
        let path = `news/create`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateNews(token, data, id) {
        let path = `news/update/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static DeleteNews(token, id) {
        let path = `news/delete/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static PostForum(token, data) {
        let path = `forum/create`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetForum(token) {
        let path = `forum/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetStatistict(token) {
        let path = `statistict/dashboard`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetBirthdateByMonth(token) {
        let path = `birthday-user/get-birtday-user`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetNewEmployee(token) {
        let path = `dashboard/get-count`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UploadJobManagement(token, data) {
        let path = `import/import-data-job`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    
    // Job Grade
    static GetJobGrade(token, page, limit) {
        let path = `job-grade/get?page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetJobGradeByID(token, id) {
        let path = `job-grade/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static PostJobGrade(token, data) {
        let path = `job-grade/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateJobGrade(token, data, id) {
        let path = `job-grade/update-job-grade/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteJobGrade(token, id) {
        let path = `job-grade/delete-job-grade/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    // Job level
    static GetJobLevel(token, page, limit) {
        let path = `job-level/get?page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetJobLevelByID(token, id) {
        let path = `job-level/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static PostJobLevel(token, data) {
        let path = `job-level/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateJobLevel(token, data, id) {
        let path = `job-level/update-job-level/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteJobLevel(token, id) {
        let path = `job-level/delete-job-level/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    

    // Job level
    static GetJobSuperior(token) {
        let path = `job-position/get-superior`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    // Attendance
    
    static GetMyAttendance(token, date, name, page, limit) {
        let path = `attendence/get?date=${date}&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetMyAttendanceById(token, id) {
        let path = `attendence/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostMyAttendanceCheckIn(token, data) {
        let path = `attendence/checkin`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static PostMyAttendanceCheckOut(token, data) {
        let path = `attendencerecord/post `;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostMyAttendance(token, data) {
        let path = `attendence/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetAttendanceRecord(token, date, name, page, limit) {
        let path = `attendencerecord/get?date=${date}&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetAttendanceRecordById(token, id) {
        let path = `attendencerecord/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetJobPosition(token, page, limit) {
        let path = `job-position/get?page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetJobPositionByID(token, id) {
        let path = `job-position/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static PostJobPosition(token, data) {
        let path = `job-position/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateJobPosition(token, data, id) {
        let path = `job-position/update-job-position/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }


    // Leave

    static getLeave(token, date, name, page, limit) {
        let path = `leave/get?dateof_filling=${date}&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static getLeaveByAdmin(token, date, name, page, limit) {
        let path = `leave/get-leave-admin?start_date=${date}&end_date=&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static DeleteJobPosition(token, id) {
        let path = `job-position/delete-job-position/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static getLeaveById(token, id) {
        let path = `leave/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static DeleteLeave(token, id) {
        let path = `leave/delete-leave/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static PostLeave(token, data) {
        let path = `leave/post`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static UpdateLeave(token, data, id) {
        let path = `leave/update-leave/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }


    // LeaveType

    static getLeaveType(token, page, limit) {
        let path = `leavetype/get?page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static getLeaveTypeById(token, id) {
        let path = `leavetype/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static DeleteLeaveType(token, id) {
        let path = `leavetype/delete-leave-setting/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static PostLeaveType(token, data) {
        let path = `leavetype/post`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }



    // Event
    static GetEvent(token) {
        let path = `event/get`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetEventById(token, id) {
        let path = `event/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static PostEvent(token, data) {
        let path = `event/post`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static DeleteEvent(token, id) {
        let path = `event/delete-event/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static UpdateEvent(token, data, id) {
        let path = `event/update-event/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    // My Todo
    static GetTodo(token) {
        let path = `mytodo/get`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetTodoById(token, id) {
        let path = `mytodo/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static PostTodo(token, data) {
        let path = `mytodo/post`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static DeleteTodo(token, id) {
        let path = `mytodo/delete-mytodo/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static UpdateTodo(token, data, id) {
        let path = `mytodo/update-mytodo/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }



    //Schedule

    static GetSchedule(token, date, name, page, limit) {
        let path = `schedule/get?dateof_filling=${date}&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetScheduleById(token, id) {
        let path = `schedule/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static DeleteSchedule(token, id) {
        let path = `schedule/delete-schedule/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static PostSchedule(token, data) {
        let path = `schedule/post`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static UpdateSchedule(token, data, id) {
        let path = `schedule/update-schedule/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }



    // User Management 
    static GetUserManagement(token) {
        let path = `user-manage/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetUserManagementById(token, id) {
        let path = `user-manage/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static CreateUserManagement(token, data) {
        let path = `user-manage/create`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data,
        })
    }

    static UpdateUserManagement(token, id, data) {
        let path = `user-manage/update/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data,
        })
    }
    
    static DeleteUserManagement(token, id) {
        let path = `user-manage/delete/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Package
    
    static GetAllPackage(token) {
        let path = `package/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Employee Status
    static GetEmployeeStatus(token) {
        let path = `employee-status/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetEmployeeStatusById(token, id) {
        let path = `employee-status/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static CreateEmployeeStatus(token, data) {
        let path = `employee-status/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateEmployeeStatus(token, data, id) {
        let path = `employee-status/update-employee-status/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static DeleteEmployeeStatus(token, id) {
        let path = `employee-status/delete-employee-status/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static UpdateLeaveType(token, data, id) {
        let path = `leavetype/update-leave-setting/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    // Work Shift
    static GetWorkShift(token) {
        let path = `work-shift/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetWorkShiftById(token, id) {
        let path = `work-shift/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static CreateWorkShift(token, data) {
        let path = `work-shift/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateWorkShift(token, data, id) {
        let path = `work-shift/update-work-shift/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static DeleteWorkShift(token, id) {
        let path = `work-shift/delete-work-shift/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    // Cost And Profit
    static GetCost(token, page, limit) {
        let path = `cost/get?page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetCostById(token, id) {
        let path = `cost/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static CreateCost(token, data) {
        let path = `cost/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateCost(token, data, id) {
        let path = `cost/update-cost/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static DeleteCost(token, id) {
        let path = `cost/delete-cost/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static GetProfit(token, page, limit) {
        let path = `profit/get?page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetProfitById(token, id) {
        let path = `profit/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static CreateProfit(token, data) {
        let path = `profit/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateProfit(token, data, id) {
        let path = `profit/update-profit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static DeleteProfit(token, id) {
        let path = `profit/delete-profit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Get Country
    static GetCountry() {
        let path = `api/countries`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET'
        })
    }

    // Get Province
    static GetProvince() {
        let path = `https://api.goapi.id/v1/regional/provinsi?api_key=9haY98mRpFbb8f3lAqgWsjIHzYrXoS`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET'
        })
    }


    // General Information
    static GetGeneralInformation(token) {
        let path = `company/get-company-id`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateGeneralInformation(token, data, id) {
        let path = `company/update-company/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Permission
    static GetPermission(token, date, name, page ,limit) {
        let path = `permission/get?dateof_filling=${date}&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetPermissionByAdmin(token, date, name, page, limit) {
        let path = `permission/get-permission-admin?dateof_filling=${date}&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetPermissionById(token, id) {
        let path = `permission/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static PostPermission(token, data) {
        let path = `permission/post`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static UpdatePermission(token, data, id) {
        let path = `permission/update-permission/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static DeletePermission(token, id) {
        let path = `permission/delete-permission/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    // Overtime
    static GetOvertime(token, date, name, page, limit) {
        let path = `overtime/get?dateof_filling=${date}&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetOvertimeByAdmin(token, date, name, page, limit) {
        let path = `overtime/get-overtime-admin?overt_ondate=${date}&fullname=${name}&page=${page}&limit=${limit}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetOvertimeById(token, id) {
        let path = `overtime/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static PostOvertime(token, data) {
        let path = `overtime/post`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static UpdateOvertime(token, data, id) {
        let path = `overtime/update-overtime/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static DeleteOvertime(token, id) {
        let path = `overtime/delete-overtime/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetJobDetail(token, id) {
        let path = `profile/get-job/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static UpdateJobDetail(token, data, id) {
        let path = `profile/post-job/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'PATCH',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    //MyProfile-Report to
    static GetAllReportTo(token) {
        let path = `report-to/get-all`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static GetReportToById(token ,id) {
        let path = `report-to/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetReportToSupervisorByLogin(token) {
        let path = `report-to/get`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    
    }

    static GetReportToSupervisorByParam(token, id) {
        let path = `report-to/get-report-to/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    
    }

    static GetReportToSupervisorById(token, id) {
        let path = `report-to/get/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    
    }

    static GetReportMethod(token) {
        let path = `report-method/get`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetReportMethodByParam(token ,id) {
        let path = `report-method/get-report/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static PostReportToSupervisor(token, data, id) {
        let path = `report-to/post-supervisor/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static UpdateReportToSupervisor(token, data, id) {
        let path = `report-to/update-report-to/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static DeleteReportToSupervisor(token, id) {
        let path = `report-to/delete-report-to/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    // Payroll
    static GetPayroll(token) {
        let path = `payroll/get-payroll`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static GetPayrollById(token, id) {
        let path = `payroll/get-payroll-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }


    // Payroll - Myprofile

    static GetPayrollMyProfileByLogin(token) {
        let path = `profile/get-myprofile-payroll`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetPayrollMyProfileByParam(token, id) {
        let path = `profile/get-myprofile-payroll/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    
    }

    static PostPayrollMyProfile(token, data, id) {
        let path = `profile/post-myprofile-payroll/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    // Income
    static GetIncome(token, id) {
        let path = `payroll/get-income/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetIncomeById(token, id) {
        let path = `payroll/get-income-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static PostIncome(token, data, id) {
        let path = `payroll/post-income/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static UpdateIncome(token, data, id) {
        let path = `payroll/update-income/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'PATCH',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static DeleteIncome(token, id) {
        let path = `payroll/delete-income/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Deduction
    static GetDeduction(token, id) {
        let path = `payroll/get-deduction/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static GetDeductionById(token, id) {
        let path = `payroll/get-deduction-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static PostDeduction(token, data, id) {
        let path = `payroll/post-deduction/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static UpdateDeduction(token, data, id) {
        let path = `payroll/update-deduction/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'PATCH',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static DeleteDeduction(token, id) {
        let path = `payroll/delete-deduction/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Benefit
    static GetBenefit(token, id) {
        let path = `payroll/get-benefit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static GetBenefitById(token, id) {
        let path = `payroll/get-benefit-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static PostBenefit(token, data, id) {
        let path = `payroll/post-benefit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static UpdateBenefit(token, data, id) {
        let path = `payroll/update-benefit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'PATCH',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    static DeleteBenefit(token, id) {
        let path = `payroll/delete-benefit/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }



    
    
    // Termination Reason
    static GetTermination(token) {
        let path = `termination-reason/get`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static GetTerminationById(token, id) {
        let path = `termination-reason/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static CreateTermination(token, data) {
        let path = `termination-reason/post`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateTermination(token, data, id) {
        let path = `termination-reason/update-termination/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static DeleteTermination(token, id) {
        let path = `termination-reason/delete-termination/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    // Get Employee

    static UploadEmployee(token, data) {
        let path = `import/import-data-employee`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'POST',
            data,
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
    }

    static getAllEmployee(token) {
        let path = `employe/get-new`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }

    static GetAllEmployeeById(token, id) {
        let path = `employe/Get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method : 'GET',
            headers: {
                'Authorization' : `Bearer ${token}`
            },
        })
    }
    // Employee
    static GetEmployee(token, name,  page, limit, jobGrade, jobLevel, jobPosition, companyLocation, joinDate, employeeStatus) {
        let path = `employe/get-new?fullname=${name}&page=${page}&limit=${limit}&jobGrade=${jobGrade}&jobLevel=${jobLevel}&jobPosition=${jobPosition}&companyLocation=${companyLocation}&joinDate=${joinDate}&employeeStatus=${employeeStatus}&`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static GetEmployeeById(token, id) {
        let path = `employe/get-by-id/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static CreateEmployee(token, data) {
        let path = `employe/post-new`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static CreateEmployee2(token, data) {
        let path = `employe/post-new-2`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    static UpdateEmployee(token, data, id) {
        let path = `employe/update-employee-new/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static DeleteEmployee(token, id) {
        let path = `employe/delete-employe-new/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    // Employee Transfer
    static GetEmployeeTransfer(token) {
        let path = `employee-transfer/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static CreateEmployeeTransfer(token, data) {
        let path = `employee-transfer/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Reimburstment Setting
    static GetFinanceReimbursmentSetting(token, page, limit) {
        let path = `finance/get-rb-setting?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFinanceReimbursmentSettingById(token, id) {
        let path = `finance/get-rb-setting-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceReimbursmentSetting(token, data) {
        let path = `finance/post-rb-setting`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceReimbursmentSetting(token, data, id) {
        let path = `finance/update-rb-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceReimbursmentSetting(token, id) {
        let path = `finance/delete-rb-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Reimburstment
    static GetFinanceReimbursment(token, name, page, limit) {
        let path = `finance/get-reimburshment?search=${name}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetFinanceReimbursmentById(token, id) {
        let path = `finance/get-reimburshment-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceReimbursment(token, data) {
        let path = `finance/post-reimburshment`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceReimbursment(token, data, id) {
        let path = `finance/update-reimburshment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceReimbursment(token, id) {
        let path = `finance/delete-reimburshment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    // Finance Cash Advance Setting
    static GetFinanceCashAdvanceSetting(token, page, limit) {
        let path = `finance/get-ca-setting?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFinanceCashAdvanceSettingById(token, id) {
        let path = `finance/get-ca-setting-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceCashAdvanceSetting(token, data) {
        let path = `finance/post-ca-setting`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceCashAdvanceSetting(token, data, id) {
        let path = `finance/update-ca-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceCashAdvanceSetting(token, id) {
        let path = `finance/delete-ca-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Cash Advance
    static GetFinanceCashAdvance(token, name, page, limit) {
        let path = `finance/get-cashadvance?search=${name}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetFinanceCashAdvanceById(token, id) {
        let path = `finance/get-cashadvance-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceCashAdvance(token, data) {
        let path = `finance/post-cashadvance`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceCashAdvance(token, data, id) {
        let path = `finance/update-cashadvance/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceCashAdvance(token, id) {
        let path = `finance/delete-cashadvance/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    // Finance Loan Setting
    static GetFinanceLoanSetting(token, page, limit) {
        let path = `finance/get-loan-setting?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFinanceLoanSettingById(token, id) {
        let path = `finance/get-loan-setting-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceLoanSetting(token, data) {
        let path = `finance/post-loan-setting`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceLoanSetting(token, data, id) {
        let path = `finance/update-loan-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceLoanSetting(token, id) {
        let path = `finance/delete-loan-setting/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Finance Loan
    static GetFinanceLoan(token, name,  page, limit) {
        let path = `finance/get-loan?search=${name}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetFinanceLoanById(token, id) {
        let path = `finance/get-loan-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateFinanceLoan(token, data) {
        let path = `finance/post-loan`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateFinanceLoan(token, data, id) {
        let path = `finance/update-loan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteFinanceLoan(token, id) {
        let path = `finance/delete-loan/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    // Finance Loan Installment
    static GetLoanInstallment(token) {
        let path = `finance/get-loan-installment`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetLoanInstallmentById(token, id) {
        let path = `finance/get-loan-installment-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateLoanInstallment(token, data) {
        let path = `finance/post-loan-installment`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateLoanInstallment(token, data, id) {
        let path = `finance/update-loan-installment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteLoanInstallment(token, id) {
        let path = `finance/delete-loan-installment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    // Recruitment

    static GetRecruitment(token, title, page, limit) {
        let path = `recruitment/get?title=${title}&page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetRecruitmentById(token, id) {
        let path = `recruitment/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static CreateRecruitment(token, data) {
        let path = `recruitment/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static UpdateRecruitment(token, data, id) {
        let path = `recruitment/update-recruitment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static DeleteRecruitment(token, id) {
        let path = `recruitment/delete-recruitment/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Recruitment Entry Application

    static GetRecruitmentApplicant(token) {
        let path = `entry-aplicant/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetRecruitmentApplicantById(token, id) {
        let path = `entry-aplicant/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static CreateRecruitmentApplicant(token, data) {
        let path = `entry-aplicant/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Recruitment Stage
    static GetRecruitmentStage(token) {
        let path = `recruitment-stage/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetRecruitmentStageById(token, id) {
        let path = `recruitment-stage/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostRecruitmentStage(token, data) {
        let path = `recruitment-stage/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostEndRecruitment(token, data, id) {
        let path = `recruitment-stage/edit-status-recruitment-stage-applicant/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateRecruitmentStage(token, data, id) {
        let path = `recruitment-stage/edit-recruitment-stage/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Document

    static GetDocument(token, title) {
        let path = `document/get?title=${title}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetDocumentById(token, id) {
        let path = `document/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetDocumentResponden(token, id) {
        let path = `document/get-document-answer-by-documentId/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetDocumentRespondenDetail(token, id, documentId) {
        let path = `document/get-document-answer-by-id/${id}/${documentId}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    
    
    static CreateDocument(token, data) {
        let path = `document/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static CreateDocumentAnswer(token, data) {
        let path = `document/post-documentAnswer`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static UpdateDocument(token, data, id) {
        let path = `document/update-document/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static DeleteDocument(token, id) {
        let path = `document/delete-document/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // My Profile
    static GetPersonalDetail(token) {
        let path = `profile/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetPersonalDetailById(token, id) {
        let path = `profile/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostPersonalDetail(token, data , id) {
        let path = `profile/post/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    // Inbox

    static GetInbox(token) {
        let path = `inbox/get-all`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostInboxApprovallReimbursment(token, data, id) {
        let path = `approval/post-approval-reimburshment/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static PostInboxApprovallLoan(token, data, id) {
        let path = `approval/post-approval-loan/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static PostInboxApprovallCashAdvance(token, data, id) {
        let path = `approval/post-approval-cashadvance/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallLeave(token, data, id) {
        let path = `approval/post-approval-leave/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallPermission(token, data, id) {
        let path = `approval/post-approval-permission/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }

    static PostInboxApprovallOvertime(token, data, id) {
        let path = `approval/post-approval-overtime/${id}`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    
    static UpdateStatusReadInbox(token, data) {
        let path = `inbox/readStatus`;
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
    }
    

    // Emergency Contact
    static GetEmergencyContactByLogin(token, page, limit) {
        let path = `profile/get-emergency-contact-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetEmergencyContactByParam(token, param) {
        let path = `profile/get-emergency-contact/${param}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetEmergencyContactById(token, id) {
        let path = `profile/get-emergency-contact-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static PostEmergencyContact(token, data, id) {
        let path = `profile/post-emergency-contact/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteEmergencyContact(token, id) {
        let path = `profile/delete-emergency-contact/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateEmergencyContact(token, data, id, paramUser) {
        let path = `profile/update-emergency-contact/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    // Dependents

    static GetDependentsByLogin(token, page, limit) {
        let path = `profile/get-dependent-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetDependentsByParams(token, params, page, limit) {
        let path = `profile/get-dependent/${params}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetDependentsById(token, id) {
        let path = `profile/get-dependent-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static PostDependents(token, data, id) {
        let path = `profile/post-dependent/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteDependents(token, id) {
        let path = `profile/delete-dependent/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateDependents(token, data, id) {
        let path = `profile/update-dependent/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Immigration

    static GetImmigrationByLogin(token, page, limit) {
        let path = `profile/get-immigration-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetImmigrationById(token, id) {
        let path = `profile/get-immigration-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetImmigrationByParam(token, param, page, limit) {
        let path = `profile/get-immigration/${param}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static PostImmigration(token, data, id) {
        let path = `profile/post-immigration/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteImmigration(token, id) {
        let path = `profile/delete-immigration/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateImmigration(token, data, id) {
        let path = `profile/update-immigration/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Job
    static GetJobDetailByLogin(token, id) {
        let path = `profile/get-myprofile-job/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostJobDetail(token, data, id) {
        let path = `profile/get-myprofile-job/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }


    // Qualification

    // Work Experience
    static GetWorkExperienceByLogin(token, page, limit) {
        let path = `profile/get-workexperience-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkExperienceById(token, id) {
        let path = `profile/get-workexperience-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetWorkExperienceByParam(token, id, page, limit) {
        let path = `profile/get-workexperience/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkExperience(token, data, id) {
        let path = `profile/post-workexperience/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkExperience(token, id) {
        let path = `profile/delete-workexperience/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkExperience(token, data, id) {
        let path = `profile/update-workexperience/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Work Education
    static GetWorkEducation(token, page, limit) {
        let path = `profile/get-workeducation-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkEducationById(token, id) {
        let path = `profile/get-workeducation-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkEducationByParam(token, id, page, limit) {
        let path = `profile/get-workeducation/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkEducation(token, data, id) {
        let path = `profile/post-workeducation/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkEducation(token, id) {
        let path = `profile/delete-workeducation/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkEducation(token, data, id) {
        let path = `profile/update-workeducation/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Work Skill
    static GetWorkSkill(token, page, limit) {
        let path = `profile/get-workskill-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkSkillById(token, id) {
        let path = `profile/get-workskill-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkSkillByParam(token, id, page, limit) {
        let path = `profile/get-workskill/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkSkill(token, data, id) {
        let path = `profile/post-workskill/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkSkill(token, id) {
        let path = `profile/delete-workskill/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkSkill(token, data, id) {
        let path = `profile/update-workskill/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Work Language
    static GetWorkLanguage(token, page, limit) {
        let path = `profile/get-worklanguage-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkLanguageById(token, id) {
        let path = `profile/get-worklanguage-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkLanguageByParam(token, id, page, limit) {
        let path = `profile/get-worklanguage/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkLanguage(token, data, id) {
        let path = `profile/post-worklanguage/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkLanguage(token, id) {
        let path = `profile/delete-worklanguage/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkLanguage(token, data, id) {
        let path = `profile/update-worklanguage/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Work License
    static GetWorkLicense(token, page, limit) {
        let path = `profile/get-worklicense-login?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkLicenseById(token, id) {
        let path = `profile/get-worklicense-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static GetWorkLicenseByParam(token, id, page, limit) {
        let path = `profile/get-worklicense/${id}?page=${page}&limit=${limit}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostWorkLicense(token, data, id) {
        let path = `profile/post-worklicense/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkLicense(token, id) {
        let path = `profile/delete-worklicense/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    static UpdateWorkLicense(token, data, id) {
        let path = `profile/update-worklicense/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // File
    static GetFileByLogin(token) {
        let path = `profile/get-file-login`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetFileByLoginByParam(token, id) {
        let path = `profile/get-file/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteFile(token, id) {
        let path = `profile/delete-file/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostFile(token, data, id) {
        let path = `profile/post-file/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    
    // Geo Location
    static PostGeoLocation(token, data) {
        let path = `geo-location/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetGeoLocation(token) {
        let path = `geo-location/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetGeoLocationById(token, id) {
        let path = `geo-location/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static UpdateGeoLocation(token, data, id) {
        let path = `geo-location/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteGeoLocation(token, id) {
        let path = `geo-location/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Set Time
    static GetSetTime(token) {
        let path = `set-time/get-set-time`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static CreateSetTime(token, data) {
        let path = `set-time/create-set-time`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }


    // Subsidiary
    static GetSubsidiary(token) {
        let path = `subsidiary/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetSubsidiaryById(token, id) {
        let path = `subsidiary/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateSubsidiary(token, data, id) {
        let path = `subsidiary/update-subsidiary/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static PostSubsidiary(token, data) {
        let path = `subsidiary/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteSubsidiary(token, id) {
        let path = `subsidiary/delete-subsidiary/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetTotalEmployee(token, param) {
        let path = `subsidiary/get-user-total/${param}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Training
    static CreateTraining(token, data, id) {
        let path = `training/post/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateTraining(token, data, id) {
        let path = `training/update-training/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetTrainingByUserId(token, param) {
        let path = `training/get-training/${param}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetTrainingById(token, id) {
        let path = `training/get-training-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetTrainingByLogin(token) {
        let path = `training/get-by-login`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Official Work Travel Setting

    static CreateWorkTravelSetting(token, data) {
        let path = `work-travel/create`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkTravelSetting(token, data, id) {
        let path = `work-travel/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkTrravelSettingById(token, id) {
        let path = `work-travel/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetWorkTravelSetting(token) {
        let path = `work-travel/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteWorkTravelSetting(token, id) {
        let path = `work-travel/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Job Category
    static CreateJobCategory(token, data) {
        let path = `job-category/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateJobCategory(token, data, id) {
        let path = `job-category/update-job-category/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetJobCategoryById(token, id) {
        let path = `job-category/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetJobCategory(token) {
        let path = `job-category/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeleteJobCategory(token, id) {
        let path = `job-category/delete-job-category/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Polling 
    static CreatePolling(token, data) {
        let path = `job-category/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdatePolling(token, data, id) {
        let path = `job-category/update-job-category/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetPollingById(token, id) {
        let path = `polling/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetActivePolling(token) {
        let path = `polling/get-active`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPolling(token, title) {
        let path = `polling/get?titleQuery=${title}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetClosePolling(token) {
        let path = `polling/get-close`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static DeletePolling(token, id) {
        let path = `job-category/delete-job-category/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Punishment
    static CreatePunishment(token, data) {
        let path = `punishment/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdatePunishment(token, data, id) {
        let path = `punishment/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetPunishmentById(token, id) {
        let path = `punishment/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetPunishment(token) {
        let path = `punishment/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeletePunishment(token, id) {
        let path = `punishment/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Official Work Travel Time Management
    static CreateWorkTravel(token, data) {
        let path = `work-travels/post`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateWorkTravel(token, data, id) {
        let path = `work-travels/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetWorkTravelById(token, id) {
        let path = `work-travels/get/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetWorkTravel(token) {
        let path = `work-travels/get`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteWorkTravel(token, id) {
        let path = `work-travels/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    // Announcement
    static CreateAnnouncement(token, data) {
        let path = `announcment/create`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static UpdateAnnouncement(token, data, id) {
        let path = `announcment/update/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    static GetAnnouncementById(token, id) {
        let path = `announcment/get-by-id/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static GetAnnouncement(token) {
        let path = `announcment/get-all`
        return request(`${this.urlAPI()}${path}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    static DeleteAnnouncement(token, id) {
        let path = `announcment/delete/${id}`
        return request(`${this.urlAPI()}${path}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    

}
export default Api;