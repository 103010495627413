import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'

const PayrollData = () => {
  return (
    <div className='space-y-[20px]'>
        <div className="flex items-center justify-between">
            <h1 className='text-xl text-[#000000] font-medium'>Payroll</h1>
            <button  className='bg-[#015995] text-xs font-medium py-[10px] px-[11px] text-white rounded-md flex items-center gap-[7px]'>
                <HiOutlinePencil />
                <h1>Edit Payroll Data</h1>
            </button>

        </div>
        <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Basic Salary <span className='text-[#E00101]'>*</span></h2>
        <input type="text" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-1/2 text-[12px] mb-[20.5px]' placeholder='Input Salary...'/>

        <div className='mb-[20px]'>
            <h1 className="text-black text-base font-medium mb-[12px]">Income</h1>
            <div className='flex gap-[21px] mb-[21.5px] w-full'>
                <div className='w-full'>
                    <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Income</h2>
                    <div className='flex justify-between gap-[12px] items-center w-full mb-[12px]'>
                        <div className='relative  w-full'>
                            <select className='bg-white border rounded-[6px] text-[#40444C] text-[12px] px-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Income...</option>
                                {/* {Object.values(dataProvince).map((item, idx) => (
                                <option data-id={item.id} key={idx} value={item.name}>{item.name}</option>
                                ))} */}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl'/>
                        </div>

                        <h1 className='bg-gray-300 rounded-md py-[10px] px-[23px] text-xs font-medium w-full'>Rp 1.200.000</h1>

                        <button  className='px-[12px] py-[9px] bg-[#FFE1E1] rounded-[6px] flex items-center justify-center'>
                            <CgTrashEmpty className='text-[#780000] text-xl'/>
                        </button>
                    </div>
                    <button className='px-[8px] py-[7px] flex gap-[4px] bg-[#CFDEF8] text-[#113B61] rounded-lg'>
                        <AiOutlinePlus />
                        <h1 className="text-xs font-medium">Add Income</h1>
                    </button>
                </div>
            </div>

            <h1 className="text-black text-base font-medium mb-[12px]">Deduction</h1>
            <div className='w-full'>
                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Deduction</h2>
                <div className='flex justify-between gap-[12px] items-center w-full mb-[12px]'>
                    <div className='relative  w-full'>
                        <select className='bg-white border rounded-[6px] text-[#40444C] text-[12px] px-[21.74px] py-[10px] w-full appearance-none'>
                            <option selected value={''}>Select Deduction...</option>
                            {/* {Object.values(dataProvince).map((item, idx) => (
                            <option data-id={item.id} key={idx} value={item.name}>{item.name}</option>
                            ))} */}
                        </select>
                        <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl'/>
                    </div>

                    <h1 className='bg-gray-300 rounded-md py-[10px] px-[23px] text-xs font-medium w-full'>Rp 1.200.000</h1>

                    <button  className='px-[12px] py-[9px] bg-[#FFE1E1] rounded-[6px] flex items-center justify-center'>
                        <CgTrashEmpty className='text-[#780000] text-xl'/>
                    </button>
                </div>
                <button className='px-[8px] py-[7px] flex gap-[4px] bg-[#CFDEF8] text-[#113B61] rounded-lg'>
                    <AiOutlinePlus />
                    <h1 className="text-xs font-medium">Add Deduction</h1>
                </button>
            </div>

        </div>
        
        <hr className='border-[#D8DBDF] mb-[29.5px] border'/>

        <h1 className="text-black text-xl font-medium mb-[28px]">Bank Info</h1>
        <div className='flex gap-[21px]'>
            <div className='w-full'>
                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Bank Name <span className='text-[#E00101]'>*</span></h2>
                <div className='relative'>
                    <select  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                        <option selected value={''}>Select Bank Name</option>
                        <option value={'Single'}>Single</option>
                        <option value={'Married'}>Married</option>
                    </select>
                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                </div>
            </div>
            <div className='w-full'>
                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Account Holder Name <span className='text-[#E00101]'>*</span></h2>
                <input type="text" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='Full Name'/>
            </div>
        </div>

        <div className='w-1/2'>
            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Account Number <span className='text-[#E00101]'>*</span></h2>
            <input type="text" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='Full Name'/>
        </div>
        
        <hr className='border-[#D8DBDF] mb-[29.5px] border'/>

        <h1 className="text-black text-xl font-medium mb-[28px]">Tax Configuration <span className='text-[#E00101]'>*</span></h1>
        <div className='flex gap-[21px]'>
            <div className='w-full'>
                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>NPWP  <span className='text-[#E00101]'>*</span></h2>
                <input type="number" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='NPWP...'/>
            </div>
            <div className='w-full'>
                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Prorate <span className='text-[#E00101]'>*</span></h2>
                <div className='relative'>
                    <select className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                        <option selected value={''}>Select Prorate</option>
                        <option value={'Single'}>Single</option>
                        <option value={'Married'}>Married</option>
                    </select>
                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                </div>
            </div>
        </div>

        <div className='flex gap-[21px]'>
            <div className='w-full'>
                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>PTKP Status <span className='text-[#E00101]'>*</span></h2>
                <div className='relative'>
                    <select className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                        <option selected value={''}>Select Prorate</option>
                        <option value={'Single'}>Single</option>
                        <option value={'Married'}>Married</option>
                    </select>
                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                </div>
            </div>
            <div className='w-full'>
                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Tax Configuration <span className='text-[#E00101]'>*</span></h2>
                <div className='relative'>
                    <select className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                        <option selected value={''}>Select Prorate</option>
                        <option value={'Single'}>Single</option>
                        <option value={'Married'}>Married</option>
                    </select>
                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                </div>
            </div>
        </div>
        
        <div className='w-1/2 mt-[21px]'>
            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Tax Configuration</h2>
            <div className='relative'>
                <select className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                    <option selected value={''}>Select Prorate</option>
                    <option value={'Single'}>Single</option>
                    <option value={'Married'}>Married</option>
                </select>
                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E] text-xl'/>
            </div>
        </div>

        <hr className='border-[#D8DBDF] mb-[29.5px] mt-[29.5px] border'/>
        <h1 className="text-black text-xl font-medium mb-[28px]">Benefit</h1>
        
        <div className='flex gap-[21px]'>
            <div className='w-full'>
                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>NPWP  <span className='text-[#E00101]'>*</span></h2>
                <input type="text" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='BPJS Ketengakerjaan...'/>
            </div>
            <div className='w-full'>
                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>BPJS Kesejahteraan <span className='text-[#E00101]'>*</span></h2>
                <div className='relative'>
                    <select className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                        <option selected value={''}>BPJS Kesejahteraan</option>
                        <option value={'Single'}>Single</option>
                        <option value={'Married'}>Married</option>
                    </select>
                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                </div>
            </div>
        </div>

        <div className='w-1/2'>
            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>BPJS Kesehatan <span className='text-[#E00101]'>*</span></h2>
            <input type="text" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='BPJS Kesehatan...'/>
        </div>
    </div>
  )
}

export default PayrollData