import React, { useEffect, useState } from 'react'
import { Navbar } from '../../../../components'
import axios from 'axios'
import { IoMdArrowDropdown } from 'react-icons/io'
import moment from 'moment'
import Api from '../../../../Api'
import toast from 'react-hot-toast'
import { RxCross2 } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import { IconInsertPhoto } from '../../../../assets'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'

const AddEmployee = () => {

    const [index, setIndex] = useState(0)
    const [refresh, setRefresh] = useState(false)
    //State Personal Detail
    const [dataCountry, setDataCountry] = useState([])
    const [dataCity, setDataCity] = useState([])
    const [dataProvince, setDataProvince] = useState()
    const [idProvince, setIdProvince] = useState()
    const [name,setName]= useState('')
    const [phone,setPhone]= useState('')
    const [email,setEmail]= useState('')
    const [statusAccount,setStatusAccount]= useState('')
    const [jobGrade,setJobGrade]= useState('')
    const [jobCategory,setJobCategory]= useState('')
    const [jobPosition,setJobPosition]= useState('')
    const [jobLevel,setJobLevel]= useState('')
    const [employeeStatus, setEmployeeStatus]= useState('')
    const [password,setPassword]= useState('')
    const [idNumber, setIdNumber]= useState('')
    const [employeeId, setEmployeeId]= useState('')
    const [birthdate, setBirthDate]= useState('')
    const [street, setStreet]= useState('')
    const [city, setCity]= useState('')
    const [province, setProvince]= useState('')
    const [maritalStatus, setMaritalStatus]= useState('')
    const [postalCode, setPostalCode]= useState('')
    const [gender, setGender]= useState('')
    const [otherEmail, setOtherEmail] = useState('')
    const [telephone, setTelephone] = useState('')
    //State Upload Sign
    const [namepath, setNamePath] = useState('')
    const [file, setFile] = useState('')
    const [viewImage, setViewImage] = useState('')
    const [uploadImage, setUploadImage] = useState('')

    const [joinDate, setJoinDate] = useState('')
    const [contractStartDate, setContractStartDate] = useState('')
    const [contractEndDate, setContractEndDate] = useState('')
    const [basicSalary, setBasicSalary] = useState('')
    const [income, setIncome] = useState('')
    const [deduction, setDeduction] = useState('')
    const [benefit, setBenefit] = useState('')
    const [bankName, setBankName] = useState('')
    const [accountBankName, setAccountBankName] = useState('')
    const [accountBankNumber, setAccountBankNumber] = useState('')
    const [npwp, setNpwp] = useState('')
    const [nppBpjsKesehatan, setNppBpjsKesehatan] = useState('')
    const [prorate, setProrate] = useState('')
    const [ptkpStatus, setPtkpStatus] = useState('')
    const [taxConfiguration, setTaxConfiguration] = useState('')
    const [salaryTaxConfiguration, setSalaryTaxConfiguration] = useState('')
    const [bpjsKesehatan, setBpjsKesehatan] = useState('')
    const [bpjsKetenagakerjaan, SetBpjsKetenagakerjaan] = useState('')


    const navigate = useNavigate()

    const createEmployee = async () => {
        const data = {
            image : uploadImage,
            fullname: name,
            role: 'USER',
            email: email,
            password: password,
            isVerified: true,
            id_number: idNumber,
            birthdate: birthdate,
            marital_status: maritalStatus,
            gender: gender,
            street: street,
            province: province,
            city: city,
            postal_code: postalCode,
            mobile_phone: phone,
            employee_id: employeeId,
            join_date: joinDate,
            job_grade: jobGrade,
            job_level: jobLevel,
            job_position: jobPosition,
            job_category: jobCategory,
            employe_status: employeeStatus,
            // subsidiary: Subsidiary 1,
            contract_start_date: contractStartDate,
            contract_end_date: contractEndDate,
            basic_salary: basicSalary,
            income: '1000',
            deduction: '1000',
            bank_name: bankName,
            account_name: accountBankName,
            account_number: accountBankNumber,
            npwp: npwp,
            prorate: prorate,
            ptkp_status: ptkpStatus,
            tax_configuration: taxConfiguration,
            salary_tax_configuration: salaryTaxConfiguration,
            npp_bpjs_kesehatan: nppBpjsKesehatan,
            bpjs_kesehatan: bpjsKesehatan,
            bpjs_ketenagakerjaan: bpjsKetenagakerjaan
        }
        try {
            console.log(data)
            const response = await Api.CreateEmployee2(localStorage.getItem('hris-token'), data)
            toast.success('Success Create Employee')
        } catch (error) {
            console.log(error)
        }
    }


    // Handle Sign
    const handleChangeFile = (e) => {
        const maxSize = 10 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize){
            toast.error('gambar tidak boleh lebih dari 10 MB')
            setFile(null)
        } else if (!allowedExtensions.includes(fileExtension)){
            toast.error('file harus berformat jpg, jpeg, atau png')
        } else{
            setNamePath(e.target.files[0].name)
            setFile(e.target.files[0])
        }
    }
    //Handle Image
    const handleViewImage = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize){
            toast.error('gambar harus < 2MB')
            setUploadImage(null)
        } else if (!allowedExtensions.includes(fileExtension)){
            toast.error('file harus jpg, jpeg, atau png')
        } else {
            setViewImage(URL.createObjectURL(e.target.files[0]))
            const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                setUploadImage(reader.result)
            };
        }
    }

    const getCountry = async () => {
        try {
            const response = await Api.GetCountry(localStorage.getItem('hris-token'));
            const sortedData = response.data.sort((a, b) => {
              return a.name.common.localeCompare(b.name.common);
            });
            setDataCountry(sortedData);
        } catch (error) {
            console.log(error);
        }
      }
    
      const getProvince = () => {
        axios
          .get(
            `https://api.goapi.id/v1/regional/provinsi?api_key=9haY98mRpFbb8f3lAqgWsjIHzYrXoS`
          )
          .then((response) => {
            setDataProvince(response.data.data)
          })
          .catch((error) => {
          });
      };
    
      const handleProvinceChange = (e) => {
        const selectedProvince = e.target.value;
        const selectedIdProvince = e.target.options[e.target.selectedIndex].getAttribute('data-id');
        
        setProvince(selectedProvince);
        setIdProvince(selectedIdProvince);
      };
    
      const getCity = () => {
        axios
          .get(
            `https://api.goapi.id/v1/regional/kota?provinsi_id=${idProvince}&api_key=9haY98mRpFbb8f3lAqgWsjIHzYrXoS`
          )
          .then((response) => {
            setDataCity(response.data.data)
          })
          .catch((error) => {
          });
      };

      const [dataJobGrade, setDataJobGrade] = useState('')
      const [dataJobPosition, setDataJobPosition] = useState('')
      const [dataJobCategory, setDataJobCategory] = useState('')
      const [dataJobLevel, setDataJobLevel] = useState('')
      const [dataEmployeStatus, setDataEmployeStatus] = useState('')

      const getJobGrade = async() => {
            try {
                const response = await Api.GetJobGrade(localStorage.getItem('hris-token'), '', '')
                setDataJobGrade(response.data.data)
            } catch (error) {
                console.log(error)
            }
        }
      const getJobLevel = async() => {
            try {
                const response = await Api.GetJobLevel(localStorage.getItem('hris-token'), '', '')
                setDataJobLevel(response.data.data)
            } catch (error) {
                console.log(error)
            }
        }
      const getJobPosition = async() => {
            try {
                const response = await Api.GetJobPosition(localStorage.getItem('hris-token'), '', '')
                setDataJobPosition(response.data.data)
                console.log(response)
            } catch (error) {
                console.log(error)
            }
        }
      const getJobCategory = async () => {
        try {
            const response = await Api.GetJobCategory(localStorage.getItem('hris-token'))
            setDataJobCategory(response.data.jobCategory)
        } catch (error) {
            console.log(error)
        }
    }

    const getDataEmployeeStatus = async() => {
        try {
            const response = await Api.GetEmployeeStatus(localStorage.getItem('hris-token'))
            setDataEmployeStatus(response.data.employe_status)
        } catch (error) {
            console.log(error)
        }
    }



      useEffect(() => {
        getCountry()
        getProvince()
        getCity()
        setRefresh(false)
      },[refresh])
    
      useEffect(() => {
        getCity()
      }, [province, dataProvince])

      useEffect(() => {
        getJobGrade()
        getJobLevel()
        getJobPosition()
        getJobCategory()
        getDataEmployeeStatus()
      }, [index])

    if (!dataProvince) {
    return <div className='flex items-center justify-center h-screen text-xl'>Loading...</div>
    }

    return (
        <div>
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='flex px-[30px] py-[35px] gap-[30px]'>
                    <div className='w-full space-y-[24px] overflow-hidden'>
                        <Navbar  SubNavbar={true} NameSubNavbar={'Add Employee'} LinkBack={'/employee'}/>
                        <h1 className="text-zinc-800 text-xl font-semibold">Add Employee</h1>
                        <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                            
                            <div className='flex px-3 justify-around items-center'>
                                <div className='flex gap-[16px] items-center '>
                                    <div className={`w-[42px] h-[42px] rounded-full flex items-center justify-center text-white text-xl font-semibold ${index === 0 ? 'bg-[#7E0000]' : 'bg-[#00AE46]'} `}>1</div>
                                    <h1 className={`${index === 0 ? 'text-red-900' : 'text-[#00AE46]'} text-base font-semibold`}>Personal</h1>
                                </div>
                                <hr className={`${index === 0 ? 'border-[#B6BAC3] border-dashed' : 'border-[#00AE46] border'} border-2  w-[158px]`}/>
                                <div className='flex gap-[16px] items-center'>
                                    <div className={`w-[42px] h-[42px] rounded-full flex items-center justify-center text-white text-xl font-semibold ${index === 1 ? 'bg-[#7E0000]' : index===2 ? 'bg-[#00AE46]' : 'bg-[#B6BAC3]'}`}>2</div>
                                    <h1 className={`${index === 1 ? 'text-red-900' : index === 2?  'text-[#00AE46]' : 'text-[#B6BAC3]'} text-base font-semibold`}>Employee Data</h1>
                                </div>
                                <hr className={`${index === 1? 'border-[#B6BAC3] border-dashed'  : index === 2 ? 'border-[#00AE46] border' : 'border-[#B6BAC3] border-dashed'} border-2 border-[#B6BAC3] w-[158px]  `}/>
                                <div className='flex gap-[16px] items-center'>
                                    <div className={`w-[42px] h-[42px] rounded-full flex items-center justify-center text-white text-xl font-semibold ${index === 2 ? 'bg-[#7E0000]' : 'bg-[#B6BAC3]'} `}>3</div>
                                    <h1 className={`${index === 2 ? 'text-red-900' : 'text-gray-400'} text-base font-semibold`}>Payroll</h1>
                                </div>
                            </div>

                            {index === 0 ? (
                                <div>
                                    <h1 className="text-black text-xl font-medium mb-[28px]">Personal Data</h1>
                                    
                                    <div>
                                        
                                        <div className='flex gap-[14px] mb-[28px]'>
                                            <h1 className='text-gray-500 text-[11px] font-medium'>Photo <span className='text-[#E00101]'>*</span></h1>
                                            <label htmlFor='upload-image'>
                                                <div className='w-[87px] h-[87px] rounded-full bg-[#D9D9D9] bg-cover shadow-md border' style={{ backgroundImage: `url(${viewImage})` }}>
                                                    {!viewImage &&
                                                        <div className='flex flex-col justify-center items-center space-y-3 h-full'>
                                                            <img src={IconInsertPhoto} alt='Insert Humanusia' className='object-contain'/>
                                                        </div>
                                                    }
                                                </div>
                                                <input type='file' className='hidden' id='upload-image' onChange={ (e) => handleViewImage(e) }/>
                                            </label>
                                        </div>

                                        <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Full Name <span className='text-[#E00101]'>*</span></h2>
                                        <input type="text" onChange={(e) => setName(e.target.value)} value={name} className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-1/2 text-[12px] mb-[20.5px]' placeholder='Input Full Name...'/>

                                        <hr className='border-[#D8DBDF] mb-[29.5px] border-2'/>

                                        <div className='w-1/2'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>ID Number <span className='text-[#E00101]'>*</span></h2>
                                            <input onChange={(e) => setIdNumber(e.target.value)} value={idNumber} type="number" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px]' placeholder='Input ID Number...'/>
                                        </div>

                                        <hr className='border-[#D8DBDF] mb-[29.5px] border-2'/>

                                        <div className='flex gap-[21px] mb-[12px] w-full'>
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Birth of Date <span className='text-[#E00101]'>*</span></h2>
                                                <input onChange={(e) => setBirthDate(e.target.value)} value={birthdate} type="date" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='Full Name'/>
                                            </div>
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Marital Status <span className='text-[#E00101]'>*</span></h2>
                                                <div className='relative'>
                                                    <select onChange={(e) => setMaritalStatus(e.target.value)} value={maritalStatus} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                        <option selected value={''}>Select Marital Status</option>
                                                        <option value={'Single'}>Single</option>
                                                        <option value={'Married'}>Married</option>
                                                    </select>
                                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='text-[#40444C] text-[11px] font-medium'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-2'>Gender <span className='text-[#E00101]'>*</span></h2>
                                            <div className="flex gap-[28px]">
                                                <div className="flex items-center mb-5">
                                                    <input onChange={(e) => setGender(e.target.value)} id="default-radio-1" type="radio" value="Male" name="default-radio" className="w-4 h-4 bg-gray-100 border-gray-300"/>
                                                    <label for="default-radio-1" className="ml-[21.13px] text-[#40444C] text-[12px] font-medium">Male</label>
                                                </div>
                                                <div className="flex items-center mb-5">
                                                    <input onChange={(e) => setGender(e.target.value)}  id="default-radio-2" type="radio" value="Female" name="default-radio" className="w-4 h-4 bg-gray-100 border-gray-300"/>
                                                    <label for="default-radio-2" className="ml-[21.13px] text-[#40444C] text-[12px] font-medium">Female</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mb-[28px]'>
                                            <h1 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Sign <span className='text-[#E00101]'>*</span></h1>
                                            <div className='flex items-center gap-[13px]'>
                                                <label htmlFor='upload-file' className='cursor-pointer'>
                                                    <div className='flex w-[473px] h-[35px] bg-gray-300 rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                                        <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                                            <div className="text-center text-sky-950 text-[9px] font-medium">Browse</div>
                                                        </div>
                                                        <h1 className='text-zinc-700 text-xs font-medium'>{namepath? namepath : 'No File Selected' }</h1>
                                                        <input type='file' id='upload-file' accept=".jpeg, .jpg, .png" onChange={(e) => handleChangeFile(e)}  className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required/>
                                                    </div>
                                                </label>
                                            </div>
                                            <h1 className=" text-red-600 text-[8px] font-normal">* Maks 500 KB</h1>
                                        </div>
                                        
                                    

                                    </div>

                                    <div>
                                        <h1 className="text-black text-xl font-medium mb-[24px]">Contact Details</h1>
                                        <div className='flex gap-[21px] mb-[21.5px] w-full'>
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Province <span className='text-[#E00101]'>*</span></h2>
                                                <div className='relative'>
                                                    <select onChange={handleProvinceChange} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] px-[21.74px] py-[10px] w-full appearance-none'>
                                                        <option selected value={''}>Select Province...</option>
                                                        {Object.values(dataProvince).map((item, idx) => (
                                                        <option data-id={item.id} key={idx} value={item.name}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                    <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl'/>
                                                </div>
                                            </div>
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>City <span className='text-[#E00101]'>*</span></h2>
                                                <div className='relative w-full'>
                                                    <select onChange={(e) => setCity(e.target.value)} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                        <option selected value={''}>Input City...</option>
                                                        {Object.values(dataCity).map((item, idx) => (
                                                        <option key={idx} value={item.name}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex gap-[22px]">
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Street <span className='text-[#E00101]'>*</span></h2>
                                                <input onChange={(e) => setStreet(e.target.value)} value={street} type="text" placeholder='Input Street...' className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px]'/>
                                            </div>

                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Postal Code <span className='text-[#E00101]'>*</span></h2>
                                                <input onChange={(e) => setPostalCode(e.target.value)} value={postalCode} type="number"  placeholder='Input Postal Code...' className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px]'/>
                                            </div>                                        
                                        </div>

                                        <hr className='border-[#D8DBDF] mb-[29.5px] border-2'/>
                                        
                                        <div className="flex gap-[22px] w-full">
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Mobile Phone <span className='text-[#E00101]'>*</span></h2>
                                                <input onChange={(e) => setPhone(e.target.value)} type="number" value={phone} className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px]' placeholder='Input Mobile Phone...'/>
                                            </div>
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Telephone</h2>
                                                <input onChange={(e) => setTelephone(e.target.value)}  type="number" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px]' placeholder='Input Telephone...'/>
                                            </div>
                                        </div>

                                        <div className="text-black text-xl font-medium mb-[24px]">Account Details</div>

                                        <div className='w-1/2'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Email <span className='text-[#E00101]'>*</span></h2>
                                            <input onChange={(e) => setEmail(e.target.value)}  type="text" value={email} className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px]' placeholder='Input Email...'/>
                                        </div>
                                        <div className="flex gap-[22px] mb-[50px] w-full">
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Password <span className='text-[#E00101]'>*</span></h2>
                                                <input onChange={(e) => setPassword(e.target.value)}  type="password" value={password} className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px]' placeholder='Input Password...'/>
                                            </div>
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Confirm Password <span className='text-[#E00101]'>*</span></h2>
                                                <input  type="password" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px]' placeholder='Input Password...'/>
                                            </div>
                                        </div>

                                        <hr className='border-[#D8DBDF] mb-[29.5px] border-2'/>

                                        <div className='w-1/2'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Status Account <span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative w-full'>
                                                <select onChange={(e) => setStatusAccount(e.target.value)}  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select Status Account....</option>
                                                    <option value={true}>Enable</option>
                                                    <option value={false}>Disable</option>
                                                    
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex gap-2 justify-end'>
                                        <button onClick={() => navigate(-1)} className='text-[14px] font-medium py-[10px] px-[25px] text-[#0E5073] rounded-md border flex items-center justify-center gap-2 bg-[#EDEEF1]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M6.00016 1L1.3335 5.66667L6.00016 10.3333" stroke="#113B61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M1.3335 5.66797H12.0002" stroke="#113B61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            Back
                                        </button>
                                        <button onClick={() => setIndex(1)} className='bg-[#0E5073] text-[14px] font-medium py-[10px] px-[25px] text-white rounded-md'>Next</button>
                                    </div>
                                </div>
                            ) : index === 1 ? (
                                <div>
                                    <h1 className="text-black text-xl font-medium mb-[28px]">Employee Data</h1>
                                    
                                    <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Employee ID <span className='text-[#E00101]'>*</span></h2>
                                    <input type="number" onChange={(e) => setEmployeeId(e.target.value)} value={employeeId} className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-1/2 text-[12px] mb-[20.5px]' placeholder='Input Employee ID...'/>

                                    <div className='flex gap-[21px] mb-[12px] w-full'>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Join Date <span className='text-[#E00101]'>*</span></h2>
                                            <input onChange={(e) => setJoinDate(e.target.value)} value={(joinDate)} type='date' className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] '/>
                                        </div>
                                    </div>

                                    <div className='flex gap-[21px] mb-[21.5px] w-full'>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Job Grade<span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative'>
                                                <select onChange={(e) => setJobGrade(e.target.value)} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] px-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select Job Grade...</option>
                                                    {Object.values(dataJobGrade).map((item, idx) => (
                                                        <option key={idx} value={item.id}>{item.job_grade}</option>
                                                    ))}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Job Level<span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative w-full'>
                                                <select onChange={(e) => setJobLevel(e.target.value)}  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select Job Level...</option>
                                                    {Object.values(dataJobLevel).map((item, idx) => (
                                                    <option key={idx} value={item.id}>{item.level_name}</option>
                                                    ))}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex gap-[21px] mb-[21.5px] w-full'>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Job Category<span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative w-full'>
                                                <select onChange={(e) => setJobCategory(e.target.value)}  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select Job Category...</option>
                                                    {Object.values(dataJobCategory).map((item, idx) => (
                                                        <option key={idx} value={item.id}>{item.category_name}</option>
                                                    ))}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Job Position<span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative w-full'>
                                                <select onChange={(e) => setJobPosition(e.target.value)}  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select Job Position...</option>
                                                    {Object.values(dataJobPosition).map((item, idx) => (
                                                        <option key={idx} value={item.id}>{item.position_name}</option>
                                                    ))}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex gap-[21px] mb-[21.5px] w-full'>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Subsidiary<span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative'>
                                                <select  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] px-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select Subsidiary...</option>
                                                    {/* {Object.values(dataProvince).map((item, idx) => (
                                                    <option data-id={item.id} key={idx} value={item.name}>{item.name}</option>
                                                    ))} */}
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-1/2 mb-[20px]'>
                                        <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Employee Status<span className='text-[#E00101]'>*</span></h2>
                                        <div className='relative w-full'>
                                            <select onChange={(e) => setEmployeeStatus(e.target.value)} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                <option selected value={''}>Select Employee Status....</option>
                                                {Object.values(dataEmployeStatus).map((item, idx) => (
                                                    <option key={idx} value={item.id}>{item.employee_status}</option>
                                                    ))}
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                        </div>
                                    </div>

                                    <div className='flex gap-[21px] mb-[12px] w-full'>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Contract Start Date <span className='text-[#E00101]'>*</span></h2>
                                            <input onChange={(e) => setContractStartDate(e.target.value)} value={contractStartDate} type="date"  className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='Full Name'/>
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Contract End Date <span className='text-[#E00101]'>*</span></h2>
                                            <input onChange={(e) => setContractEndDate(e.target.value)} value={contractEndDate} type="date"  className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='Full Name'/>
                                        </div>
                                    </div>

                                    <div className='mb-[28px]'>
                                        <h1 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Contract Attachment</h1>
                                        <div className='flex items-center gap-[13px]'>
                                            <label htmlFor='upload-file' className='cursor-pointer'>
                                                <div className='flex w-[473px] h-[35px]  rounded-md border border-stone-300 gap-[11px] items-center px-1.5'>
                                                    <div className="w-[54px] h-[27px] px-2.5 py-2 bg-gray-200 rounded-md justify-center items-center gap-2.5 inline-flex">
                                                        <div className="text-center text-sky-950 text-[9px] font-medium">Browse</div>
                                                    </div>
                                                    <h1 className='text-zinc-700 text-xs font-medium'>{namepath? namepath : 'No File Selected' }</h1>
                                                    <input type='file' id='upload-file' accept=".jpeg, .jpg, .png" onChange={(e) => handleChangeFile(e)}  className='rounded-[12px] outline-none border border-[#E3E8F1] w-foll px-[20px] py-[15px] hidden' required/>
                                                </div>
                                            </label>
                                        </div>
                                        <h1 className=" text-red-600 text-[8px] font-normal">* Maks file .pdf or .doc 500 KB</h1>
                                    </div>

                                    <div className='flex gap-2 justify-end'>
                                        <button onClick={() => setIndex(0)} className='text-[14px] font-medium py-[10px] px-[25px] text-[#0E5073] rounded-md border flex items-center justify-center gap-2 bg-[#EDEEF1]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M6.00016 1L1.3335 5.66667L6.00016 10.3333" stroke="#113B61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M1.3335 5.66797H12.0002" stroke="#113B61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            Back
                                        </button>
                                        <button onClick={() => setIndex(2)} className='bg-[#0E5073] text-[14px] font-medium py-[10px] px-[25px] text-white rounded-md'>Next</button>
                                    </div>

                                </div>
                            ) : index === 2 ? (
                                <div>
                                    <h1 className="text-black text-xl font-medium mb-[28px]">Employee Data</h1>
                                    
                                    <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Basic Salary <span className='text-[#E00101]'>*</span></h2>
                                    <input type="number" onChange={(e) => setBasicSalary(e.target.value)} value={basicSalary} className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-1/2 text-[12px] mb-[20.5px]' placeholder='Input Salary...'/>

                                    <div className='mb-[20px]'>
                                        <h1 className="text-black text-base font-medium mb-[12px]">Income</h1>
                                        <div className='flex gap-[21px] mb-[21.5px] w-full'>
                                            <div className='w-full'>
                                                <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Income</h2>
                                                <div className='flex justify-between gap-[12px] items-center w-full mb-[12px]'>
                                                    <div className='relative w-full'>
                                                        <select   className='bg-white border rounded-[6px] text-[#40444C] text-[12px] px-[21.74px] py-[10px] w-full appearance-none'>
                                                            <option selected value={''}>Select Income...</option>
                                                            {/* {Object.values(dataProvince).map((item, idx) => (
                                                            <option data-id={item.id} key={idx} value={item.name}>{item.name}</option>
                                                            ))} */}
                                                        </select>
                                                        <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl'/>
                                                    </div>

                                                    <h1 className='bg-gray-300 rounded-md py-[10px] px-[23px] text-xs font-medium w-full'>Rp 1.200.000</h1>

                                                    <button  className='px-[12px] py-[9px] bg-[#FFE1E1] rounded-[6px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#780000] text-xl'/>
                                                    </button>
                                                </div>
                                                <button className='px-[8px] py-[7px] flex gap-[4px] bg-[#CFDEF8] text-[#113B61] rounded-lg'>
                                                    <AiOutlinePlus />
                                                    <h1 className="text-xs font-medium">Add Income</h1>
                                                </button>
                                            </div>
                                        </div>

                                        <h1 className="text-black text-base font-medium mb-[12px]">Deduction</h1>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Deduction</h2>
                                            <div className='flex justify-between gap-[12px] items-center w-full mb-[12px]'>
                                                <div className='relative  w-full'>
                                                    <select onChange={handleProvinceChange}  className='bg-white border rounded-[6px] text-[#40444C] text-[12px] px-[21.74px] py-[10px] w-full appearance-none'>
                                                        <option selected value={''}>Select Deduction...</option>
                                                        {/* {Object.values(dataProvince).map((item, idx) => (
                                                        <option data-id={item.id} key={idx} value={item.name}>{item.name}</option>
                                                        ))} */}
                                                    </select>
                                                    <IoMdArrowDropdown className='absolute top-[10px] right-2 text-[#2E2E2E ] text-xl'/>
                                                </div>

                                                <h1 className='bg-gray-300 rounded-md py-[10px] px-[23px] text-xs font-medium w-full'>Rp 1.200.000</h1>

                                                <button  className='px-[12px] py-[9px] bg-[#FFE1E1] rounded-[6px] flex items-center justify-center'>
                                                    <CgTrashEmpty className='text-[#780000] text-xl'/>
                                                </button>
                                            </div>
                                            <button className='px-[8px] py-[7px] flex gap-[4px] bg-[#CFDEF8] text-[#113B61] rounded-lg'>
                                                <AiOutlinePlus />
                                                <h1 className="text-xs font-medium">Add Deduction</h1>
                                            </button>
                                        </div>

                                    </div>
                                    
                                    <hr className='border-[#D8DBDF] mb-[29.5px] border'/>

                                    <h1 className="text-black text-xl font-medium mb-[28px]">Bank Info</h1>
                                    <div className='flex gap-[21px]'>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Bank Name <span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative'>
                                                <select onChange={(e) => setBankName(e.target.value)} value={bankName} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select Bank Name</option>
                                                    <option value={'BNI'}>BNI</option>
                                                    <option value={'BCA'}>BCA</option>
                                                    <option value={'Mandiri'}>Mandiri</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Account Holder Name <span className='text-[#E00101]'>*</span></h2>
                                            <input onChange={(e) => setAccountBankName(e.target.value)} value={accountBankName} type="text" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='Input Account Holder Name...'/>
                                        </div>
                                    </div>

                                    <div className='w-1/2'>
                                        <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Account Number <span className='text-[#E00101]'>*</span></h2>
                                        <input onChange={(e) => setAccountBankNumber(e.target.value)} value={accountBankNumber} type="text" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='Input Account Number...'/>
                                    </div>
                                    
                                    <hr className='border-[#D8DBDF] mb-[29.5px] border'/>

                                    <h1 className="text-black text-xl font-medium mb-[28px]">Tax Configuration <span className='text-[#E00101]'>*</span></h1>
                                    <div className='flex gap-[21px]'>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>NPWP</h2>
                                            <input onChange={(e) => setNpwp(e.target.value)} type="number" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='NPWP...'/>
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Prorate <span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative'>
                                                <select onChange={(e) => setProrate(e.target.value)} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select Prorate</option>
                                                    <option value={'Based on Working Day'}>Based on Working Day</option>
                                                    <option value={'Based on Calendar Day'}>Based on Calendar Day</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex gap-[21px]'>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>PTKP Status <span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative'>
                                                <select onChange={(e) => setPtkpStatus(e.target.value)} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select PTKP Status</option>
                                                    <option value={'TK/0'}>TK/0</option>
                                                    <option value={'TK/1'}>TK/1</option>
                                                    <option value={'TK/2'}>TK/2</option>
                                                    <option value={'TK/3'}>TK/3</option>
                                                    <option value={'K/I/0'}>K/I/0</option>
                                                    <option value={'K/I/1'}>K/I/1</option>
                                                    <option value={'K/I/2'}>K/I/2</option>
                                                    <option value={'K/I/3'}>K/I/3</option>
                                                    <option value={'K/0'}>K/0</option>
                                                    <option value={'K/1'}>K/1</option>
                                                    <option value={'K/2'}>K/2</option>
                                                    <option value={'K/3'}>K/3</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Tax Configuration <span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative'>
                                                <select onChange={(e) => setTaxConfiguration(e.target.value)} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select Tax Configuration</option>
                                                    <option value={'Nett'}>Nett</option>
                                                    <option value={'Gross'}>Gross</option>
                                                    <option value={'Gross Up'}>Gross Up</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='w-1/2 mt-[21px]'>
                                        <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>Salary Tax Configuration</h2>
                                        <div className='relative'>
                                            <select onChange={(e) => setSalaryTaxConfiguration(e.target.value)} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                <option selected value={''}>Select Salary Tax Configuration</option>
                                                <option value={'Taxble'}>Taxble</option>
                                                <option value={'Non Taxable'}>Non Taxable</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E] text-xl'/>
                                        </div>
                                    </div>

                                    <hr className='border-[#D8DBDF] mb-[29.5px] mt-[29.5px] border'/>
                                    <h1 className="text-black text-xl font-medium mb-[28px]">Benefit</h1>
                                    
                                    <div className='flex gap-[21px]'>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>NPP<span className='text-[#E00101]'>*</span></h2>
                                            <input onChange={(e) => setNppBpjsKesehatan(e.target.value)} value={nppBpjsKesehatan} type="text" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='BPJS Ketengakerjaan...'/>
                                        </div>
                                        <div className='w-full'>
                                            <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>BPJS Kesejahteraan <span className='text-[#E00101]'>*</span></h2>
                                            <div className='relative'>
                                                <select onChange={(e) => setBpjsKesehatan(e.target.value)} className='bg-white border rounded-[6px] text-[#40444C] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                                    <option selected value={''}>Select BPJS Kesehatan</option>
                                                    <option value={'By Employee'}>By Employee</option>
                                                    <option value={'By Company'}>By Company</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-1/2'>
                                        <h2 className='text-[#40444C] text-[12px] font-medium mb-[9px]'>BPJS Ketenagakerjaan <span className='text-[#E00101]'>*</span></h2>
                                        <input onChange={(e) => SetBpjsKetenagakerjaan(e.target.value)} type="text" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#40444C] w-full text-[12px] mb-[20.5px] ' placeholder='BPJS Kesehatan...'/>
                                    </div>

                                    <div className='flex gap-2 justify-end'>
                                        <button onClick={() => setIndex(1)} className='text-[14px] font-medium py-[10px] px-[25px] text-[#0E5073] rounded-md border flex items-center justify-center gap-2 bg-[#EDEEF1]'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                                <path d="M6.00016 1L1.3335 5.66667L6.00016 10.3333" stroke="#113B61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M1.3335 5.66797H12.0002" stroke="#113B61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            Back
                                        </button>
                                        <button onClick={createEmployee} className='bg-[#0E5073] text-[14px] font-medium py-[10px] px-[25px] text-white rounded-md'>Finish</button>
                                    </div>
                                </div>
                            ) : null}

                            

                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEmployee