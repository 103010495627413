import React, { useEffect, useState } from 'react'
import { IconAdmin, IconDashboard, IconDocument, IconEmployee, IconFinance, IconLogout, IconPayroll, IconConfiguration, IconRecruitment, IconTimeManagement, Logo, Logo2, LogoH, IconDashboardSelected, IconAdminSelected, IconConfigurationSelected, IconEmployeeSelected, IconDocumentSelected, IconTimeManagementSelected, IconPayrollSelected, IconRecruitmentSelected, IconFinanceSelected, IconPerformanceSelected, IconPerformance } from '../../assets'
import { RiArrowRightSLine, RiArrowLeftSLine, RiArrowRightSFill } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Api from '../../Api';

const Sidebar = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [display, setDisplay] = useState(true)
    const [admin, setAdmin] = useState(false)
    const [job, setJob] = useState(false)
    const [organization, setOrganization] = useState(false)
    const [employee, setEmployee] = useState(false)
    const [configuration, setConfiguration] = useState(false)
    const [timeManagement, setTimeManagement] = useState(false)
    const [attendance, setAttendance] = useState(false)
    const [role, setRole] = useState('')
    const [selectedSidebar, setSelectedSidebar] = useState('Dashboard')
    const [document, setDocument] = useState(false)
    const [payroll, setPayroll] = useState(false)
    const [finance, setFinance] = useState(false)
    const [performance, setPerformance] = useState(false)
    const [recruitment, setRecruitment] = useState(false)
    const [dashboard, setDashboard] = useState(false)

    const Fetch = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setRole(response.data.results.user.role)
        } catch (error) {
            console.log(error)
            navigate('/login')
        }
    }

    const Logout = async() => {
        localStorage.removeItem('hris-token')
        localStorage.removeItem('hris-role')
        navigate('/')
    }

    useEffect(() => {
        Fetch()
    },[])

    return (
        <div className={`hidden lg:block py-[40px] border h-screen bg-white shadow-sm sticky ${display ? 'w-[290px] px-[14px]' : 'w-[124px] px-[45px]'} transition-all duration-1000 ease-in-out rounded-[20px] ml-[46px] mt-[34px] shadow-inner`}>
            <div className='border-b-2 w-full py-[8px] relative'>
                <Link to={'/dashboard'} className='flex items-center justify-center'>
                    <img src={display ? Logo2 : LogoH} alt='Humanusia' className={`${display? 'h-[60px] w-[188px]' : 'w-[33px] h-[47px] object-contain'}`}/>
                </Link>
                <button 
                onClick={ () => {setDisplay(!display)} } 
                className='bg-[#147DB4] shadow-md shadow-[#147DB44D] rounded-full w-[28px] h-[28px] absolute -right-[30px] top-5 flex items-center justify-center'>
                    {display ?
                        <RiArrowLeftSLine className='text-xl text-white'/>
                        :
                        <RiArrowRightSLine className='text-xl text-white'/>
                    }
                </button>
            </div>

            {role === 'ADMIN' || role === 'SUBSIDIARY' ?
                <div className={`my-[40px] h-[410px] overflow-auto scrollbar-hide flex flex-col ${display ? 'items-start' : 'items-center'}`}>
                    <Link to={'/dashboard'} 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(false)
                            setFinance(false)
                            setRecruitment(false)
                            setJob(false); 
                            setDocument(false); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setTimeManagement(false)
                            setDashboard(!dashboard)
                            setPerformance(false)
                        }}
                        className={`${dashboard ? 'text-[#780000] bg-[#FFEDE5]' : 'text-[#6B7280]'} flex items-center gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                            <img src={dashboard? IconDashboardSelected : IconDashboard} alt='Humanusia Icon'/>
                            {display &&
                                <h1 className={`${dashboard ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Dashboard</h1>
                            }
                    </Link>
                        
                    <button 
                    onClick={() => 
                        {setAdmin(!admin); 
                        setConfiguration(false)
                        setPayroll(false)
                        setFinance(false)
                        setRecruitment(false)
                        setJob(false); 
                        setDocument(false); 
                        setOrganization(false); 
                        setDisplay(true); 
                        setEmployee(false); 
                        setPerformance(false)
                        setTimeManagement(false)
                        setDashboard(false)
                        }} 
                        className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} gap-[16px] text-[12px] ${admin ? 'text-[#780000] bg-[#FFEDE5]' : 'text-black'} hover:text-[#780000] font-[500] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <div className='flex items-center gap-[16px]'>
                            <img src={admin? IconAdminSelected : IconAdmin} alt='Humanusia Icon'/>
                            {display &&
                                <h1 className={`${admin ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Admin</h1>

                            }
                        </div>
                        {display && 
                            <RiArrowRightSFill className='text-xl'/>
                        }
                    </button>
                    <div className={`${admin ? 'min-h-[200px]' : 'min-h-0'} transition-all duration-1000 ease-in-out w-full  pl-[39px] overflow-auto scrollbar-hide`}>
                        <Link to={'/data-import'}>
                            <h1 className={`${location.pathname === '/data-import' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]`}>Import Data</h1>
                        </Link>
                        <Link to={'/user-management'}>
                            <h1 className={`${location.pathname === '/user-management'? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500] mt-[25px]`}>User Management</h1>
                        </Link>
                        <Link to={'/job-management'}>
                            <h1 className={`${location.pathname === '/job-management' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500] mt-[25px]`}>Job Management</h1>
                        </Link>
                        
                        {/* <button onClick={()=> setJob(!job)} className={`text-[12px] ${job ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[25px]`}>
                            Job
                            <RiArrowRightSFill className='text-xl'/>
                        </button>
                        <div className={`${job ? 'h-[120px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                        </div> */}
                        
                        <Link to={'/general-information'}>
                            <h1 className={`${location.pathname === '/general-information' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500] mt-[25px]`}>General Information</h1>
                        </Link>
                        <Link to={'/general-information/cost-profit'}>
                            <h1 className={`${location.pathname === '/general-information/cost-profit' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Cost & Profit</h1>
                        </Link>


                        {/* <button onClick={ () => setOrganization(!organization) } className={`text-[12px] ${organization ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[25px]`}>
                            Organization
                            <RiArrowRightSFill className='text-xl'/>
                        </button>
                        <div className={`${organization ? 'h-[80px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                            <Link to={''}>
                                <h1 className='text-[12px] text-black hover:text-[#780000] font-[500] mt-[25px]'>Location</h1>
                            </Link>
                            <Link to={'/structure'}>
                                <h1 className='text-[12px] text-black hover:text-[#780000] font-[500] mt-[25px]'>Structure</h1>
                            </Link>
                        </div> */}

                        <Link to={'/subsidiary'}>
                        <h1 className={`${location.pathname === '/subsidiary' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Subsidiary</h1>

                        </Link>
                        

                    </div>

                    <button 
                    onClick={() => 
                        {setAdmin(false); 
                        setConfiguration(!configuration)
                        setPayroll(false)
                        setFinance(false)
                        setRecruitment(false)
                        setJob(false); 
                        setDocument(false); 
                        setOrganization(false); 
                        setDisplay(true); 
                        setEmployee(false); 
                        setTimeManagement(false)
                        setPerformance(false)
                        setDashboard(false)
                        }} 
                     className={` ${selectedSidebar === 'configuration' ? 'bg-[#FFEDE5]' : null} flex items-center ${display ? 'justify-between w-full ' : 'w-fit'} gap-[16px] text-[12px] ${configuration ? 'text-[#780000] bg-[#FFEDE5]' : 'text-black'} hover:text-[#780000] font-[500] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <div className='flex items-center gap-[16px]'>
                            <img src={configuration? IconConfigurationSelected : IconConfiguration} alt='Humanusia Icon'/>
                            {display &&
                                <h1 className={`${configuration ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Configuration</h1>

                            }
                        </div>
                        {display && 
                            <RiArrowRightSFill className='text-xl'/>
                        }
                    </button>
                    <div className={`${configuration ? 'min-h-[150px]' : 'min-h-0'} transition-all duration-1000 ease-in-out w-full  pl-[39px] overflow-auto scrollbar-hide`}>        
                        {/* <button onClick={()=> setConfiguration(!configuration)} className={`text-[12px] ${configuration ? 'text-[#780000]' : 'text-black'} hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[25px]`}>
                            Configuration
                            <RiArrowRightSFill className='text-xl'/>
                        </button>
                        <div className={`${configuration ? 'h-[80px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                            <Link to={''}>
                                <h1 className='text-[12px] text-black hover:text-[#780000] font-[500] mt-[25px]'>Reporting Methods</h1>
                            </Link>
                        </div> */}
                        <Link to={'/employee-configuration'}>
                            <h1 className={`${location.pathname === '/employee-configuration' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500] `}>Employee Configuration</h1>
                        </Link>
                        <Link to={'/payroll-setting'}>
                            <h1 className={`${location.pathname === '/payroll-setting' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Payroll Setting</h1>

                        </Link>
                        <Link to={'/time-management-setting'}>
                            <h1 className={`${location.pathname === '/time-management-setting' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Time Management Setting</h1>
                        </Link>
                        <Link to={'/performance-setting'}>
                            <h1 className={`${location.pathname === '/performance-setting' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Performance Setting</h1>
                        </Link>
                        <Link to={'/punishment'}>
                            <h1 className={`${location.pathname === '/punishment' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Punishment</h1>
                        </Link>

                        {/* <Link to={'/employee/employee-transfer'}>
                            <h1 className='text-[12px] text-black hover:text-[#780000] font-[500] mt-[25px]'>Employee Transfer</h1>
                        </Link>
                        <Link to={'/employee/employee-report'}>
                            <h1 className='text-[12px] text-black hover:text-[#780000] font-[500] mt-[25px]'>Report</h1>
                        </Link> */}
                    </div>

                    <Link to={'/employee'} 
                        onClick={() => 
                        {setAdmin(false); 
                        setConfiguration(false)
                        setPayroll(false)
                        setFinance(false)
                        setRecruitment(false)
                        setJob(false); 
                        setDocument(false); 
                        setOrganization(false); 
                        setDisplay(true); 
                        setEmployee(!employee); 
                        setTimeManagement(false)
                        setPerformance(false)
                        setDashboard(false)
                        }} 
                        className={` ${employee? 'bg-[#FFEDE5]' : null} flex items-center gap-[16px]  hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <img src={employee? IconEmployeeSelected: IconEmployee} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className={`${employee ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Employee</h1>

                        }
                    </Link>

                    
                    

                    <Link  to={'/document'} 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(false)
                            setFinance(false)
                            setRecruitment(false)
                            setJob(false); 
                            setDocument(!document); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setTimeManagement(false)
                            setPerformance(false)
                            setDashboard(false)
                            }} 
                        className={` ${document? 'bg-[#FFEDE5]' : null} flex items-center gap-[16px]  hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <img src={document? IconDocumentSelected: IconDocument} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className={`${document ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Document</h1>

                        }
                    </Link>

                    <button 
                    onClick={() => 
                        {setAdmin(false); 
                        setConfiguration(false)
                        setPayroll(false)
                        setFinance(false)
                        setRecruitment(false)
                        setJob(false); 
                        setDocument(false); 
                        setOrganization(false); 
                        setDisplay(true); 
                        setEmployee(false); 
                        setPerformance(false)
                        setTimeManagement(!timeManagement)
                        setDashboard(false)
                        }} 
                    className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} text-[12px] ${timeManagement ? 'text-[#780000] bg-[#FFEDE5]' : 'text-black'} hover:text-[#780000] font-[500] gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <div className='flex items-center gap-[16px]'>
                            <img src={timeManagement? IconTimeManagementSelected: IconTimeManagement} alt='Humanusia Icon'/>
                            {display &&
                                <h1 className={`${timeManagement ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500] truncate text-start`}>Time Management</h1>

                            }
                        </div>
                        {display && 
                            <RiArrowRightSFill className='text-black text-xl'/>
                        }
                    </button>
                    <div className={`${timeManagement ? 'min-h-[150px]' : 'min-h-0'} transition-all duration-1000 ease-in-out w-full pl-[39px] overflow-auto scrollbar-hide`}>        
                        {/* <button onClick={()=> setAttendance(!attendance)} className={`${attendance ? 'text-[#780000]' : 'text-black'} text-[12px] hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[25px]`}>
                            Attendance
                            <RiArrowRightSFill className='text-xl'/>
                        </button>
                        <div className={`${attendance ? 'h-[80px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                        </div> */}
                        <Link to={'/time-management/attendance'}>
                            <h1 className={`${location.pathname === '/time-management/attendance' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]`}>Attendance</h1>

                        </Link>
                        {/* <Link to={'/time-management/my-attendance'}>
                            <h1 className={`${location.pathname === '/time-management/my-attendance' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]`}>My Attendance</h1>

                        </Link> */}

                        <Link to={'/time-management/time-of'}>
                            <h1 className={`${location.pathname === '/time-management/time-of' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Time Of</h1>

                        </Link>
                        {/* <Link to={'/time-management/schedule'}>
                            <h1 className='text-[12px] text-[#6B7280] hover:text-[#780000] font-[500] mt-[25px]'>Schedule</h1>
                        </Link> */}
                        <Link to={'/time-management/calendar'}>
                            <h1 className={`${location.pathname === '/payroll-setting' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Calendar</h1>

                        </Link>

                        <Link to={'/time-management/official-work-travel'}>
                            <h1 className={`${location.pathname === '/time-management/official-work-travel' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Official Work Travel</h1>
                        </Link>
                    </div>

                    <Link 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(!payroll)
                            setFinance(false)
                            setRecruitment(false)
                            setJob(false); 
                            setDocument(false); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setTimeManagement(false)
                            setPerformance(false)
                            setDashboard(false)
                            }} 
                    to={'/payroll'} className={`${payroll ? 'text-[#780000] bg-[#FFEDE5]' : null} flex items-center gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <img src={payroll? IconPayrollSelected: IconPayroll} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className={`${payroll ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Payroll</h1>

                        }
                    </Link>

                    <Link to={'/finance'} 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(false)
                            setFinance(!finance)
                            setRecruitment(false)
                            setJob(false); 
                            setDocument(false); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setTimeManagement(false)
                            setPerformance(false)
                            setDashboard(false)
                            }} 
                        className={` ${finance ? 'text-[#780000] bg-[#FFEDE5]' : null} flex items-center gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <img src={finance? IconFinance : IconFinance} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className={`${finance ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Finance</h1>

                        }
                    </Link>

                    <Link to={'/recruitment'} 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(false)
                            setFinance(false)
                            setRecruitment(!recruitment)
                            setJob(false); 
                            setDocument(false); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setTimeManagement(false)
                            setPerformance(false)
                            setDashboard(false)
                            }}
                    className={`${recruitment ? 'text-[#780000] bg-[#FFEDE5]' : null} flex items-center gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <img src={recruitment? IconRecruitmentSelected: IconRecruitment} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className={`${recruitment ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Recruitment</h1>

                        }
                    </Link>

                    <Link to={'/performance'} 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(false)
                            setFinance(false)
                            setRecruitment(false)
                            setJob(false); 
                            setDocument(false); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setTimeManagement(false)
                            setPerformance(!performance)
                            setDashboard(false)
                            }}
                    className={`${performance ? 'text-[#780000] bg-[#FFEDE5]' : null} flex items-center gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <img src={performance? IconPerformanceSelected: IconPerformance} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className={`${performance ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Performance</h1>

                        }
                    </Link>

                    {/* <Link to={'/myprofile'} className='flex items-center gap-[16px]'>
                        <img src={IconRecruitment} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className='text-[12px] text-black hover:text-[#780000] font-[500]'>My Profile</h1>
                        }
                    </Link> */}
                </div>
                :
                <div className={`my-[40px] h-[410px] overflow-auto scrollbar-hide flex flex-col ${display ? 'items-start' : 'items-center'}`}>
                    <Link to={'/dashboard'} 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(false)
                            setFinance(false)
                            setRecruitment(false)
                            setJob(false); 
                            setDocument(false); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setPerformance(false)
                            setTimeManagement(false)
                            setDashboard(!dashboard)
                        }}
                        className={`${dashboard ? 'text-[#780000] bg-[#FFEDE5]' : 'text-[#6B7280]'} flex items-center gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                            <img src={dashboard? IconDashboardSelected : IconDashboard} alt='Humanusia Icon'/>
                            {display &&
                                <h1 className={`${dashboard ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Dashboard</h1>
                            }
                    </Link>

                    <Link  to={'/document'} 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(false)
                            setFinance(false)
                            setRecruitment(false)
                            setJob(false); 
                            setDocument(!document); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setTimeManagement(false)
                            setPerformance(false)
                            setDashboard(false)
                            }} 
                        className={` ${document? 'bg-[#FFEDE5]' : null} flex items-center gap-[16px]  hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <img src={document? IconDocumentSelected: IconDocument} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className={`${document ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Document</h1>

                        }
                    </Link>

                    <button 
                    onClick={() => 
                        {setAdmin(false); 
                        setConfiguration(false)
                        setPayroll(false)
                        setFinance(false)
                        setRecruitment(false)
                        setJob(false); 
                        setDocument(false); 
                        setOrganization(false); 
                        setDisplay(true); 
                        setEmployee(false); 
                        setTimeManagement(!timeManagement)
                            setPerformance(false)
                            setDashboard(false)
                        }} 
                    className={`flex items-center ${display ? 'justify-between w-full' : 'w-fit'} text-[12px] ${timeManagement ? 'text-[#780000] bg-[#FFEDE5]' : 'text-black'} hover:text-[#780000] font-[500] gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <div className='flex items-center gap-[16px]'>
                            <img src={timeManagement? IconTimeManagementSelected: IconTimeManagement} alt='Humanusia Icon'/>
                            {display &&
                                <h1 className={`${timeManagement ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500] truncate text-start`}>Time Management</h1>

                            }
                        </div>
                        {display && 
                            <RiArrowRightSFill className='text-black text-xl'/>
                        }
                    </button>
                    <div className={`${timeManagement ? 'h-[150px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[39px] overflow-auto scrollbar-hide`}>        
                        {/* <button onClick={()=> setAttendance(!attendance)} className={`${attendance ? 'text-[#780000]' : 'text-black'} text-[12px] hover:text-[#780000] font-[500] flex items-center justify-between w-full mt-[25px]`}>
                            Attendance
                            <RiArrowRightSFill className='text-xl'/>
                        </button>
                        <div className={`${attendance ? 'h-[80px]' : 'h-0'} transition-all duration-1000 ease-in-out w-full pl-[10px] overflow-hidden`}>
                        </div> */}
                        <Link to={'/time-management/my-attendance'}>
                            <h1 className={`${location.pathname === '/time-management/my-attendance' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]`}>My Attendance</h1>

                        </Link>
                        <Link to={'/time-management/employee-record'}>
                            <h1 className={`${location.pathname === '/time-management/employee-record' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Employee Record</h1>

                        </Link>

                        <Link to={'/time-management/time-of'}>
                            <h1 className={`${location.pathname === '/time-management/time-of' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Time Of</h1>

                        </Link>
                        {/* <Link to={'/time-management/schedule'}>
                            <h1 className='text-[12px] text-[#6B7280] hover:text-[#780000] font-[500] mt-[25px]'>Schedule</h1>
                        </Link> */}
                        <Link to={'/time-management/calendar'}>
                            <h1 className={`${location.pathname === '/payroll-setting' ? 'text-[#780000]' : 'text-[#6B7280]' } text-[12px] text-[#6B7280] hover:text-[#780000] font-[500]  mt-[25px]`}>Calendar</h1>

                        </Link>
                    </div>
                    

                    <Link to={'/finance'} 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(false)
                            setFinance(!finance)
                            setRecruitment(false)
                            setJob(false); 
                            setDocument(false); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setTimeManagement(false)
                            setPerformance(false)
                            setDashboard(false)
                            }} 
                        className={` ${finance ? 'text-[#780000] bg-[#FFEDE5]' : null} flex items-center gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <img src={finance? IconFinance : IconFinance} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className={`${finance ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Finance</h1>

                        }
                    </Link>

                    <Link to={'/performance'} 
                        onClick={() => 
                            {setAdmin(false); 
                            setConfiguration(false)
                            setPayroll(false)
                            setFinance(false)
                            setRecruitment(false)
                            setJob(false); 
                            setDocument(false); 
                            setOrganization(false); 
                            setDisplay(true); 
                            setEmployee(false); 
                            setTimeManagement(false)
                            setPerformance(!performance)
                            setDashboard(false)
                            }} 
                        className={` ${performance ? 'text-[#780000] bg-[#FFEDE5]' : null} flex items-center gap-[16px] hover:bg-[#FFEDE5] w-full py-[14px] rounded-md ${display? 'pl-[12px] pr-[19px]' : 'justify-center'} mb-[4px]`}>
                        <img src={performance? IconPerformanceSelected: IconPerformance} alt='Humanusia Icon'/>
                        {display &&
                            <h1 className={`${performance ? 'text-[#780000]' : 'text-[#6B7280]'} text-[14px] hover:text-[#780000] font-[500]`}>Performance</h1>
                        }
                    </Link>
                </div>
            }

            <div className={` border-t-2 w-full pt-[30px] flex items-end ${display ? 'justify-start' : 'justify-center'} `}>
                <button onClick={Logout} className='flex items-center gap-[16px] hover:bg-[#FFEDE5] w-full rounded-md py-[12px] pl-[12px]'>
                    <img src={IconLogout} alt='Humanusia Icon'/>
                    {display &&
                        <h1 className='text-[#780000] font-[500] text-[14px] '>Sign Out</h1>
                    }
                </button>
            </div>
        </div>
    )
}

export default Sidebar