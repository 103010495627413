import React, { useState } from 'react'
import { Modal, Navbar } from '../../../../components'
import { RxCross2 } from 'react-icons/rx'
import { AiOutlinePlus } from 'react-icons/ai'
import { MdImportExport } from 'react-icons/md'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'

const PayrollSetting = () => {

    const [menu, setMenu] = useState('Take Home Pay')
    const [showAddIncome, setShowAddIncome]= useState()
    const [showEditIncome, setShowEditIncome]= useState()
    const [showAddDeduction, setShowAddDeduction]= useState()
    const [showEditDeduction, setShowEditDeduction]= useState()
    const [showAddBenefit, setShowAddBenefit]= useState()
    const [showEditBenefit, setShowEditBenefit]= useState()
    const [showAddTHR, setShowAddTHR]= useState()
    const [showEditTHR, setShowEditTHR]= useState()
    const [showAddNPP, setShowAddNPP]= useState()
    const [showEditNPP, setShowEditNPP]= useState()
    const [isUnderstand, setIsUnderstand] = useState(false)
    const [isUnderstandInTHR, setIsUnderstandInTHR] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    // State Payroll
    const [ptkpStatus, setPtkpStatus] = useState() 
    const [salaryType, setSalaryType] = useState() 
    const [prorate, setProrate] = useState() 
    const [nppBpjsKetenagakerjaan, setNppBpjsKetenagakerjaan] = useState() 
    const [taxConfiguration, setTaxConfiguration] = useState() 
    const [salaryTaxConfiguration, setSalaryTaxConfiguration] = useState() 
    const [bpjsKesehatan, setBpjsKesehatan] = useState() 
    const [bpjsKetenagakerjaan, setBpjsKetenagakerjaan] = useState() 
    const [npwp, setNpwp] = useState() 
    const [bankName, setBankName] = useState() 
    const [bankHolderName, setBankHolderName] = useState() 
    const [bankAccountNumber, setBankAccountNumber] = useState() 
    const [basicSalary, setBasicSalary] = useState() 
    const [persentageBpjsKesehatan, setPersentageBpjsKesehatan] = useState()
    const [persentageBpjsKetenagakerjaan, setPersentageBpjsKetenagakerjaan] = useState()
    const [tunjanganTetap, setTunjanganTetap] = useState() 
    const [tunjanganTidakTetap, setTunjanganTidakTetap] = useState() 

    const [titleIncome, setTitleIncome] = useState()
    const [amountIncome, setAmountIncome] = useState()
    const [titleDeduction, setTitleDeduction] = useState()
    const [amountDeduction, setAmountDeduction] = useState()
    const [titleBenefit, setTitleBenefit] = useState()
    const [amountBenefit, setAmountBenefit] = useState()


   return (
    <div className='bg-[#F8F9FB] min-h-screen'>
        <Modal 
            activeModal={showEditTHR}
            title={!isUnderstandInTHR? 'Formula for calculating Employee THR' : 'Add THR'}
            buttonClose={ () => setShowEditTHR(!showEditTHR)}
            width={'553px'}
            content= {
                <div className='space-y-[20px] w-[full]'>
                    {!isUnderstandInTHR? (
                        <>
                            <h1 className=" text-neutral-500 text-sm font-normal">In general, the formula for calculating THR holiday allowances is </h1>
                            <div className="w-[449px] h-[87px] bg-gray-200 rounded-[3px]" />
                            <h1 className="w-[460px] text-neutral-500 text-sm font-normal">This formula also applies to calculating THR for employees of any status, including how to calculate THR for contract employees.<br/></h1>
                            <div className='flex items-center justify-end'>
                                <button onClick={() => setIsUnderstandInTHR(true)} className='px-[30px] py-2.5 bg-sky-900 rounded-md text-white text-sm font-medium'>
                                    Okay, I Understand
                                </button>
                            </div>
                        </>
                        
                    ) : (
                        <>
                            content dalam thr
                        </>
                    )}

                    
                </div>
            }
        />
        <Modal 
            activeModal={showAddNPP}
            title={!isUnderstand? 'JKK BPJS Ketenagakerjaan' : 'Add NPP BPJS Ketenagakerjaan'}
            buttonClose={ () => setShowAddNPP(!showAddNPP)}
            width={'553px'}
            content= {
                <div className='space-y-[20px] w-[full]'>
                    {!isUnderstand? (
                        <>
                            <div className="w-[473px] text-zinc-700 text-sm font-normal ">BPJS Ketenagakerjaan has several types of programs, one of which is JKK. The JKK (Jaminan Kecelakaan Kerja) program is a program that provides protection against the risk of accidents that occur in work relationships.</div>
                            <div className="text-zinc-700 text-sm font-semibold ">1. Very low risk level of 0.24% of wages per month.<br/>2. Low risk level of 0.54% of wages per month.<br/>3. Medium risk level is 0.89% of wages per month.<br/>4. High risk level is 1.27% of wages per month.<br/>5. Very high risk level is  1.74% of wages per month.</div>

                            <div className='flex justify-end'>
                                <button onClick={() => setIsUnderstand(true)} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-fit'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Okay, I Understand</h1>
                                </button>
                            </div>
                        </>
                        
                    ) : (
                        <>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>NPP Name <span className='text-[#E00101]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Input NPP name...'/>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>NPP Number <span className='text-[#E00101]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Input NPP number...'/>
                        </div>

                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Subsidiary <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-full'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select company subsidiary</option>
                                        <option value="Option 1">Option 1</option>
                                        <option value="Option 2">Option 2</option>
                                        <option value="Option 3">Option 3</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>JJK <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-full'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select JJK</option>
                                        <option value="">0,24% = Very low level of risk</option>
                                        <option value="">0,54% = Low level of risk</option>
                                        <option value="">0,89% = Medium risk level</option>
                                        <option value="">1,27%  = High risk level</option>
                                        <option value="">1,74% = Very high risk level</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddNPP(!showAddNPP)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={() => {setShowAddNPP(!showAddNPP); setIsUnderstand(false)}} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                        </>
                    )}

                    
                </div>
            }
        />
        <Modal 
            activeModal={showEditNPP}
            title={!isUnderstand? 'JKK BPJS Ketenagakerjaan' : 'Edit NPP BPJS Ketenagakerjaan'}
            buttonClose={ () => setShowEditNPP(!showEditNPP)}
            width={'553px'}
            content= {
                <div className='space-y-[20px] w-[full]'>
                    {!isUnderstand? (
                        <>
                            <div className="w-[473px] text-zinc-700 text-sm font-normal ">BPJS Ketenagakerjaan has several types of programs, one of which is JKK. The JKK (Jaminan Kecelakaan Kerja) program is a program that provides protection against the risk of accidents that occur in work relationships.</div>
                            <div className="text-zinc-700 text-sm font-semibold ">1. Very low risk level of 0.24% of wages per month.<br/>2. Low risk level of 0.54% of wages per month.<br/>3. Medium risk level is 0.89% of wages per month.<br/>4. High risk level is 1.27% of wages per month.<br/>5. Very high risk level is  1.74% of wages per month.</div>

                            <div className='flex justify-end'>
                                <button onClick={() => setIsUnderstand(true)} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-fit'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Okay, I Understand</h1>
                                </button>
                            </div>
                        </>
                        
                    ) : (
                        <>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>NPP Name <span className='text-[#E00101]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Input NPP name...'/>
                        </div>

                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>NPP Number <span className='text-[#E00101]'>*</span></h1>
                            <input type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Input NPP number...'/>
                        </div>

                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Subsidiary <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-full'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select company subsidiary</option>
                                        <option value="Option 1">Option 1</option>
                                        <option value="Option 2">Option 2</option>
                                        <option value="Option 3">Option 3</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>JJK <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-full'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select JJK</option>
                                        <option value="">0,24% = Very low level of risk</option>
                                        <option value="">0,54% = Low level of risk</option>
                                        <option value="">0,89% = Medium risk level</option>
                                        <option value="">1,27%  = High risk level</option>
                                        <option value="">1,74% = Very high risk level</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowEditNPP(!showEditNPP)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={() => {setShowEditNPP(!showEditNPP); setIsUnderstand(false)}} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                        </>
                    )}

                    
                </div>
            }
        />
        <Modal 
            activeModal={showAddIncome}
            title={'Add Income'}
            buttonClose={ () => setShowAddIncome(!showAddIncome)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Incomes Name <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setTitleIncome(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Income Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Amount <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setAmountIncome(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='flex gap-[20px] w-full items-center'>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Payment Type <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select payment type</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Daily">Daily</option>
                                        <option value="One Time">One Time</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Tax <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select Tax</option>
                                        <option value="Taxable">Taxable</option>
                                        <option value="Non Taxable">Non Taxable</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[8px]'>Assign To <span className='text-[#E00101]'>*</span></h1>
                        <div className='space-y-[16px]'>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio1" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio1">All Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio2" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio2">Search Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio3" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio3">Based on Job Grade </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio4" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio4">Based on Job Level  </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio5" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio5">Based on Job Position </label>
                            </div>
                        </div>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddIncome(!showAddIncome)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button  className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditIncome}
            title={'Edit Income'}
            buttonClose={ () => setShowEditIncome(!showEditIncome)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Incomes Name <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setTitleIncome(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Income Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Amount <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setAmountIncome(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='flex gap-[20px] w-full items-center'>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Payment Type <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select payment type</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Daily">Daily</option>
                                        <option value="One Time">One Time</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Tax <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select Tax</option>
                                        <option value="Taxable">Taxable</option>
                                        <option value="Non Taxable">Non Taxable</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[8px]'>Assign To <span className='text-[#E00101]'>*</span></h1>
                        <div className='space-y-[16px]'>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio1" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio1">All Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio2" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio2">Search Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio3" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio3">Based on Job Grade </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio4" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio4">Based on Job Level  </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio5" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio5">Based on Job Position </label>
                            </div>
                        </div>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddIncome(!showAddIncome)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button  className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showAddDeduction}
            title={'Add Deduction'}
            buttonClose={ () => setShowAddDeduction(!showAddDeduction)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Deduction Name <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setTitleIncome(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Deduction Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Amount <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setAmountIncome(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='flex gap-[20px] w-full items-center'>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Payment Type <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select payment type</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Daily">Daily</option>
                                        <option value="One Time">One Time</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Tax <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select Tax</option>
                                        <option value="Taxable">Taxable</option>
                                        <option value="Non Taxable">Non Taxable</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[8px]'>Assign To <span className='text-[#E00101]'>*</span></h1>
                        <div className='space-y-[16px]'>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio1" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio1">All Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio2" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio2">Search Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio3" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio3">Based on Job Grade </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio4" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio4">Based on Job Level  </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio5" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio5">Based on Job Position </label>
                            </div>
                        </div>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddIncome(!showAddIncome)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button  className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditDeduction}
            title={'Edit Deduction'}
            buttonClose={ () => setShowEditDeduction(!showEditDeduction)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Deduction Name <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setTitleIncome(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Deduction Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Amount <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setAmountIncome(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='flex gap-[20px] w-full items-center'>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Payment Type <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select payment type</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Daily">Daily</option>
                                        <option value="One Time">One Time</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Tax <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select Tax</option>
                                        <option value="Taxable">Taxable</option>
                                        <option value="Non Taxable">Non Taxable</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[8px]'>Assign To <span className='text-[#E00101]'>*</span></h1>
                        <div className='space-y-[16px]'>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio1" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio1">All Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio2" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio2">Search Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio3" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio3">Based on Job Grade </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio4" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio4">Based on Job Level  </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio5" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio5">Based on Job Position </label>
                            </div>
                        </div>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddIncome(!showAddIncome)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button  className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showAddBenefit}
            title={'Add Benefit'}
            buttonClose={ () => setShowAddBenefit(!showAddBenefit)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Benefit Name <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setTitleIncome(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Benefit Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Amount <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setAmountIncome(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='flex gap-[20px] w-full items-center'>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Payment Type <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select payment type</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Daily">Daily</option>
                                        <option value="One Time">One Time</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Tax <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select Tax</option>
                                        <option value="Taxable">Taxable</option>
                                        <option value="Non Taxable">Non Taxable</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[8px]'>Assign To <span className='text-[#E00101]'>*</span></h1>
                        <div className='space-y-[16px]'>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio1" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio1">All Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio2" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio2">Search Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio3" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio3">Based on Job Grade </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio4" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio4">Based on Job Level  </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio5" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio5">Based on Job Position </label>
                            </div>
                        </div>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddIncome(!showAddIncome)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button  className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditBenefit}
            title={'Edit Benefit'}
            buttonClose={ () => setShowEditBenefit(!showEditBenefit)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Benefit Name <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setTitleIncome(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Benfit Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Amount <span className='text-[#E00101]'>*</span></h1>
                        <input onChange={(e) => setAmountIncome(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='flex gap-[20px] w-full items-center'>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Payment Type <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select payment type</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Daily">Daily</option>
                                        <option value="One Time">One Time</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[#40444C] text-[11px] font-medium mb-[5px]'>Tax <span className='text-[#E00101]'>*</span></h1>
                            <div className="mb-[12px]">
                                <div className='relative w-[233px]'>
                                    <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select Tax</option>
                                        <option value="Taxable">Taxable</option>
                                        <option value="Non Taxable">Non Taxable</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[#40444C] text-[11px] font-medium mb-[8px]'>Assign To <span className='text-[#E00101]'>*</span></h1>
                        <div className='space-y-[16px]'>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio1" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio1">All Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio2" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio2">Search Employee</label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio3" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio3">Based on Job Grade </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio4" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio4">Based on Job Level  </label>
                            </div>
                            <div className='flex items-center gap-[14px] font-medium'>
                                <input type="radio" id="radio5" name="radio-option" value="" />
                                <label className='text-[#40444C] font-medium text-xs' for="radio5">Based on Job Position </label>
                            </div>
                        </div>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddIncome(!showAddIncome)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button  className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <div className='flex px-[30px] py-[35px] gap-[30px]'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Payroll Setting'} LinkBack={'/dashboard'}/>
                <h1 className="text-zinc-800 text-xl font-semibold">Payroll Setting</h1>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='border-b-2 flex items-center text-[16px]'>
                        <button onClick={ () => setMenu('Take Home Pay') } className={`${menu === 'Take Home Pay' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Take Home Pay</button>
                        {/* <button onClick={ () => setMenu('Allowance') } className={`${menu === 'Allowance' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Allowance</button> */}
                        <button onClick={ () => setMenu('THR') } className={`${menu === 'THR' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>THR</button>
                        <button onClick={ () => setMenu('PPH 21') } className={`${menu === 'PPH 21' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>PPH 21</button>
                    </div>
                    {menu === 'Take Home Pay'? (
                        <>
                            <h1 className="text-black text-xl font-semibold">Prorate Setting</h1>
                            <div className='space-y-[16px]'>
                                <div className='flex items-center gap-[26px]'>
                                    <input type="radio" id="radio1" name="radio-option" value="" />
                                    <label className='text-[#40444C] text-[16px]' for="radio1">All Employee</label>
                                </div>
                                <div className='flex items-center gap-[26px]'>
                                    <input type="radio" id="radio2" name="radio-option" value="" />
                                    <label className='text-[#40444C] text-[16px]' for="radio2">Search Employee</label>
                                </div>
                            </div>
                            <hr />
                            <h1 className="text-black text-xl font-semibold">Payroll Componen</h1>

                            <div className='flex gap-[54px] items-center justify-center'>

                                <div className='bg-gray-200 px-[40px] py-[20px] w-[285px] rounded-[10px]'>
                                    <div className='flex items-center justify-center p-[10px] bg-[#219EBC] text-[16px] text-white rounded-lg mb-[20px]'>Income</div>
                                    <div className='space-y-[10px] mb-[100px]'>
                                        <div className='py-[8px] px-[10px] text-[11px] text-zinc-700 rounded-md border border-black w-fit'>Basic Salary</div>
                                        <div className='py-[8px] px-[10px] text-[11px] text-zinc-700 rounded-md border border-black w-fit flex items-center'>
                                            <h1>Tunjangan tetap</h1>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M5.80764 5.80666C5.8657 5.74846 5.93467 5.70228 6.0106 5.67077C6.08653 5.63926 6.16793 5.62305 6.25014 5.62305C6.33235 5.62305 6.41375 5.63926 6.48968 5.67077C6.56561 5.70228 6.63458 5.74846 6.69264 5.80666L10.0001 9.11541L13.3076 5.80666C13.3658 5.74855 13.4347 5.70246 13.5107 5.67101C13.5866 5.63956 13.668 5.62337 13.7501 5.62337C13.8323 5.62337 13.9137 5.63956 13.9896 5.67101C14.0655 5.70246 14.1345 5.74855 14.1926 5.80666C14.2508 5.86477 14.2968 5.93376 14.3283 6.00968C14.3597 6.08561 14.3759 6.16698 14.3759 6.24916C14.3759 6.33134 14.3597 6.41272 14.3283 6.48864C14.2968 6.56457 14.2508 6.63355 14.1926 6.69166L10.8839 9.99916L14.1926 13.3067C14.2508 13.3648 14.2968 13.4338 14.3283 13.5097C14.3597 13.5856 14.3759 13.667 14.3759 13.7492C14.3759 13.8313 14.3597 13.9127 14.3283 13.9886C14.2968 14.0646 14.2508 14.1336 14.1926 14.1917C14.1345 14.2498 14.0655 14.2959 13.9896 14.3273C13.9137 14.3588 13.8323 14.375 13.7501 14.375C13.668 14.375 13.5866 14.3588 13.5107 14.3273C13.4347 14.2959 13.3658 14.2498 13.3076 14.1917L10.0001 10.8829L6.69264 14.1917C6.63453 14.2498 6.56554 14.2959 6.48962 14.3273C6.4137 14.3588 6.33232 14.375 6.25014 14.375C6.16796 14.375 6.08659 14.3588 6.01066 14.3273C5.93474 14.2959 5.86575 14.2498 5.80764 14.1917C5.74953 14.1336 5.70343 14.0646 5.67199 13.9886C5.64054 13.9127 5.62435 13.8313 5.62435 13.7492C5.62435 13.667 5.64054 13.5856 5.67199 13.5097C5.70343 13.4338 5.74953 13.3648 5.80764 13.3067L9.11639 9.99916L5.80764 6.69166C5.74944 6.63361 5.70326 6.56464 5.67175 6.48871C5.64024 6.41277 5.62402 6.33137 5.62402 6.24916C5.62402 6.16695 5.64024 6.08555 5.67175 6.00962C5.70326 5.93369 5.74944 5.86472 5.80764 5.80666Z" fill="#C1121F"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='flex items-center justify-center'>
                                        <button onClick={() => setShowAddIncome(!showAddIncome)} className='bg-white py-2 px-4 rounded-[10px] flex items-center  gap-[16px]'>
                                            <AiOutlinePlus  className='text-xl text-[#333333]'/>
                                            <h1 className='text-[#333333] text-sm font-[500]'>Add Income</h1>
                                        </button>
                                    </div>
                                </div>

                                <div className='bg-gray-200 px-[40px] py-[20px] w-[285px] rounded-[10px]'>
                                    <div className='flex items-center justify-center p-[10px] bg-[#219EBC] text-[16px] text-white rounded-lg mb-[20px]'>Deduction</div>
                                    <div className='space-y-[10px] mb-[100px]'>
                                        <div className='py-[8px] px-[10px] text-[11px] text-zinc-700 rounded-md border border-black w-fit'>Basic Salary</div>
                                        <div className='py-[8px] px-[10px] text-[11px] text-zinc-700 rounded-md border border-black w-fit flex items-center'>
                                            <h1>Tunjangan tetap</h1>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M5.80764 5.80666C5.8657 5.74846 5.93467 5.70228 6.0106 5.67077C6.08653 5.63926 6.16793 5.62305 6.25014 5.62305C6.33235 5.62305 6.41375 5.63926 6.48968 5.67077C6.56561 5.70228 6.63458 5.74846 6.69264 5.80666L10.0001 9.11541L13.3076 5.80666C13.3658 5.74855 13.4347 5.70246 13.5107 5.67101C13.5866 5.63956 13.668 5.62337 13.7501 5.62337C13.8323 5.62337 13.9137 5.63956 13.9896 5.67101C14.0655 5.70246 14.1345 5.74855 14.1926 5.80666C14.2508 5.86477 14.2968 5.93376 14.3283 6.00968C14.3597 6.08561 14.3759 6.16698 14.3759 6.24916C14.3759 6.33134 14.3597 6.41272 14.3283 6.48864C14.2968 6.56457 14.2508 6.63355 14.1926 6.69166L10.8839 9.99916L14.1926 13.3067C14.2508 13.3648 14.2968 13.4338 14.3283 13.5097C14.3597 13.5856 14.3759 13.667 14.3759 13.7492C14.3759 13.8313 14.3597 13.9127 14.3283 13.9886C14.2968 14.0646 14.2508 14.1336 14.1926 14.1917C14.1345 14.2498 14.0655 14.2959 13.9896 14.3273C13.9137 14.3588 13.8323 14.375 13.7501 14.375C13.668 14.375 13.5866 14.3588 13.5107 14.3273C13.4347 14.2959 13.3658 14.2498 13.3076 14.1917L10.0001 10.8829L6.69264 14.1917C6.63453 14.2498 6.56554 14.2959 6.48962 14.3273C6.4137 14.3588 6.33232 14.375 6.25014 14.375C6.16796 14.375 6.08659 14.3588 6.01066 14.3273C5.93474 14.2959 5.86575 14.2498 5.80764 14.1917C5.74953 14.1336 5.70343 14.0646 5.67199 13.9886C5.64054 13.9127 5.62435 13.8313 5.62435 13.7492C5.62435 13.667 5.64054 13.5856 5.67199 13.5097C5.70343 13.4338 5.74953 13.3648 5.80764 13.3067L9.11639 9.99916L5.80764 6.69166C5.74944 6.63361 5.70326 6.56464 5.67175 6.48871C5.64024 6.41277 5.62402 6.33137 5.62402 6.24916C5.62402 6.16695 5.64024 6.08555 5.67175 6.00962C5.70326 5.93369 5.74944 5.86472 5.80764 5.80666Z" fill="#C1121F"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='flex items-center justify-center'>
                                        <button onClick={() => setShowAddDeduction(!showAddDeduction)} className='bg-white py-2 px-4 rounded-[10px] flex items-center  gap-[16px]'>
                                            <AiOutlinePlus  className='text-xl text-[#333333]'/>
                                            <h1 className='text-[#333333] text-sm font-[500]'>Add Deduction</h1>
                                        </button>
                                    </div>
                                </div>

                                <div className='bg-gray-200 px-[40px] py-[20px] w-[285px] rounded-[10px]'>
                                    <div className='flex items-center justify-center p-[10px] bg-[#219EBC] text-[16px] text-white rounded-lg mb-[20px]'>Benefits</div>
                                    <div className='space-y-[10px] mb-[100px]'>
                                        <div className='py-[8px] px-[10px] text-[11px] text-zinc-700 rounded-md border border-black w-fit'>Basic Salary</div>
                                        <div className='py-[8px] px-[10px] text-[11px] text-zinc-700 rounded-md border border-black w-fit flex items-center'>
                                            <h1>Tunjangan tetap</h1>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M5.80764 5.80666C5.8657 5.74846 5.93467 5.70228 6.0106 5.67077C6.08653 5.63926 6.16793 5.62305 6.25014 5.62305C6.33235 5.62305 6.41375 5.63926 6.48968 5.67077C6.56561 5.70228 6.63458 5.74846 6.69264 5.80666L10.0001 9.11541L13.3076 5.80666C13.3658 5.74855 13.4347 5.70246 13.5107 5.67101C13.5866 5.63956 13.668 5.62337 13.7501 5.62337C13.8323 5.62337 13.9137 5.63956 13.9896 5.67101C14.0655 5.70246 14.1345 5.74855 14.1926 5.80666C14.2508 5.86477 14.2968 5.93376 14.3283 6.00968C14.3597 6.08561 14.3759 6.16698 14.3759 6.24916C14.3759 6.33134 14.3597 6.41272 14.3283 6.48864C14.2968 6.56457 14.2508 6.63355 14.1926 6.69166L10.8839 9.99916L14.1926 13.3067C14.2508 13.3648 14.2968 13.4338 14.3283 13.5097C14.3597 13.5856 14.3759 13.667 14.3759 13.7492C14.3759 13.8313 14.3597 13.9127 14.3283 13.9886C14.2968 14.0646 14.2508 14.1336 14.1926 14.1917C14.1345 14.2498 14.0655 14.2959 13.9896 14.3273C13.9137 14.3588 13.8323 14.375 13.7501 14.375C13.668 14.375 13.5866 14.3588 13.5107 14.3273C13.4347 14.2959 13.3658 14.2498 13.3076 14.1917L10.0001 10.8829L6.69264 14.1917C6.63453 14.2498 6.56554 14.2959 6.48962 14.3273C6.4137 14.3588 6.33232 14.375 6.25014 14.375C6.16796 14.375 6.08659 14.3588 6.01066 14.3273C5.93474 14.2959 5.86575 14.2498 5.80764 14.1917C5.74953 14.1336 5.70343 14.0646 5.67199 13.9886C5.64054 13.9127 5.62435 13.8313 5.62435 13.7492C5.62435 13.667 5.64054 13.5856 5.67199 13.5097C5.70343 13.4338 5.74953 13.3648 5.80764 13.3067L9.11639 9.99916L5.80764 6.69166C5.74944 6.63361 5.70326 6.56464 5.67175 6.48871C5.64024 6.41277 5.62402 6.33137 5.62402 6.24916C5.62402 6.16695 5.64024 6.08555 5.67175 6.00962C5.70326 5.93369 5.74944 5.86472 5.80764 5.80666Z" fill="#C1121F"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='flex items-center justify-center'>
                                        <button onClick={() => setShowAddBenefit(!showAddBenefit)} className='bg-white py-2 px-4 rounded-[10px] flex items-center  gap-[16px]'>
                                            <AiOutlinePlus  className='text-xl text-[#333333]'/>
                                            <h1 className='text-[#333333] text-sm font-[500]'>Add Benefits</h1>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <h1 className="text-black text-xl font-semibold">BPJS Ketenagakerjaan Setting</h1>
                            <div className='border rounded-xl pt-[39px] px-[26px]'>
                                <div className='flex items-center justify-between mb-[30px]'>
                                    <div className='flex gap-[10px] items-center'>
                                        <div className="text-zinc-800 text-base font-semibold">NPP BPJS Ketenagakerjaan</div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M7.535 5.625L7.085 6.085C6.725 6.445 6.5 6.75 6.5 7.5H5.5V7.25C5.5 6.695 5.725 6.195 6.085 5.835L6.705 5.205C6.89 5.025 7 4.775 7 4.5C7 4.23478 6.89464 3.98043 6.70711 3.79289C6.51957 3.60536 6.26522 3.5 6 3.5C5.73478 3.5 5.48043 3.60536 5.29289 3.79289C5.10536 3.98043 5 4.23478 5 4.5H4C4 3.96957 4.21071 3.46086 4.58579 3.08579C4.96086 2.71071 5.46957 2.5 6 2.5C6.53043 2.5 7.03914 2.71071 7.41421 3.08579C7.78929 3.46086 8 3.96957 8 4.5C7.99928 4.92162 7.83217 5.32591 7.535 5.625ZM6.5 9.5H5.5V8.5H6.5M6 1C5.34339 1 4.69321 1.12933 4.08658 1.3806C3.47995 1.63188 2.92876 2.00017 2.46447 2.46447C1.52678 3.40215 1 4.67392 1 6C1 7.32608 1.52678 8.59785 2.46447 9.53553C2.92876 9.99983 3.47995 10.3681 4.08658 10.6194C4.69321 10.8707 5.34339 11 6 11C7.32608 11 8.59785 10.4732 9.53553 9.53553C10.4732 8.59785 11 7.32608 11 6C11 3.235 8.75 1 6 1Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <button onClick={() => setShowAddNPP(!showAddNPP)} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus  className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add NPP</h1>
                                    </button>
                                </div>
                                <div>
                                    <div className='overflow-auto scrollbar-hide'>
                                        <table className='w-full space-y-[10px]'>
                                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>NPP Name</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>NPP Number</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Branch</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>JKK</h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                                
                                                <div className='flex items-center justify-center gap-[6px] w-1/2'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Action </h1>
                                                    <MdImportExport className='text-[#737373] text-xs'/>
                                                </div>
                                            </div>
                                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                        <div className='min-w-[200px] max-w-[200px]'>
                                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, rem!</h1>
                                                        </div>
                                                        <div className='min-w-[200px] max-w-[200px]'>
                                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, rem!</h1>
                                                        </div>
                                                        <div className='min-w-[200px] max-w-[200px]'>
                                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, rem!</h1>
                                                        </div>
                                                        <div className='min-w-[200px] max-w-[200px]'>
                                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, rem!</h1>
                                                        </div>
                                                        <div className='w-1/2 flex items-center justify-center gap-[12px]'>
                                                            <button onClick={() => setShowEditNPP(!showEditNPP)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                <HiOutlinePencil className='text-[#003049]'/>
                                                            </button>
                                                            <button  className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                <CgTrashEmpty className='text-[#003049]'/>
                                                            </button>
                                                        </div>
                                                    </div>
                                            
                                        </table>
                                    </div>
                                    {/* <Pagination
                                        currentPage={currentPage} 
                                        totalPages={totalPages} 
                                        onPageChange={handlePageChange}
                                        onPrevChange={handlePrevChange}
                                        onNextChange={handleNextChange}
                                    /> */}
                                </div>
                            </div>

                            <hr />
                            <h1 className="text-black text-xl font-semibold">Cut Off</h1>
                            <div>
                                <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">SalaryTax Setting <span className='text-[#780000] font-medium'>*</span></h1>
                                <div className="mb-[12px]">
                                    <div className='relative w-[337px]'>
                                        <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[19px]  appearance-none'>
                                            <option selected value="">Select Employee Salary Tax Setting</option>
                                            <option value="Taxable">Taxable</option>
                                            <option value="Non Taxable">Non Taxable</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='flex items-center justify-end pb-[60px]'>
                                <button className=' px-[30px] py-[10px] bg-[#015995] rounded-lg text-white'> Save</button>
                            </div>
                        </>
                    ) : menu === 'THR' ? (
                        <>
                            <div>
                                <h1 className="text-zinc-800 text-xl font-semibold">THR</h1>
                                <h1 className="text-gray-500 text-xs font-normal">THR Setting</h1>
                            </div>
                            <div className='flex gap-[53px] items-start'>
                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Prorate <span className='text-[#E00101]'>*</span></h1>
                                    <div className="mb-[12px]">
                                        <div className='relative w-[337px]'>
                                            <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected value="">Select prorate</option>
                                                <option value="Daily">Daily</option>
                                                <option value="Monthly">Monthly</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>New Employee can get THR after <span className='text-[#E00101]'>*</span></h1>
                                    <div className='flex items-center gap-[16px]'>
                                        <input type='number' min={0} max={12} className='rounded-md border py-[8px] px-[13px] w-[72px] text-sm outline-none' placeholder='0' />
                                        <h1 className="text-zinc-700 text-sm font-medium">Month</h1>
                                    </div>
                                </div>
                            </div>

                            <div className='space-y-[12px] pb-[31px]'>
                                <h1 className='text-neutral-500 text-[11px] font-medium '>THR Component <span className='text-[#E00101]'>*</span></h1>
                                <div className='flex items-center gap-[13px] font-medium'>
                                    <input onChange={() => {setIsChecked(false)}} type="radio" id="radio1" name="radio-option" value="" />
                                    <label className='text-neutral-500 text-[11px] font-medium' for="radio1">Default</label>
                                </div>
                                <div className='flex items-center gap-[13px] font-medium'>
                                    <input onChange={() => setIsChecked(!isChecked)} type="radio" id="radio2" name="radio-option" value="" />
                                    <label className='text-neutral-500 text-[11px] font-medium' for="radio2">Custom THR Component</label>
                                </div>
                                {isChecked&& (
                                    <div className='flex items-center gap-[11px]'>
                                        <div className="">
                                            <div className='relative w-[337px]'>
                                                <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-zinc-600 w-full py-[10px] px-[8px] appearance-none'>
                                                    <option selected value="">Select Component</option>
                                                    <option value="option 1">option 1</option>
                                                    <option value="option 2">option 2</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                            </div>
                                        </div>
                                        <button onClick={() => setShowEditTHR(!showEditTHR)} className='w-[35px] h-[35px] rounded-md flex items-center justify-center bg-[#015995] text-white'>
                                            <AiOutlinePlus className='text-xl'/>
                                        </button>

                                    </div>
                                )}
                            </div>

                            <button className="w-[86px] h-[38px] px-[30px] py-2.5 bg-[#015995] rounded-md justify-center items-center gap-2.5 inline-flex">
                                <div className="text-center text-white text-sm font-medium">Save</div>
                            </button>
                        </>
                    ) : menu === 'PPH 21' ? (
                        <>
                            <h1 className="text-zinc-800 text-[15px] font-semibold">PTKP Setting </h1>

                            <div className='space-y-[16px] w-4/12 pb-[5px]'>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">Individual Taxpayer <span className='text-[#E00101]'>*</span></h1>
                                    <input type="number" className='px-[8px] outline-none py-[10px] border rounded-md text-xs w-full' placeholder='Rp' />
                                </div>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">Additional Individual Taxpayer <span className='text-[#E00101]'>*</span></h1>
                                    <input type="number" className='px-[8px] outline-none py-[10px] border rounded-md text-xs w-full' placeholder='Rp' />
                                    <div className="text-stone-300 text-[9px] font-medium italic tracking-tight">(Additional for the wife whose income is combined with the husband's)</div>
                                </div>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">Additional Married Taxpayers <span className='text-[#E00101]'>*</span></h1>
                                    <input type="number" className='px-[8px] outline-none py-[10px] border rounded-md text-xs w-full' placeholder='Rp' />
                                </div>
                                <div>
                                    <h1 className="text-neutral-500 text-[11px] font-medium mb-[5px]">Additional for dependents <span className='text-[#E00101]'>*</span></h1>
                                    <input type="number" className='px-[8px] outline-none py-[10px] border rounded-md text-xs w-full' placeholder='Rp' />
                                </div>
                            </div>

                            <hr  className='border-[1px] border-[#CACACA]'/>

                            <h1 className="text-zinc-800 text-[15px] font-semibold">PKP Setting </h1>

                            <div className='space-y-[18px] pb-[5px]'>
                                <div className='flex gap-[11px] text-neutral-500 items-center'>
                                    <h1 className=" text-xs">If Income</h1>
                                    <input type="number" placeholder='Rp' className='text-sm border rounded-md py-[6px] px-[10px] w-[177px] outline-none' />
                                    <h1 className=" text-sm">-</h1>
                                    <input type="number" placeholder='Rp' className='text-sm border rounded-md py-[6px] px-[10px] w-[177px] outline-none' />
                                    <h1 className=" text-sm">=</h1>
                                    <h1 className="text-neutral-500 text-sm font-normal">Rates</h1>
                                    <div className='flex items-center border rounded-md py-[6px] w-[66px] px-[10px]'>
                                        <input type="text" className='text-sm outline-none appearance-none w-1/2' />
                                        <h1 className="text-neutral-500 text-sm font-medium">%</h1>
                                    </div>
                                    <button className='w-[32px] h-[32px] flex items-center justify-center bg-[#FFE1E1] text-[#780000] rounded-md'>
                                         <CgTrashEmpty className='text-md' />
                                    </button>
                                </div>
                                <div className='flex gap-[11px] text-neutral-500 items-center'>
                                    <h1 className=" text-xs">If Income</h1>
                                    <input type="number" placeholder='Rp' className='text-sm border rounded-md py-[6px] px-[10px] w-[177px] outline-none' />
                                    <h1 className=" text-sm">-</h1>
                                    <input type="number" placeholder='Rp' className='text-sm border rounded-md py-[6px] px-[10px] w-[177px] outline-none' />
                                    <h1 className=" text-sm">=</h1>
                                    <h1 className="text-neutral-500 text-sm font-normal">Rates</h1>
                                    <div className='flex items-center border rounded-md py-[6px] w-[66px] px-[10px]'>
                                        <input type="text" className='text-sm outline-none appearance-none w-1/2' />
                                        <h1 className="text-neutral-500 text-sm font-medium">%</h1>
                                    </div>
                                    <button className='w-[32px] h-[32px] flex items-center justify-center bg-[#FFE1E1] text-[#780000] rounded-md'>
                                         <CgTrashEmpty className='text-md' />
                                    </button>
                                </div>
                            </div>

                            <button onClick={() => setShowAddNPP(!showAddNPP)} className='bg-[#E1F4FF] py-[5px] px-[12px] rounded-md flex items-center gap-[5px]'>
                                <AiOutlinePlus  className='text-lg text-[#219EBC]'/>
                                <h1 className='text-[#219EBC] text-xs font-[500]'>Add</h1>
                            </button>

                            <hr  className='border-[1px] border-[#CACACA] '/>
                            
                            <div className='flex items-center justify-start pb-[41px] pt-[30px]'>
                                <button className=' px-[30px] py-[10px] bg-[#015995] rounded-lg text-white'> Save</button>
                            </div>
                        </>
                    ) : null}


                </div>
            </div>
        </div>
    </div>
  )
}

export default PayrollSetting