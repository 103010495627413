import React, { useEffect, useState } from 'react'
import { Modal, Navbar, Sidebar } from '../../../../components'
import { BiFilter, BiTime, BiTransfer } from 'react-icons/bi'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight,  } from 'react-icons/md'
import { TbFileExport } from 'react-icons/tb'
import { FiSearch } from 'react-icons/fi'
import moment from 'moment'
import { BsCheck } from 'react-icons/bs'
import { IoMdArrowDropdown } from 'react-icons/io'
import { IoClose } from 'react-icons/io5'
import Api from '../../../../Api'
import { toast } from 'react-hot-toast'

const EmployeeTransfer = () => {

    const approvalStatus = 'Approved'
    const [showDetail, setShowDetail] = useState(false)
    const [showModal, setShowModal] = useState(false)

    // Employe Transfer State
    const [dataEmployeeTransfer, setDataEmployeeTransfer] = useState('')
    const [employee, setEmployee] = useState('')
    const [employeeStatus, setEmployeeStatus] = useState('')
    const [effectiveDate, setEffectiveDate] = useState('')
    const [transferType, setTransferType] = useState('')
    const [componentTransfer, setComponentTransfer] = useState('')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [reason, setReason] = useState('')

    const GetData = async() => {
        try {
            const response = await Api.GetEmployeeTransfer(localStorage.getItem('hris-token'))
            console.log(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const PostData = async() => {
        try {
            const data = {
                user: employee,
                employee_status: employeeStatus,
                effective_date: effectiveDate,
                transfer_type: transferType,
                component_transfer: componentTransfer,
                from: from,
                to: to, 
                reason: reason
            }
            const response = await Api.CreateEmployeeTransfer(localStorage.getItem('hris-token'), data)
            console.log(response)
            toast.success('Success Create')
        } catch (error) {
            console.log(error)
            toast.error('Failed Create')
        }
    }

    useEffect(() => {
        GetData()
    }, [])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModal}
                buttonClose={() => setShowModal(!showModal)}
                width={'550px'}
                title={'Employee Transfer'}
                content={
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Select Employee <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select value={''} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                    <option selected value={''}>Select Employee</option>
                                    <option value={'Option 1'}>Option 1</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='flex items-center gap-3'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee Status <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select value={''} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                        <option selected value={''}>Select Employee Status</option>
                                        <option value={'Full Time'}>Full Time</option>
                                        <option value={'Part Time'}>Part Time</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Effective Date <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input type={'date'} className= 'border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Effective Date'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Transfer Type <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input type={'text'} className= 'border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Transfer Type'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Select Component Transfer <span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select value={''} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                    <option selected value={''}>Select Component Transfer</option>
                                    <option value={'Option 1'}>Option 1</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='flex items-center gap-3'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>From <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select value={''} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                        <option selected value={''}>Select...</option>
                                        <option value={'Option 1'}>Option 1</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>To <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select value={''} className={`border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none`}>
                                        <option selected value={''}>Select...</option>
                                        <option value={'Option 1'}>Option 1</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Reason<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <textarea rows={4} type={'text'} className= 'border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Reason'/>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModal(!showModal)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Save</button>
                        </div>
                    </div>
                }
            />
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <Sidebar/>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Employee Transfer'} LinkBack={'/dashboard'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                            <div className='w-full lg:w-auto'>
                                <h1 className='text-[#454545] text-[16px] font-[500]'>
                                    Employee Transfer History
                                </h1>
                                <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                    List of Employee Transfer 
                                </h1>
                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                            <div className='flex items-center gap-[13px] mt-3 lg:mt-0 w-full lg:w-auto'>
                                <button className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <BiFilter className='text-[#0E5073]'/>
                                    <h1 className='text-[#0E5073] text-sm font-[500]'>Filter</h1>
                                </button>
                                <button className='bg-white border lg:w-fit w-full rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <TbFileExport className='text-[#0E5073]'/>
                                    <h1 className='text-[#0E5073] text-sm font-[500]'>Export</h1>
                                </button>
                            </div>
                            <div className='flex flex-col lg:flex-row lg:items-center gap-[13px] mt-3 lg:mt-0 w-full lg:w-auto'>
                                <div className='relative'>
                                    <input placeholder='Search...' className='bg-white border rounded-[6px] text-[#A8A8A8] text-sm pl-10 w-full lg:w-[300px] flex items-center gap-[12px] px-[10px] py-[8px]'/>
                                    <FiSearch className='text-[#A8A8A8] absolute top-[11px] left-3'/>
                                </div>
                                <button onClick={ () => setShowModal(!showModal)} className='bg-[#0E5073] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <BiTransfer className='text-white'/>
                                    <h1 className='text-white text-sm font-[500]'>Employee Transfer</h1>
                                </button>
                            </div>
                        </div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[500]'>Employee Name</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[500]'>Employee Status</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[500]'>Effective Date</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-xs font-[500]'>Transfer Type</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-xs font-[500]'>Submitted by</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-xs font-[500]'>Approval</h1>
                                    </div>
                                </div>
                                <div>
                                    <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] ${showDetail ? 'rounded-t-[3px]' : 'rounded-[3px]'}`}>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>Lee Min Ho</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>Contract</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>{moment().format('DD/MM/YYYY')}</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>Promotion</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#737373] text-xs font-[500] truncate'>Cristofer Carder</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            {
                                                approvalStatus === 'Approved' ?
                                                <div className='flex flex-col items-center justify-center space-y-1'>
                                                    <div className='bg-[#00AB9A] w-[25px] h-[25px] rounded-full flex items-center justify-center'>
                                                        <BsCheck className='text-white'/>
                                                    </div>
                                                    <button className='text-[#737373] text-[7px] font-[500] underline underline-offset-1'>Detail</button>
                                                </div>
                                                : approvalStatus === 'Waiting' ?
                                                <div className='flex flex-col items-center justify-center space-y-1'>
                                                    <div className='bg-[#FFD900] w-[25px] h-[25px] rounded-full flex items-center justify-center'>
                                                        <BiTime className='text-white'/>
                                                    </div>
                                                    <button className='text-[#737373] text-[7px] font-[500] underline underline-offset-1'>Detail</button>
                                                </div>
                                                :
                                                <div className='flex flex-col items-center justify-center space-y-1'>
                                                    <div className='bg-[#C1121F] w-[25px] h-[25px] rounded-full flex items-center justify-center'>
                                                        <IoClose className='text-white'/>
                                                    </div>
                                                    <button className='text-[#737373] text-[7px] font-[500] underline underline-offset-1'>Detail</button>
                                                </div>
                                            }
                                        </div>
                                        <div className='flex items-center justify-center w-full gap-[12px]'>
                                            <button onClick={ () => setShowDetail(!showDetail)} className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                <MdKeyboardArrowDown className='text-[#003049]'/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${ showDetail ? 'h-52 border' : 'h-0 border-0' }`}>
                                        <div className='grid grid-cols-12 gap-5 px-4 py-4'>
                                            <div className='flex flex-col gap-3 col-span-2'>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Transfer Type</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Component Type</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>From</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>To</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Reasons</h1>
                                            </div>
                                            <div className='flex flex-col gap-3 col-span-1'>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                            </div>
                                            <div className='flex flex-col gap-3 col-span-9'>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>Promotion</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>Job Level</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>Staff IT</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>Manager IT</h1>
                                                <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>Lorem ipsum dolor sit amet consectetur. Feugiat amet tortor pellentesque quisque magnis. Nisl malesuada eget non et orci nibh aenean eu.</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </table>
                        </div>
                        <div className='flex items-center justify-between mt-[30px]'>
                            <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                            <div className='flex items-center gap-[8px]'>
                                <MdKeyboardArrowLeft className='text-[#A098AE]'/>
                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                    <h1 className='text-[#780000] text-[10px]'>1</h1>
                                </div>
                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                    <h1 className='text-white text-[10px]'>2</h1>
                                </div>
                                <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                    <h1 className='text-[#780000] text-[10px]'>3</h1>
                                </div>
                                <MdKeyboardArrowRight className='text-[#A098AE]'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeTransfer