import React, { useEffect, useState } from 'react'
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { Modal, ModalDelete, Navbar, Sidebar } from '../../../../components'
import Api from '../../../../Api'

const UserManagement = () => {

    const [showModalAdd, setShowModalAdd] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [dataUser, setDataUser] = useState('')
    const [getIdUser, setGetIdUser] = useState('')
    const [dataSubsidiary, setDataSubsidiary] = useState('')


    const getSubsidiary = async () => {
            try {
                const response = await Api.GetSubsidiary(localStorage.getItem('hris-token'))
                console.log(response)
                setDataSubsidiary(response.data.data)
            } catch (error) {
                console.log(error)
            }
    }

    const getDataUser = async() => {
        try {
            const response = await Api.GetUserManagement(localStorage.getItem('hris-token'))
            console.log(response, 'userMgmnt')
            setDataUser(response.data.results.data)
        } catch (error) {
            
        }
    }
    
    const [role, setRole] = useState('')
    const [employeeName, setEmployeeName] = useState('')
    const [isVerified, setIsVerified] = useState('')
    const [password, setPassword] = useState('')
    const [location, setLocation] = useState('')
    const [email, setEmail] = useState('')
    const [subsidiary, setSubsidiary] = useState('')

    const getEmployeeDropdown = async() => {
        try {
            const response = await Api.GetEmployee(localStorage.getItem('hris-token'), '', '', '','','','','','','',)
            console.log(response, 'employee')
        } catch (error) {
            console.log(error)
        }
    }

    const postDataUser = async() => {
        try {
            
            const data = {
                role: role,
                isVerified: isVerified === 'true' ? true : false,
                fullname: employeeName,
                email: email,
                password: password,
                subsidiary: subsidiary
            }
            console.log(data, 'sendedData')
            const response = await Api.CreateUserManagement(localStorage.getItem('hris-token'), data)
            setRefresh(true)
            setShowModalAdd(!showModalAdd)
        } catch (error) {
            console.log(error)
        }
    }

    const openModalUpdateDataUser = async(id) => {
        setShowModalEdit(!showModalEdit)
        setGetIdUser(id)
        console.log(id, 'idUser')
        try {
            const response = await Api.GetUserManagementById(localStorage.getItem('hris-token'), id)
            console.log(response, 'byId')
            setRole(response.data.results.data.role)
            setEmployeeName(response.data.results.data.fullname)
            setIsVerified(response.data.results.data.isVerified)
            setEmail(response.data.results.data.email)
            setSubsidiary(response.data.results.data.user_detail.subsidiary.id)
        } catch (error) {
            console.log(error)
        }
    }

    const updateDataUser = async() => {
        try {
            const data = {
                ...(role && { role: role }),
                ...(isVerified && { isVerified: isVerified }),
                ...(employeeName && { fullname: employeeName }),
                ...(email && { email: email }),
                ...(password && { password: password }),
                ...(subsidiary && { subsidiary: subsidiary })
            }
            // const data = {
            //     role: role,
            //     isVerified: isVerified === 'true' ? true : false,
            //     fullname: employeeName,
            //     email: email,
            //     password: password,
            //     subsidiary: subsidiary
            // }
            console.log(data, 'sendedEditData')
            const response = await Api.UpdateUserManagement(localStorage.getItem('hris-token'), getIdUser, data)
            setRefresh(true)
            setShowModalEdit(!showModalEdit)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDataUserModal = async(id) => {
        setShowModalDelete(!showModalDelete)
        setGetIdUser(id)
    }

    const deleteDataUser = async(id) => {
        try {
            const response = await Api.DeleteUserManagement(localStorage.getItem('hris-token'), getIdUser)    
            setRefresh(true)
            setShowModalDelete(!showModalDelete)     
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getEmployeeDropdown()
        getSubsidiary()
        getDataUser()
        setRefresh(false)
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModalAdd}
                title={'Add User'}
                buttonClose={ () => setShowModalAdd(!showModalAdd)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>User Role<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={ (e) => setRole(e.target.value) } value={role} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select User Role</option>
                                        <option value={'USER'}>User</option>
                                        <option value={'LEADER'}>Leader</option>
                                        <option value={'ADMIN'}>Admin</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={ (e) => setEmployeeName(e.target.value) } value={employeeName} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Input Employee Name...' />
                            </div>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Status<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={ (e) => setIsVerified(e.target.value) } value={isVerified} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select Status</option>
                                        <option value={true}>Enable</option>
                                        <option value={false}>Disable</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Email<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={ (e) => setEmail(e.target.value) } value={email} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Your Email...' />
                            </div>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Password<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={ (e) => setPassword(e.target.value)} type='password' className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Your Password...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Confirm Password<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input type='password' value={password.slice(0,10)} onChange={ (e) => setPassword(e.target.value) } className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Your password...'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Assigned to Subsidiary<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setSubsidiary(e.target.value) } className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Subsidiary...</option>
                                    {Object.values(dataSubsidiary).map((item, idx) => (
                                        <option key={idx} value={item.id}>{item.company_name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalAdd(!showModalAdd)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={postDataUser} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEdit}
                title={'Edit User'}
                buttonClose={ () => setShowModalEdit(!showModalEdit)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>User Role<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={ (e) => setRole(e.target.value) } value={role} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select User Role</option>
                                        <option value={'USER'}>User</option>
                                        <option value={'LEADER'}>Leader</option>
                                        <option value={'ADMIN'}>Admin</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={ (e) => setEmployeeName(e.target.value) } value={employeeName} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Input Employee Name...' />
                            </div>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Status<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={ (e) => setIsVerified(e.target.value) } value={isVerified} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select Status</option>
                                        <option value={true}>Enable</option>
                                        <option value={false}>Disable</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Email<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={ (e) => setEmail(e.target.value) } value={email} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Your Email...' />
                            </div>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Password<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input onChange={ (e) => setPassword(e.target.value)} type='password' className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Your Password...' />
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Confirm Password<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input type='password' value={password.slice(0,10)} onChange={ (e) => setPassword(e.target.value) } className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Your password...'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Assigned to Subsidiary<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setSubsidiary(e.target.value) } className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Subsidiary...</option>
                                    {Object.values(dataSubsidiary).map((item, idx) => (
                                        <option key={idx} value={item.id}>{item.company_name}</option>
                                    ))}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalEdit(!showModalEdit)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={updateDataUser} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={ () => setShowModalDelete(!showModalDelete)}
                submitButton={deleteDataUser}
            />
            <div className='w-full space-y-[24px] overflow-hidde'>
                <Navbar SubNavbar={true} NameSubNavbar={'User Management'} LinkBack={'/dashboard'}/>
                <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                    <div className='flex flex-col lg:flex-row items-center justify-between'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-[#454545] text-[16px] font-[500]'>Users</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>list of users</h1>
                        </div>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <div className='relative'>
                                <AiOutlineSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                <input placeholder='Search by Employee Name ...' className='text-[#A8A8A8] text-sm font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                            </div>
                            <button onClick={ () => setShowModalAdd(!showModalAdd)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white'/>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add User</h1>
                            </button>
                        </div>
                    </div>
                    <div className='mt-[44px] overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-[#737373] text-xs font-[600]'>Email</h1>
                                    <MdImportExport className='text-[#737373] text-xs'/>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-[#737373] text-xs font-[600]'>User Role</h1>
                                    <MdImportExport className='text-[#737373] text-xs'/>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                    <h1 className='text-[#737373] text-xs font-[600]'>Employee Name</h1>
                                    <MdImportExport className='text-[#737373] text-xs'/>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-[#737373] text-xs font-[600]'>Status</h1>
                                    <MdImportExport className='text-[#737373] text-xs'/>
                                </div>
                                <div className='flex items-center justify-center gap-[15px] w-full'>
                                    <h1 className='text-[#737373] text-xs font-[600]'>Action</h1>
                                </div>
                            </div>
                            {dataUser.length === 0 ?
                            <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>-</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>-</h1>
                                </div>
                                <div className='min-w-[300px] max-w-[300px]'>
                                    <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>-</h1>
                                </div>
                                <div className='min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>-</h1>
                                </div>
                                <div className='w-full flex items-center justify-center gap-[5px]'>
                                    <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>-</h1>
                                </div>
                            </div>
                            :
                            Object.values(dataUser).map((data, index) => {
                                return (
                                    <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>{data.email}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>{data.role}</h1>
                                        </div>
                                        <div className='min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>{data.fullname}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>{!data.isVerified ? 'Disabled' : 'Enable'}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[5px]'>
                                            <button onClick={() => openModalUpdateDataUser(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]'/>
                                            </button>
                                            <button onClick={() => deleteDataUserModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty className='text-[#003049]'/>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                            } 
                        </table>
                    </div>
                    <div className='flex items-center justify-between mt-[30px]'>
                        <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                        <div className='flex items-center gap-[8px]'>
                            <MdKeyboardArrowLeft className='text-[#A098AE]'/>
                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                <h1 className='text-[#780000] text-[10px]'>1</h1>
                            </div>
                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                <h1 className='text-white text-[10px]'>2</h1>
                            </div>
                            <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                <h1 className='text-[#780000] text-[10px]'>3</h1>
                            </div>
                            <MdKeyboardArrowRight className='text-[#A098AE]'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserManagement