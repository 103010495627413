import React, { useEffect, useState } from 'react'
import { Modal, Navbar, Pagination, Sidebar } from '../../../../components'
import { TbFileExport } from 'react-icons/tb'
import { BiCheckbox, BiSearch } from 'react-icons/bi'
import { BsFilter, BsListCheck } from 'react-icons/bs'
import { AiFillCheckCircle, AiOutlineEye } from 'react-icons/ai'
import { FiEye } from 'react-icons/fi'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdPlayArrow } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import moment from 'moment'

const EntryApplication = () => {
    const navigate = useNavigate()
    const [showModalMoreInformation, setShowModalMoreInformation] = useState()
    const [showFilter, setShowFilter] = useState()
    const [refresh, setRefresh] = useState(false)

    const [dataApplicant, setDataApplicant] = useState('')
    const [dataApplicantDetailModal, setDataApplicantDetailModal] = useState('')
    const [dataExperienceApplicant, setDataExperienceApplicant] = useState('')
    const [dataEducationApplicant, setDataEducationApplicant] = useState('')

    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const GetEntryApplication = async() => {
        try {
            const response = await Api.GetRecruitmentApplicant(localStorage.getItem('hris-token'), currentPage, limit)
            setDataApplicant(response.data.applicants)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDetailModal = async(id) => {
        setShowModalMoreInformation(!showModalMoreInformation)
        try {
            const response = await Api.GetRecruitmentApplicantById(localStorage.getItem('hris-token'), id)
            setDataApplicantDetailModal(response.data.applicant)
            setDataExperienceApplicant(response.data.applicant.experience)
            setDataEducationApplicant(response.data.applicant.educations)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetEntryApplication()
    }, [refresh])

    return (
        <div>
            <Modal 
                activeModal={showModalMoreInformation}
                title={'More Information '}
                buttonClose={ () => setShowModalMoreInformation(!showModalMoreInformation)}
                width={'1000px'}
                content= {
                    <div className='space-y-[20px]'>

                        <div className='w-full bg-[#F8F8F8]  pl-[20px]  rounded-[15px]'>
                            <h1 className='text-[13px] pt-[23px] mb-[30px] font-semibold text-[#5C5C5C]'>Personal detail</h1>
                            <div className='flex gap-[58px] mb-[48px] text-[11px] text-[#737373]'>
                                <div className='space-y-[21px] font-semibold '>
                                    <h1>Full Name</h1>
                                    <h1>Age</h1>
                                    <h1>Position</h1>
                                    <h1>Major</h1>
                                </div>
                                <div className='space-y-[21px]'>
                                    <h1>: {dataApplicantDetailModal.name}</h1>
                                    <h1>: {dataApplicantDetailModal.age}</h1>
                                    <h1>: {!dataApplicantDetailModal.recruitment ? '-' : dataApplicantDetailModal.recruitment.position}</h1>
                                    <h1>: -</h1>
                                </div>
                            </div>

                            <h1 className='font-semibold text-[13px] mb-[16px] text-[#5C5C5C]'>Experience</h1>

                            {Object.values(dataExperienceApplicant).map((item, idx) => (
                                <>
                                <div className='text-[#737373]'>
                                    <h2 className='font-semibold text-[11px] mb-[7px]'>{item.position? item.position : '-'}</h2>
                                    <p className='text-[11px] mb-[7px] '>{item.companyName ? item.companyName : '-'}</p>
                                    <hr className='mb-[16px]'/>
                                </div>
                                </>
                            ))}

                            <h1 className='font-semibold text-[13px] mb-[16px] text-[#5C5C5C]'>Education</h1>
                            {Object.values(dataEducationApplicant).map((item, idx) => (
                                <>
                                <div className='text-[#737373]'>
                                    <h2 className='font-semibold text-[11px] mb-[7px]'>{item.InstitutionName? item.InstitutionName : '-'}</h2>
                                    <p className='text-[11px] mb-[7px] '>{item.FieldOfStudy ? item.FieldOfStudy : '-'}</p>
                                    <hr className='mb-[16px]'/>
                                </div>
                                </>
                            ))}
                        </div>

                        <button onClick={() => navigate('/recruitment/recruitment-stage', {state: {applicantId: dataApplicantDetailModal.id}})}  className='text-[#219EBC] text-[14px] font-medium underline'>See Recruitment Stage</button>
                        <div className='flex items-center justify-end '>
                            <div className='relative '>
                                <button onClick={() => navigate('/recruitment/detail-applicant', {state: {applicantId: dataApplicantDetailModal.id}})} className='border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px] bg-[#003049]'>
                                    <h1 className=' text-white text-sm font-[500]'>Read Detail</h1>
                                    <MdPlayArrow className='text-white'/>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showFilter}
                buttonClose={ () => setShowFilter(!showFilter)}
                width={'450px'}
                content= {
                    <div className='space-y-[20px]'>
                        <div className='flex gap-[27px] items-center justify-center'>
                            <BsFilter className='text-[#282828] text-2xl'/>
                            <h1 className='text-[#282828] text-[20px] font-semibold'>Filter</h1>
                        </div>
                        <div className='text-[#5C5C5C] text-[12px] font-medium'>
                            <div className='flex justify-center mb-[30px]'>
                                <div className='relative bg-white w-full'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input placeholder='Search by experience...' className='h-[38px] text-[#5C5C5C] text-[10px] font-[500] pl-12 border rounded-[6px] py-2 w-full'/>
                                </div>
                            </div>
                            <div className='mb-[30px]'>
                                <h1 className='mb-[6px] font-semibold'>Tanggal Melamar</h1>
                                <input type="date" className='py-[9px] px-[20px] w-full rounded-md border text-[12px] font-medium' />
                            </div>

                            <h1 className='mb-[6px] font-semibold'>Submer Lowongan</h1>
                            <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Instagram Loker</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Linkedin</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Job Fair Jogja Sch Juli</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Google Form</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Sponsor Instagram / Facebook</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Website</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Whatsapp</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Email</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>HardFile</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>General Affair</h1>
                                </div>
                            </div>
                        </div>
                        <div className='text-[#5C5C5C] text-[12px] font-medium'>
                            <h1 className='mb-[6px] font-semibold'>Position</h1>
                            <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Designer </h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Programmer</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Manager</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Sales Marketing</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Advertiser</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Admin Inputer</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Human Resource</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Finance</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>Secretary</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>General Affair</h1>
                                </div>
                            </div>
                        </div>
                        <div className='text-[#5C5C5C] text-[12px] font-medium'>
                            <h1 className='mb-[6px] font-semibold'>Pendidikan Terakhir</h1>
                            <div className='py-[17.5px] px-[27.5px] bg-white border shadow-sm rounded-md '>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>SMA/SMK </h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>S1</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>S2</h1>
                                </div>
                                <div className='flex items-center gap-[19.5px] mb-[3px]'>
                                    <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    <h1>S3</h1>
                                </div>
                            </div>
                        </div>
                        <button className='py-[10px] px-[150px] bg-[#0E5073] text-white text-[14px] font-medium flex justify-center rounded-md'>Apply Filter</button>
                    </div>
                }
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Entry Application'} LinkBack={'/recruitment'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className='text-[20px] font-semibold text-[#454545]'>Entry Application</h1>
                            <h2 className='text-[10px] text-[#737373]'>List of  job applicant</h2>
                        </div>
                        
                        <div className="flex gap-[20px] mt-[31px] relative">
                            <button onClick={() => setShowFilter(!showFilter)} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <BsFilter className='text-[#003049]'/>
                                <h1 className='text-[#003049] text-sm font-[500]'>Filter</h1>
                            </button>
                            <button className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <TbFileExport className='text-[#003049]'/>
                                <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                            </button>
                            <button onClick={() => navigate('/recruitment/all-stage')} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <FiEye className='text-[#003049]'/>
                                <h1 className='text-[#003049] text-sm font-[500]'>All Stages</h1>
                            </button>
                            <button onClick={() => navigate('/recruitment/archive-applicant')} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <h1 className='text-[#5C5C5C] text-sm font-[500]'>Archive Applicant</h1>
                            </button>
                            <div className="absolute right-0">
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                    <div className='relative'>
                                        <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                        <input placeholder='Search...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px] text-[#737373]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[220px] max-w-[220px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Position</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Full Name</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[140px] max-w-[140px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Apply Date</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Source of vacancies</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[100px] max-w-[100px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Phone Number</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                    </div>
                                    {Object.values(dataApplicant).map((data, index) => {
                                        return (
                                            <div key={index} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[220px] max-w-[220px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.recruitment.position ? '-' : data.recruitment.position}</h1>
                                                </div>
                                                <div className='min-w-[170px] max-w-[170px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.name ? '-' : data.name}</h1>
                                                </div>
                                                <div className='min-w-[140px] max-w-[140px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.createdAt ? '-' : moment(data.createdAt).format('DD MMMM YYYY')}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px] flex'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.source ? '-' : data.source}</h1>
                                                </div>
                                                <div className='min-w-[100px] max-w-[100px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.phone ? '-' : data.phone}</h1>
                                                </div>
                                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                                    <button onClick={() => handleDetailModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <FiEye className='text-[#003049]'/>
                                                    </button>
                                                    <button onClick={() => navigate('/recruitment/recruitment-stage', {state: {applicantId: dataApplicantDetailModal.id ? dataApplicantDetailModal.id : data.id}})} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <BsListCheck className='text-[#00AE46]'/>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPage} 
                                totalPages={totalPages} 
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EntryApplication