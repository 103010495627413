import React, { useEffect, useState } from 'react'
import { Modal } from '../../../../../components'
import Api from '../../../../../Api'
import { useLocation } from 'react-router-dom'
import { IoMdArrowDropdown } from 'react-icons/io'
import { CgTrashEmpty } from 'react-icons/cg'
import { AiOutlinePlus } from 'react-icons/ai'
import { HiPencil } from 'react-icons/hi'
import { toast } from 'react-hot-toast'

const Payroll = () => {
    const [showAddIncome, setShowAddIncome]= useState()
    const [showAddDeduction, setShowAddDeduction]= useState()
    const [showAddBenefit, setShowAddBenefit]= useState()
    const [showEditIncome, setShowEditIncome]= useState()
    const [showEditDeduction, setShowEditDeduction]= useState()
    const [showEditBenefit, setShowEditBenefit]= useState()
    const [openEdit, setOpenEdit] = useState(false)
    const [userId, setUserId] = useState()
    const [data, setData] = useState()
    const [dataPayroll, setDataPayroll] = useState()
    const [idPayroll, setIdPayroll] = useState()
    const [dataIncome, setDataIncome] = useState('')
    const [dataDeduction, setDataDeduction] = useState('')
    const [dataBenefit, setDataBenefit] = useState('')
    const [totalIncome, setTotalIncome] = useState()
    const [totalDeduction, setTotalDeduction] = useState()
    const [totalBenefit, setTotalBenefit] = useState()
    const [idIncome, setIdIncome] = useState()
    const [idDeduction, setIdDeduction] = useState()
    const [idBenefit, setIdBenefit] = useState()
    const [refresh, setRefresh] = useState(false)
    const params = useLocation()

    // State Payroll
    const [ptkpStatus, setPtkpStatus] = useState() 
    const [salaryType, setSalaryType] = useState() 
    const [prorate, setProrate] = useState() 
    const [nppBpjsKetenagakerjaan, setNppBpjsKetenagakerjaan] = useState() 
    const [taxConfiguration, setTaxConfiguration] = useState() 
    const [salaryTaxConfiguration, setSalaryTaxConfiguration] = useState() 
    const [bpjsKesehatan, setBpjsKesehatan] = useState() 
    const [bpjsKetenagakerjaan, setBpjsKetenagakerjaan] = useState() 
    const [npwp, setNpwp] = useState() 
    const [bankName, setBankName] = useState() 
    const [bankHolderName, setBankHolderName] = useState() 
    const [bankAccountNumber, setBankAccountNumber] = useState() 
    const [basicSalary, setBasicSalary] = useState() 
    const [persentageBpjsKesehatan, setPersentageBpjsKesehatan] = useState()
    const [persentageBpjsKetenagakerjaan, setPersentageBpjsKetenagakerjaan] = useState()
    const [tunjanganTetap, setTunjanganTetap] = useState() 
    const [tunjanganTidakTetap, setTunjanganTidakTetap] = useState() 

    const [titleIncome, setTitleIncome] = useState()
    const [amountIncome, setAmountIncome] = useState()
    const [titleDeduction, setTitleDeduction] = useState()
    const [amountDeduction, setAmountDeduction] = useState()
    const [titleBenefit, setTitleBenefit] = useState()
    const [amountBenefit, setAmountBenefit] = useState()

    const Fetch = async () => {
        try {
          const response = await Api.Fetch(localStorage.getItem('hris-token'))
        //   console.log(response, 'fet')
          setUserId(response.data.results.data.id)
        } catch (error) {
          console.log(error)
        }
      }

    const updatePayroll = async () => {
        try {
            const data = {
                ptkp_statusId : ptkpStatus,
                prorate : prorate,
                tax_configuration : taxConfiguration,
                bpjs_kesehatan : bpjsKesehatan,
                npwp : npwp,
                salary_type : salaryType,
                npp_bpjs_ketenagakerjaan : nppBpjsKetenagakerjaan,
                salary_tax_configuration : salaryTaxConfiguration,
                bpjs_ketenagakerjaan : bpjsKetenagakerjaan,
                bank_name : bankName,
                bank_holder_name : bankHolderName,
                bank_account_number : bankAccountNumber,
                basic_salary : basicSalary,
                persentage_bpjs_kesehatan : persentageBpjsKesehatan,
                persentage_bpjs_ketenagakerjaan : persentageBpjsKetenagakerjaan
            }
            if(!params.state){
                const response = await Api.PostPayrollMyProfile(localStorage.getItem('hris-token'), data,  userId)
                console.log(response)
                toast.success('Success Update')
                setRefresh(true)
            } else {
                const response = await Api.PostPayrollMyProfile(localStorage.getItem('hris-token'), data,  params.state.employeeId)
                console.log(response)
                toast.success('Success Update')
                setRefresh(true)
            }
        } catch (error) {
            console.log(error) 
            toast.error('Failed Update') 
        }
    }

    const getPayroll = async () => {
        try {
            if (!params.state){
                const response = await Api.GetPayrollMyProfileByLogin(localStorage.getItem('hris-token'))
                setDataPayroll(response.data[0])
                setIdPayroll(response.data[0].id)
                console.log('dataPayroll', response)
            } else {
                const response = await Api.GetPayrollMyProfileByParam(localStorage.getItem('hris-token'), params.state.employeeId)
                setDataPayroll(response.data)
                setIdPayroll(response.data.id)
                console.log(response)
            }
        } catch (error) {
           console.log(error) 
        }
    }

    const getIncome = async () => {
        try {
            const response = await Api.GetIncome(localStorage.getItem('hris-token'), idPayroll)
            setDataIncome(response.data.incomes)
            setTotalIncome(response.data.totalIncome)
        } catch (error) {
            console.log(error)
        }
    }

    

    const deleteIncome = async (id) => {
        try {
            const response = await Api.DeleteIncome(localStorage.getItem('hris-token'), id)
            toast.success('Success Delete')
            setRefresh(true)
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
            
        }
    }
    const deleteDeduction = async (id) => {
        try {
            const response = await Api.DeleteDeduction(localStorage.getItem('hris-token'), id)
            toast.success('Success Delete')
            setRefresh(true)
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
            
        }
    }
    const deleteBenefit = async (id) => {
        try {
            const response = await Api.DeleteBenefit(localStorage.getItem('hris-token'), id)
            toast.success('Success Delete')
            setRefresh(true)
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
            
        }
    }

    const openEditBenefit = async (id) => {
        setShowEditBenefit(!showEditBenefit)
        setIdBenefit(id)
        try {
            const response = await Api.GetBenefitById(localStorage.getItem('hris-token'), id)
            setTitleBenefit(response.data[0].title)
            setAmountBenefit(response.data[0].amount)
            console.log(response.data ,'openEditBenefit')
        } catch (error) {
            console.log(error)
        }
    }
    const openEditIncome = async (id) => {
        setShowEditIncome(!showEditIncome)
        setIdIncome(id)
        try {
            const response = await Api.GetIncomeById(localStorage.getItem('hris-token'), id)
            setTitleIncome(response.data[0].title)
            setAmountIncome(response.data[0].amount)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }
    const openEditDeduction = async (id) => {
        setShowEditDeduction(!showEditDeduction)
        setIdDeduction(id)
        try {
            const response = await Api.GetDeductionById(localStorage.getItem('hris-token'), id)
            setTitleDeduction(response.data[0].title)
            setAmountDeduction(response.data[0].amount)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const postIncome = async () => {
        try {
            const data = {
                title : titleIncome,
                amount : amountIncome
            }
            const response = await Api.PostIncome(localStorage.getItem('hris-token'), data, idPayroll)
            toast.success('Success Create Income')
            setRefresh(true)
        } catch (error) {
            toast.error('Failed Create Income')
        }
    }

    const postDeduction = async () => {
        try {
            const data = {
                title : titleDeduction,
                amount : amountDeduction
            }
            const response = await Api.PostDeduction(localStorage.getItem('hris-token'), data, idPayroll)
            toast.success('Success Create Deduction')
            setRefresh(true)
        } catch (error) {
            toast.error('Failed Create Deduction')
        }
    }

    const postBenefit = async () => {
        try {
            const data = {
                title : titleBenefit,
                amount : amountBenefit
            }
            const response = await Api.PostBenefit(localStorage.getItem('hris-token'), data, idPayroll)
            toast.success('Success Create Benefit')
            setRefresh(true)
        } catch (error) {
            toast.error('Failed Create Benefit')
        }
    }

    const editIncome = async () => {
        try {
            const data = {
                title : titleIncome,
                amount : amountIncome
            }
            const response = await Api.UpdateIncome(localStorage.getItem('hris-token'), data, idIncome)
            console.log(response)
            toast.success('Success Edit Income')
            setRefresh(true)
        } catch (error) {
            toast.error('Failed Edit Income')
        }
    }

    const editDeduction = async () => {
        try {
            const data = {
                title : titleDeduction,
                amount : amountDeduction
            }
            const response = await Api.UpdateDeduction(localStorage.getItem('hris-token'), data, idDeduction)
            console.log(response)
            toast.success('Success Edit Deduction')
            setRefresh(true)
        } catch (error) {
            toast.error('Failed Edit Deduction')
        }
    }

    const editBenefit = async () => {
        try {
            const data = {
                title : titleBenefit,
                amount : amountBenefit
            }
            const response = await Api.UpdateBenefit(localStorage.getItem('hris-token'), data, idBenefit)
            console.log(response)
            toast.success('Success Edit Benefit')
            setRefresh(true)
        } catch (error) {
            toast.error('Failed Edit Benefit')
        }
    }



    const getDeduction = async () => {
        try {
            const response = await Api.GetDeduction(localStorage.getItem('hris-token'), idPayroll)
            setDataDeduction(response.data.deductions)
            setTotalDeduction(response.data.totalDeduction)
        } catch (error) {
            console.log(error)
        }
    }
    const getBenefit = async () => {
        try {
            const response = await Api.GetBenefit(localStorage.getItem('hris-token'), idPayroll)
            setDataBenefit(response.data.benefits)
            setTotalBenefit(response.data.totalBenefit)
        } catch (error) {
            console.log(error)
        }
    }
    
    useEffect(() => {
        getPayroll()
        Fetch()
    }, [])

    useEffect(() => {
        getIncome()
        getDeduction()
        getBenefit()
    })


  return (
    <div>
        <Modal 
            activeModal={showAddIncome}
            title={'Add Income'}
            buttonClose={ () => setShowAddIncome(!showAddIncome)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Incomes Name</h1>
                        <input onChange={(e) => setTitleIncome(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Income Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Amount</h1>
                        <input onChange={(e) => setAmountIncome(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddIncome(!showAddIncome)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postIncome} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditIncome}
            title={'Edit Income'}
            buttonClose={ () => setShowEditIncome(!showEditIncome)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Incomes Name</h1>
                        <input onChange={(e) => setTitleIncome(e.target.value)} value={titleIncome} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Income Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Amount</h1>
                        <input onChange={(e) => setAmountIncome(e.target.value)} value={amountIncome} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowEditIncome(!showEditIncome)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postIncome} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showAddDeduction}
            title={'Add Deduction'}
            buttonClose={ () => setShowAddDeduction(!showAddDeduction)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Deduction Name</h1>
                        <input onChange={(e) => setTitleDeduction(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Income Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Amount</h1>
                        <input onChange={(e) => setAmountDeduction(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddDeduction(!showAddDeduction)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postDeduction} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditDeduction}
            title={'Edit Deduction'}
            buttonClose={ () => setShowEditDeduction(!showEditDeduction)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Deduction Name</h1>
                        <input onChange={(e) => setTitleDeduction(e.target.value)} value={titleDeduction}  type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Income Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Amount</h1>
                        <input onChange={(e) => setAmountDeduction(e.target.value)} value={amountDeduction}  type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowEditDeduction(!showEditDeduction)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postDeduction} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showAddBenefit}
            title={'Add Benefit'}
            buttonClose={ () => setShowAddBenefit(!showAddBenefit)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Benefit Name</h1>
                        <input onChange={(e) => setTitleBenefit(e.target.value)} type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Income Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Amount</h1>
                        <input onChange={(e) => setAmountBenefit(e.target.value)} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddBenefit(!showAddBenefit)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postBenefit} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditBenefit}
            title={'Edit Benefit'}
            buttonClose={ () => setShowEditBenefit(!showEditBenefit)}
            width={'553px'}
            content= {
                <div className='space-y-[25px] w-[full]'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Benefit Name</h1>
                        <input onChange={(e) => setTitleBenefit(e.target.value)} value={titleBenefit}  type="text" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Income Name...'/>
                    </div>

                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[11px] font-medium mb-[5px]'>Amount</h1>
                        <input onChange={(e) => setAmountBenefit(e.target.value)} value={amountBenefit} type="number" className='py-[10px] px-[8px] border rounded-md w-full text-[#5C5C5C] text-[12px]' placeholder='Nominal or Persentage' />
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowEditBenefit(!showEditBenefit)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postBenefit} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <div>
            <h1 className='pb-[12px] w-full border-b-2 border-b-[#EDEDED] text-[#282828] text-[20px] font-semibold'>Payroll</h1>
            <div className='py-[35px] flex justify-between'>
                <div>
                    <h1 className='text-[#454545] font-medium'>Payroll Info</h1>
                    <h1 className='text-[#737373] text-[10px]'>Payroll info for this employee</h1>
                </div>
                <button onClick={() => setOpenEdit(!openEdit)} className='px-4 border rounded-md'>Edit</button>
            </div>
            {openEdit === true ? (
                <>
                    <div className='py-[16px] grid grid-cols-12 gap-4 border-b-2 border-[#EDEDED]'>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>PTKP Status</h1>
                            <div className='relative'>
                                <select onChange={(e) => setPtkpStatus(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none'>
                                    <option selected value={''}>Select PTKP Status</option>
                                    <option value={'TK/0'}>TK/0</option>
                                    <option value={'TK/1'}>TK/1</option>
                                    <option value={'TK/2'}>TK/2</option>
                                    <option value={'TK/3'}>TK/3</option>
                                    <option value={'K/I/0'}>K/I/0</option>
                                    <option value={'K/I/1'}>K/I/1</option>
                                    <option value={'K/I/2'}>K/I/2</option>
                                    <option value={'K/I/3'}>K/I/3</option>
                                    <option value={'K/0'}>K/0</option>
                                    <option value={'K/1'}>K/1</option>
                                    <option value={'K/2'}>K/2</option>
                                    <option value={'K/3'}>K/3</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Salary Type</h1>
                            <div className='relative'>
                                <select onChange={(e) => setSalaryType(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none'>
                                    <option selected value={''}>Select Salary Type</option>
                                    <option value={'Monthly'}>Monthly</option>
                                    <option value={'Weekly'}>Weekly</option>
                                    <option value={'Daily'}>Daily</option>
                                    <option value={'One Time'}>One Time</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Prorate</h1>
                            <div className='relative'>
                                <select onChange={(e) => setProrate(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none'>
                                    <option selected value={''}>Select Prorate</option>
                                    <option value={'Based on Working Day'}>Based on Working Day</option>
                                    <option value={'Based on Calendar Day'}>Based on Calendar Day</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>NPP BPJS Ketenagakerjaan</h1>
                            <div className='relative'>
                                <select onChange={(e) => setNppBpjsKetenagakerjaan(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none'>
                                    <option selected value={''}>Select NPP BPJS Ketenagakerjaan</option>
                                    <option value={'Default'}>Default</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Tax Configuration</h1>
                            <div className='relative'>
                                <select onChange={(e) => setTaxConfiguration(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none'>
                                    <option selected value={''}>Select Tax Configuration</option>
                                    <option value={'Nett'}>Nett</option>
                                    <option value={'Gross'}>Gross</option>
                                    <option value={'Gross Up'}>Gross Up</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Salary Tax Configuration</h1>
                            <div className='relative'>
                                <select onChange={(e) => setSalaryTaxConfiguration(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none'>
                                    <option selected value={''}>Select Salary Tax Configuration</option>
                                    <option value={'Taxble'}>Taxble</option>
                                    <option value={'Non Taxable'}>Non Taxable</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>BPJS Kesehatan</h1>
                            <div className='relative'>
                                <select onChange={(e) => setBpjsKesehatan(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none'>
                                    <option selected value={''}>Select BPJS Kesehatan</option>
                                    <option value={'By Employee'}>By Employee</option>
                                    <option value={'By Company'}>By Company</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='col-span-3 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Persentage of BPJS Kesehatan</h1>
                            <div className='relative'>
                                <input onChange={(e) => setPersentageBpjsKesehatan(e.target.value)}  type='number' className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none' placeholder='0%'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>BPJS Ketenagakerjaan</h1>
                            <div className='relative'>
                                <select onChange={(e) => setBpjsKetenagakerjaan(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none'>
                                    <option selected value={''}>Select BPJS Ketenagakerjaan</option>
                                    <option value={'By Employee'}>By Employee</option>
                                    <option value={'By Company'}>By Company</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='col-span-3 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Persentage of BPJS Ketenagakerjaan</h1>
                            <div className='relative'>
                                <input onChange={(e) => setPersentageBpjsKetenagakerjaan(e.target.value)}  type='number' className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none' placeholder='0%'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>NPWP</h1>
                            <div className='relative'>
                                <input onChange={(e) => setNpwp(e.target.value)}  type='text' className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none' placeholder='Ex. 7869-9878X-N0987'/>
                            </div>
                        </div>
                    </div>
                    <div className='py-[35px]'>
                        <h1 className='text-[#454545] font-medium'>Bank Info</h1>
                        <h1 className='text-[#737373] text-[10px]'>Payroll info for this employee</h1>
                    </div>
                    <div className='py-[16px] grid grid-cols-12 gap-4 border-b-2 border-[#EDEDED]'>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Bank Name</h1>
                            <div className='relative'>
                                <select onChange={(e) => setBankName(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none'>
                                    <option selected value={''}>Select Bank Name</option>
                                    <option value={'BNI'}>BNI</option>
                                    <option value={'BCA'}>BCA</option>
                                    <option value={'BRI'}>BRI</option>
                                    <option value={'BSI'}>BSI</option>
                                    <option value={'Mandiri'}>Mandiri</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Bank Holder Name</h1>
                            <div className='relative'>
                                <input onChange={(e) => setBankHolderName(e.target.value)}  type='text' className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none' placeholder='Bank Holder Name'/>
                            </div>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Bank Account Number</h1>
                            <div className='relative'>
                                <input onChange={(e) => setBankAccountNumber(e.target.value)}  type='number' className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] w-full appearance-none outline-none' placeholder='Bank Account Number'/>
                            </div>
                        </div>
                    </div>
                    <div className='py-[16px]'>
                        <div className='border-2 rounded-[10px] border-[#EDEDED]'>
                            <div className='bg-[#F6F6F6] rounded-t-[10px] px-[29px] py-[13px]'>
                                <h1 className='text-[#454545] font-medium'>Payroll Component Info</h1>
                            </div>
                            <div className='py-[30px] space-y-8'>
                                <div className='grid grid-cols-12 gap-4 items-center px-[30px]'>
                                    <h1 className='text-[#454545] text-sm font-semibold col-span-2'>Basic Salary</h1>
                                    <input type='number' onChange={(e) => setBasicSalary(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] px-[20px] py-[10px] col-span-10 w-full appearance-none outline-none' placeholder='Basic Salary'/>
                                </div>
                                <div className='space-y-4'>
                                    <div className='bg-[#EBF7FF] w-full px-[27px] py-[8px] rounded-[3px] flex items-center justify-between'>
                                        <h1 className='text-[#737373] text-sm'>Incomes</h1>
                                        <button onClick={ () => setShowAddIncome(!showAddIncome)} className='w-[35px] h-[35px] bg-[#E1F4FF] rounded-[9px] flex items-center justify-center'>
                                            <AiOutlinePlus className='text-xl text-[#219EBC]'/>
                                        </button>
                                    </div>
                                    {Object.values(dataIncome).map((item, idx) => (
                                        <>
                                            <div key={idx} className='grid grid-cols-12  gap-4 items-center px-[27px]'>
                                                <h1 className='text-[#737373] text-xs font-medium col-span-2'>{item? item.title : '-'}</h1>
                                                <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-8 w-full appearance-none outline-none flex items-center'>{item? item.amount : '-'}</h1>
                                                <button onClick={() => openEditIncome(item.id)} className='w-full h-[35px] bg-[#F6F6F6] rounded-[9px] flex items-center justify-center col-span-1'>
                                                    <HiPencil/>
                                                </button>
                                                <button onClick={() => deleteIncome(item.id)} className='w-full h-[35px] bg-[#FFE1E1] rounded-[9px] flex items-center justify-center col-span-1'>
                                                    <CgTrashEmpty className='text-[#780000]'/>
                                                </button>
                                            </div>
                                        </>
                                    ))}
                                    <div className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                        <h1 className='text-[#737373] text-xs font-medium col-span-2'>Total Income</h1>
                                        <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-9 w-full appearance-none outline-none flex items-center'>{totalIncome? totalIncome : '-'}</h1>
                                    </div>
                                </div>
                                <div className='space-y-4'>
                                    <div className='bg-[#EBF7FF] w-full px-[27px] py-[8px] rounded-[3px] flex items-center justify-between'>
                                        <h1 className='text-[#737373] text-sm'>Deduction</h1>
                                        <button onClick={ () => setShowAddDeduction(!showAddDeduction)} className='w-[35px] h-[35px] bg-[#E1F4FF] rounded-[9px] flex items-center justify-center'>
                                            <AiOutlinePlus className='text-xl text-[#219EBC]'/>
                                        </button>
                                    </div>
                                    {Object.values(dataDeduction).map((item, idx) => (
                                        <>
                                            <div key={idx} className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                                <h1 className='text-[#737373] text-xs font-medium col-span-2'>{item? item.title : '-'}</h1>
                                                <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-8 w-full appearance-none outline-none flex items-center'>{item? item.amount : '-'}</h1>
                                                <button onClick={() => openEditDeduction(item.id)} className='w-full h-[35px] bg-[#F6F6F6] rounded-[9px] flex items-center justify-center col-span-1'>
                                                    <HiPencil/>
                                                </button>
                                                <button onClick={() => deleteDeduction(item.id)} className='w-full h-[35px] bg-[#FFE1E1] rounded-[9px] flex items-center justify-center col-span-1'>
                                                    <CgTrashEmpty className='text-[#780000]'/>
                                                </button>
                                                </div>
                                        </>
                                    ))}
                                    <div className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                        <h1 className='text-[#737373] text-xs font-medium col-span-2'>Total Deduction</h1>
                                        <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-9 w-full appearance-none outline-none flex items-center'>{totalIncome? totalIncome : '-'}</h1>
                                    </div>
                                </div>
                                <div className='space-y-4'>
                                    <div className='bg-[#EBF7FF] w-full px-[27px] py-[8px] rounded-[3px] flex items-center justify-between'>
                                        <h1 className='text-[#737373] text-sm'>Benefit</h1>
                                        <button onClick={ () => setShowAddBenefit(!showAddBenefit) } className='w-[35px] h-[35px] bg-[#E1F4FF] rounded-[9px] flex items-center justify-center'>
                                            <AiOutlinePlus className='text-xl text-[#219EBC]'/>
                                        </button>
                                    </div>
                                    {Object.values(dataBenefit).map((item, idx) => (
                                        <>
                                            <div key={idx} className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                                <h1 className='text-[#737373] text-xs font-medium col-span-2'>{item? item.title : '-'}</h1>
                                                <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-8 w-full appearance-none outline-none flex items-center'>{item? item.amount : '-'}</h1>
                                                <button onClick={() => openEditBenefit(item.id)} className='w-full h-[35px] bg-[#F6F6F6] rounded-[9px] flex items-center justify-center col-span-1'>
                                                <HiPencil/>
                                                </button>
                                                <button onClick={() => deleteBenefit(item.id)} className='w-full h-[35px] bg-[#FFE1E1] rounded-[9px] flex items-center justify-center col-span-1'>
                                                <CgTrashEmpty className='text-[#780000]'/>
                                                </button>
                                                </div>
                                        </>
                                    ))}
                                    <div className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                        <h1 className='text-[#737373] text-xs font-medium col-span-2'>Total Benefit</h1>
                                        <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-9 w-full appearance-none outline-none flex items-center'>{totalIncome? totalIncome : '-'}</h1>
                                    </div>
                                </div>
                                <div className='flex gap-2 justify-end px-[30px]'>
                                    <button onClick={() => setOpenEdit(!openEdit)} className='text-[14px] font-medium py-[10px] px-[25px] text-[#0E5073] rounded-md border'>Cancel</button>
                                    <button onClick={updatePayroll} className='bg-[#0E5073] text-[14px] font-medium py-[10px] px-[25px] text-white rounded-md'>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                <div className='py-[16px] grid grid-cols-12 gap-4 border-b-2 border-[#EDEDED]'>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>PTKP Status</h1>
                            <input readOnly value={dataPayroll? dataPayroll.ptkp_statusId : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Salary Type</h1>
                            <input readOnly value={dataPayroll? dataPayroll.salary_type : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Prorate</h1>
                            <input readOnly value={dataPayroll? dataPayroll.prorate : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>NPP BPJS Ketenagakerjaan</h1>
                            <input readOnly value={dataPayroll? dataPayroll.npp_bpjs_ketenagakerjaan : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Tax Configuration</h1>
                            <input readOnly value={dataPayroll? dataPayroll.tax_configuration : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Salary Tax Configuration</h1>
                            <input readOnly value={dataPayroll? dataPayroll.salary_tax_configuration : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>BPJS Kesehatan</h1>
                            <input readOnly value={dataPayroll? dataPayroll.bpjs_kesehatan : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className='col-span-3 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Persentage of BPJS Kesehatan</h1>
                            <input readOnly value={dataPayroll? dataPayroll.persentage_bpjs_kesehatan : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>BPJS Ketenagakerjaan</h1>
                            <input readOnly value={dataPayroll? dataPayroll.bpjs_ketenagakerjaan : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className='col-span-3 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Persentage of BPJS Ketenagakerjaan</h1>
                            <input readOnly value={dataPayroll? dataPayroll.persentage_bpjs_ketenagakerjaan : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>

                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>NPWP</h1>
                            <input readOnly value={dataPayroll? dataPayroll.npwp : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>

                        </div>
                    </div>
                    <div className='py-[35px]'>
                        <h1 className='text-[#454545] font-medium'>Bank Info</h1>
                        <h1 className='text-[#737373] text-[10px]'>Payroll info for this employee</h1>
                    </div>
                    <div className='py-[16px] grid grid-cols-12 gap-4 border-b-2 border-[#EDEDED]'>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Bank Name</h1>
                            <input readOnly value={dataPayroll? dataPayroll.bank_name : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>

                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Bank Holder Name</h1>
                            <input readOnly value={dataPayroll? dataPayroll.bank_holder_name : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>

                        </div>
                        <div className='col-span-6 space-y-[10px]'>
                            <h1 className='text-[#737373] text-[12px]'>Bank Account Number</h1>
                            <input readOnly value={dataPayroll? dataPayroll.bank_account_number : '-'} className='bg-[#CACACA] border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>

                        </div>
                    </div>
                    <div className='py-[16px]'>
                        <div className='border-2 rounded-[10px] border-[#EDEDED]'>
                            <div className='bg-[#F6F6F6] rounded-t-[10px] px-[29px] py-[13px]'>
                                <h1 className='text-[#454545] font-medium'>Payroll Component Info</h1>
                            </div>
                            <div className='py-[30px] space-y-8'>
                                <div className='grid grid-cols-12 gap-4 items-center px-[30px]'>
                                    <h1 className='text-[#454545] text-sm font-semibold col-span-2'>Basic Salary</h1>
                                    <input readOnly value={dataPayroll? dataPayroll.basic_salary : '-'} type='number' className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] py-[10px] col-span-10 w-full appearance-none outline-none' placeholder='Basic Salary'/>
                                    
                                </div>
                                <div className='space-y-4'>
                                    <div className='bg-[#EBF7FF] w-full px-[27px] py-[8px] rounded-[3px] flex items-center justify-between'>
                                        <h1 className='text-[#737373] text-sm'>Incomes</h1>
                                    </div>
                                        {Object.values(dataIncome).map((item, idx) => (
                                            <>
                                            <div key={idx} className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                                <h1 className='text-[#737373] text-xs font-medium col-span-3'>{item? item.title : '-'}</h1>
                                                <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-9 w-full appearance-none outline-none flex items-center'>{item? item.amount : '-'}</h1>
                                             </div>
                                        </>
                                        ))}
                                        <div className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                            <h1 className='text-[#737373] text-xs font-medium col-span-3'>Total Income</h1>
                                            <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-9 w-full appearance-none outline-none flex items-center'>{totalIncome? totalIncome : '-'}</h1>
                                        </div>
                                </div>
                                <div className='space-y-4'>
                                    <div className='bg-[#EBF7FF] w-full px-[27px] py-[8px] rounded-[3px] flex items-center justify-between'>
                                        <h1 className='text-[#737373] text-sm'>Deduction</h1>
                                    </div>
                                    {Object.values(dataDeduction).map((item, idx) => (
                                            <>
                                            <div key={idx} className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                                <h1 className='text-[#737373] text-xs font-medium col-span-3'>{item? item.title : '-'}</h1>
                                                <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-9 w-full appearance-none outline-none flex items-center'>{item? item.amount : '-'}</h1>
                                             </div>
                                        </>
                                    ))}
                                    <div className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                        <h1 className='text-[#737373] text-xs font-medium col-span-3'>Total Deduction</h1>
                                        <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-9 w-full appearance-none outline-none flex items-center'>{totalDeduction? totalDeduction : '-'}</h1>
                                    </div>
                                </div>
                                <div className='space-y-4'>
                                    <div className='bg-[#EBF7FF] w-full px-[27px] py-[8px] rounded-[3px] flex items-center justify-between'>
                                        <h1 className='text-[#737373] text-sm'>Benefit</h1>
                                    </div>
                                    {Object.values(dataBenefit).map((item, idx) => (
                                            <>
                                            <div key={idx} className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                                <h1 className='text-[#737373] text-xs font-medium col-span-3'>{item? item.title : '-'}</h1>
                                                <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-9 w-full appearance-none outline-none flex items-center'>{item? item.amount : '-'}</h1>
                                             </div>
                                        </>
                                    ))}
                                    <div className='grid grid-cols-12 gap-4 items-center px-[27px]'>
                                        <h1 className='text-[#737373] text-xs font-medium col-span-3'>Total Benefit</h1>
                                        <h1 className='bg-[#CACACA] border rounded-[6px] text-[#737373] text-[12px] px-[20px] h-[35px] col-span-9 w-full appearance-none outline-none flex items-center'>{totalBenefit? totalBenefit : '-'}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            
        </div>
    </div>
  )
}

export default Payroll