import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination, Sidebar } from '../../../../../components'
import { BiPlus } from 'react-icons/bi'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { TbFileExport } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import { FaFileUpload } from 'react-icons/fa'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../../Api'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast'

const JobManagement = () => {
    const [jobManagement, setJobManagement] = useState('Job Grade')
    const [showModalAddJobGrade, setShowModalAddJobGrade] = useState(false)
    const [showModalEditJobGrade, setShowModalEditJobGrade] = useState(false)
    const [showModalDeleteJobGrade, setShowModalDeleteJobGrade] = useState(false)

    const [showModalAddJobLevel, setShowModalAddJobLevel] = useState(false)
    const [showModalEditJobLevel, setShowModalEditJobLevel] = useState(false)
    const [showModalDeleteJobLevel, setShowModalDeleteJobLevel] = useState(false)

    const [showModalAddJobPosition, setShowModalAddJobPosition] = useState(false)
    const [showModalEditJobPosition, setShowModalEditJobPosition] = useState(false)
    const [showModalDeleteJobPosition, setShowModalDeleteJobPosition] = useState(false)

    const [showModalAddJobCategory, setShowModalAddJobCategory] = useState(false)
    const [showModalEditJobCategory, setShowModalEditJobCategory] = useState(false)
    const [showModalDeleteJobCategory, setShowModalDeleteJobCategory] = useState(false)

    // Pagination Job Grade
    const limit = 5
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    // Job Grade
    const [jobGradeDropdown, setJobGradeDropdown] = useState('')
    const [jobGradeDropdownName, setJobGradeDropdownName] = useState('')
    const [dataJobGrade, setDataJobGrade] = useState('')
    const [jobGrade, setJobGrade] = useState('')
    const [numberRanges, setNumberRanges] = useState('')
    const [range, setRange] = useState('')
    const [currencyType, setCurrencyType] = useState('')
    const [minimumSalary, setMinimumSalary] = useState('')
    const [maximumSalary, setMaximumSalary] = useState('')
    const [idJobGrade, setIdJobGrade] = useState('')
    const [refresh, setRefresh] = useState(false)


    const getJobGrade = async() => {
        try {
            const response = await Api.GetJobGrade(localStorage.getItem('hris-token'), currentPage, limit)
            setDataJobGrade(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }

    const postJobGrade = async() => {
        try {
            const data = {
                job_grade : jobGrade,
                range : range,
                currency_type : currencyType,
                minimum_salary : minimumSalary,
                maximum_salary : maximumSalary 
            }
            await Api.PostJobGrade(localStorage.getItem('hris-token'), data)
            setJobGrade('')
            setNumberRanges('')
            setRange('')
            setCurrencyType('')
            setMinimumSalary('')
            setMaximumSalary('')
            setShowModalAddJobGrade(!showModalAddJobGrade)
            setRefresh(true)
            toast.success('Successfully added data!!!')
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    const OpenEditJobGrade = async(id) => {
        setIdJobGrade(id)
        setShowModalEditJobGrade(!showModalEditJobGrade)
        try {
            const response = await Api.GetJobGradeByID(localStorage.getItem('hris-token'), id)
            setJobGrade(response.data[0].job_grade)
            setNumberRanges(response.data[0].number_ranges)
            setRange(response.data[0].range)
            setCurrencyType(response.data[0].currency_type)
            setMinimumSalary(response.data[0].minimum_salary)
            setMaximumSalary(response.data[0].maximum_salary)
        } catch (error) {
            console.log(error)
        }
    }
    
    const editJobGrade = async() => {
        try {
            const data = {
                job_grade : jobGrade,
                number_ranges : numberRanges,
                range : range,
                currency_type : currencyType,
                minimum_salary : minimumSalary,
                maximum_salary : maximumSalary 
            }
            const response = await Api.UpdateJobGrade(localStorage.getItem('hris-token'), data, idJobGrade)
            setShowModalEditJobGrade(!showModalEditJobGrade)
            setRefresh(true)
            toast.success('Successfully updated data!!!')
        } catch (error) {
            toast.error('Failed to update data!!!')
        }
    }
    
    const deleteJobGradeModal = async(id) => {
        setShowModalDeleteJobGrade(!showModalDeleteJobGrade)
        setIdJobGrade(id)
    }
    
    const deleteJobGrade = async(id) => {
        try {
            const response = await Api.DeleteJobGrade(localStorage.getItem('hris-token'), idJobGrade)
            setShowModalDeleteJobGrade(!showModalDeleteJobGrade)
            setRefresh(true)
            toast.success('Successfully deleted data!!!')
        } catch (error) {
            toast.error('Failed to delete data!!!')
        }
    }

    // Pagination Job level
    const limitLevel = 5
    const [currentPageLevel, setCurrentPageLevel] = useState(1)
    const [totalPagesLevel, setTotalPagesLevel] = useState('')

    const handlePageChangeLevel = (page) => {
        setCurrentPageLevel(page);
        setRefresh(true)
    };
    
    const handlePrevChangeLevel = () => {
        if(currentPageLevel === 1) {
            setCurrentPageLevel(1)
        } else {
            setCurrentPageLevel(currentPageLevel - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChangeLevel = () => {
        if(currentPageLevel === totalPagesLevel) {
            setCurrentPageLevel(totalPagesLevel)
        } else {
            setCurrentPageLevel(currentPageLevel + 1);
        }
        setRefresh(true)
    };

    // Job Level

    const [dataJobLevel, setDataJobLevel] = useState('')
    const [jobLevel, setJobLevel] = useState('')
    const [jobLevelName, setJobLevelName] = useState('')
    const [idJobLevel, setIdJobLevel] = useState('')

    const OpenEditJobLevel = async(id) => {
        setIdJobLevel(id)
        setShowModalEditJobLevel(!showModalEditJobLevel)
        try {
            const response = await Api.GetJobLevelByID(localStorage.getItem('hris-token'), id)
            console.log(response, 'openEditJobLevel')
            setJobLevelName(response.data[0].level_name)
            setJobGradeDropdown(response.data[0].job_grade.id)
        } catch (error) {
            console.log(error)
        }
    }

    const getJobLevel = async() => {
        try {
            const response = await Api.GetJobLevel(localStorage.getItem('hris-token'), currentPageLevel, limitLevel)
            console.log(response, 'jobLevel')
            setDataJobLevel(response.data.data)
            setCurrentPageLevel(response.data.currentPage)
            setTotalPagesLevel(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }

    const postJobLevel = async() => {
        try {
            const data = {
                level_name : jobLevelName,
                job_grade : jobGradeDropdown
            }
            const response = await Api.PostJobLevel(localStorage.getItem('hris-token'), data)
            setShowModalAddJobLevel(!showModalAddJobLevel)
            setRefresh(true)
            setJobLevelName('')
            setJobGradeDropdown('')
            toast.success('Successfully added data!!!')
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    const editJobLevel = async() => {
        try {
            const data = {
                level_name : jobLevelName,
                job_grade : jobGradeDropdown
            }
            console.log(data, 'dataSended')
            const response = await Api.UpdateJobLevel(localStorage.getItem('hris-token'), data, idJobLevel)
            setShowModalEditJobLevel(!showModalEditJobLevel)
            setRefresh(true)
            toast.success('Successfully updated data!!!')
        } catch (error) {
            toast.error('Failed to update data!!!')
            console.log(error)
        }
    }
    
    const deleteJobLevelModal = async(id) => {
        setShowModalDeleteJobLevel(!showModalDeleteJobLevel)
        setIdJobLevel(id)
    }

    const deleteJobLevel = async(id) => {
        try {
            const response = await Api.DeleteJobLevel(localStorage.getItem('hris-token'), idJobLevel)
            setShowModalDeleteJobLevel(!showModalDeleteJobLevel)
            setRefresh(true)
            toast.success('Successfully deleted data!!!')
        } catch (error) {
            toast.error('Failed to delete data!!!')
        }
    }

    // Pagination Job Position
    const limitPosition = 5
    const [currentPagePosition, setCurrentPagePosition] = useState(1)
    const [totalPagesPosition, setTotalPagesPosition] = useState('')

    const handlePageChangePosition = (page) => {
        setCurrentPagePosition(page);
        setRefresh(true)
    };
    
    const handlePrevChangePosition = () => {
        if(currentPagePosition === 1) {
            setCurrentPagePosition(1)
        } else {
            setCurrentPagePosition(currentPagePosition - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChangePosition = () => {
        if(currentPagePosition === totalPagesPosition) {
            setCurrentPagePosition(totalPagesPosition)
        } else {
            setCurrentPagePosition(currentPagePosition + 1);
        }
        setRefresh(true)
    };
    
    // Job Position

    const [dataJobPosition, setDataJobPosition] = useState('')
    const [dataJobPositionDropdown, setDataJobPositionDropdown] = useState('')
    const [jobPosition, setJobPosition] = useState('')
    const [jobPositionName, setJobPositionName] = useState('')
    const [idJobPosition, setIdJobPosition] = useState('')
    const [jobId, setJobId] = useState('')
    const [idJobPositionComp, setIdJobPositionComp] = useState('')
    const [superiorId, setSuperiorId] = useState([])
    const [superiorNameById, setSuperiorNameById] = useState('')
    const [superior, setSuperior] = useState('')
    const [jobCategory, setJobCategory] = useState('')
    const [jobCategoryDropdownName, setJobCategoryDropdownName] = useState('')

    const OpenEditJobPosition = async(id) => {
        setIdJobPosition(id)
        setShowModalEditJobPosition(!showModalEditJobPosition)
        try {
            const response = await Api.GetJobPositionByID(localStorage.getItem('hris-token'), id)
            console.log(response, 'jobPositionById')
            setJobPositionName(response.data.jobPosition.position_name)
            setIdJobPositionComp(response.data.jobPosition.jobId)
            setJobGradeDropdown(response.data.jobPosition.job_grade.id)
            setJobGradeDropdownName(response.data.jobPosition.job_grade.job_grade) 
            setJobCategoryDropdownName(response.data.jobPosition.job_category.category_name) 
            setJobCategory(response.data.jobPosition.job_category.id) 
            // setSuperiorNameById(response.data.jobPosition.superior.superior_title)
        } catch (error) {
            console.log(error)
        }
    }

    const getJobPosition = async() => {
        try {
            const resJobPosition = await Api.GetJobPosition(localStorage.getItem('hris-token'), currentPagePosition, limitPosition)
            // const resJobSuperior = await Api.GetJobSuperior(localStorage.getItem('hris-token'))
            // console.log(resJobSuperior, 'jobSuperior')
            // setSuperior(resJobSuperior.data)
            setDataJobPosition(resJobPosition.data.data)
            setCurrentPagePosition(resJobPosition.data.currentPage)
            setTotalPagesPosition(resJobPosition.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }

    const getJobPositionDropdown = async () => {
        try {
            const response = await Api.GetJobPosition(localStorage.getItem('hris-token'), 1, 100)
            console.log(response, 'jobPosition')
            setDataJobPositionDropdown(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const postJobPosition = async() => {
        try {
            if(dataJobPosition.length === 0) {
                const data = {
                    position_name : jobPositionName,
                    jobId : idJobPositionComp,
                    job_grade : jobGradeDropdown,
                    job_category: jobCategory,
                }
                console.log(data, 'sendedData')
                const response = await Api.PostJobPosition(localStorage.getItem('hris-token'), data)
                setShowModalAddJobPosition(!showModalAddJobPosition)
                setRefresh(true)
                toast.success('Successfully added data!!!')
            } else {
                const data = {
                    position_name : jobPositionName,
                    jobId : idJobPositionComp,
                    job_grade : jobGradeDropdown,
                    job_category: jobCategory,
                    superiorId : superiorId
                }
                console.log(data, 'sendedData')
                const response = await Api.PostJobPosition(localStorage.getItem('hris-token'), data)
                setShowModalAddJobPosition(!showModalAddJobPosition)
                setRefresh(true)
                toast.success('Successfully added data!!!')
            }
            setJobPositionName('')
            setIdJobPosition('')
            setJobGradeDropdown('')
            setSuperiorId('')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!!!')
        }
    }

    const editJobPosition = async() => {
        try {
            const data = {
                position_name : jobPositionName,
                jobId : idJobPositionComp,
                job_grade : jobGradeDropdown,
                superiorId : superiorId,
                superior_title : jobPositionName
            }
            console.log(data, 'sendedData')
            const response = await Api.UpdateJobPosition(localStorage.getItem('hris-token'), data, idJobPosition)
            setShowModalEditJobPosition(!showModalEditJobPosition)
            setRefresh(true)
            toast.success('Successfully updated data!!!')
        } catch (error) {
            toast.error('Failed to update data!!!')
            console.log(error)
        }
    }
    
    const deleteJobPositionModal = async(id) => {
        setShowModalDeleteJobPosition(!showModalDeleteJobPosition)
        setIdJobPosition(id)
    }

    const deleteJobPosition = async(id) => {
        try {
            const response = await Api.DeleteJobPosition(localStorage.getItem('hris-token'), idJobPosition)
            setShowModalDeleteJobPosition(!showModalDeleteJobPosition)
            setRefresh(true)
            toast.success('Successfully deleted data!!!')
        } catch (error) {
            toast.error('Failed to delete data!!!')
        }
    }

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === '') {
        // Jika "Select Superior" dipilih, tidak perlu melakukan apa-apa
        return;
        }

        // Periksa apakah ID Superior sudah ada dalam array
        if (superiorId.includes(selectedValue)) {
        // Jika sudah ada, jangan lakukan apapun
            return
        } else {
        // Jika belum ada, tambahkan ID ke dalam array
        setSuperiorId([...superiorId, selectedValue]);
        }
      };

      const handleRemoveClickedName = (index) => {
        const updatedSuperiorId = superiorId.filter((_, i) => i !== index);
        setSuperiorId(updatedSuperiorId);
    };


    // Job Category
    const [categoryName, setCategoryName] = useState('')
    const [dataJobCategory, setDataJobCategory] = useState([])
    const [directTo, setDirectTo] = useState('')
    const [idJobCategory, setIdJobCategory] = useState('')

    const crateJobCategory = async () => {
        const data = {
            category_name: categoryName,
            direct_to: directTo
        }
        try {
            const response = await Api.CreateJobCategory(localStorage.getItem('hris-token'), data)
            setCategoryName('')
            setDirectTo('')
            setRefresh(true)
            toast.success('Successfully add data!!!')
            setShowModalAddJobCategory(!showModalAddJobCategory)
        } catch (error) {
            toast.error('Failed to add data!!!')
            
        }
    }

    const openEditJobCategory = async (id) => {
        setShowModalEditJobCategory(!showModalEditJobCategory)
        setIdJobCategory(id)
        try {
            const response = await Api.GetJobCategoryById(localStorage.getItem('hris-token'), id)
            setCategoryName(response.data.data[0].category_name)
            setDirectTo(response.data.data[0].direct_to)
        } catch (error) {
            console.log(error)
        }
    }

    const editJobCategory = async () => {
        const data = {
            category_name: categoryName,
            direct_to: directTo
        }
        try {
            const response = await Api.UpdateJobCategory(localStorage.getItem('hris-token'), data, idJobCategory)
            setCategoryName('')
            setDirectTo('')
            setRefresh(true)
            toast.success('Successfully updated data!!!')
            setShowModalEditJobCategory(!showModalEditJobCategory)
        } catch (error) {
            toast.error('Failed to update data!!!')
        }
    }

    const getJobCategory = async () => {
        try {
            const response = await Api.GetJobCategory(localStorage.getItem('hris-token'))
            console.log(response, 'jobcategory')
            setDataJobCategory(response.data.jobCategory)
        } catch (error) {
            console.log(error)
        }
    }
    const deleteJobCategory = async() => {
        try {
            const response = await Api.DeleteJobCategory(localStorage.getItem('hris-token'), idJobCategory)
            setShowModalDeleteJobCategory(!showModalDeleteJobCategory)
            setRefresh(true)
            toast.success('Successfully deleted data!!!')
        } catch (error) {
            toast.error('Failed to delete data!!!')
        }
    }

    const deleteJobCategoryModal = async(id) => {
        setShowModalDeleteJobCategory(!showModalDeleteJobCategory)
        setIdJobCategory(id)
    }


    // Export Job
    const exportToExcel = async () => {
        // Sample data array
        const dataGrade = await Api.GetJobGrade(localStorage.getItem('hris-token'), '', '');
        const dataLevel = await Api.GetJobLevel(localStorage.getItem('hris-token'), '', '');
        const dataPosition = await Api.GetJobPosition(localStorage.getItem('hris-token'), '', '');

        // Define custom headers for each table
        const gradeHeaders = ['Job Grade', 'Number Ranges', 'Range', 'Currency Type', 'Minimum Salary', 'Maximum Salary'];
        const levelHeaders = ['Level Name'];
        const positionHeaders = ['Position Name', 'Job ID'];

        // Create modified data arrays with custom headers
        const jobGrade = Object.values(dataGrade.data.data).map(({ job_grade, number_ranges, range, currency_type, minimum_salary, maximum_salary }) => ({
            'Job Grade': job_grade,
            'Number Ranges': number_ranges,
            'Range': range,
            'Currency Type': currency_type,
            'Minimum Salary': minimum_salary,
            'Maximum Salary': maximum_salary,
        }));

        const jobLevel = Object.values(dataLevel.data.data).map(({ level_name }) => ({
            'Level Name': level_name,
        }));

        const jobPosition = Object.values(dataPosition.data.data).map(({ position_name, jobId }) => ({
            'Position Name': position_name,
            'Job ID': jobId,
        }));

        // Create a new worksheet for each table
        const worksheetGrade = XLSX.utils.json_to_sheet(jobGrade, { header: gradeHeaders });
        const worksheetLevel = XLSX.utils.json_to_sheet(jobLevel, { header: levelHeaders });
        const worksheetPosition = XLSX.utils.json_to_sheet(jobPosition, { header: positionHeaders });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheetGrade, 'Job Grade');
        XLSX.utils.book_append_sheet(workbook, worksheetLevel, 'Job Level');
        XLSX.utils.book_append_sheet(workbook, worksheetPosition, 'Job Position');

        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, 'Job Management.xlsx');
    };


    useEffect(() => {
        getJobGrade()
        getJobPositionDropdown()
        getJobLevel()
        getJobPosition()
        setRefresh(false)
        getJobCategory()
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModalAddJobGrade}
                title={'Add Job Grade'}
                buttonClose={ () => setShowModalAddJobGrade(!showModalAddJobGrade)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Grade Name<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                                <input onChange={ (e) => setJobGrade(e.target.value) } type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Job Grade Name...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Currency<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select onChange={ (e) => setCurrencyType(e.target.value) } className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option value={''} disabled selected>Select Currency</option>
                                        <option value={'Indonesian Rupiah'}>Rp (Indonesia Rupiah)</option>
                                        <option value={'American Dollars'}>$ (American Dollars)</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Range<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input onChange={ (e) => setRange(e.target.value) } type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Range'/>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Minimum Salary<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                                <input onChange={ (e) => setMinimumSalary(e.target.value) } type={'number'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Minimum Salary...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Maximum Salary<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                                <input onChange={ (e) => setMaximumSalary(e.target.value) } type={'number'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Maximum Salary...'/>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalAddJobGrade(!showModalAddJobGrade)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={postJobGrade} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalAddJobLevel}
                title={'Add Job Level'}
                buttonClose={ () => setShowModalAddJobLevel(!showModalAddJobLevel)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Level Name<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input onChange={ (e) => setJobLevelName(e.target.value) } type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Job Level Name...'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Grade<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setJobGradeDropdown(e.target.value) } className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option value={''} selected disabled>Select Job Grade</option>
                                    {Object.values(dataJobGrade).map((data,index) => {
                                        return (
                                            <option value={data.id}>{data.job_grade}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalAddJobLevel(!showModalAddJobLevel)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={postJobLevel} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalAddJobPosition}
                title={'Add Job Position'}
                buttonClose={ () => setShowModalAddJobPosition(!showModalAddJobPosition)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Position Name<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input onChange={ (e) => setJobPositionName(e.target.value) } type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Job Position Name...'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Position ID<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input onChange={ (e) => setIdJobPositionComp(e.target.value) } type={'number'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Job Position ID...'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Grade<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setJobGradeDropdown(e.target.value) } className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected disabled value={''}>Select Job Grade</option>
                                    {Object.values(dataJobGrade).map((data,index) => {
                                        return (
                                            <option value={data.id}>{data.job_grade}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Category<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setJobCategory(e.target.value) } value={jobCategory} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Job Category...</option>
                                    {Object.values(dataJobCategory).map((data,index) => {
                                        return (    
                                            <option key={index} value={data.id}>{data.category_name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Superior Title/ID<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={handleSelectChange} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Superior</option>
                                    {Object.values(dataJobPositionDropdown).map((data,index) => {
                                        return (
                                            <option  key={index} value={data.id}>{data.position_name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        {superiorId.length > 0 && (
                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                {superiorId.map((name, index) => (
                                    <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                        <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                            <h1>{name}</h1>
                                            <button onClick={() => handleRemoveClickedName(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                    <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalAddJobPosition(!showModalAddJobPosition)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={postJobPosition} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalAddJobCategory}
                title={'Add Job Category'}
                buttonClose={ () => setShowModalAddJobCategory(!showModalAddJobCategory)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Category Name<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input onChange={(e) => setCategoryName(e.target.value) } value={categoryName} type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Job Category Name...'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Direct To<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setDirectTo(e.target.value) } className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Direct-to</option>
                                    {/* {Object.values(dataJobCategory).map((item, idx) => (
                                        <option value={item.id}>{item.category_name}</option>

                                    ))} */}
                                    <option value={'Kepala Perwakilan'}>Kepala Perwakilan</option>
                                    <option value={'Kepala Keasistenan Utama'}>Kepala Keasistenan Utama</option>
                                    <option value={'Sekretaris Jenderal'}>Sekretaris Jenderal</option>
                                    <option value={'Asisten Kantor Perwakilan'}>Asisten Kantor Perwakilan</option>
                                    <option value={'Asisten Pusat'}>Asisten Pusat</option>
                                    <option value={'PNS'}>PNS</option>
                                    <option value={'Tenaga Pendukung'}>Tenaga Pendukung</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalAddJobCategory(!showModalAddJobCategory)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={crateJobCategory} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEditJobCategory}
                title={'Edit Job Category'}
                buttonClose={ () => setShowModalEditJobCategory(!showModalEditJobCategory)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Category Name<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input onChange={ (e) => setCategoryName(e.target.value)} value={categoryName} type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Job Position Name...'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Direct To<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setDirectTo(e.target.value) } value={directTo} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Direct-to</option>
                                    <option value={'Anggota Ombudsman'}>Anggota Ombudsman</option>
                                    <option value={'Kepala Perwakilan'}>Kepala Perwakilan</option>
                                    <option value={'Kepala Keasistenan Utama'}>Kepala Keasistenan Utama</option>
                                    <option value={'Sekretaris Jenderal'}>Sekretaris Jenderal</option>
                                    <option value={'Asisten Kantor Perwakilan'}>Asisten Kantor Perwakilan</option>
                                    <option value={'Asisten Pusat'}>Asisten Pusat</option>
                                    <option value={'PNS'}>PNS</option>
                                    <option value={'Tenaga Pendukung'}>Tenaga Pendukung</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalEditJobCategory(!showModalEditJobCategory)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={editJobCategory} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEditJobGrade}
                title={'Edit Job Grade'}
                buttonClose={ () => setShowModalEditJobGrade(!showModalEditJobGrade)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Grade Name<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                                <input value={jobGrade} onChange={ (e) => setJobGrade(e.target.value) } type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Job Grade Name...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Currency<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                                <div className='relative'>
                                    <select value={currencyType} onChange={ (e) => setCurrencyType(e.target.value) } className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                        <option value={''} disabled>Select Currency</option>
                                        <option value={'Indonesian Rupiah'}>Rp (Indonesia Rupiah)</option>
                                        <option value={'American Dollars'}>$ (American Dollars)</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Range<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input value={range} onChange={ (e) => setRange(e.target.value) } type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Range'/>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Minimum Salary<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                                <input value={minimumSalary} onChange={ (e) => setMinimumSalary(e.target.value) } type={'number'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Minimum Salary...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Maximum Salary<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                                <input value={maximumSalary} onChange={ (e) => setMaximumSalary(e.target.value) } type={'number'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Maximum Salary...'/>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalEditJobGrade(!showModalEditJobGrade)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={editJobGrade} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEditJobLevel}
                title={'Edit Job Level'}
                buttonClose={ () => setShowModalEditJobLevel(!showModalEditJobLevel)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Level Name<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input onChange={ (e) => setJobLevelName(e.target.value) } value={jobLevelName} type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder={'Job Level Name...'}/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Grade<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setJobGradeDropdown(e.target.value) } value={jobGradeDropdown} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option value={''} selected disabled>Select Job Grade</option>
                                    {Object.values(dataJobGrade).map((data,index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.job_grade}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalEditJobLevel(!showModalEditJobLevel)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={editJobLevel} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEditJobPosition}
                title={'Edit Job Position'}
                buttonClose={ () => setShowModalEditJobPosition(!showModalEditJobPosition)}
                width={'550px'}
                content= {
                    <div className='space-y-[25px]'>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Position Name<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input value={jobPositionName} onChange={ (e) => setJobPositionName(e.target.value) } type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Job Position Name...'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Position ID<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <input value={idJobPositionComp} onChange={ (e) => setIdJobPositionComp(e.target.value) } type={'number'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full' placeholder='Job Position ID...'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Grade<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setJobGradeDropdown(e.target.value) }  className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option value={''} selected disabled>Select Job Grade</option>
                                    {Object.values(dataJobGrade).map((data,index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.job_grade}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Job Category<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={ (e) => setJobCategory(e.target.value) } className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Job Category...</option>
                                    {Object.values(dataJobCategory).map((data,index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.category_name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Superior Title/ID<span className='text-[#E00101] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <select onChange={handleSelectChange} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                    <option selected value={''}>Select Superior</option>
                                    {Object.values(dataJobPositionDropdown).map((data,index) => {
                                        return (
                                            <option key={index} value={data.id}>{data.position_name}</option>
                                        )
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#40444C] text-xl'/>
                            </div>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalEditJobPosition(!showModalEditJobPosition)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={editJobPosition} className='bg-[#015995] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDeleteJobGrade}
                buttonClose={ () => setShowModalDeleteJobGrade(!showModalDeleteJobGrade)}
                submitButton={deleteJobGrade}
            />
            <ModalDelete
                activeModal={showModalDeleteJobLevel}
                buttonClose={ () => setShowModalDeleteJobLevel(!showModalDeleteJobLevel)}
                submitButton={deleteJobLevel}
            />
            <ModalDelete
                activeModal={showModalDeleteJobPosition}
                buttonClose={ () => setShowModalDeleteJobPosition(!showModalDeleteJobPosition)}
                submitButton={deleteJobPosition}
            />
            <ModalDelete
                activeModal={showModalDeleteJobCategory}
                buttonClose={ () => setShowModalDeleteJobCategory(!showModalDeleteJobCategory)}
                submitButton={deleteJobCategory}
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Job Management'} LinkBack={'/dashboard'}/>
                <div className='space-y-[24px] bg-white border px-[16px] py-[30px] rounded-[12px]'>
                    <div className='border-b-2 flex items-center '>
                        <button onClick={ () => setJobManagement('Job Grade') } className={`${jobManagement === 'Job Grade' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Job Grade</button>
                        <button onClick={ () => setJobManagement('Job Level') } className={`${jobManagement === 'Job Level' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Job Level</button>
                        <button onClick={ () => setJobManagement('Job Category') } className={`${jobManagement === 'Job Category' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Job Category</button>
                        <button onClick={ () => setJobManagement('Job Position') } className={`${jobManagement === 'Job Position' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2' : 'text-[#D1D1D1]'} px-[70px] py-[10px]`}>Job Position</button>
                    </div>
                    <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                        <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                            <div className='w-full lg:w-auto'>
                                <h1 className='text-[#454545] text-[16px] font-[500]'>
                                    {
                                        jobManagement === 'Job Grade' ?
                                        'Job Grades'
                                        : jobManagement === 'Job Level' ?
                                        'Job Level'
                                        : jobManagement === 'Job Position' ? 
                                        'Job Position'
                                        : jobManagement === 'Job Category' ? 
                                        'Job Category'
                                        : null
                                    }
                                </h1>
                                <h1 className='text-[#40444C] text-xs font-[500]'>
                                    {
                                        jobManagement === 'Job Grade' ?
                                        'list of job grade'
                                        : jobManagement === 'Job Level' ?
                                        'list of job level'
                                        : jobManagement === 'Job Position' ?
                                        'list of job position'
                                        : jobManagement === 'Job Category' ?
                                        'list of job category'
                                        : null
                                    }
                                </h1>
                            </div>
                            <div className='flex items-center gap-[13px]'>
                                <button onClick={exportToExcel} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <TbFileExport className='text-[#003049]'/>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                                </button>
                                <Link to={'/job-management/bulk-upload'} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <FaFileUpload className='text-[#003049]'/>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Bulk Upload</h1>
                                </Link>
                                <button onClick={ jobManagement === 'Job Grade' ? () => setShowModalAddJobGrade(!showModalAddJobGrade) : jobManagement === 'Job Level' ? () => setShowModalAddJobLevel(!showModalAddJobLevel) : jobManagement === 'Job Position' ? () => setShowModalAddJobPosition(!showModalAddJobPosition) : () => setShowModalAddJobCategory(!showModalAddJobCategory)} className='bg-[#015995] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                    <BiPlus className='text-white'/>
                                    <h1 className='text-white text-sm font-[500]'>{ jobManagement === 'Job Grade' ? 'Add Job Grade' : jobManagement === 'Job Level' ? 'Add Job Level' : jobManagement === 'Job Position' ? 'Add Job Position' : 'Add Job Category' }</h1>
                                </button>
                            </div>
                        </div>
                        { 
                            jobManagement === 'Job Grade' ?
                            <div>
                                <div className='overflow-auto scrollbar-hide'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Grade Name</h1>
                                                <MdImportExport className='text-[#40444C] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Range</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Currency</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Minimum Salary</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[190px] max-w-[190px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Maximum Salary</h1>
                                            </div>
                                            <div className='flex items-center justify-center gap-[15px] w-full'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Action</h1>
                                            </div>
                                        </div>
                                        {Object.values(dataJobGrade).map((data, index) => {
                                            return (
                                                <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='min-w-[190px] max-w-[190px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.job_grade}</h1>
                                                    </div>
                                                    <div className='min-w-[190px] max-w-[190px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.range}</h1>
                                                    </div>
                                                    <div className='min-w-[190px] max-w-[190px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.currency_type}</h1>
                                                    </div>
                                                    <div className='min-w-[190px] max-w-[190px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.currency_type === 'Indonesian Rupiah' ? 'Rp' + ' ' + data.minimum_salary : '$' + ' ' + data.minimum_salary}</h1>
                                                    </div>
                                                    <div className='min-w-[190px] max-w-[190px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.currency_type === 'Indonesian Rupiah' ? 'Rp' + ' ' + data.maximum_salary : '$' + ' ' + data.maximum_salary}</h1>
                                                    </div>
                                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                                        <button onClick={() => OpenEditJobGrade(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <HiOutlinePencil className='text-[#003049]'/>
                                                        </button>
                                                        <button onClick={() => deleteJobGradeModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <CgTrashEmpty className='text-[#003049]'/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </table>
                                </div>
                                <Pagination 
                                    currentPage={currentPage} 
                                    totalPages={totalPages} 
                                    onPageChange={handlePageChange}
                                    onPrevChange={handlePrevChange}
                                    onNextChange={handleNextChange}
                                />
                            </div>
                            : jobManagement === 'Job Level' ?
                            <div>
                                <div className='overflow-auto scrollbar-hide'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Level Name</h1>
                                                <MdImportExport className='text-[#40444C] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Job Grade</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Action</h1>
                                            </div>
                                        </div>
                                        {Object.values(dataJobLevel).map((data, index) => {
                                            return (
                                                <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.level_name}</h1>
                                                    </div>
                                                    <div className='min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.job_grade.job_grade}</h1>
                                                    </div>
                                                    <div className='min-w-[200px] max-w-[200px] flex items-center gap-[12px]'>
                                                        <button onClick={ () => OpenEditJobLevel(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <HiOutlinePencil className='text-[#003049]'/>
                                                        </button>
                                                        <button onClick={ () => deleteJobLevelModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <CgTrashEmpty className='text-[#003049]'/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </table>
                                </div>
                                <Pagination 
                                    currentPage={currentPageLevel} 
                                    totalPages={totalPagesLevel} 
                                    onPageChange={handlePageChangeLevel}
                                    onPrevChange={handlePrevChangeLevel}
                                    onNextChange={handleNextChangeLevel}
                                />
                            </div>
                            : jobManagement === 'Job Position' ?
                            <div>
                                <div className='overflow-auto scrollbar-hide'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Position Name</h1>
                                                <MdImportExport className='text-[#40444C] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Job Grade</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Action</h1>
                                            </div>
                                        </div>
                                        { dataJobPosition.length === 0 ?
                                        <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500] truncate'>-</h1>
                                            </div>
                                            <div className='min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500] truncate'>-</h1>
                                            </div>
                                        </div>
                                        :
                                        Object.values(dataJobPosition).map((data, index) => {
                                            return (

                                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.position_name}</h1>
                                                    </div>
                                                    <div className='min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.job_grade.job_grade}</h1>
                                                    </div>
                                                    <div className='min-w-[200px] max-w-[200px] flex items-center gap-[12px]'>
                                                        <button onClick={ () => OpenEditJobPosition(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <HiOutlinePencil className='text-[#003049]'/>
                                                        </button>
                                                        <button onClick={ () => deleteJobPositionModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <CgTrashEmpty className='text-[#003049]'/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </table>
                                </div>
                                <Pagination 
                                    currentPage={currentPagePosition} 
                                    totalPages={totalPagesPosition} 
                                    onPageChange={handlePageChangePosition}
                                    onPrevChange={handlePrevChangePosition}
                                    onNextChange={handleNextChangePosition}
                                />
                            </div>
                            : jobManagement === 'Job Category' ? (
                                <div>
                                <div className='overflow-auto scrollbar-hide'>
                                    <table className='w-full space-y-[10px]'>
                                        <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Category Name</h1>
                                                <MdImportExport className='text-[#40444C] text-xs'/>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Direct to</h1>
                                            </div>
                                            <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500]'>Action</h1>
                                            </div>
                                        </div>
                                        { dataJobCategory.length === 0 ?
                                        <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500] truncate'>-</h1>
                                            </div>
                                            <div className='min-w-[300px] max-w-[300px]'>
                                                <h1 className='text-[#40444C] text-xs font-[500] truncate'>-</h1>
                                            </div>
                                        </div>
                                        :
                                        Object.values(dataJobCategory).map((data, index) => {
                                            return (
                                                <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                    <div className='min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.category_name?? '-'}</h1>
                                                    </div>
                                                    <div className='min-w-[300px] max-w-[300px]'>
                                                        <h1 className='text-[#40444C] text-xs font-[500] truncate'>{data.direct_to?? '-'}</h1>
                                                    </div>
                                                    <div className='min-w-[200px] max-w-[200px] flex items-center gap-[12px]'>
                                                        <button onClick={ () => openEditJobCategory(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <HiOutlinePencil className='text-[#003049]'/>
                                                        </button>
                                                        <button onClick={() => deleteJobCategoryModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <CgTrashEmpty className='text-[#003049]'/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </table>
                                </div>
                                <Pagination 
                                    currentPage={currentPagePosition} 
                                    totalPages={totalPagesPosition} 
                                    onPageChange={handlePageChangePosition}
                                    onPrevChange={handlePrevChangePosition}
                                    onNextChange={handleNextChangePosition}
                                />
                            </div>
                            ) : null
                        }
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default JobManagement