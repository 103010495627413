import React, { useEffect, useState } from 'react'
import { Modal, Navbar } from '../../../../components'
import PersonalDetail from './components/PersonalDetail'
import EmergencyContact from './components/EmergencyContact'
import Dependents from './components/Dependents'
import Imigration from './components/Imigration'
import JobDetails from './components/JobDetails'
import Payroll from './components/Payroll'
import ReportTo from './components/ReportTo'
import Qualifications from './components/Qualifications'
import File from './components/File'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import { useLocation } from 'react-router-dom'
import { LogoH } from '../../../../assets'
import moment from 'moment'
import { handleLink } from '../../../../utils/handleLink'
import { toast } from 'react-hot-toast'
import imageHandle from '../../../../utils/imageHandle'
import Training from './components/Training'
import { BiCloudUpload } from 'react-icons/bi'
import { RxCross2 } from 'react-icons/rx'

const MyProfile = () => {
    const [index, setIndex] = useState(0)

    //Emergency Contact Modal
    const [showAddEmergencyContact, setShowAddEmergencyContact] = useState()
    const [showEditEmergencyContact, setShowEditEmergencyContact] = useState()
    //Dependents Modal
    const [showAddDependents, setShowAddDependents] = useState()
    const [showEditDependents, setShowEditDependents] = useState()
    //Training Modal
    const [showAddTraining, setShowAddTraining] = useState()
    const [showEditTraining, setShowEditTraining] = useState()
    //Imigration Modal
    const [showAddImigration, setShowAddImigration] = useState()
    const [showEditImigration, setShowEditImigration] = useState()
    //Report-to Modal
    const [showAddSupervisor, setShowAddSupervisor] = useState()
    const [showEditSupervisor, setShowEditSupervisor] = useState()
    const [showAddSubordinate, setShowAddSubordinate] = useState()
    const [showEditSubordinate, setShowEditSubordinate] = useState()
    //Qualification Modal
    const [showAddWorkExperience, setShowAddWorkExperience] = useState()
    const [showEditWorkExperience, setShowEditWorkExperience] = useState()
    const [showAddEducation, setShowAddEducation] = useState()
    const [showEditEducation, setShowEditEducation] = useState()
    const [showAddSkills, setShowAddSkills] = useState()
    const [showEditSkills, setShowEditSkills] = useState()
    const [showAddLanguages, setShowAddLanguages] = useState()
    const [showEditLanguages, setShowEditLanguages] = useState()
    const [showAddLicense, setShowAddLicense] = useState()
    const [showEditLicense, setShowEditLicense] = useState()
    
    const [dataPersonalDetail, setDataPersonalDetail] = useState('')
    const params = useLocation()
    const [userId, setUserId] = useState('')

    const Fetch = async () => {
        try {
          const response = await Api.Fetch(localStorage.getItem('hris-token'))
          setUserId(response.data.results.user.id)
        } catch (error) {
          console.log(error)
        }
    }

    //State Training

    const [idTraining, setIdTraining] = useState('')
    const [namepath, setNamePath] = useState('')
    const [file, setFile] = useState('')
    const [trainingName, setTrainingName] = useState('')
    const [trainingDate, setTrainingDate] = useState('')
    const [trainingDescription, setTrainingDescription] = useState('')
    const [trainingFile, setTrainingFile] = useState('')
    const [trainingTime, setTrainingTime] = useState('')

    const resetTrainingForm = () => {
        setTrainingName('')
        setTrainingDate('')
        setTrainingDescription('')
        setTrainingFile('')
        setTrainingTime('')
    }

    const openEditTraining = async (id) => {
        setIdTraining(id)
        setShowEditTraining(!showEditTraining)
        try {
            const response = await Api.GetTrainingById(localStorage.getItem('hris-token'), id)
            setTrainingName(response.data.training_name)
            setTrainingDate(response.data.date)
            setTrainingFile(response.data.file)
            setNamePath(response.data.training_name)
            setTrainingDescription(response.data.description)
            setTrainingTime(response.data.time)
        } catch (error) {
            console.log(error)
        }
    }

    const createTraining = async () => {
        const formData = new FormData();
        formData.append('training_name', trainingName);
        formData.append('date', trainingDate);
        formData.append('description', trainingDescription);
        formData.append('file', trainingFile);
        formData.append('time', trainingTime);
        try {
            console.log(formData, 'dataFormData')
            const response = await Api.CreateTraining(localStorage.getItem('hris-token'), formData, userId)
            setRefresh(true)
            resetTrainingForm()
        } catch (error) {
            toast.error('Failed Create Training')
        }
    }

    const updateTraining = async () => {
        const formData = new FormData();
        formData.append('training_name', trainingName);
        formData.append('date', trainingDate);
        formData.append('description', trainingDescription);
        formData.append('file', trainingFile);
        formData.append('time', trainingTime);
        try {
            const response = await Api.UpdateTraining(localStorage.getItem('hris-token'), formData, idTraining)
            toast.success('Success Update Training')
            setRefresh(true)
            resetTrainingForm()
        } catch (error) {
            toast.error('Failed Update Training')
            console.log(error)
        }
    }

    const handleChangeFile = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['png', 'pdf'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize){
            toast.error('file tidak boleh lebih dari 2 MB')
            setTrainingFile(null)
        } else if (!allowedExtensions.includes(fileExtension)){
            toast.error('file harus berformat pdf atau png')
        } else{
            setNamePath(e.target.files[0].name)
            setTrainingFile(e.target.files[0])
        }
    }

    // State Personal Detail
    const [namePersonalDetail, setNamePersonalDetail] = useState('')
    const [imagePersonalDetail, setImagePersonalDetail] = useState('')
    const [jobPositionPersonalDetail, setJobPositionPersonalDetail] = useState('')

    const getDataPersonalDetail = async () => {
        try{
            if(!params.state){
                const response = await Api.GetPersonalDetail(localStorage.getItem('hris-token'))
                setNamePersonalDetail(response.data[0].fullname)
                setImagePersonalDetail(response.data[0].user_detail.image)
                setJobPositionPersonalDetail(response.data[0].user_detail.job_position.position_name)
                console.log(response.data, 'gerPerDet')

            } else {
                const response = await Api.GetPersonalDetailById(localStorage.getItem('hris-token'), params.state.employeeId)
                setNamePersonalDetail(response.data[0].fullname)
                setImagePersonalDetail(response.data[0].user_detail.image)
                setJobPositionPersonalDetail(response.data[0].user_detail.job_position.position_name)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Emergency Contact
    const [nameEmergencyContact, setNameEmergencyContact] = useState()
    const [telephoneEmergencyContact, setTelephoneEmergencyContact] = useState()
    const [mobileEmergencyContact, setMobileEmergencyContact] = useState()
    const [addresEmergencyContact, setAddressEmergencyContact] = useState()
    const [relationshipEmergencyContact, setRelationshipEmergencyContact] = useState()
    const [idEmergencyContact, setIdEmergencyContact] = useState()

    const resetFormEmergencyContact = () => {
        setNameEmergencyContact('')
        setTelephoneEmergencyContact('')
        setMobileEmergencyContact('')
        setAddressEmergencyContact('')
        setRelationshipEmergencyContact('')
        setIdEmergencyContact('')
    }
    
    const editEmergencyContact = async () => {
        try {
            const data = {
                name: nameEmergencyContact,
                telephone: telephoneEmergencyContact,
                mobile: mobileEmergencyContact,
                addres: addresEmergencyContact, 
                relationship: relationshipEmergencyContact
            }
            const response = await Api.UpdateEmergencyContact(localStorage.getItem('hris-token'), data, idEmergencyContact)
            resetFormEmergencyContact()
            setRefresh(true)
            toast.success('Success Edit Emergency Contact')
            setShowEditEmergencyContact(!showEditEmergencyContact)
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')

        }
    }

    const openEditEmergencyContact = async (id) => {
        setShowEditEmergencyContact(!showEditEmergencyContact)
        setIdEmergencyContact(id)
        try{
            const response = await Api.GetEmergencyContactById(localStorage.getItem('hris-token'), id)
            setNameEmergencyContact(response.data[0].name)
            setTelephoneEmergencyContact(response.data[0].telephone)
            setMobileEmergencyContact(response.data[0].mobile)
            setRelationshipEmergencyContact(response.data[0].relationship)
            setAddressEmergencyContact(response.data[0].addres)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }
    
    const postEmergencyContact = async () => {
        try {
            const data = {
                name: nameEmergencyContact,
                telephone: telephoneEmergencyContact,
                mobile: mobileEmergencyContact,
                addres: addresEmergencyContact,
                relationship: relationshipEmergencyContact
            }
            if (!params.state){
                const response = await Api.PostEmergencyContact(localStorage.getItem('hris-token'), data, userId)
                console.log(response)
                resetFormEmergencyContact()
                setRefresh(true)
                toast.success('Success Create Emergency Contact')
                setShowAddEmergencyContact(!showAddEmergencyContact)
            } else {
                const response = await Api.PostEmergencyContact(localStorage.getItem('hris-token'), data, params.state.employeeId)
                console.log(response)
                resetFormEmergencyContact()
                setRefresh(true)
                toast.success('Success Create Emergency Contact')
                setShowAddEmergencyContact(!showAddEmergencyContact)
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    // Dependents
    const [nameDependents, setNameDependents] = useState()
    const [relationshipDependents, setRelationshipDependents] = useState()
    const [birthDateDependents, setBirthDateDependents] = useState()
    const [idDependents, setIdDependents] = useState()
    
    const resetFormDependents = () => {
        setNameDependents('')
        setRelationshipDependents('')
        setBirthDateDependents('')
        setIdDependents('')
    }

    const postDependents = async () => {
        try{
            const data = {
                name: nameDependents,
                relationship: relationshipDependents,
                birthday: birthDateDependents
            } 
            if(!params.state){
                const response = await Api.PostDependents(localStorage.getItem('hris-token'), data, userId)
                resetFormDependents()
                setRefresh(true)
                toast.success('Success Create Dependents')
                setShowAddDependents(!showAddDependents)
            } else {
                const response = await Api.PostDependents(localStorage.getItem('hris-token'), data, params.state.employeeId)
                resetFormDependents()
                setRefresh(true)
                toast.success('Success Create Dependents')
                setShowAddDependents(!showAddDependents)
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const openEditDependents = async (id) => {
        setShowEditDependents(!showEditDependents)
        setIdDependents(id)
        try {
            const response = await Api.GetDependentsById(localStorage.getItem('hris-token'), id)
            setNameDependents(response.data[0].name)
            setRelationshipDependents(response.data[0].relationship)
            setBirthDateDependents(response.data[0].birthday)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const editDependents = async () => {
        try{
            const data = {
                name: nameDependents,
                relationship: relationshipDependents,
                birthday: birthDateDependents
            }
            const response = await Api.UpdateDependents(localStorage.getItem('hris-token'), data, idDependents)
            resetFormDependents()
            setRefresh(true)
            toast.success('Success Edit Dependents')
            setShowEditDependents(!showEditDependents)
        } catch (error) {
            console.log(error)
            toast.error('Error Edit Dependents')
        }
    }



    // Imigration
    const [documentType, setDocumentType] = useState()
    const [number, setNumber] = useState()
    const [issueDateImmigration, setIssueDateImmigration] = useState()
    const [expiryDateImmigration, setExpiryDateImmigration] = useState()
    const [eligibleStatus, setEligibleStatus] = useState()
    const [issuedby, setIssuedby] = useState()
    const [eligileIssueDate, setEligileIssueDate] = useState()
    const [comment, setComment] = useState()
    const [idImmigration, setIdImmigration] = useState()

    const resetFormImigration = () => {
        setDocumentType('')
        setNumber('')
        setIssueDateImmigration('')
        setExpiryDateImmigration('')
        setEligibleStatus('')
        setIssuedby('')
        setEligileIssueDate('')
        setComment('')
        setIdImmigration('')
    }

    const postImmigration = async () => {
        try {
            const data = {
                documentType: documentType,
                number: number,
                issueDate: issueDateImmigration,
                expiryDate: expiryDateImmigration,
                issuedby: issuedby,
                note: comment,
            }
            if(!params.state){
                const response = await Api.PostImmigration(localStorage.getItem('hris-token'), data, userId)
                resetFormImigration()
                setRefresh(true)
                toast.success('Success Create Immigration')
                setShowAddImigration(!showAddImigration)
            } else {
                const response = await Api.PostImmigration(localStorage.getItem('hris-token'), data, params.state.employeeId)
                resetFormImigration()
                setRefresh(true)
                toast.success('Success Create Immigration')
                setShowAddImigration(!showAddImigration)
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create Immigration')
        }
    }

    const openEditImirgration = async (id) => {
        setShowEditImigration(!showEditImigration)
        setIdImmigration(id)
        try {
            const response = await Api.GetImmigrationById(localStorage.getItem('hris-token'), id)
            setDocumentType(response.data[0].documentType)
            setNumber(response.data[0].number)
            setIssueDateImmigration(response.data[0].issueDate)
            setExpiryDateImmigration(response.data[0].expiryDate)
            setEligibleStatus(response.data[0].eligibleStatus)
            setIssuedby(response.data[0].issuedby)
            setEligileIssueDate(response.data[0].eligileIssueDate)
            setComment(response.data[0].note)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const editImmigration = async () => {
        try {
            const data = {
                documentType: documentType,
                number: number,
                issueDate: issueDateImmigration,
                expiryDate: expiryDateImmigration,
                issuedby: issuedby,
                note: comment,
            }
            const response = await Api.UpdateImmigration(localStorage.getItem('hris-token'), data, idImmigration)
            resetFormImigration()
            setRefresh(true)
            toast.success('Success Edit Immigration')
            setShowEditImigration(!showEditImigration)
        } catch (error) {
            console.log(error)
            toast.error('Success Edit Immigration')
        }
    }

    // Report To 
    const [nameSupervisor, setNameSupervisor] = useState('')
    const [reportingMethodSupervisor, setReportingMethodSupervisor] = useState('')
    const [nameSubordinate, setNameSubordinate] = useState('')
    const [reportingMethodSubordinate, setReportingMethodSubordinate] = useState('')
    const [idReportToSupervisor, setIdReportSupervisor] = useState()
    const [dataReportMethod, setDataReportMethod] = useState('')
    const [refresh, setRefresh] = useState(false)

    const resetFormReportTo = () =>{
        setNameSupervisor('')
        setReportingMethodSupervisor('')
    }

    

    const postReportToSupervisor = async () => {
        try {
            const data = {
                // name : nameSupervisor,
                // // report_method : reportingMethodSupervisor
                // report_to_employee: supervisor_employee_id,
                // report_method: report_method_value,
                // structureId: structure_id_value,
                // list_of_report: supervisor
            }
            
            if(!params.state){
                const response = await Api.PostReportToSupervisor(localStorage.getItem('hris-token'), data, userId)
                resetFormReportTo()
                toast.success('Success Create')
                setRefresh(true)
                setShowAddSupervisor(!showAddSupervisor)
            } else {
                const response = await Api.PostReportToSupervisor(localStorage.getItem('hris-token'), data, params.state.employeeId)
                resetFormReportTo()
                setRefresh(true)
                toast.error('Failed Create')
                setShowAddSupervisor(!showAddSupervisor)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const openEditReportToSupervisor = async (id) => {
        setShowEditSupervisor(!showEditSupervisor)
        setIdReportSupervisor(id)
        try {
            const response = await Api.GetReportToSupervisorById(localStorage.getItem('hris-token'), id)
            setNameSupervisor(response.data.name)
            setReportingMethodSupervisor(response.data.report_method.name)
        } catch (error) {
            console.log(error)
        }
    }

    const editSupervisor = async () => {
        try {
            const data = {
                name : nameSupervisor,
                report_method : reportingMethodSupervisor
            }
            const response = await Api.UpdateReportToSupervisor(localStorage.getItem('hris-token'), data, idReportToSupervisor)
            resetFormReportTo()
            setRefresh(true)
            toast.success('Success Edit')
            setShowEditSupervisor(!showEditSupervisor)
        } catch (error) {
            console.log(error)
            toast.error('Failed Edit')
        }
    }

    const getReportMethod = async () => {
        try {
            if(!params.state){
                const response = await Api.GetReportMethod(localStorage.getItem('hris-token'))
                setDataReportMethod(response.data)
            } else {
                const response = await Api.GetReportMethodByParam(localStorage.getItem('hris-token'), params.state.employeeId)
                setDataReportMethod(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // Qualification
    const [companyNameWorkExperience, setCompanyNameWorkExperience] = useState('')
    const [jobTittleeWorkExperience, setJobTittleeWorkExperience] = useState('')
    const [startDateWorkExperience, setStartDateWorkExperience] = useState('')
    const [endDateWorkExperience, setEndDateWorkExperience] = useState('')
    const [commentWorkExperience, setCommentWorkExperience] = useState('')

    const [nameSkill, setNameSkill] = useState('')
    const [yearExperienceSkill, setYearExperienceSkill] = useState('')
    const [commentSkill, setCommentSkill] = useState('')

    const [educationLevel, setEducationLevel] = useState('')
    const [institute, setInstitute] = useState('')
    const [major, setMajor] = useState('')
    const [startYear, setStartYear] = useState('')
    const [endYear, setEndYear] = useState('')
    const [gpa, setGpa] = useState('')

    const [languages, setLanguages] = useState('')
    const [fluency, setFluency] = useState('')
    const [competency, setCompetency] = useState('')
    const [commentLanguages, setCommentLanguages] = useState('')

    const [licenseType, setLicenseType] = useState('')
    const [licenseNumber, setLicenseNumber] = useState('')
    const [issuedDate, setIssuedDate] = useState('')
    const [expiryDate, setExpiryDate] = useState('')

    const [idWorkExperience, setIdWorkExperience] = useState('')
    const [idWorkEducation, setIdWorkEducation] = useState('')
    const [idWorkSkill, setIdWorkSkill] = useState('')
    const [idWorkLisence, setIdWorkLisence] = useState('')
    const [idWWorkLanguage, setIdWorkLanguage] = useState('')

    const resetFormQualification = () => {
        setCompanyNameWorkExperience('')
        setJobTittleeWorkExperience('')
        setStartDateWorkExperience('')
        setEndDateWorkExperience('')
        setCommentWorkExperience('')
        setNameSkill('')
        setYearExperienceSkill('')
        setCommentSkill('')
        setEducationLevel('')
        setInstitute('')
        setMajor('')
        setStartYear('')
        setEndYear('')
        setGpa('')
        setLanguages('')
        setFluency('')
        setCompetency('')
        setCommentLanguages('')
        setLicenseType('')
        setLicenseNumber('')
        setIssuedDate('')
        setExpiryDate('')
        setIdWorkExperience('')
        setIdWorkEducation('')
        setIdWorkSkill('')
        setIdWorkLanguage('')
    }

    const postWorkExperience = async () => {
        try {
            const data = {
                companyName : companyNameWorkExperience,
                jobTitle : jobTittleeWorkExperience,
                comment : commentWorkExperience,
                startDate : startDateWorkExperience,
                endDate : endDateWorkExperience
            }
            if (!params.state){
                const response = await Api.PostWorkExperience(localStorage.getItem('hris-token'), data, userId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddWorkExperience(!showAddWorkExperience)
            } else {
                const response = await Api.PostWorkExperience(localStorage.getItem('hris-token'), data, params.state.employeeId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddWorkExperience(!showAddWorkExperience)
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkExperience = async () => {
        try {
            const data = {
                companyName : companyNameWorkExperience,
                jobTitle : jobTittleeWorkExperience,
                comment : commentWorkExperience,
                startDate : startDateWorkExperience,
                endDate : endDateWorkExperience
            }
            const response = await Api.UpdateWorkExperience(localStorage.getItem('hris-token'), data, idWorkExperience)
            resetFormQualification()
            setRefresh(true)
            toast.success('Success Update !')
            setShowEditWorkExperience(!showEditWorkExperience)
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const openEditWorkExperience = async (id) => {
        setShowEditWorkExperience(!showEditWorkExperience)
        setIdWorkExperience(id)
        try {
            const response = await Api.GetWorkExperienceById(localStorage.getItem('hris-token'), id)
            setCompanyNameWorkExperience(response.data[0].companyName)
            setJobTittleeWorkExperience(response.data[0].jobTitle)
            setStartDateWorkExperience(response.data[0].startDate)
            setEndDateWorkExperience(response.data[0].endDate)
            setCommentWorkExperience(response.data[0].comment)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditSkill = async (id) => {
        setShowEditSkills(!showEditSkills)
        setIdWorkSkill(id)
        try {
            const response = await Api.GetWorkSkillById(localStorage.getItem('hris-token'), id)
            setNameSkill(response.data[0].skill)
            setYearExperienceSkill(response.data[0].yearsOfExperience)
            setCommentSkill(response.data[0].comment)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditWorkEducation = async (id) => {
        setShowEditEducation(!showEditEducation)
        setIdWorkEducation(id)
        try {
            const response = await Api.GetWorkEducationById(localStorage.getItem('hris-token'), id)
            setEducationLevel(response.data[0].level)
            setInstitute(response.data[0].institute)
            setMajor(response.data[0].major)
            setStartYear(moment(response.data[0].startDate).format('YYYY'))
            setEndYear(moment(response.data[0].endDate).format('YYYY'))
            setGpa(response.data[0].gap)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditWorkLanguage = async (id) => {
        setShowEditLanguages(!showEditLanguages)
        setIdWorkLanguage(id)
        try {
            const response = await Api.GetWorkLanguageById(localStorage.getItem('hris-token'), id)
            setLanguages(response.data[0].language)
            setFluency(response.data[0].fluency)
            setCompetency(response.data[0].competency)
            setCommentLanguages(response.data[0].language)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditWorkLicense = async (id) => {
        setShowEditLicense(!showEditLicense)
        setIdWorkLisence(id)
        try {
            const response = await Api.GetWorkLicenseById(localStorage.getItem('hris-token'), id)
            setLicenseType(response.data[0].licenseType)
            setLicenseNumber(response.data[0].licenseNumber)
            setIssuedDate(response.data[0].issuedDate)
            setExpiryDate(response.data[0].expiryDate)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const postWorkEducation = async () => {
        try {
            const data = {
                level : educationLevel,
                institute : institute,
                major : major,
                start_year : startYear,
                end_year : endYear,
                gap : gpa,
            }
            if (!params.state){
                const response = await Api.PostWorkEducation(localStorage.getItem('hris-token'), data, userId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddEducation(!showAddEducation)
            } else {
                const response = await Api.PostWorkEducation(localStorage.getItem('hris-token'), data, params.state.employeeId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddEducation(!showAddEducation)
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkEducation = async () => {
        try {
            const data = {
                level : educationLevel,
                institute : institute,
                major : major,
                start_year : startYear,
                end_year : endYear,
                gap : gpa,
            }
            const response = await Api.UpdateWorkEducation(localStorage.getItem('hris-token'), data, idWorkEducation)
            resetFormQualification()
            setRefresh(true)
            setShowEditEducation(!showEditEducation)
            toast.success('Success Update !')
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const postWorkSkill = async () => {
        try {
            const data = {
                skill : nameSkill,
                yearsOfExperience : yearExperienceSkill,
                comment : commentSkill
            }

            if(!params.state){
                const response = await Api.PostWorkSkill(localStorage.getItem('hris-token'), data, userId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddSkills(!showAddSkills)
            } else{
                const response = await Api.PostWorkSkill(localStorage.getItem('hris-token'), data, params.state.employeeId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddSkills(!showAddSkills)
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkSkill = async () => {
        try {
            const data = {
                skill : nameSkill,
                yearsOfExperience : yearExperienceSkill,
                comment : commentSkill
            }
            const response = await Api.UpdateWorkSkill(localStorage.getItem('hris-token'), data, idWorkSkill)
            resetFormQualification()
            setRefresh(true)
            setShowEditSkills(!showEditSkills)
            toast.success('Success Update !')
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const postWorkLanguage = async () => {
        try {
            const data = {
                language : languages,
                fluency : fluency,
                competency : competency,
                comment : commentLanguages
            }
            if (!params.state){
                const response = await Api.PostWorkLanguage(localStorage.getItem('hris-token'), data, userId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddLanguages(!showAddLanguages)
            } else{
                const response = await Api.PostWorkLanguage(localStorage.getItem('hris-token'), data, params.state.employeeId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddLanguages(!showAddLanguages)
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkLanguage = async () => {
        try {
            const data = {
                language : languages,
                fluency : fluency,
                competency : competency,
                comment : commentLanguages
            }
            const response = await Api.UpdateWorkLanguage(localStorage.getItem('hris-token'), data, idWWorkLanguage)
            resetFormQualification()
            setRefresh(true)
            setShowEditLanguages(!showEditLanguages)
            toast.success('Success Update !')
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const postWorkLicense = async () => {
        try {
            const data = {
                licenseType : licenseType,
                licenseNumber : licenseNumber,
                issuedDate : issuedDate,
                expiryDate :expiryDate
            }
            if(!params.state){
                const response = await Api.PostWorkLicense(localStorage.getItem('hris-token'), data, userId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddLicense(!showAddLicense)
            } else {
                const response = await Api.PostWorkLicense(localStorage.getItem('hris-token'), data, params.state.employeeId)
                resetFormQualification()
                setRefresh(true)
                toast.success('Success Create!')
                setShowAddLicense(!showAddLicense)
            }
        } catch (error) {
            console.log(error)
            toast.error('Failed Create!')
        }
    }

    const editWorkLisence = async () => {
        try {
            const data = {
                licenseType : licenseType,
                licenseNumber : licenseNumber,
                issuedDate : issuedDate,
                expiryDate :expiryDate
            }
            const response = await Api.UpdateWorkLicense(localStorage.getItem('hris-token'), data, idWorkLisence)
            resetFormQualification()
            setRefresh(true)
            setShowEditLicense(!showEditLicense)
            toast.success('Success Update !')
            
        } catch (error) {
            console.log(error)
            toast.error('Failed Update !')
        }
    }

    const [viewImage, setViewImage] = useState()

    const handleViewImage = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize){
            toast.error('Gambar tidak boleh lebih dari 2 MB !')
        } else if (!allowedExtensions.includes(fileExtension)){
            toast.error('File harus bertipe jpg, jpeg, atau png !')
        } else {
            setViewImage(URL.createObjectURL(e.target.files[0]))
            const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                updateProfilePersonalDetail(reader.result)
            };
        }
    }

    const updateProfilePersonalDetail = async (imageUploaded) => {
        try { 
            const data = {
                image : imageUploaded
            }
            if (!params.state){
            console.log(data)
            const response = await Api.PostPersonalDetail(localStorage.getItem('hris-token'), data, userId)
            toast.success('Success Update Gambar')
            setRefresh(true)
            console.log(response)
          } else {
            console.log(data)
            const response = await Api.PostPersonalDetail(localStorage.getItem('hris-token'), data, params.state.employeeId)
            toast.success('Success Update Gambar')
            setRefresh(true)
            console.log(response)
          }
        } catch (error) {
          console.log(error)
          toast.error(error.message)
        }
    }


    useEffect(() => {
        Fetch()
        getDataPersonalDetail()
        getReportMethod()
        setRefresh(false)
    }, [refresh])

  return (
    <div>
        {/* Modal */}
        <div>

            <Modal 
                activeModal={showAddTraining}
                title={'Add Training'}
                buttonClose={ () => setShowAddTraining(!showAddTraining)}
                width={'558px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className='w-full'>
                                <h1 className='text-[12px] text-[#6B7280] mb-[9px]'>Training Name <span className='text-[#E00101]'>*</span></h1>
                            <input type="text" onChange={(e) => setTrainingName(e.target.value)} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#6B7280] w-full' placeholder='Input Training Name...'/>
                        </div>
                        <div className="flex gap-[14px]">
                            <div className='w-full'>
                                <h2 className='text-[#6B7280] text-[12px] font-medium mb-[9px]'>Date <span className='text-[#E00101]'>*</span></h2>
                                <input type="date" onChange={(e) => setTrainingDate(e.target.value)} className='border-[#CACACA] pr-2 rounded-md py-[10px] pl-[21.74px] text-[#6B7280] w-full text-[12px] mb-[20.5px] border'/>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#6B7280] text-[12px] font-medium mb-[9px]'>Time of Training (hours) <span className='text-[#E00101]'>*</span></h2>
                                <input type="time" onChange={(e) => setTrainingTime(e.target.value)} className='border-[#CACACA] pr-2 rounded-md py-[10px] pl-[21.74px] text-[#6B7280] w-full text-[12px] mb-[20.5px] border'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#6B7280] mb-[9px]'>File <span className='text-[#E00101]'>*</span></h1>
                            
                            <div className='flex items-center gap-[13px] w-full mb-[12px]'>
                                <label htmlFor='upload-file' className='cursor-pointer w-full'>
                                    <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full space-y-[4px]'>
                                        <div className='flex items-center justify-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" fill="none">
                                                <path d="M5.45866 15.3337C4.06533 15.3337 2.87671 14.8539 1.89283 13.8945C0.908936 12.9289 0.416992 11.7525 0.416992 10.3653C0.416992 9.17366 0.774492 8.11033 1.48949 7.17533C2.2106 6.24033 3.15171 5.64449 4.31283 5.38783C4.69783 3.98227 5.46171 2.8456 6.60449 1.97783C7.75338 1.10394 9.05199 0.666992 10.5003 0.666992C12.2909 0.666992 13.8064 1.29033 15.047 2.53699C16.2937 3.77755 16.917 5.2931 16.917 7.08366C17.9742 7.20588 18.8481 7.66421 19.5387 8.45866C20.2353 9.24088 20.5837 10.1575 20.5837 11.2087C20.5837 12.3575 20.1834 13.3323 19.3828 14.1328C18.5823 14.9334 17.6075 15.3337 16.4587 15.3337H11.417C10.9159 15.3337 10.485 15.1534 10.1245 14.7928C9.76394 14.4384 9.58366 14.0075 9.58366 13.5003V8.77949L8.11699 10.2003L6.83366 8.91699L10.5003 5.25033L14.167 8.91699L12.8837 10.2003L11.417 8.77949V13.5003H16.4587C17.1003 13.5003 17.6412 13.2773 18.0812 12.8312C18.5273 12.3912 18.7503 11.8503 18.7503 11.2087C18.7503 10.567 18.5273 10.0262 18.0812 9.58616C17.6412 9.14005 17.1003 8.91699 16.4587 8.91699H15.0837V7.08366C15.0837 5.81866 14.6375 4.73699 13.7453 3.83866C12.8531 2.94644 11.7714 2.50033 10.5003 2.50033C9.23532 2.50033 8.15366 2.94644 7.25533 3.83866C6.3631 4.73699 5.91699 5.81866 5.91699 7.08366H5.45866C4.57255 7.08366 3.81783 7.39838 3.19449 8.02783C2.56505 8.65116 2.25033 9.40588 2.25033 10.292C2.25033 11.1781 2.56505 11.942 3.19449 12.5837C3.81783 13.1948 4.57255 13.5003 5.45866 13.5003H7.75033V15.3337" fill="#6B7280"/>
                                            </svg>
                                        </div>
                                        <h1 className="text-red-600 text-xs font-normal">Upload File Here</h1>
                                        <h2 className="text-center text-gray-500 text-[8px]">PDF max 200 kB or PNG max 2 MB</h2>
                                    </div>
                                    <input type='file' id='upload-file' accept=".pdf, .png" onChange={(e) => handleChangeFile(e)}  className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required/>
                                </label>
                            </div>

                            {(namepath && trainingFile) && 
                                <div className='py-[6px] px-[8px] bg-[#D8DBDF] rounded flex gap-[8px] justify-between w-fit'>
                                    <h1 className='text-[10px] text-[#737373] truncate'>{namepath}</h1>
                                    <button onClick={() => setTrainingFile('')}> <RxCross2 className='text-sm'/></button>
                                </div>
                            }
                        </div>
                        <div className='w-full'>
                                <h1 className='text-[12px] text-[#6B7280] mb-[9px]'>Description</h1>
                            <textarea onChange={(e) => setTrainingDescription(e.target.value)} rows={4} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#6B7280] w-full resize-none' placeholder='Input Description...'/>
                        </div>
                        <div className="flex gap-[10px] items-end  justify-end">
                            <button  className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={createTraining} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditTraining}
                title={'Edit Training'}
                buttonClose={ () => setShowEditTraining(!showEditTraining)}
                width={'558px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className='w-full'>
                                <h1 className='text-[12px] text-[#6B7280] mb-[9px]'>Training Name <span className='text-[#E00101]'>*</span></h1>
                            <input type="text" value={trainingName} onChange={(e) => setTrainingName(e.target.value)} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#6B7280] w-full' placeholder='Input Training Name...'/>
                        </div>
                        <div className="flex gap-[14px]">
                            <div className='w-full'>
                                <h2 className='text-[#6B7280] text-[12px] font-medium mb-[9px]'>Date <span className='text-[#E00101]'>*</span></h2>
                                <input  value={moment(trainingDate).format('YYYY-MM-DD')} type="date" onChange={(e) => setTrainingDate(e.target.value)} className='border-[#CACACA] pr-2 rounded-md py-[10px] pl-[21.74px] text-[#6B7280] w-full text-[12px] mb-[20.5px] border'/>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#6B7280] text-[12px] font-medium mb-[9px]'>Time of Training (hours) <span className='text-[#E00101]'>*</span></h2>
                                <input  value={trainingTime} type="time" onChange={(e) => setTrainingTime(e.target.value)} className='border-[#CACACA] pr-2 rounded-md py-[10px] pl-[21.74px] text-[#6B7280] w-full text-[12px] mb-[20.5px] border'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#6B7280] mb-[9px]'>File <span className='text-[#E00101]'>*</span></h1>
                            
                            <div className='flex items-center gap-[13px] w-full mb-[12px]'>
                                <label htmlFor='upload-file' className='cursor-pointer w-full'>
                                    <div className='border shadow-sm rounded-lg relative text-center justify-center px-[70px] py-[11px] w-full space-y-[4px]'>
                                        <div className='flex items-center justify-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" fill="none">
                                                <path d="M5.45866 15.3337C4.06533 15.3337 2.87671 14.8539 1.89283 13.8945C0.908936 12.9289 0.416992 11.7525 0.416992 10.3653C0.416992 9.17366 0.774492 8.11033 1.48949 7.17533C2.2106 6.24033 3.15171 5.64449 4.31283 5.38783C4.69783 3.98227 5.46171 2.8456 6.60449 1.97783C7.75338 1.10394 9.05199 0.666992 10.5003 0.666992C12.2909 0.666992 13.8064 1.29033 15.047 2.53699C16.2937 3.77755 16.917 5.2931 16.917 7.08366C17.9742 7.20588 18.8481 7.66421 19.5387 8.45866C20.2353 9.24088 20.5837 10.1575 20.5837 11.2087C20.5837 12.3575 20.1834 13.3323 19.3828 14.1328C18.5823 14.9334 17.6075 15.3337 16.4587 15.3337H11.417C10.9159 15.3337 10.485 15.1534 10.1245 14.7928C9.76394 14.4384 9.58366 14.0075 9.58366 13.5003V8.77949L8.11699 10.2003L6.83366 8.91699L10.5003 5.25033L14.167 8.91699L12.8837 10.2003L11.417 8.77949V13.5003H16.4587C17.1003 13.5003 17.6412 13.2773 18.0812 12.8312C18.5273 12.3912 18.7503 11.8503 18.7503 11.2087C18.7503 10.567 18.5273 10.0262 18.0812 9.58616C17.6412 9.14005 17.1003 8.91699 16.4587 8.91699H15.0837V7.08366C15.0837 5.81866 14.6375 4.73699 13.7453 3.83866C12.8531 2.94644 11.7714 2.50033 10.5003 2.50033C9.23532 2.50033 8.15366 2.94644 7.25533 3.83866C6.3631 4.73699 5.91699 5.81866 5.91699 7.08366H5.45866C4.57255 7.08366 3.81783 7.39838 3.19449 8.02783C2.56505 8.65116 2.25033 9.40588 2.25033 10.292C2.25033 11.1781 2.56505 11.942 3.19449 12.5837C3.81783 13.1948 4.57255 13.5003 5.45866 13.5003H7.75033V15.3337" fill="#6B7280"/>
                                            </svg>
                                        </div>
                                        <h1 className="text-red-600 text-xs font-normal">Upload File Here</h1>
                                        <h2 className="text-center text-gray-500 text-[8px]">PDF max 200 kB or PNG max 2 MB</h2>
                                    </div>
                                    <input type='file' id='upload-file' accept=".pdf, .png" onChange={(e) => handleChangeFile(e)} className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required/>
                                </label>
                            </div>

                            {(namepath && trainingFile) && 
                                <div className='py-[6px] px-[8px] bg-[#D8DBDF] rounded flex gap-[8px] justify-between w-fit'>
                                    <h1 className='text-[10px] text-[#737373] truncate'>{namepath}</h1>
                                    <button onClick={() => setTrainingFile('')}> <RxCross2 className='text-sm'/></button>
                                </div>
                            }
                        </div>
                        <div className='w-full'>
                                <h1 className='text-[12px] text-[#6B7280] mb-[9px]'>Description</h1>
                            <textarea value={trainingDescription} onChange={(e) => setTrainingDescription(e.target.value)} rows={4} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#6B7280] w-full resize-none' placeholder='Input Description...'/>
                        </div>
                        <div className="flex gap-[10px] items-end  justify-end">
                            <button onClick={() => setShowEditTraining(!showEditTraining)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={updateTraining} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                }
            />

            <Modal 
                activeModal={showAddDependents}
                title={'Add Dependents'}
                buttonClose={ () => setShowAddDependents(!showAddDependents)}
                width={'558px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className="flex  gap-[14px]">
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Name</h1>
                                <input onChange={(e) => setNameDependents(e.target.value)} value={nameDependents} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Contact Name...'/>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Relationship</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setRelationshipDependents(e.target.value)} value={relationshipDependents} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option selected disabled={''}>Select Relationship</option>
                                        <option value={'Child'}>Child</option>
                                        <option value={'Other'}>Other</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-[14px]">
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Birth of Date</h2>
                                <input onChange={(e) => setBirthDateDependents(e.target.value)} value={birthDateDependents} type="date" className='border-[#CACACA] pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px] border'/>
                            </div>
                        </div>
                        <div className="flex gap-[10px] items-end  justify-end">
                            <button onClick={() => setShowAddDependents(!showAddDependents)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postDependents} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditDependents}
                title={'Edit Dependents'}
                buttonClose={ () => setShowEditDependents(!showEditDependents)}
                width={'558px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className="flex  gap-[14px]">
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Name</h1>
                                <input value={nameDependents} onChange={(e) => setNameDependents(e.target.value)} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Dependents name...'/>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Relationship</h2>
                                <div className='relative'>
                                    <select value={relationshipDependents} onChange={(e) => setRelationshipDependents(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select Relationship</option>
                                        <option value={'Child'}>Child</option>
                                        <option value={'Other'}>Other</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Birth of Date</h2>
                            <input onChange={(e) => setBirthDateDependents(e.target.value)} value={moment(birthDateDependents).format('YYYY-MM-DD')} type="date" className='border pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px]'/>
                        </div>
                        <div className="flex gap-[10px] items-end  justify-end ">
                            <button onClick={() => setShowEditDependents(!showEditDependents)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={editDependents} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                }
            />

            <Modal 
                activeModal={showAddEmergencyContact}
                title={'Add Emergency Contact'}
                buttonClose={ () => setShowAddEmergencyContact(!showAddEmergencyContact)}
                width={'558px'}
                content= {
                    <div className='space-y-[20px]'>
                        <div className="flex gap-[14px]">
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Name</h1>
                                <input onChange={(e) => setNameEmergencyContact(e.target.value)} value={nameEmergencyContact} type="text" className='py-[10px] border w-full rounded-md pl-[13px] pr-2 text-[11px] text-[#737373]' placeholder='Contact Name...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Relationship</h1>
                                <input  onChange={(e) => setRelationshipEmergencyContact(e.target.value)} value={relationshipEmergencyContact} type="text" className='py-[10px] border w-full rounded-md pl-[13px] pr-2 text-[11px] text-[#737373]' placeholder='Relationship...' />
                            </div>
                        </div>
                        <div className="flex gap-[14px]">
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Telephone</h1>
                                <input  onChange={(e) => setTelephoneEmergencyContact(e.target.value)} value={telephoneEmergencyContact} type="number" className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373]' placeholder='Telephone...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Mobile</h1>
                                <input  onChange={(e) => setMobileEmergencyContact(e.target.value)} value={mobileEmergencyContact} type="number" className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373]' placeholder='Mobile...' />
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373]'>Address</h1>
                            <textarea rows={2}  onChange={(e) => setAddressEmergencyContact(e.target.value)} value={addresEmergencyContact} className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] resize-none' placeholder='Address...' />
                        </div>

                        <div className="flex gap-[10px] justify-end ">
                            <button onClick={() => setShowAddEmergencyContact(!showAddEmergencyContact)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postEmergencyContact} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditEmergencyContact}
                title={'Edit Emergency Contact'}
                buttonClose={ () => setShowEditEmergencyContact(!showEditEmergencyContact)}
                width={'558px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className="flex gap-[14px]">
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Name</h1>
                                <input value={nameEmergencyContact} onChange={(e) => setNameEmergencyContact(e.target.value)} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Contact Name...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Relationship</h1>
                                <input value={relationshipEmergencyContact} onChange={(e) => setRelationshipEmergencyContact(e.target.value)} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Relationship...' />
                            </div>
                        </div>
                        <div className="flex gap-[14px]">
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Telephone</h1>
                                <input value={telephoneEmergencyContact} onChange={(e) => setTelephoneEmergencyContact(e.target.value)} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Mobile</h1>
                                <input value={mobileEmergencyContact} onChange={(e) => setMobileEmergencyContact(e.target.value)} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' />
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373]'>Address</h1>
                            <textarea rows={2} value={addresEmergencyContact}  onChange={(e) => setAddressEmergencyContact(e.target.value)}  className='py-[10px] border w-full rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] resize-none' placeholder='Address...' />
                        </div>
                        <div className="flex gap-[10px] items-end  justify-end ">
                            <button onClick={() => setShowEditEmergencyContact(!showEditEmergencyContact)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={editEmergencyContact} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showAddImigration}
                title={'Add Migration'}
                buttonClose={ () => setShowAddImigration(!showAddImigration)}
                width={'558px'}
                content= {
                    <div className='space-y-[24px] w-[full]'>
                        <div className='flex gap-[44px]'>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Document</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setDocumentType(e.target.value)} value={documentType} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select Document</option>
                                        <option value={'Visa'}>Visa</option>
                                        <option value={'Passport'}>Passport</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Number</h2>
                                <input onChange={(e) => setNumber(e.target.value)} value={number} type="number" className='py-[10px] pl-[13px] pr-2 rounded-md text-[11px] text-[#A8A8A8] w-full border'placeholder='Document Number...'/>
                            </div>
                        </div>
                        <div className='flex gap-[44px] w-full'>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Issued Date</h2>
                                <input onChange={(e) => setIssueDateImmigration(e.target.value)} value={issueDateImmigration} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name'/>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Expiry Date</h2>
                                <input onChange={(e) => setExpiryDateImmigration(e.target.value)} value={expiryDateImmigration} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name'/>
                            </div>
                        </div>
                        <div className='flex gap-[44px] w-full'>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Issued by</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setIssuedby(e.target.value)} value={issuedby} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select Country</option>
                                        <option value={'Indonesia'}>Indonesia</option>
                                        <option value={'Inggris'}>Inggris</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h2 className='text-[#737373] text-[12px] mb-[12px]'>Note</h2>
                            <textarea onChange={(e) => setComment(e.target.value)} type="date" value={comment} className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px] border appearance-none resize-none' rows={4}/>
                        </div>
                        <div className="flex gap-[10px] items-end justify-end">
                            <button onClick={() => setShowAddImigration(!showAddImigration)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={postImmigration} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditImigration}
                title={'Edit Migration'}
                buttonClose={ () => setShowEditImigration(!showEditImigration)}
                width={'558px'}
                content= {
                    <div className='space-y-[24px] w-[full]'>
                        <div className='flex gap-[44px]'>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Document</h2>
                                <div className='relative'>
                                    <select value={documentType} onChange={(e) => setDocumentType(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select Document</option>
                                        <option value={'Visa'}>Visa</option>
                                        <option value={'Passport'}>Passport</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Number</h2>
                                <input value={number} onChange={(e) => setNumber(e.target.value)} type="number" className='py-[10px] pl-[13px] pr-2 rounded-md text-[11px] text-[#A8A8A8] w-full border'placeholder='Document Number...'/>
                            </div>
                        </div>
                        <div className='flex gap-[44px]'>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Issued Date</h2>
                                <input value={moment(issueDateImmigration).format('YYYY-MM-DD')} onChange={(e) => setIssueDateImmigration(e.target.value)} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name'/>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Expiry Date</h2>
                                <input value={moment(expiryDateImmigration).format('YYYY-MM-DD')} onChange={(e) => setExpiryDateImmigration(e.target.value)} type="date" className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px] border ' placeholder='Full Name'/>
                            </div>
                        </div>
                        <div className='flex gap-[44px]'>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] mb-[12px]'>Issued by</h2>
                                <div className='relative'>
                                    <select value={issuedby} onChange={(e) => setIssuedby(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>Select Country</option>
                                        <option value={'Indonesia'}>Indonesia</option>
                                        <option value={'English'}>English</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div> 
                        </div>
                        <div className='w-full'>
                            <h2 className='text-[#737373] text-[12px] mb-[12px]'>Note</h2>
                            <textarea value={comment} onChange={(e) => setComment(e.target.value)} className=' pr-2 rounded-md py-[10px] pl-[21.74px] text-[#737373] w-full text-[12px] mb-[20.5px] border appearance-none resize-none' rows={4}/>
                        </div>
                        <div className="flex gap-[10px] items-end justify-end">
                            <button onClick={() => setShowEditImigration(!setShowEditImigration)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={editImmigration} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showAddSupervisor}
                title={'Add Supervisor'}
                buttonClose={ () => setShowAddSupervisor(!showAddSupervisor)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className="flex  gap-[14px]">
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Name *</h1>
                                <input onChange={(e) => setNameSupervisor(e.target.value)} value={nameSupervisor} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Type for hints...'/>
                            </div>
                            {/* <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Structure ID *</h1>
                                <input  type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                            </div> */}
                            
                        </div>
                        <div className="flex gap-[14px]">
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Reporting Method *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setReportingMethodSupervisor(e.target.value)} value={reportingMethodSupervisor} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>-- Select --</option>
                                        {Object.values(dataReportMethod).map((item) =>(
                                            <option value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddSupervisor(!showAddSupervisor)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={postReportToSupervisor} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditSupervisor}
                title={'Edit Supervisor'}
                buttonClose={ () => setShowEditSupervisor(!showEditSupervisor)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className="flex  gap-[14px]">
                            <div className='w-full'>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Name *</h1>
                                <input type="text" onChange={(e) => setNameSupervisor(e.target.value)} value={nameSupervisor} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Type for hints...'/>
                            </div>
                            {/* <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Structure ID *</h1>
                                <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]'/>
                            </div> */}
                            
                        </div>
                        <div className="flex gap-[14px]">
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Reporting Method *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setReportingMethodSupervisor(e.target.value)} value={reportingMethodSupervisor} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                        <option selected value={''}>-- Select --</option>
                                        {Object.values(dataReportMethod).map((item) =>(
                                            <option value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowEditSupervisor(!showEditSupervisor)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editSupervisor} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showAddSubordinate}
                title={'Add Subordinate'}
                buttonClose={ () => setShowAddSubordinate(!showAddSubordinate)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className="flex  gap-[14px]">
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Name *</h1>
                                <input onChange={(e) => setNameSubordinate(e.target.value)} value={nameSubordinate} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]' placeholder='Type for hints...'/>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Structure ID *</h1>
                                <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]'/>
                            </div>
                            
                        </div>
                        <div className="flex gap-[14px]">
                            <div>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Reporting Method *</h2>
                                <div className='relative'>
                                    <select onChange={(e) => setReportingMethodSubordinate(e.target.value)} value={reportingMethodSubordinate} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[252px] appearance-none'>
                                        <option selected value={''}>-- Select --</option>
                                        <option value={''}></option>
                                        <option value={''}></option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddSubordinate(!showAddSubordinate)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditSubordinate}
                title={'Edit Subordinate'}
                buttonClose={ () => setShowEditSubordinate(!showEditSubordinate)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className="flex  gap-[14px]">
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Name *</h1>
                                <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]' placeholder='Type for hints...'/>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Structure ID *</h1>
                                <input type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]'/>
                            </div>
                            
                        </div>
                        <div className="flex gap-[14px]">
                            <div>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Reporting Method *</h2>
                                <div className='relative'>
                                    <select value={''} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[252px] appearance-none'>
                                        <option selected value={''}>-- Select --</option>
                                        <option value={''}></option>
                                        <option value={''}></option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                                </div>
                            </div>
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={ () => setShowEditSubordinate(!showEditSubordinate)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />

            <Modal 
                activeModal={showAddEducation}
                title={'Add Educations'}
                buttonClose={ () => setShowAddEducation(!showAddEducation)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Education Level *</h1>
                            <input onChange={(e) => setEducationLevel(e.target.value)} value={educationLevel} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Ex : Senior High School'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Institute</h1>
                            <input onChange={(e) => setInstitute(e.target.value)} value={institute} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Institute name...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Major/Specialization</h1>
                            <input onChange={(e) => setMajor(e.target.value)} value={major} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Specialization in Education....'/>
                        </div>
                        <div className="flex gap-[25px]">
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Start Year *</h2>
                                <input onChange={(e) => setStartYear(e.target.value)} value={startYear} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>End Year *</h2>
                                <input onChange={(e) => setEndYear(e.target.value)} value={endYear} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>GPA *</h1>
                            <input onChange={(e) => setGpa(e.target.value)} value={gpa} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-1/2' placeholder='ex : 3.88 or 90.75'/>
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddEducation(!showAddEducation)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={postWorkEducation} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditEducation}
                title={'Edit Educations'}
                buttonClose={ () => setShowEditEducation(!showEditEducation)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Education Level *</h1>
                            <input onChange={(e) => setEducationLevel(e.target.value)} value={educationLevel} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Ex : Senior High School'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Institute</h1>
                            <input onChange={(e) => setInstitute(e.target.value)} value={institute} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Institute name...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Major/Specialization</h1>
                            <input onChange={(e) => setMajor(e.target.value)} value={major} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Specialization in Education....'/>
                        </div>
                        <div className="flex gap-[25px]">
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Start Year *</h2>
                                <input onChange={(e) => setStartYear(e.target.value)} value={moment(startYear).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                            </div>
                            <div className='w-full'>
                                <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>End Year *</h2>
                                <input onChange={(e) => setEndYear(e.target.value)} value={moment(endYear).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>GPA *</h1>
                            <input onChange={(e) => setGpa(e.target.value)} value={gpa} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-1/2' placeholder='ex : 3.88 or 90.75'/>
                        </div>
                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowEditEducation(!showEditEducation)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editWorkEducation} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showAddWorkExperience}
                title={'Add Work Experience'}
                buttonClose={ () => setShowAddWorkExperience(!showAddWorkExperience)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Company Name *</h1>
                            <input onChange={(e) => setCompanyNameWorkExperience(e.target.value)} value={companyNameWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Company name...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Job title*</h1>
                            <input onChange={(e) => setJobTittleeWorkExperience(e.target.value)} value={jobTittleeWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Job title...'/>
                        </div>
                        <div className="flex gap-[25px]">
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Start Date *</h1>
                                <input onChange={(e) => setStartDateWorkExperience(e.target.value)} value={startDateWorkExperience} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]' placeholder='Ex : Senior High School'/>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>End Date *</h1>
                                <input onChange={(e) => setEndDateWorkExperience(e.target.value)} type="date" value={endDateWorkExperience} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]' placeholder='Ex : Senior High School'/>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Comment</h1>
                            <textarea onChange={(e) => setCommentWorkExperience(e.target.value)} value={commentWorkExperience} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full resize-none' rows={3} placeholder='Your Comment...'/>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddWorkExperience(!showAddWorkExperience)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={postWorkExperience} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditWorkExperience}
                title={'Edit Work Experience'}
                buttonClose={ () => setShowEditWorkExperience(!showEditWorkExperience)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Comany Name *</h1>
                            <input onChange={(e) => setCompanyNameWorkExperience(e.target.value)} value={companyNameWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Company name...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Job title*</h1>
                            <input onChange={(e) => setJobTittleeWorkExperience(e.target.value)} value={jobTittleeWorkExperience} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Job title...'/>
                        </div>
                        <div className="flex gap-[25px]">
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Start Date *</h1>
                                <input onChange={(e) => setStartDateWorkExperience(e.target.value)} value={moment(startDateWorkExperience).format('YYYY-MM-DD') } type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]' placeholder='Ex : Senior High School'/>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>End Date *</h1>
                                <input onChange={(e) => setEndDateWorkExperience(e.target.value)} value={moment(endDateWorkExperience).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]' placeholder='Ex : Senior High School'/>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Comment</h1>
                            <textarea onChange={(e) => setCommentWorkExperience(e.target.value)} value={commentWorkExperience} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full resize-none' rows={3} placeholder='Your Comment...'/>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowEditWorkExperience(!showEditWorkExperience)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editWorkExperience} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showAddSkills}
                title={'Add Skill'}
                buttonClose={ () => setShowAddSkills(!showAddSkills)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Skill Name *</h1>
                            <input onChange={(e) => setNameSkill(e.target.value)} value={nameSkill} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Skill name...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Year of Experience</h1>
                            <input onChange={(e) => setYearExperienceSkill(e.target.value)} value={yearExperienceSkill} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Year of Experience...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Comment</h1>
                            <textarea onChange={(e) => setCommentSkill(e.target.value)} value={commentSkill} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full resize-none' rows={3} placeholder='Your Comment...'/>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={ () => setShowAddSkills(!showAddSkills)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={postWorkSkill} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditSkills}
                title={'Edit Skill'}
                buttonClose={ () => setShowEditSkills(!showEditSkills)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Skill Name *</h1>
                            <input onChange={(e) => setNameSkill(e.target.value)} value={nameSkill} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Skill name...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Year of Experience</h1>
                            <input onChange={(e) => setYearExperienceSkill(e.target.value)} value={yearExperienceSkill} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Year of Experience...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Comment</h1>
                            <textarea onChange={(e) => setCommentSkill(e.target.value)} value={commentSkill} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full resize-none' rows={3} placeholder='Your Comment...'/>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowEditSkills(!showEditSkills)}  className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editWorkSkill} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showAddLanguages}
                title={'Add Language'}
                buttonClose={ () => setShowAddLanguages(!showAddLanguages)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Language *</h1>
                            <input onChange={(e) => setLanguages(e.target.value)} value={languages} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Languages...'/>
                        </div>
                        <div>
                            <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Fluency *</h2>
                            <div className='relative'>
                                <select onChange={(e) => setFluency(e.target.value)} value={fluency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                    <option selected value={''}>Select fluency...</option>
                                    <option value="Writing">Writing </option>
                                    <option value="Speaking">Speaking </option>
                                    <option value="Reading">Reading  </option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                            </div>
                        </div>
                        <div>
                            <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Competency *</h2>
                            <div className='relative'>
                                <select onChange={(e) => setCompetency(e.target.value)} value={competency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                    <option selected value={''}>Select competency...</option>
                                    <option value="Poor">Poor</option>
                                    <option value="Basic">Basic</option>
                                    <option value="Good">Good</option>
                                    <option value="Mother Tongue">Mother Tongue</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Comment</h1>
                            <textarea onChange={(e) => setCommentLanguages(e.target.value)} value={commentLanguages} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full resize-none' rows={3} placeholder='Your Comment...'/>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddLanguages(!showAddLanguages)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={postWorkLanguage} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditLanguages}
                title={'Edit Language'}
                buttonClose={ () => setShowEditLanguages(!showEditLanguages)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Language *</h1>
                            <input onChange={(e) => setLanguages(e.target.value)} value={languages} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Languages...'/>
                        </div>
                        <div>
                            <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Fluency *</h2>
                            <div className='relative'>
                                <select onChange={(e) => setEndYear(e.target.value)} value={fluency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                    <option selected value={''}>Select fluency...</option>
                                    <option value="Writing">Writing </option>
                                    <option value="Speaking">Speaking </option>
                                    <option value="Reading">Reading  </option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                            </div>
                        </div>
                        <div>
                            <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Competency *</h2>
                            <div className='relative'>
                                <select onChange={(e) => setCompetency(e.target.value)} value={competency} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-[536px] appearance-none'>
                                    <option selected value={''}>Select competency...</option>
                                    <option value="Poor">Poor</option>
                                    <option value="Basic">Basic</option>
                                    <option value="Good">Good</option>
                                    <option value="Mother Tongue">Mother Tongue</option>
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>Comment</h1>
                            <textarea onChange={(e) => setComment(e.target.value)} value={commentLanguages} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full resize-none' rows={3}/>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={ () => setShowEditLanguages(!showEditLanguages)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]' placeholder='Your Comment...'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editWorkLanguage} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showAddLicense}
                title={'Add License'}
                buttonClose={ () => setShowAddLicense(!showAddLicense)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>License Type *</h1>
                            <input onChange={(e) => setLicenseType(e.target.value)} value={licenseType} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Your License Type...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>License Number *</h1>
                            <input onChange={(e) => setLicenseNumber(e.target.value)} value={licenseNumber} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Your License Number...'/>
                        </div>
                        <div className="flex gap-[32px]">
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Issued date *</h1>
                                <input onChange={(e) => setIssuedDate(e.target.value)} value={issuedDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]' />
                            </div>
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Expiry date </h1>
                                <input onChange={(e) => setExpiryDate(e.target.value)} value={expiryDate} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]'/>
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddLicense(!showAddLicense)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={postWorkLicense} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditLicense}
                title={'Edit License'}
                buttonClose={ () => setShowEditLicense(!showEditLicense)}
                width={'615px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>License Type *</h1>
                            <input onChange={(e) => setLicenseType(e.target.value)} value={licenseType} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Your License Name...'/>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px]'>License Number *</h1>
                            <input onChange={(e) => setLicenseNumber(e.target.value)} value={licenseNumber} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Your License Number...'/>
                        </div>
                        <div className="flex gap-[32px]">
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Issued date *</h1>
                                <input onChange={(e) => setIssuedDate(e.target.value)} value={moment(issuedDate).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]'/>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px]'>Expiry date </h1>
                                <input onChange={(e) => setExpiryDate(e.target.value)} value={moment(expiryDate).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[252px]' />
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowEditLicense(!showEditLicense)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#015995] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editWorkLisence} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
         <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Profile'} LinkBack={'/dashboard'}/>
                    <div className='space-y-[24px] bg-[#F8F9FB] px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='flex flex-col relative'>
                        <img src='https://cdn.discordapp.com/attachments/990841636386897971/1008574993103331358/Rectangle_213.png' className='h-[91px] w-full rounded-t-xl'/>
                        <div className='pl-[185px] h-[91px] w-full rounded-b-xl bg-white'>
                            <h1 className='text-[20px] font-semibold text-[#454545]'>{namePersonalDetail}</h1>
                            <p className='text-[11px] text-[#A8A8A8] font-semibold'>{jobPositionPersonalDetail ? jobPositionPersonalDetail : 'ADMIN' }</p>
                        </div>
                        <label htmlFor='upload-image'>
                            <div className='w-[95px] h-[95px] rounded-full border-4 border-white absolute left-[75px] top-[55px] bottom-[36px] bg-cover hover:bg-slate-500' style={{ 
                                backgroundImage: `url(${imagePersonalDetail? imageHandle(imagePersonalDetail) : viewImage  })`, 
                                backgroundSize: 'cover', // Menambahkan properti backgroundSize untuk memastikan gambar penuh
                                backgroundPosition: 'center', // Menambahkan properti backgroundPosition untuk mengatur posisi gambar
                                width: '95px',
                                height: '95px',
                                borderRadius: '50%',
                                 }}>
                                {/* <img className='w-[95px] h-[95px] rounded-full border-4 border-white absolute left-[75px] top-[55px] bottom-[36px] object-cover hover:bg-transparent' src={imagePersonalDetail? imageHandle(imagePersonalDetail) : imageHandle(viewImage)} alt="Profile" /> */}
                                <input type='file' id='upload-image' onChange={(e) => handleViewImage(e)}  className='hidden'/>
                            </div>
                        </label>
                    </div>
                    <div className="flex relative gap-10">
                        <div className='w-[267px] h-[762px] py-[18px]  shadow rounded-l-xl border-r-6 relative pl-[18px] bg-white'>
                            
                            <button onClick={() => setIndex(0)} className={index === 0 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 0 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                    Personal Details
                                </h1>
                            </button>

                            <button onClick={() => setIndex(1)} className={index === 1 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 1 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                    Emergency Contact
                                </h1>
                            
                            </button>

                            <button onClick={() => setIndex(2)} className={index === 2 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 2 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                    Dependents
                                </h1>
                            </button>
                            
                            <button onClick={() => setIndex(3)} className={index === 3 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 3 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                Immigration
                                </h1>
                            </button>
                            
                            <button onClick={() => setIndex(4)} className={index === 4 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 4 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                Job
                                </h1>
                            </button>
                            
                            <button onClick={() => setIndex(5)} className={index === 5 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 5 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                Payroll
                                </h1>
                            </button>

                            <button onClick={() => setIndex(6)} className={index === 6 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 6 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                Report-to
                                </h1>
                            </button>

                            <button onClick={() => setIndex(7)} className={index === 7 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 7 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                Qualifications
                                </h1>
                            </button>
                            
                            <button onClick={() => setIndex(8)} className={index === 8 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 8 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                File
                                </h1>
                            </button>

                            <button onClick={() => setIndex(9)} className={index === 9 ? 'flex items-center justify-start bg-[#EFF9FF] w-[231px] h-[38px] rounded-lg border-r-[5px] border-[#219EBC]' : 'flex items-center justify-start w-[231px] h-[38px] rounded-lg'}>
                                <h1 className={index === 9 ? 'text-[14px] font-semibold text-[#219EBC] py-[10px] pl-[10px]' : 'text-[14px] font-semibold text-[#A8A8A8] py-[10px] pl-[10px]'}>
                                Training
                                </h1>
                            </button>
                        </div>

                        <div className="ml-auto w-full p-5 space-y-5 bg-white rounded-r-xl h-[762px] overflow-y-auto border shadow-md scrollbar-default">
                            {index === 0 ? (
                            <PersonalDetail/>
                            ) : index === 1 ? (
                            <EmergencyContact
                                modalAddContact={() => setShowAddEmergencyContact(!showEditEmergencyContact)}
                                modalEditContact={openEditEmergencyContact}
                                refreshPage={refresh}/>
                                
                            ) : index === 2 ? (
                            <Dependents 
                                ModalAddDepents={ () => setShowAddDependents(!showAddDependents) }
                                ModalEditDepents={openEditDependents}
                                refreshPage={refresh}/>
                            ) : index === 3 ? (
                            <Imigration 
                                modalAddImigration={ () => setShowAddImigration(!showAddImigration) }
                                modalEditImigration={openEditImirgration}
                                refreshPage={refresh}/>
                            ) : index === 4 ? (
                            <JobDetails
                             />
                            ) : index === 5 ? (
                            <Payroll />
                            ) : index === 6 ? (
                            <ReportTo
                                modalAddSubordinate={() => setShowAddSubordinate(!showAddSubordinate)} 
                                modalEditSubordinate={() => setShowEditSubordinate(!showEditSubordinate)} 
                                modalAddSupervisor={() => setShowAddSupervisor(!showAddSupervisor)} 
                                modalEditSupervisor={openEditReportToSupervisor}
                                refreshPage={refresh} />
                            ): index === 7 ? (
                            <Qualifications 
                                modalAddWorkExperience={() => setShowAddWorkExperience(!showAddWorkExperience)}
                                modalEditWorkExperience={openEditWorkExperience}
                                modalAddEducation={() => setShowAddEducation(!showAddEducation)}
                                modalEditEducation={openEditWorkEducation}
                                modalAddSkills={() => setShowAddSkills(!showAddSkills)}
                                modalEditSkills={openEditSkill}
                                modalAddLanguages={() => setShowAddLanguages(!showAddLanguages)}
                                modalEditLanguages={openEditWorkLanguage}
                                modalAddLicense={() => setShowAddLicense(!showAddLicense)}
                                modalEditLicense={openEditWorkLicense}
                                refreshPage={refresh}
                                />
                            ) : index === 8 ? (
                            <File />
                            ) : index === 9? (
                            <Training 
                                ModalAddTraining={() => setShowAddTraining(!showAddTraining)}
                                ModalEditTraining={openEditTraining}
                                refreshPage={refresh}
                            />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyProfile