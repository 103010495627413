import moment from 'moment'
import React from 'react'

const InboxDetailOvertime = ({EmployeeName, JobPosition,  OvertimeSubDate, OvertimeOnDate, StartTime, EndTIme, Description, BackToWork, BackToWorkTime,  onClickDecline, onClickApprove, buttonHide}) => {
  return (
    <div>
        <div className='space-y-[40px] w-[full]'>
            <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-[773px]'>
                <div className='w-[669px]'>
                    <div className="grid grid-cols-2 text-[11px] text-[#737373] gap-y-[20px] ">
                        <h1 className='font-semibold'>Employee Name</h1>
                        <h1 className='font-medium'>: {EmployeeName ? EmployeeName : ''} </h1>
                        <h1 className='font-semibold'>Job Position</h1>
                        <h1 className='font-medium'>: {JobPosition? JobPosition : '-'} </h1>
                        <h1 className='font-semibold'>Overtime submission date</h1>
                        <h1 className='font-medium'>: {OvertimeSubDate? moment(OvertimeSubDate).format('DD-MM-YYYY') : '-'}</h1>
                        <h1 className='font-semibold'>Overtime on date </h1>
                        <h1 className='font-medium'>: {OvertimeOnDate? moment(OvertimeOnDate).format('DD-MM-YYYY') : '-'}</h1>
                        <h1 className='font-semibold'>Start Time</h1>
                        <h1 className='font-medium'>: {StartTime? StartTime : '-'}</h1>
                        <h1 className='font-semibold'>End Time </h1>
                        <h1 className='font-medium'>: {EndTIme ? EndTIme: '-'}</h1>
                        <h1 className='font-semibold'>Description</h1>
                        <h1 className='font-medium'>: {Description ? Description : '-'}.</h1>
                    </div>
                </div>

                <h1 className='text-[#454545] mt-[21px] mb-[11px] font-semibold'>Back to Work</h1>

                <div className='w-[260px]'>
                    <div className="grid grid-cols-2 text-[11px] text-[#737373] gap-y-[20px]">
                        <h1 className='font-medium'>Date</h1>
                        <h1>: {BackToWork? moment(BackToWork).format('DD-MM-YYYY') : '-'}</h1>
                        <h1 className='font-medium'>Time</h1>
                        <h1>: {BackToWorkTime? BackToWorkTime : '-'}</h1>
                    </div>
                </div>
            </div>
        </div>
        {buttonHide &&
            <div className='flex items-center justify-end gap-[20px]'>
                <button onClick={onClickDecline} className='bg-[#C1121F] text-[#EDEDED] text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                    Decline
                </button>
                <button onClick={onClickApprove} className='bg-[#04BB00] text-[#EDEDED] text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                    Approve
                </button>
            </div>
        }
    </div>
  )
}

export default InboxDetailOvertime