import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { TbFileExport } from 'react-icons/tb'
import Api from '../../../../../Api'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { ModalDelete, Pagination } from '../../../../../components'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

    const Qualifications = ({modalAddWorkExperience, modalEditWorkExperience, modalAddEducation, modalEditEducation, modalAddSkills, modalEditSkills, modalAddLanguages, modalEditLanguages, modalAddLicense, modalEditLicense, refreshPage}) => {
    const [dataWEx, setDataWEx] = useState('')
    const [dataWEd, setDataWEd] = useState('')
    const [dataWSkills, setDataWSkills] = useState('')
    const [dataWLanguages, setDataWLanguages] = useState('')
    const [dataWLicense, setDataWLicense] = useState('')
    const [refresh, setRefresh] = useState(false)
    
    // State Modal Delete
    const [showModalDeleteWorkExperience, setShowModalDeleteWorkExperience] = useState()
    const [showModalDeleteWorkEducation, setShowModalDeleteWorkEducation] = useState()
    const [showModalDeleteWorkSkill, setShowModalDeleteWorkSkill] = useState()
    const [showModalDeleteWorkLicense, setShowModalDeleteWorkLicense] = useState()
    const [showModalDeleteWorkLanguage, setShowModalDeleteWorkLanguage] = useState()
    
    const [idWorkExperience, setIdWorkExperience] = useState()
    const [idWorkEducation, setIdWorkEducation] = useState()
    const [idWorkLicense, setIdWorkLicense] = useState()
    const [idWorkLanguage, setIdWorkLanguage] = useState()
    const [idWorkSKill, setIdWorkSkill] = useState()
    const [qualification, setQualification] = useState('Work Experience')

    const params = useLocation()
    const limit = 5

    // Pagination Work Experience
    const [currentPageWorkExperience, setCurrentPageWorkExperience] = useState(1)
    const [totalPagesWorkExperience, setTotalPagesWorkExperience] = useState('')
    const [totalDataWorkExperience, setTotalDataWorkExperience] = useState('')
    const handlePageWorkExperienceChange = (page) => {
        setCurrentPageWorkExperience(page);
        setRefresh(true)
    };
    
    const handlePrevWorkExperienceChange = () => {
        if(currentPageWorkExperience === 1) {
            setCurrentPageWorkExperience(1)
        } else {
            setCurrentPageWorkExperience(currentPageWorkExperience - 1);
        }
        setRefresh(true)
    };
    
    const handleNextWorkExperienceChange = () => {
        if(currentPageWorkExperience === totalPagesWorkExperience) {
            setCurrentPageWorkExperience(totalPagesWorkExperience)
        } else {
            setCurrentPageWorkExperience(currentPageWorkExperience + 1);
        }
        setRefresh(true)
    };

    // Pagination Work Education
    const [currentPageWorkEducation, setCurrentPageWorkEducation] = useState(1)
    const [totalPagesWorkEducation, setTotalPagesWorkEducation] = useState('')
    const [totalDataWorkEducation, setTotalDataWorkEducation] = useState('')
    const handlePageWorkEducationChange = (page) => {
        setCurrentPageWorkEducation(page);
        setRefresh(true)
    };
    
    const handlePrevWorkEducationChange = () => {
        if(currentPageWorkEducation === 1) {
            setCurrentPageWorkEducation(1)
        } else {
            setCurrentPageWorkEducation(currentPageWorkEducation - 1);
        }
        setRefresh(true)
    };
    
    const handleNextWorkEducationChange = () => {
        if(currentPageWorkEducation === totalPagesWorkEducation) {
            setCurrentPageWorkEducation(totalPagesWorkEducation)
        } else {
            setCurrentPageWorkEducation(currentPageWorkEducation + 1);
        }
        setRefresh(true)
    };
    
    // Pagination Work Skills
    const [currentPageWorkSkill, setCurrentPageWorkSkill] = useState(1)
    const [totalPagesWorkSkill, setTotalPagesWorkSkill] = useState('')
    const [totalDataWorkSkill, setTotalDataWorkSkill] = useState('')
    const handlePageWorkSkillChange = (page) => {
        setCurrentPageWorkSkill(page);
        setRefresh(true)
    };
    
    const handlePrevWorkSkillChange = () => {
        if(currentPageWorkSkill === 1) {
            setCurrentPageWorkSkill(1)
        } else {
            setCurrentPageWorkSkill(currentPageWorkSkill - 1);
        }
        setRefresh(true)
    };
    
    const handleNextWorkSkillChange = () => {
        if(currentPageWorkSkill === totalPagesWorkSkill) {
            setCurrentPageWorkSkill(totalPagesWorkSkill)
        } else {
            setCurrentPageWorkSkill(currentPageWorkSkill + 1);
        }
        setRefresh(true)
    };

    // Pagination License
    const [currentPageLicense, setCurrentPageLicense] = useState(1)
    const [totalPagesLicense, setTotalPagesLicense] = useState('')
    const [totalDataLicense, setTotalDataLicense] = useState('')
    const handlePageLicenseChange = (page) => {
        setCurrentPageLicense(page);
        setRefresh(true)
    };
    
    const handlePrevLicenseChange = () => {
        if(currentPageLicense === 1) {
            setCurrentPageLicense(1)
        } else {
            setCurrentPageLicense(currentPageLicense - 1);
        }
        setRefresh(true)
    };
    
    const handleNextLicenseChange = () => {
        if(currentPageLicense === totalPagesLicense) {
            setCurrentPageLicense(totalPagesLicense)
        } else {
            setCurrentPageLicense(currentPageLicense + 1);
        }
        setRefresh(true)
    };

    // Pagination Language
    const [currentPageLanguage, setCurrentPageLanguage] = useState(1)
    const [totalPagesLanguage, setTotalPagesLanguage] = useState('')
    const [totalDataLanguage, setTotalDataLanguage] = useState('')
    const handlePageLanguageChange = (page) => {
        setCurrentPageLanguage(page);
        setRefresh(true)
    };
    
    const handlePrevLanguageChange = () => {
        if(currentPageLanguage === 1) {
            setCurrentPageLanguage(1)
        } else {
            setCurrentPageLanguage(currentPageLanguage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextLanguageChange = () => {
        if(currentPageLanguage === totalPagesLanguage) {
            setCurrentPageLanguage(totalPagesLanguage)
        } else {
            setCurrentPageLanguage(currentPageLanguage + 1);
        }
        setRefresh(true)
    };




    const deleteWorkExperienceModal = (id) => {
        setShowModalDeleteWorkExperience(!showModalDeleteWorkExperience)
        setIdWorkExperience(id)
    }
    const deleteWorkEducationModal = (id) => {
        setShowModalDeleteWorkEducation(!showModalDeleteWorkEducation)
        setIdWorkEducation(id)
    }
    const deleteWorkLicenseModal = (id) => {
        setShowModalDeleteWorkLicense(!showModalDeleteWorkLicense)
        setIdWorkLicense(id)
    }
    const deleteWorkLanguageModal = (id) => {
        setShowModalDeleteWorkLanguage(!showModalDeleteWorkLanguage)
        setIdWorkLanguage(id)
    }
    const deleteWorkSkillModal = (id) => {
        setShowModalDeleteWorkSkill(!showModalDeleteWorkSkill)
        setIdWorkSkill(id)
    }

    const getWorkExperience = async () => {
        try {
            if(!params.state){
                const response = await Api.GetWorkExperienceByLogin(localStorage.getItem('hris-token'), currentPageWorkExperience, limit)
                setDataWEx(response.data.response)
                setCurrentPageWorkExperience(response.data.currentPage)
                setTotalDataWorkExperience(response.data.totalCount)
                setTotalPagesWorkExperience(response.data.totalPages)
            } else {
                const response = await Api.GetWorkExperienceByParam(localStorage.getItem('hris-token'), params.state.employeeId, currentPageWorkExperience, limit)
                setDataWEx(response.data.response)
                setCurrentPageWorkExperience(response.data.currentPage)
                setTotalDataWorkExperience(response.data.totalCount)
                setTotalPagesWorkExperience(response.data.totalPages)

            }
        } catch (error) {
            console.log(error)
        }
    }
    const getWorkEducation = async () => {
        try {
            if(!params.state){
                const response = await Api.GetWorkEducation(localStorage.getItem('hris-token'), currentPageWorkEducation, limit)
                setDataWEd(response.data.response)
                setTotalPagesWorkEducation(response.data.totalPages)
                setTotalDataWorkEducation(response.data.totalCount)
            } else {
                const response = await Api.GetWorkEducationByParam(localStorage.getItem('hris-token'), params.state.employeeId, currentPageWorkEducation, limit)
                setDataWEd(response.data.response)
                

            }
        } catch (error) {
            console.log(error)
        }
    }
    const getSkill = async () => {
        try {
            if(!params.state){
                const response = await Api.GetWorkSkill(localStorage.getItem('hris-token'), currentPageWorkSkill, limit)
                setDataWSkills(response.data.response)
                setTotalPagesWorkSkill(response.data.totalPages)
                setTotalDataWorkSkill(response.data.totalCount)
            } else {
                const response = await Api.GetWorkSkillByParam(localStorage.getItem('hris-token'), params.state.employeeId, currentPageWorkSkill, limit)
                setDataWSkills(response.data.response)
                setTotalPagesWorkSkill(response.data.totalPages)
                setTotalDataWorkSkill(response.data.totalCount)

            }

        } catch (error) {
            console.log(error)
        }
    }
    const getWorkLanguage = async () => {
        try {
            if(!params.state){
                const response = await Api.GetWorkLanguage(localStorage.getItem('hris-token'), currentPageLanguage, limit)
                setDataWLanguages(response.data.response)
                setTotalPagesLanguage(response.data.totalPages)
                setTotalDataLanguage(response.data.totalCount)
            } else {
                const response = await Api.GetWorkLanguageByParam(localStorage.getItem('hris-token'), params.state.employeeId, currentPageLanguage, limit)
                setDataWLanguages(response.data.response)
                setTotalPagesLanguage(response.data.totalPages)
                setTotalDataLanguage(response.data.totalCount)

            }
        } catch (error) {
            console.log(error)
        }
    }
    const getWorkLicense = async () => {
        try {
            if(!params.state){
                const response = await Api.GetWorkLicense(localStorage.getItem('hris-token'), currentPageLicense, limit)
                setDataWLicense(response.data.response)
                setTotalPagesLicense(response.data.totalPages)
                setTotalDataLicense(response.data.totalCount)
                
            } else {
                const response = await Api.GetWorkLicenseByParam(localStorage.getItem('hris-token'), params.state.employeeId, currentPageLicense, limit)
                setDataWLicense(response.data.response)
                setTotalPagesLicense(response.data.totalPages)
                setTotalDataLicense(response.data.totalCount)

            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteWorkExperience = async () => {
        try {
            const response = await Api.DeleteWorkExperience(localStorage.getItem('hris-token'), idWorkExperience)
            setShowModalDeleteWorkExperience(!showModalDeleteWorkExperience)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }
    const deleteWorkEducation = async () => {
        try {
            const response = await Api.DeleteWorkEducation(localStorage.getItem('hris-token'), idWorkEducation)
            setShowModalDeleteWorkEducation(!showModalDeleteWorkEducation)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }
    const deleteWorkLanguage = async () => {
        try {
            const response = await Api.DeleteWorkLanguage(localStorage.getItem('hris-token'), idWorkLanguage)
            setShowModalDeleteWorkLanguage(!showModalDeleteWorkLanguage)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }
    const deleteWorkLicense = async () => {
        try {
            const response = await Api.DeleteWorkLicense(localStorage.getItem('hris-token'), idWorkLicense)
            setShowModalDeleteWorkLicense(!showModalDeleteWorkLicense)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }
    const deleteSkill = async () => {
        try {
            const response = await Api.DeleteWorkSkill(localStorage.getItem('hris-token'), idWorkSKill)
            setShowModalDeleteWorkSkill(!showModalDeleteWorkSkill)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }
    // Export Employee
    const exportQualification = () => {
        // Sample data array
        const dataWorkEx = dataWEx;
        const dataWorkEd = dataWEd;
        const dataWorkSkill = dataWSkills;
        const dataWorkLanguage = dataWLanguages;
        const dataWorkLicense = dataWLicense;

        // Define custom headers for each table
        const HeadersWorkExperience = ['Company', 'Job Title', 'Start Date', 'End Date'];
        const HeadersWorkEducation = ['Level', 'End Year', 'GAP Score', 'Institute', 'Major'];
        const HeadersWorkSkill = ['Skill', 'Years of Experience', 'Comment'];
        const HeaderWorkLanguage = ['Language', 'Fluency', 'Competency', 'Comment']
        const HeaderWorkLicense = ['License Type', 'License Number', 'Issued Date', 'Expiry Date']

        // Create modified data arrays with custom headers
        const WorkExperience = dataWorkEx.map(({ companyName, jobTitle, startDate, endDate, comment }) => ({
            'Company': companyName, 
            'Job Title': jobTitle, 
            'Start Date': moment(startDate).format('DD-MM-YYYY'), 
            'End Date': moment(endDate).format('DD-MM-YYYY'),
            'Comment': comment,
        }));
        const WorkEducation = dataWorkEd.map(({ level, endDate, gap, institute, major }) => ({
            'Level': level, 
            'End Year': moment(endDate).format('YYYY'), 
            'GAP Score': gap, 
            'Institute': institute,
            'Major': major,
        }));
        const WorkSkills = dataWorkSkill.map(({ skill, yearsOfExperience, comment}) => ({
            'Skill': skill, 
            'Years of Experience': yearsOfExperience, 
            'Comment': comment,
        }));
        const WorkLanguage = dataWorkLanguage.map(({ language, fluency, competency, comment}) => ({
            'Language': language, 
            'Fluency': fluency, 
            'Competency': competency,
            'Comment': comment,
        }));
        const WorkLicense = dataWorkLicense.map(({ licenseType, issuedDate, expiryDate, licenseNumber}) => ({
            'License Type': licenseType, 
            'License Number': licenseNumber, 
            'Issued Date': moment(issuedDate).format('DD-MM-YYYY'),
            'Expiry Date': moment(expiryDate).format('DD-MM-YYYY'),
        }));

        // Create a new worksheet for each table
        const workSheetWorkExperience = XLSX.utils.json_to_sheet(WorkExperience, { header: HeadersWorkExperience });
        const workSheetWorkEducation = XLSX.utils.json_to_sheet(WorkEducation, { header: HeadersWorkEducation });
        const workSheetWorkSkills = XLSX.utils.json_to_sheet(WorkSkills, { header: HeadersWorkSkill });
        const workSheetWorkLanguage = XLSX.utils.json_to_sheet(WorkLanguage, { header: HeaderWorkLanguage });
        const workSheetWorkLicense = XLSX.utils.json_to_sheet(WorkLicense, { header: HeaderWorkLicense });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(workbook, workSheetWorkExperience, 'Work Experience');
        XLSX.utils.book_append_sheet(workbook, workSheetWorkEducation, 'Work Education');
        XLSX.utils.book_append_sheet(workbook, workSheetWorkSkills, 'Work Skills');
        XLSX.utils.book_append_sheet(workbook, workSheetWorkLanguage, 'Work Language');
        XLSX.utils.book_append_sheet(workbook, workSheetWorkLicense, 'Work License');

        // Generate Excel file buffer
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Convert buffer to Blob
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file using FileSaver.js
        saveAs(excelBlob, 'Qualification.xlsx');
    };

    useEffect(() => {
        getWorkExperience()
        getWorkEducation()
        getSkill()
        getWorkLanguage()
        getWorkLicense()
        setRefresh(false)
    }, [refresh, refreshPage])

  return (
    <div>
        <ModalDelete
                activeModal={showModalDeleteWorkExperience}
                buttonClose={() => setShowModalDeleteWorkExperience(!showModalDeleteWorkExperience)}
                submitButton={deleteWorkExperience}
        />
        <ModalDelete
                activeModal={showModalDeleteWorkEducation}
                buttonClose={() => setShowModalDeleteWorkEducation(!showModalDeleteWorkEducation)}
                submitButton={deleteWorkEducation}
        />
        <ModalDelete
                activeModal={showModalDeleteWorkLicense}
                buttonClose={() => setShowModalDeleteWorkLicense(!showModalDeleteWorkLicense)}
                submitButton={deleteWorkLicense}
        />
        <ModalDelete
                activeModal={showModalDeleteWorkLanguage}
                buttonClose={() => setShowModalDeleteWorkLanguage(!showModalDeleteWorkLanguage)}
                submitButton={deleteWorkLanguage}
        />
        <ModalDelete
                activeModal={showModalDeleteWorkSkill}
                buttonClose={() => setShowModalDeleteWorkSkill(!showModalDeleteWorkSkill)}
                submitButton={deleteSkill}
        />
        
        <div className='flex items-center justify-between'>
            <div className='mb-[8.5px]'>
                <h1 className='text-[20px] font-medium'>Qualifications</h1>
                <p className='text-[10px] text-[#737373]'>List of  record employee qualifications</p>
            </div>
            <button onClick={exportQualification} className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                <TbFileExport className='text-[#003049]'/>
                <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
            </button>
        </div>
        <hr className='mb-[35.5px]'/>
        <div className='border-b-2 flex items-center text-[16px]'>
            <button onClick={ () => setQualification('Work Experience') } className={`${qualification === 'Work Experience' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[28px] py-[10px]`}>Work Experience</button>
            <button onClick={ () => setQualification('Skills') } className={`${qualification === 'Skills' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[28px] py-[10px]`}>Skills</button>
            <button onClick={ () => setQualification('Educations') } className={`${qualification === 'Educations' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[28px] py-[10px]`}>Educations</button>
            <button onClick={ () => setQualification('Languages') } className={`${qualification === 'Languages' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[28px] py-[10px]`}>Languages</button>
            <button onClick={ () => setQualification('License') } className={`${qualification === 'License' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[28px] py-[10px]`}>License</button>
        </div>
        <hr className='mb-[24.5px]'/>
        {
            qualification === 'Work Experience' ? 
                <div className='border rounded-xl mb-[30px] bg-white shadow'>
                    <div className='mb-[8.5px]'>
                        <div className='flex relative items-center justify-between pt-[32px] px-[19px]'>
                            <div>
                                <h1 className='text-[16px] font-medium'>Work Experience</h1>
                                <p className='text-[10px] text-[#737373]'>List of work experience</p>
                            </div>
                            <div className=''>
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[27px]'>
                                    <button onClick={modalAddWorkExperience} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus  className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='mb-[24.5px] mx-5'/>
                    <div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px] text-[#737373] pl-3'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    {/* <div className='flex items-center gap-[6px] min-w-[30px] max-w-[30px]'>
                                        <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                    </div> */}
                                    <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Company</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Job Title</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Start Date</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>End Date</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Comment</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                </div>
                                {Object.values(dataWEx).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        {/* <div className='flex items-center gap-[6px] min-w-[30px] max-w-[30px]'>
                                            <input id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded "/>
                                        </div> */}
                                        <div className='min-w-[170px] max-w-[170px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item ? item.companyName : '-'}</h1>
                                        </div>
                                        <div className='min-w-[170px] max-w-[170px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item ? item.jobTitle : '-'}</h1>
                                        </div>
                                        
                                        <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>{item? moment(item.startDate).format('DD-MM-YYYY') : '-'}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>{item? moment(item.endDate).format('DD-MM-YYYY') : '-'}</h1>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[170px] max-w-[170px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>{item?.comment}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => deleteWorkExperienceModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty className='text-[#003049]'/>
                                            </button>
                                            <button onClick={() => modalEditWorkExperience(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]'/>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        <div className='px-[14px] py-[8px]'>
                            <Pagination
                                currentPage={currentPageWorkExperience} 
                                totalPages={totalPagesWorkExperience} 
                                lengthData={totalDataWorkExperience}
                                onPageChange={handlePageWorkExperienceChange}
                                onPrevChange={handlePrevWorkExperienceChange}
                                onNextChange={handleNextWorkExperienceChange}
                            />
                        </div>
                    </div>
                </div>
            : qualification === 'Skills' ?
                <div className='border rounded-xl mb-[30px] bg-white shadow'>
                <div className='mb-[8.5px]'>
                    <div className='flex relative items-center justify-between pt-[32px] px-[19px]'>
                        <div>
                            <h1 className='text-[16px] font-medium'>Skills</h1>
                            <p className='text-[10px] text-[#737373]'>List of  employee skills</p>
                        </div>
                        <div className=''>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[27px]'>
                                <button onClick={modalAddSkills} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='mb-[24.5px] mx-5'/>
                <div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px] text-[#737373] pl-3'>
                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Skill</h1>
                                    <MdImportExport className='text-[#737373] text-xs'/>
                                </div>
                                <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Year of Experience</h1>
                                    <MdImportExport className='text-[#737373] text-xs'/>
                                </div>
                                <div className='flex items-center justify-center gap-[6px] w-full'>
                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                    <MdImportExport className='text-[#737373] text-xs'/>
                                </div>
                            </div>
                            {Object.values(dataWSkills).map((item, idx) => (
                                <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.skill ? item.skill : '-'}</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.yearsOfExperience ? item.yearsOfExperience : '-'}</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <button onClick={() => deleteWorkSkillModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]'/>
                                        </button>
                                        <button onClick={() => modalEditSkills(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-[#003049]'/>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </table>
                    </div>
                    <div className='px-[14px] py-[8px]'>
                        <Pagination
                            currentPage={currentPageWorkSkill} 
                            totalPages={totalPagesWorkSkill} 
                            lengthData={totalDataWorkSkill}
                            onPageChange={handlePageWorkSkillChange}
                            onPrevChange={handlePrevWorkSkillChange}
                            onNextChange={handleNextWorkSkillChange}
                        />
                    </div>
                </div>
                </div>
            : qualification === 'Educations'?
                <div className='border rounded-xl mb-[30px] bg-white shadow'>
                    <div className='mb-[8.5px]'>
                        <div className='flex relative items-center justify-between pt-[32px] px-[19px]'>
                            <div>
                                <h1 className='text-[16px] font-medium'>Educations</h1>
                                <p className='text-[10px] text-[#737373]'>List of  employee educations</p>
                            </div>
                            <div className=''>
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[27px]'>
                                    <button onClick={modalAddEducation} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus  className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='mb-[24.5px] mx-5'/>
                    <div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px] text-[#737373] pl-3'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Level</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>End Year</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>GAP/Score</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                </div>
                                {Object.values(dataWEd).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.level ? item.level: '-'}</h1>
                                        </div>
                                        <div className='min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.end_year ? moment(item.end_year).format('YYYY') : '-'}</h1>
                                        </div>
                                        <div className='min-w-[250px] max-w-[250px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.gap ? item.gap : '-'}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => deleteWorkEducationModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty className='text-[#003049]'/>
                                            </button>
                                            <button onClick={() => modalEditEducation(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]'/>
                                            </button>
                                        </div>
                                    </div>
                                )) }
                            </table>
                        </div>
                        <div className='px-[14px] py-[8px]'>
                            <Pagination
                                currentPage={currentPageWorkEducation} 
                                totalPages={totalPagesWorkEducation} 
                                lengthData={totalDataWorkEducation}
                                onPageChange={handlePageWorkEducationChange}
                                onPrevChange={handlePrevWorkEducationChange}
                                onNextChange={handleNextWorkEducationChange}
                            />
                        </div>
                    </div>
                </div>
            : qualification === 'Languages'?
                <div className='border rounded-xl mb-[30px] bg-white shadow'>
                    <div className='mb-[8.5px]'>
                        <div className='flex relative items-center justify-between pt-[32px] px-[19px]'>
                            <div>
                                <h1 className='text-[16px] font-medium'>Languages</h1>
                                <p className='text-[10px] text-[#737373]'>List of  employee languages</p>
                            </div>
                            <div className=''>
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[27px]'>
                                    <button onClick={modalAddLanguages} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus  className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='mb-[24.5px] mx-5'/>
                    <div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px] text-[#737373] pl-3'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Language</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Fluency</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Compelency</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Comment</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                </div>
                                {Object.values(dataWLanguages).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.language ? item.language : '-'}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.fluency ? item.fluency : '-'}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.competency ? item.competency : '-'}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.comment ? item.comment : '-'}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => deleteWorkLanguageModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty className='text-[#003049]'/>
                                            </button>
                                            <button onClick={() => modalEditLanguages(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]'/>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        <div className='px-[14px] py-[8px]'>
                            <Pagination
                                currentPage={currentPageLanguage} 
                                totalPages={totalPagesLanguage} 
                                lengthData={totalDataLanguage}
                                onPageChange={handlePageLanguageChange}
                                onPrevChange={handlePrevLanguageChange}
                                onNextChange={handleNextLanguageChange}
                            />
                        </div>
                    </div>
                </div>
            : qualification === 'License'?
                <div className='border rounded-xl mb-[30px] bg-white shadow'>
                    <div className='mb-[8.5px]'>
                        <div className='flex relative items-center justify-between pt-[32px] px-[19px]'>
                            <div>
                                <h1 className='text-[16px] font-medium'>License</h1>
                                <p className='text-[10px] text-[#737373]'>List of employee educations</p>
                            </div>
                            <div className=''>
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[27px]'>
                                    <button onClick={modalAddLicense} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus  className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='mb-[24.5px] mx-5'/>
                    <div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px] text-[#737373] pl-3'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>License Type</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Issued Date</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>ExpiryDate</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                </div>
                                {Object.values(dataWLicense).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.licenseType ? item.licenseType : '-'}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.issuedDate ? moment(item.issuedDate).format('DD-MM-YYYY') : '-'}</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.expiryDate ? moment(item.expiryDate).format('DD-MM-YYYY') : '-'}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => deleteWorkLicenseModal(item.id)}  className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <CgTrashEmpty className='text-[#003049]'/>
                                            </button>
                                            <button onClick={() => modalEditLicense(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]'/>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        <div className='px-[14px] py-[8px]'>
                            <Pagination
                                currentPage={currentPageLicense} 
                                totalPages={totalPagesLicense} 
                                lengthData={totalDataLicense}
                                onPageChange={handlePageLicenseChange}
                                onPrevChange={handlePrevLicenseChange}
                                onNextChange={handleNextLicenseChange}
                            />
                        </div>
                    </div>
                </div>
            : null
        }
    </div>
  )
}

export default Qualifications