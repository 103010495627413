import React from 'react'

const InboxDetailCashAdvance = ({EmployeeName, Policy, Amount, RequestDate, DateUse, Notes, Interest, Installment, buttonHide, onClickDecline, onClickApprove, jobPosition}) => {
    return (
        <div className='space-y-2'>
            <div className='bg-[#F8F9FB] space-y-[16px] rounded-[15px] px-[33px] py-[31px]'>
                <div className='border-b-2'>
                    <h1 className='text-[rgb(46,46,46)] text-sm font-[500]'>Detail Cash Advance</h1>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Employee Name</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!EmployeeName ? ': Empty' : `: ${EmployeeName}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Job Position</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!jobPosition ? ': Empty' : `: ${jobPosition}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Cash Advance Policy</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!Policy ? ': Empty' : `: ${Policy}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Request Date</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!RequestDate ? ': Empty' : `: ${RequestDate}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Date of Use</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!DateUse ? ': Empty' : `: ${DateUse}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Amount</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!Amount ? ': Rp. 0' : `: Rp. ${Amount}`}</h1>
                    </div>
                </div>
                <div className='lg:grid flex flex-col grid-cols-12'>
                    <div className='col-span-2'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>Notes</h1>
                    </div>
                    <div className='col-span-10 mt-1 lg:mt-0'>
                        <h1 className='text-[#737373] text-[11px] font-[600]'>{!Notes ? ': Empty' : `: ${Notes}`}</h1>
                    </div>
                </div>
            </div>
            {buttonHide &&
                <div className='flex items-center justify-end gap-[20px]'>
                    <button onClick={onClickDecline} className='bg-[#C1121F] text-[#EDEDED] text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Decline
                    </button>
                    <button onClick={onClickApprove} className='bg-[#04BB00] text-[#EDEDED] text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Approve
                    </button>
                </div>
            }
        </div>   
    )
}

export default InboxDetailCashAdvance