import React, { useEffect, useState } from 'react'
import { Modal, Navbar, Pagination } from '../../../../components'
import { BiSearch } from 'react-icons/bi'
import { MdImportExport } from 'react-icons/md'
import { FiEye } from 'react-icons/fi'
import { FaMapMarkerAlt } from 'react-icons/fa'
import Api from "../../../../Api"
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { Logo, LogoH } from '../../../../assets'
import { debounce } from 'lodash'
import L from 'leaflet'
import { useGeolocated } from "react-geolocated";
import { MapContainer, TileLayer, Marker, Popup, Circle, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import axios from 'axios'



const MyAttendance = () => {
    const [showAddCheckIn, setShowAddCheckIn] = useState()
    const [showDetailAttendance, setShowDetailAttendance] = useState()
    const [dataAttendance, setDataAttendance] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [geodata, setGeoData] = useState('')
    const [refresh, setRefresh] = useState('')

    const limit = 10
    const [currentPageAttendance, setCurrentPageAttendance] = useState(1)
    const [totalPagesAttendance, setTotalPagesAttendance] = useState('')
    const [nameSearchAttendance, setNameSearchAttendance] = useState('')
    const [dateSearchAttendance, setDateSearchAttendance] = useState('')

    const [photoClockIn, setPhotoClockIn] = useState('')
    const [photoClockOut, setPhotoClockOut] = useState('')
    const [detailData, setDetailData] = useState('')

    
    const handlePageChange = (page) => {
        setCurrentPageAttendance(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPageAttendance === 1) {
            setCurrentPageAttendance(1)
        } else {
            setCurrentPageAttendance(currentPageAttendance - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPageAttendance === totalPagesAttendance) {
            setCurrentPageAttendance(totalPagesAttendance)
        } else {
            setCurrentPageAttendance(currentPageAttendance + 1);
        }
        setRefresh(true)
    };

    const getDataAttendance = async () =>{
        try{
            const response = await Api.GetMyAttendance(localStorage.getItem('hris-token'), dateSearchAttendance, nameSearchAttendance, currentPageAttendance, limit)
            setDataAttendance(response.data.attendances)
            setCurrentPageAttendance(response.data.currentPage)
            setTotalPagesAttendance(response.data.totalPages)
            console.log(response,'atendance')
        } catch (error) {
            console.log(error)
        }
    }

    const openDetailAttendance = async (id) => {
        try {
            setShowDetailAttendance(!showDetailAttendance)
            const response = await Api.GetMyAttendanceById(localStorage.getItem('hris-token'), id)
            setDetailData(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getGeoData = async() => {
        try {
            const response = await Api.GetGeoLocation(localStorage.getItem('hris-token'))
            setGeoData(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const { coords } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    })

    
    const CheckInDataAttendance = async () =>{

        const userLatLng = L.latLng([coords.latitude, coords.longitude]);
        setLatitude(coords.latitude)
        setLongitude(coords.longitude)
        let isOutsideRadius = true;
        geodata.forEach((data) => {
            const geocodeString = data.geocode;
            const geocodeArray = JSON.parse(geocodeString);
            const centerLatLng = L.latLng(geocodeArray[0], geocodeArray[1]);
            const distance = userLatLng.distanceTo(centerLatLng);
    
            if (distance <= data.georadius) {
                isOutsideRadius = false;
            }
        });
    
        if (isOutsideRadius) {
            toast.error('You are out of radius!')
        }else {
            try{
                const data = {
                    note: "ok",
                    longitude_checkin: JSON.stringify(longitude) ,
                    latitude_checkin: JSON.stringify(latitude)
                }
                const response = await Api.PostMyAttendanceCheckIn(localStorage.getItem('hris-token'), data)
                toast.success('Check in Success')
                setRefresh(true)
            } catch (error){
                console.log(error)
                toast.error(error.response.data.message)
            }
        }
    }

    const CheckOutDataAttendance = async (id) =>{
        const userLatLng = L.latLng([coords.latitude, coords.longitude]);
        setLatitude(coords.latitude)
        setLongitude(coords.longitude)
        let isOutsideRadius = true;
        geodata.forEach((data) => {
            const geocodeString = data.geocode;
            const geocodeArray = JSON.parse(geocodeString);
            const centerLatLng = L.latLng(geocodeArray[0], geocodeArray[1]);
            const distance = userLatLng.distanceTo(centerLatLng);
    
            if (distance <= data.georadius) {
                isOutsideRadius = false;
            }
        });
    
        if (isOutsideRadius) {
            toast.error('You are out of radius!')
        } else {
            try{
                const data = {
                    longitude_checkout: JSON.stringify(longitude) ,
                    latitude_checkout: JSON.stringify(latitude)
                }
                const response = await Api.PostMyAttendanceCheckOut(localStorage.getItem('hris-token'), data, id)
                setRefresh(true)
                toast.success('Check out Success')
            } catch (error){
                console.log(error)
                toast.error('Check out Failed')
            }
        }
    }

    const debouncedSearchName = debounce(async(name) => {
        try {
            const response = await Api.GetMyAttendance(localStorage.getItem('hris-token'), dateSearchAttendance, name, currentPageAttendance, limit)
            setDataAttendance(response.data.attendances)
            setCurrentPageAttendance(response.data.currentPage)
            setTotalPagesAttendance(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }
    
    const debouncedSearchDate = debounce(async(date) => {
        try {
            const response = await Api.GetMyAttendance(localStorage.getItem('hris-token'), date, nameSearchAttendance, currentPageAttendance, limit)
            setDataAttendance(response.data.attendances)
            setCurrentPageAttendance(response.data.currentPage)
            setTotalPagesAttendance(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const debouncedLimit = debounce(async(limit) => {
        try {
            const response = await Api.GetMyAttendance(localStorage.getItem('hris-token'), dateSearchAttendance, nameSearchAttendance, currentPageAttendance, limit)
            setDataAttendance(response.data.attendances)
            setCurrentPageAttendance(response.data.currentPage)
            setTotalPagesAttendance(response.data.totalPages)
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchDate = (e) => {
        const searchDate = e.target.value
        debouncedSearchDate(searchDate)
    }

    const handleLimit= (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimit(limit)
    }



    const duration = (endTime, startTime) => {
        const format = "YYYY-MM-DD HH:mm:ss"; // Include the date in the format as well
      
        const ms = moment(endTime, format).diff(moment(startTime, format));
        const d = moment.duration(ms).abs(); // Take the absolute value of the duration
        const h = d.hours();
        const m = d.minutes();
        const s = d.seconds();
      
        if (h !== 0) {
          return `${h} h ${m} m`;
        } else {
          return `${m} m`;
        }
    };

    const customIcon = new L.icon({
        iconUrl: LogoH ,
        iconSize: [17, 17],
      });

      const [locationNameCheckout, setLocationNameCheckout] = useState('');
      const [locationNameCheckin, setLocationNameCheckin] = useState('');
    
      const getLocationNameCheckin = () => {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${detailData.latitude_checkin}&lon=${detailData.longtitude_checkin}`
          )
          .then((response) => {
            const locationNameCheckin = response.data.display_name;
            setLocationNameCheckin(locationNameCheckin);
          })
          .catch((error) => {
          });
      };

      const getLocationNameCheckout = () => {
        axios
          .get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${detailData.latitude_checkout}&lon=${detailData.longtitude_checkout}`
          )
          .then((response) => {
            const locationNameCheckout = response.data.display_name;
            setLocationNameCheckout(locationNameCheckout);
          })
          .catch((error) => {
          });
      };

    useEffect(() => {
        getLocationNameCheckout();
        getLocationNameCheckin()
    } , [detailData]);

    useEffect(() => {
        getGeoData()
        getDataAttendance()
        setRefresh(false)

        // Get Geo Location User
        const getPosition = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        setLatitude(position.coords.latitude);
                        setLongitude(position.coords.longitude);
                    },
                    error => {
                        console.error(error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };
        getPosition();
    }, [refresh])

  return (
    <div>
        {/* <Modal 
            activeModal={showAddCheckIn}
            title={'Check In'}
            buttonClose={ () => setShowAddCheckIn(!showAddCheckIn)}
            width={'542px'}
            content= {
                <div className='space-y-[20px]'>
                    <div className='relative'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Time</h1>
                        <input type="time"  className='py-[10px] border rounded-md  pl-[13px] text-[12px] text-[#737373] w-full appearance-none pr-5'/>
                    </div>
                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px]'>Note</h1>
                        <textarea rows={4} placeholder="Note check in here..." className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full resize-none'/>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        /> */}
        <Modal 
            activeModal={showDetailAttendance}
            title={'Detail Attendance'}
            buttonClose={ () => setShowDetailAttendance(!showDetailAttendance)}
            width={'715px'}
            content= {
                <div className='space-y-[20px] w-[full]'>
                        <>
                            <div className='bg-[#F8F8F8] w-[623px] rounded-2xl mb-[30px]'>
                                <div className='py-[20px] px-[20px]'>
                                    <h1 className=' text-[#5C5C5C] text-[13px] font-semibold mb-[22px]'>Attendance Information</h1>
                                    <div className='text-[11px] text-[#737373] font-semibold space-y-2'>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-2'>
                                                <h1>Employee Name</h1>
                                            </div>
                                            <div className='col-span-10'>
                                                <h1>: {detailData.user?.fullname}</h1>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-2'>
                                                <h1>Company Location</h1>
                                            </div>
                                            <div className='col-span-10'>
                                                <h1>: {detailData.company?.address}</h1>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-2'>
                                                <h1>Duration</h1>
                                            </div>
                                            <div className='col-span-10'>
                                                <h1>: {detailData.time_checkin && detailData.time_checkout ?duration(detailData.time_checkin, detailData.time_checkout) : '-'}</h1>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-2'>
                                                <h1>Work Shift</h1>
                                            </div>
                                            <div className='col-span-10'>
                                                <h1>: -</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-[84px]'>
                                <div>
                                    <div className='flex text-[14px] text-[#5C5C5C] font-semibold gap-[43px] mb-[11px]'>
                                        <h1>Check in</h1>
                                        <h2>{moment(detailData.time_checkin).format('hh:mm')}</h2>
                                    </div>
                                    <div className='flex gap-[23px]'>
                                        <div>
                                            <h1 className='text-[#5C5C5C] text-[10px] font-medium mb-[1px]'>Photo</h1>
                                            <div className='w-[77px] h-[76px] bg-[#EDEDED] rounded-[10px] flex items-center justify-center'>
                                                <img src={photoClockIn? photoClockIn : LogoH}/>
                                            </div>
                                        </div>
                                        <div>
                                            <h1 className='text-[#5C5C5C] text-[10px] font-medium mb-[1px]'>Location</h1>
                                            <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 border'>
                                            {detailData.latitude_checkin && detailData.longtitude_checkin? (
                                                <MapContainer
                                                    center={[detailData?.latitude_checkin, detailData?.longtitude_checkin]}
                                                    zoom={15}
                                                    style={{ height: '100%', width: '100%' }}
                                                >
                                                    <TileLayer
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                    />
                                                    <Marker icon={customIcon} position={[detailData.latitude_checkin, detailData?.longtitude_checkin]}>

                                                        </Marker>
                                                </MapContainer>
                                            ) : (
                                                <h1 className='text-center text-[10px]'>not checkin yet</h1>
                                            )}
                                            </div>

                                            <div className='flex gap-[9px]'>
                                                <FaMapMarkerAlt className='text-[#757D8A]' />
                                                <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                                    {locationNameCheckin?? 'Not Checkin Yet'} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex text-[14px] text-[#5C5C5C] font-semibold gap-[43px] mb-[11px]'>
                                        <h1>Check Out</h1>
                                        <h2>{detailData.time_checkout? moment(detailData?.time_checkout).format('hh:mm') :'-'}</h2>
                                    </div>
                                    <div className='flex gap-[23px]'>
                                        <div>
                                            <h1 className='text-[#5C5C5C] text-[10px] font-medium mb-[1px]'>Photo</h1>
                                            <div className='w-[77px] h-[76px] bg-[#EDEDED] rounded-[10px] flex items-center justify-center'>
                                                <img src={photoClockOut? photoClockOut : LogoH} />
                                            </div>
                                        </div>
                                        <div>
                                            <h1 className='text-[#5C5C5C] text-[10px] font-medium mb-[1px]'>Location</h1>
                                            <div className='w-[180px] h-[120px] rounded-[10px] flex items-center justify-center mb-2 text-base border'>
                                            {detailData.latitude_checkout && detailData.longtitude_checkout? (
                                                <MapContainer
                                                    center={[detailData?.latitude_checkout, detailData?.longtitude_checkout]}
                                                    zoom={15}
                                                    style={{ height: '100%', width: '100%' }}
                                                >
                                                    <TileLayer
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                                    />
                                                    <Marker icon={customIcon} position={[detailData.latitude_checkout, detailData?.longtitude_checkout]}>

                                                        </Marker>
                                                </MapContainer>
                                            ) : (
                                                <h1 className='text-center text-[10px]'>not checkout yet</h1>
                                            )}
                                            </div>

                                            <div className='flex gap-[9px]'>
                                                <FaMapMarkerAlt className='text-[#757D8A]' />
                                                <div className='w-[143px] text-[8px]  text-[#464E5F]'>
                                                {locationNameCheckout?? 'Not Checkout Yet'} 

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                </div>
            }
        />
         <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'My Attendance'} LinkBack={'/dashboard'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div>
                        <h1 className='text-[20px] text-[#454545] font-semibold'>My Attendace</h1>
                        <h2 className='text-[#737373] text-[10px]'>List of Attendance </h2>
                    </div>

                    <div className="flex lg:flex-row flex-col gap-2 mt-[31px] relative justify-between">
                        <div className='flex flex-col lg:flex-row items-center gap-[10px]'>
                            <input onChange={handleSearchDate} type="date" className='w-full lg:w-[121px] p-[10px] bg-[#F5F8FA] rounded-md border-none text-[12px] text-[#7E8299]'/>
                            <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                                <option value="" selected disabled>Set Limit</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                            </select>
                            {/* <button className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px] w-full lg:w-fit'>
                                <TbFileExport className='text-[#003049]'/>
                                <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                            </button> */}
                        </div>
                        <div className="">
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <button onClick={CheckInDataAttendance} className='bg-[#003049] py-1.5 px-4 rounded-[10px] flex items-center gap-[16px] text-white w-full lg:w-auto'>
                                    <h1 className='truncate'>Check in!</h1>
                                </button>
                                <div onChange={handleSearchName} className='relative w-full'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input placeholder='Search by Employee Name or Employee ID...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                                {/* <button onClick={() => setShowAddCheckIn(!showAddCheckIn)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Check In</h1>
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div>
            <div className='overflow-auto'>
                <table className='w-full space-y-[10px] text-[#737373]'>
                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Date</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Check In</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Location</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Check Out</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Location</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Duration</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                        <div className='flex items-center justify-center gap-[6px] w-full'>
                            <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                            <MdImportExport className='text-[#737373] text-xs'/>
                        </div>
                    </div>
                    {
                        Object.values(dataAttendance).map((item) =>(
                        <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>{moment(item.date).format('DD MMMM YYYY')}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>{moment(item.time_checkin).format('HH:mm')}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                                <a href={`https://www.google.com/maps/?q=${item.latitude_checkin},${item.longtitude_checkin}`} target='_blank' className='text-blue-500 text-[11px] font-[500] underline underline-offset-2'>Location</a>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>{!item.time_checkout ? '-' : moment(item.time_checkout).format('HH:mm')}</h1>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[160px] max-w-[160px]'>
                                <a href={`https://www.google.com/maps/?q=${item.latitude_checkout},${item.longtitude_checkout}`} target='_blank' className='text-blue-500 text-[11px] font-[500] underline underline-offset-2'>Location</a>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>{!item.time_checkout ? '-' : duration(item.time_checkin, item.time_checkout)}</h1>
                            </div>
                            <div className='w-full flex items-center justify-center gap-[12px]'>
                                <button onClick={() => openDetailAttendance(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                    <FiEye className='text-[#003049]'/>
                                </button>
                                <button onClick={() => CheckOutDataAttendance(item.id)}  className=' bg-[#CEDFEA] rounded-[9px] w-[92px] flex items-center justify-center text-[#003049] py-[5px] px-[15px] text-[11px] font-semibold'>
                                    Check Out
                                </button>
                            </div>
                        </div>
                        ))
                    }
                </table>
            </div>
            <Pagination
                currentPage={currentPageAttendance} 
                totalPages={totalPagesAttendance} 
                onPageChange={handlePageChange}
                onPrevChange={handlePrevChange}
                onNextChange={handleNextChange}
            />
        </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyAttendance