import React, { useState } from 'react'
import { Modal, Navbar } from '../../../../components'
import { BiExport, BiSearch } from 'react-icons/bi'
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

const DetailEvaluation = () => {
    const [menu, setMenu] = useState('Realization & Evidence')
    const [showAddFeedback, setShowAddFeedback] = useState()
    const navigate = useNavigate()
    const workResult = 'Sesuai Ekspektasi'
    const otherResult = 'Sesuai Ekspektasi'
    const peerEvaluation = 'Sesuai Ekspektasi'
    const performancePredicate = 'Good'
    const feedback = ''
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
        <Modal
            activeModal={showAddFeedback}
            title={'Add Feedback'}
            buttonClose={ () => setShowAddFeedback(!showAddFeedback)}
            width={'695px'}
            content= {
                <div className='space-y-6'>

                    <div className='space-y-[5px]'>
                        <h1 className="text-neutral-500 text-[11px] font-medium">Feedback</h1>
                        <div className='w-full'>
                            <div className="mb-[12px]">
                                <div className='relative w-full'>
                                    <select className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                        <option selected value="">Select Feedback</option>
                                        <option value="Di Atas Ekspektasi">Di Atas Ekspektasi</option>
                                        <option value="Sesuai Ekspektasi">Sesuai Ekspektasi</option>
                                        <option value="Di Bawah Eskpektasi">Di Bawah Eskpektasi</option>
                                    </select>
                                    <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowAddFeedback(!showAddFeedback)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                    </div>
                </div>
            }
        />
        <div className='flex px-[30px] py-[35px] gap-[30px]'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Detail Evaluation'} LinkBack={'/performance'}/>
                <h1 className="text-zinc-800 text-xl font-semibold">Detail Evaluation</h1>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className='border-b-2 flex items-center text-[16px]'>
                        <button onClick={ () => setMenu('Realization & Evidence') } className={`${menu === 'Realization & Evidence' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Realization & Evidence</button>
                        <button onClick={ () => setMenu('Evaluation List') } className={`${menu === 'Evaluation List' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Evaluation List</button>
                        <button onClick={ () => setMenu('Peer Evaluation') } className={`${menu === 'Peer Evaluation' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-[80px]`}>Peer Evaluation</button>
                    </div>

                    {menu === 'Realization & Evidence' ? (
                        <>
                        <div className='flex lg:flex-row items-center justify-between  '>
                            <div>
                                <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-[#113B61] font-medium border rounded-lg'>
                                    <BiExport className='text-lg' />
                                    <h1>Export</h1>
                                </button>
                            </div>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                                    <BiSearch className='absolute left-[12px] text-lg' />
                                    <input type="text" placeholder='Search...' className='outline-none text-[10px] w-full' />
                                </div>
                                <button onClick={() => navigate('/performance/detail-evaluation/add-evaluation')} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Evaluation Period</h1>
                                </button>
                            </div>
                        </div>

                        <div className='mt-[44px] mb-[44px] overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[15px] min-w-[280px] max-w-[280px]'>
                                        <h1 className='text-[#40444C] text-xs font-medium'>Evaluation Period</h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[280px] max-w-[280px]'>
                                        <h1 className='text-[#40444C] text-xs font-medium'>Realisation and Evidance </h1>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[280px] max-w-[280px]'>
                                        <h1 className='text-[#40444C] text-xs font-medium'>Feedback</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-[#737373] text-xs font-[600]'>Action</h1>
                                    </div>
                                </div>

                                <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[280px] max-w-[280px]'>
                                        <h1 className='text-[#40444C] text-xs font-medium '>Andi Dwinata</h1>
                                    </div>
                                    <div className='min-w-[280px] max-w-[280px]'>
                                        <h1 className='text-[#015995] font-med text-xs font-medium underline'>According to expectations</h1>
                                    </div>
                                    <div className='min-w-[280px] max-w-[280px]'>
                                        <h1 className='text-[#40444C] text-xs font-medium'>3 Realisation and Evidance</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <AiOutlineEye  className='text-[#003049]'/>
                                        </button>
                                        <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-[#003049]'/>
                                        </button>
                                        <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]'/>
                                        </button>
                                        
                                    </div>
                                </div>
                            </table>
                            {/* <Pagination
                            currentPage={currentPage} 
                            totalPages={totalPages} 
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                            /> */}
                            </div>
                        </>
                    ) : menu === 'Evaluation List' ? (
                        <>
                            <div className='mt-[44px] mb-[44px] overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[15px] min-w-[80px] max-w-[80px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>No</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>Evaluation Period</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>Work Result</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>Other Result</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>Peer Evaluation</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>Performance Predicate</h1>
                                        </div>
                                    </div>

                                    <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[80px] max-w-[80px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium '>1</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium '>Januari</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className={`${workResult === 'Sesuai Ekspektasi' ? 'bg-[#7EFFB2]' : workResult === 'Di Bawah Ekspektasi' ? 'bg-[#FF9F9F]' : 'bg-[#FFED86]'} text-[#25272C] font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>Sesuai Ekspektasi</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className={`${otherResult === 'Sesuai Ekspektasi' ? 'bg-[#7EFFB2]' : otherResult === 'Di Bawah Ekspektasi' ? 'bg-[#FF9F9F]' : 'bg-[#FFED86]'} text-[#25272C] font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>Sesuai Ekspektasi</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className={`${peerEvaluation === 'Sesuai Ekspektasi' ? 'bg-[#7EFFB2]' : peerEvaluation === 'Di Bawah Ekspektasi' ? 'bg-[#FF9F9F]' : 'bg-[#FFED86]'} text-[#25272C] font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>Sesuai Ekspektasi</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className={`${performancePredicate === 'Good' ? 'bg-[#69D3EB]' : performancePredicate === 'Di Bawah Ekspektasi' ? 'bg-[#FF9F9F]' : 'bg-[#FFED86]'} text-[#25272C] font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>Good</h1>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[80px] max-w-[80px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium '>2.</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium '>Februari</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className={`${workResult === 'Sesuai Ekspektasi' ? 'bg-[#7EFFB2]' : workResult === 'Di Bawah Ekspektasi' ? 'bg-[#FF9F9F]' : 'bg-[#FFED86]'} text-[#25272C] font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>Sesuai Ekspektasi</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className={`${otherResult === 'Sesuai Ekspektasi' ? 'bg-[#7EFFB2]' : otherResult === 'Di Bawah Ekspektasi' ? 'bg-[#FF9F9F]' : 'bg-[#FFED86]'} text-[#25272C] font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>Sesuai Ekspektasi</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className={`${peerEvaluation === 'Sesuai Ekspektasi' ? 'bg-[#7EFFB2]' : peerEvaluation === 'Di Bawah Ekspektasi' ? 'bg-[#FF9F9F]' : 'bg-[#FFED86]'} text-[#25272C] font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>Sesuai Ekspektasi</h1>
                                        </div>
                                        <div className='min-w-[200px] max-w-[200px]'>
                                            <h1 className={`${performancePredicate === 'Good' ? 'bg-[#69D3EB]' : performancePredicate === 'Di Bawah Ekspektasi' ? 'bg-[#FF9F9F]' : 'bg-[#FFED86]'} text-[#25272C] font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>Good</h1>
                                        </div>
                                    </div>
                                </table>
                            </div>
                        </>
                    ) : menu === 'Peer Evaluation' ? (
                        <>
                            <div className='border rounded-xl py-[24px] px-[23px]'>
                                <div className='space-y-[5px]'>
                                    <h1 className="text-neutral-500 text-[11px] font-medium">Approach</h1>
                                    <div className='w-2/5'>
                                        <div className="mb-[12px]">
                                            <div className='relative w-full'>
                                                <select name="" id="" className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                    <option selected value="">Select Employee...</option>
                                                    <option value="Option 1">Option 1</option>
                                                    <option value="Option 2">Option 2</option>
                                                    <option value="Option 3">Option 3</option>
                                                </select>
                                                <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h1 className="text-zinc-700 text-xl font-semibold">Perilaku Kerja</h1>

                            <div className='mt-[44px] mb-[44px] overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[15px] min-w-[80px] max-w-[80px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>No</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>Berorientasi Pada Pelayanan</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>Leader Specific Expectations</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium'>Feedback</h1>
                                        </div>
                                        <div className='flex items-center justify-center gap-[15px] w-full'>
                                            <h1 className='text-[#737373] text-xs font-[600]'>Action</h1>
                                        </div>
                                    </div>

                                    <div className='flex items-center gap-2 bg-[#F9F9F9] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[80px] max-w-[80px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium '>1</h1>
                                        </div>
                                        <div className='min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium '>Januari</h1>
                                        </div>
                                        <div className='min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-[#40444C] text-xs font-medium '>Januari</h1>
                                        </div>
                                        <div className='min-w-[150px] max-w-[150px]'>
                                            <h1 className={`${feedback === 'Sesuai Ekspektasi' ? 'bg-[#7EFFB2]' : feedback === 'Di Bawah Ekspektasi' ? 'bg-[#FF9F9F]' : feedback === 'Di Atas Ekspektasi' ? 'bg-[#FFED86]' : ''} text-[#25272C] font-semibold text-xs rounded-lg text-center px-[12px] py-[8px] w-fit`}>Sesuai Ekspektasi</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            {!feedback ? (
                                                <button onClick={ () => setShowAddFeedback(!showAddFeedback)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]'/>
                                                </button>
                                            ) : (
                                                <button disabled className='w-[29px] h-[29px] bg-[#D8DBDF] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#6B7280]'/>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </table>
                            </div>
                        </>
                    ) : null}
            </div>
            </div>
        </div>
    </div>
  )
}

export default DetailEvaluation