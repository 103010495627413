import React, { useEffect, useState } from 'react'
import { Navbar, Sidebar } from '../../../../components'
import { FaClipboardList } from 'react-icons/fa'
import { BsEnvelope, BsEnvelopeOpen, BsFillCalendarDateFill } from 'react-icons/bs'
import { FiTrash } from 'react-icons/fi'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import Api from '../../../../Api'

const Inbox = () => {
    const navigate = useNavigate()

    const [inboxData, setInboxData] = useState('')
    const [refresh, setRefresh] = useState(false)

    const GetInbox = async() => {
        try {
            const response = await Api.GetInbox(localStorage.getItem('hris-token'))
            console.log(response, 'inboxdata')
            setInboxData(response.data.data.sort((a, b) => {
                const timeA = new Date(a.createdAt).getTime();
                const timeB = new Date(b.createdAt).getTime();
                return timeB - timeA;
            }));
        } catch (error) {   
            console.log(error)
        }
    }

    const handleButton = async(data) => {
        const updateData = {
            id: data.id
        }
        try {
            if (data.type === 'Document') {
                navigate('/document/answer-document', {state: {documentId: data.applicantId,}})
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            } else {
                navigate('/inbox/inbox-detail', {state: {inboxId: data.id, applicantId: data.applicantId, type: data.type}})
                const response = await Api.UpdateStatusReadInbox(localStorage.getItem('hris-token'), updateData)
            }
            setRefresh(true)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetInbox()
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Inbox'} LinkBack={'/dashboard'}/>
                <div className='space-y-[33px] bg-white border px-[36px] py-[26px] rounded-[12px]'>
                    <div className='flex flex-col lg:flex-row items-start lg:items-center justify-between'>
                        <div>
                            <h1 className='text-[#454545] text-[20px] font-[600]'>Inbox</h1>
                            <h1 className='text-[#737373] text-[10px]'>12 unread messages</h1>
                        </div>  
                        <div className='flex flex-col lg:flex-row items-start lg:items-center gap-[5px] mt-2 lg:mt-0'>
                            <button className='flex items-center gap-[13px] border border-[#A8A8A8] rounded-[6px] px-[13px] py-[11px] w-full lg:w-auto'>
                                <BsFillCalendarDateFill className='text-[#737373]'/>
                                <h1 className='text-[#737373] text-[12px] font-[500]'>Filter By Date</h1>
                            </button>
                            {/* <Link to={'/inbox/approval'} className='flex items-center gap-[13px] border border-[#A8A8A8] rounded-[6px] px-[13px] py-[11px] w-full lg:w-auto'>
                                <FaClipboardList className='text-[#737373]'/>
                                <h1 className='text-[#737373] text-[12px] font-[500]'>History Approval</h1>
                            </Link> */}
                        </div>
                    </div>
                    <div className='space-y-[5px] overflow-auto'>
                        {Object.values(inboxData).map((data, index) => {
                            return(
                                <button key={index} onClick={ () => handleButton(data)} className={`lg:w-full w-[600px] flex items-center justify-between border ${data.isRead === true ? 'bg-white' : 'bg-[#EBF7FF]'} rounded-[6px] p-[16px]`}>
                                    <div className='flex items-center gap-[40px]'>
                                        {data.isRead === true ? 
                                        <BsEnvelopeOpen className='text-[#737373]'/>
                                        :
                                        <BsEnvelope className='text-[#737373]'/>
                                        }
                                        <h1 className='text-[#737373] text-sm'>{data.title}</h1>
                                    </div>
                                    <div className='flex items-center gap-[20px]'>
                                        <h1 className='text-[#737373] text-sm'>{moment(data.createdAt).format('HH:MM')}</h1>
                                        <button>
                                            <FiTrash className='text-[#737373] hover:text-[#C1121F]'/>
                                        </button>
                                    </div>
                                </button>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inbox