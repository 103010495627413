import React, { useEffect, useState } from "react";
import PollingStepper from "./PollingStepper";
import { Navbar } from "../../../../../components";
import axios from "axios";

const PollingForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [dataPolling, setDataPolling] = useState()
  const [questionPolling, setQuestionPolling] = useState()
  const [lengthData, setLengthData] = useState(0)

  const handleNext = () => {
    if (currentStep < lengthData - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const getPolling = () => {
    axios
      .get(
        `https://mocki.io/v1/54b72d53-f44e-45a1-8360-3f6540924a66`
      )
      .then((response) => {
        setDataPolling(response.data.polls[currentStep].steps);
        setQuestionPolling(response.data.polls[currentStep].question);
        setLengthData(response.data.polls.length)
      })
      .catch((error) => {
        console.log(error)
      });
    };

  useEffect(() => {
    getPolling()
  })

  if (!dataPolling) {
    return <div>Loading...</div>
  }

  return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Polling Form'} LinkBack={'/dashboard/polling'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div>
                            <h1 className="text-black text-[15px] font-medium mb-[16px]">Outing place voting form</h1>
                            <div>
                              <PollingStepper
                                  step={dataPolling}
                                  question={questionPolling}
                                  currentStep={currentStep}
                                  totalStep={lengthData}
                                  onPrevious={handlePrevious}
                                  onNext={handleNext}
                                  isFirst={currentStep === 0}
                                  isLast={currentStep === lengthData - 1}
                              />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
  );
};

export default PollingForm;
