import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar } from '../../../../../components'
import { AiOutlinePlus } from 'react-icons/ai'
import { MdImportExport } from 'react-icons/md'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { BiSearch } from 'react-icons/bi'
import Api from '../../../../../Api'
import toast from 'react-hot-toast'

const OfficialWorkTravelSetting = () => {
    const [showAddOfficialWorkTravel, setShowAdddOfficialWorkTravel] = useState(false)
    const [showEditOfficialWorkTravel, setShowEditdOfficialWorkTravel] = useState(false)
    const [data, setData] = useState('')
    const [typeName, setTypeName] = useState('')
    const [idData, setIdData] = useState('')
    const [refresh, setRefresh] = useState()

    const getOfficialWorkTravelSetting = async () => {
        try {
            const response = await Api.GetWorkTravelSetting(localStorage.getItem('hris-token'))
            console.log(response)
            setData(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const openEditOfficialWorkTravelSetting = async (id) => {
        setIdData(id)
        setShowEditdOfficialWorkTravel(!showEditOfficialWorkTravel)
        try {
            const response = await Api.GetWorkTrravelSettingById(localStorage.getItem('hris-token'), id)
            setTypeName(response.data[0].type_name)
        } catch (error) {
            console.log(error)
        }
    }

    const editOfficialWorkTravelSetting = async () => {
        const data = {
            'type_name' : typeName
        }
        try {
            const response = await Api.UpdateWorkTravelSetting(localStorage.getItem('hris-token'), data, idData)
            toast.success('Success Create Official Work Travel Setting!')
            setRefresh(true)
            setTypeName('')
            setShowEditdOfficialWorkTravel(!showEditOfficialWorkTravel)
        } catch (error) {
            console.log(error)
            toast.error('Failed Update Official Work Travel Setting!')

        }
    }


    const createOfficialWorkTravelSetting = async () => {
        const data ={
            'type_name': typeName
        }
        try {
            const response = await Api.CreateWorkTravelSetting(localStorage.getItem('hris-token'), data)
            toast.success('Success Create Official Work Travel Setting!')
            setRefresh(true)
            setTypeName('')
            setShowAdddOfficialWorkTravel(!showAddOfficialWorkTravel)
        } catch (error) {
            console.log(error)
            toast.error('Failed Create Official Work Travel Setting!')
        }
    }

    const [showModalOfficialWorkTravelSetting, setShowModalOfficialWorkTravelSetting] = useState()

    const deleteOfficialWorkTravelSettingModal = (id) =>{
        setShowModalOfficialWorkTravelSetting(!showModalOfficialWorkTravelSetting)
        setIdData(id)
    }

    const deleteOfficialWorkTravelSetting = async () => {
        try {
            const response = await Api.DeleteWorkTravelSetting(localStorage.getItem('hris-token'), idData)
            setShowModalOfficialWorkTravelSetting(!showModalOfficialWorkTravelSetting)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }

    useEffect(() => {
        getOfficialWorkTravelSetting()
        setRefresh(false)
    }, [refresh])
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
        <ModalDelete
            activeModal={showModalOfficialWorkTravelSetting}
            buttonClose={() => setShowModalOfficialWorkTravelSetting(!showModalOfficialWorkTravelSetting)}
            submitButton={deleteOfficialWorkTravelSetting}
        />
        <Modal 
            activeModal={showEditOfficialWorkTravel}
            title={'Edit Official Work Travel Type '}
            buttonClose={ () => setShowEditdOfficialWorkTravel(!showEditOfficialWorkTravel)}
            width={'550px'}
            content= {
                <div className='space-y-[24px] w-[full]'>
                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Type Name <span className='text-red-600'>*</span></h1>
                        <input onChange={(e) => setTypeName(e.target.value)} value={typeName} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Input official work travel name...'/>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowEditdOfficialWorkTravel(!showEditOfficialWorkTravel)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={editOfficialWorkTravelSetting} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Save</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showAddOfficialWorkTravel}
            title={'Add Official Work Travel Type '}
            buttonClose={ () => setShowAdddOfficialWorkTravel(!showAddOfficialWorkTravel)}
            width={'550px'}
            content= {
                <div className='space-y-[24px] w-[full]'>
                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Type Name <span className='text-red-600'>*</span></h1>
                        <input onChange={(e) => setTypeName(e.target.value)} value={typeName} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Input official work travel name...'/>
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAdddOfficialWorkTravel(!showAddOfficialWorkTravel)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={createOfficialWorkTravelSetting} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Submit</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <div className='w-full space-y-[24px] overflow-hidden'>
            <Navbar  SubNavbar={true} NameSubNavbar={'Official Work Travel Setting'} LinkBack={'/time-management-setting'}/>
            <h1 className="text-zinc-800 text-xl font-semibold">Official Work Travel</h1>
            <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
            <div className="py-5 space-y-5 bg-white">
                <div>
                    <h1 className='text-xl font-semibold text-[#25272C]'>Type</h1>
                    <h2 className='text-xs text-[#6B7280]'>list of Official Work Travel type</h2>
                </div>
                <div className="flex items-center justify-between mb-[36px]">
                    <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-[#113B61] font-medium border rounded-lg'>
                        <CgTrashEmpty className='text-lg' />
                        <h1>Delete</h1>
                    </button>
                    <div className='flex gap-[12px] items-center'>
                        <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                            <BiSearch className='absolute left-[12px] text-lg' />
                            <input type="text" placeholder='Search...' className='outline-none text-[10px] w-full' />
                        </div>
                        <button onClick={() => setShowAdddOfficialWorkTravel(!showAddOfficialWorkTravel)}  className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                            <AiOutlinePlus  className='text-xl text-white'/>
                            <h1 className='text-[#EDEDED] text-sm font-[500]'>Add New Type</h1>
                        </button>
                    </div>
                </div>
                <div>
                    <div className='overflow-auto scrollbar-hide'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[6px] w-1/2'>
                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Type Name</h1>
                                    <MdImportExport className='text-[#737373] text-xs'/>
                                </div>
                                
                                <div className='flex items-center justify-center gap-[6px] w-1/2'>
                                    <h1 className='text-[#737373] text-[11px] font-[500]'>Action </h1>
                                    <MdImportExport className='text-[#737373] text-xs'/>
                                </div>
                            </div>
                            {Object.values(data).map((item, idx) => (
                                <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='w-1/2'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.type_name?? '-'}</h1>
                                    </div>
                                    <div className='w-1/2 flex items-center justify-center gap-[12px]'>
                                        <button onClick={() => openEditOfficialWorkTravelSetting(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-[#003049]'/>
                                        </button>
                                        <button onClick={() => deleteOfficialWorkTravelSettingModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]'/>
                                        </button>
                                    </div>
                                </div>
                            ))}
                            
                        </table>
                    </div>
                    {/* <Pagination
                        currentPage={currentPage} 
                        totalPages={totalPages} 
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    /> */}
                </div>
                
            </div>
            </div>
        </div>
    </div>
  )
}

export default OfficialWorkTravelSetting