import React, { useState } from 'react'
import { Logo, Landing1, Logo2 } from '../../../assets'
import { Link, useNavigate } from 'react-router-dom'
import Api from '../../../Api'
import { toast } from 'react-hot-toast'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordType, setPasswordType] = useState('password')

    const login = async() => {
        try {
            const response = await Api.Login(email, password)
            if(response.data.results.data.user.role === 'SUPERADMIN') {
                toast.error("Invalid email and/or password!!!")   
            } else {
                localStorage.setItem('hris-token', response.data.results.data.token)
                localStorage.setItem('hris-role', response.data.results.data.user.role)
                navigate('/dashboard')
            }
        } catch (error) {
            console.log(error)
            toast.error("Invalid email and/or password!!!")   
        }
    }

    const ChangePasswordType = () => {
        if(passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }

    return (
        <div>
            <div className='lg:grid lg:grid-cols-12'>
                <div className='lg:col-span-7 bg-[#ECEEF6] '>
                    <div className='flex flex-col justify-between h-[100%]'>
                        <div className='px-[85px] mt-[51px]'>
                            <img src={Logo2} className='w-[203px]' alt='Humanusia Logo' />
                        </div>
                        <div className=''>
                            <img src={Landing1} className='' alt='LandingImage' />
                        </div>
                    </div>
                </div>
                <div className='lg:col-span-5 bg-white px-5 lg:px-[82px] py-[170px]'>
                    <h1 className='text-[30px] text-[#2F3044] font-medium'>Welcome Back </h1>
                    <h2 className='text-[#A8A8A8] text-[12px] mb-[34px]'>Log in back into your account</h2>
                    
                    <div className=''>
                        <h1 className='text-[13px] text-[#454545] font-medium mb-2'>Email</h1>
                        <input onChange={ (e) => setEmail(e.target.value) } type='text' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-[15px]" placeholder='Enter your email...' />
                        <h1 className='text-[13px] text-[#454545] font-medium mb-2'>Password</h1>
                        <div className='relative border'>
                            <input onChange={ (e) => setPassword(e.target.value) } type={passwordType} className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px]" placeholder='Password' />
                            <button onClick={ChangePasswordType} className='absolute right-5 top-3 text-xl text-[#A8A8A8]'>
                                {passwordType === 'text' ?
                                    <AiOutlineEye/>
                                :
                                    <AiOutlineEyeInvisible/>
                                }
                            </button>
                        </div>
                        <button onClick={login} className='py-[10px] px-[195px] text-white rounded-md bg-[#0E5073] text-[12px] w-full font-semibold mb-[14px] mt-[15px]'>Login</button>
                        <div className='flex gap-1'>
                            <h1 className='text-[14px] text-[#737373]'>Don’t have an account? </h1>
                            <Link to='/register'>
                                <h1 className='text-[14px] text-[#001A27] font-semibold underline'>Sign Up</h1>
                            </Link>
                        </div>
                </div>
            </div>
            </div>
        </div >
    )
}

export default Login