import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import "react-big-calendar/lib/css/react-big-calendar.css";
import enUS from 'date-fns/locale/en-US'
import React, { useEffect, useMemo, useState } from "react"
import DatePicker from 'react-datepicker'
import "./style.css"
import Api from '../../../../Api'

const CalendarTable = (refreshPage) => {

  //Get Event
  const [dataEvent, setDataEvent] = useState('')
  const [dataTodo, setDataTodo] = useState('')
  const [refresh, setRefresh] = useState(false)

  const [employeeId, setEmployeeId] = useState([]);

  const locales = {
    'en-US': enUS,
  }
  
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  })
  
  const { views, ...otherProps } = useMemo(
    () => ({
      views: {
        month: true,
      },
    }),
    []
  );
  


  const getEvent = async () => {
    try{
      const response = await Api.GetEvent(localStorage.getItem('hris-token'))
      setDataEvent(response.data.event)
      console.log(response)
    } catch (error){
      console.log(error)
    }
  }
  const getTodo = async () => {
    try{
      const response = await Api.GetTodo(localStorage.getItem('hris-token'))
      setDataTodo(response.data.myTodo)
      console.log(response)
    } catch (error){
      console.log(error)
    }
  }

  var dataNew = [];

  [...dataTodo, ...dataEvent].forEach(val => {
    if (val.employeeId == employeeId || val.employeeId == null) {
        dataNew.push({
            title: val.tittle,
            start: val.start_date || val.start, // Adjust based on the property name in the objects
            end: val.end_date || val.end, // Adjust based on the property name in the objects
        });
    }
});
  
  useEffect(() =>{
    getEvent()
    getTodo()
  }, [refreshPage, refresh])

  return (
    <div>
        <Calendar
          views={views}
          events={dataNew} 
          localizer={localizer} 
          startAccessor="start" 
          endAccessor="end" 
          style={{height:500, fontSize:12}} />
    </div>
  )
}

export default CalendarTable