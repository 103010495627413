import React, { useEffect, useState } from 'react'
import { Modal, Navbar, Pagination, Sidebar } from '../../../../components'
import { TbFileExport } from 'react-icons/tb'
import { BiCheckbox, BiSearch } from 'react-icons/bi'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { FiEye } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../Api'
import moment from 'moment'

const ArchiveApplicant = () => {
    const [showModalDetailInformation, setShowModalDetailInformation] = useState()
    const navigate = useNavigate()

    const [dataApplicant, setDataApplicant] = useState('')
    const [dataApplicantDetailModal, setDataApplicantDetailModal] = useState('')
    const [refresh, setRefresh] = useState(false)

    const limit = 10
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const GetEntryApplication = async() => {
        try {
            const response = await Api.GetRecruitmentApplicant(localStorage.getItem('hris-token'), currentPage, limit)
            setDataApplicant(response.data.applicants.filter(item => item.status === false))
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDetailModal = async(id) => {
        setShowModalDetailInformation(!showModalDetailInformation)
        try {
            const response = await Api.GetRecruitmentApplicantById(localStorage.getItem('hris-token'), id)
            console.log(response)
            setDataApplicantDetailModal(response.data.applicant)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetEntryApplication()
    }, [])

  return (
    <div>
        <Modal 
                activeModal={showModalDetailInformation}
                title={'Detail Information'}
                buttonClose={ () => setShowModalDetailInformation(!showModalDetailInformation)}
                width={'832px'}
                content= {
                    <div className='space-y-[20px] w-[full]'>
                        <div className='space-y-[24px] bg-[#F8F8F8] px-[28px] py-[40px] rounded-[12px] border'>
                            <div className='pb-[39px]'>
                                <h1 className='text-[#5C5C5C] text-[15px] font-semibold mb-[28px]'>Personal Detail</h1>
                                <div className='flex gap-[87px] text-[#737373] text-[11px]'>
                                    <div className='font-medium space-y-[27px]'>
                                        <h1>Full Time</h1>
                                        <h1>Birth Date</h1>
                                        <h1>Gender</h1>
                                        <h1>Age</h1>
                                        <h1>Email</h1>
                                        <h1>Sumber Lowongan</h1>
                                        <h1>Tanggal Melamar</h1>
                                        <h1>Position</h1>
                                        <h1>Nomor Telepon</h1>
                                        <h1>Alamat KTP</h1>
                                        <h1>Alamat Domisili</h1>
                                    </div>
                                    <div className='space-y-[27px]'>
                                        <h1>: {dataApplicantDetailModal.name? dataApplicantDetailModal.name : '-'}</h1>
                                        <h1>: {dataApplicantDetailModal.birthDate? moment(dataApplicantDetailModal.birthDate).format('DD-MM-YYYY') : '-'}</h1>
                                        <h1>: {dataApplicantDetailModal.gender? dataApplicantDetailModal.gender : '-'}</h1>
                                        <h1>: {dataApplicantDetailModal.age? dataApplicantDetailModal.age : '-'} </h1>
                                        <h1>: {dataApplicantDetailModal.email? dataApplicantDetailModal.email : '-'}</h1>
                                        <h1>: {dataApplicantDetailModal.source? dataApplicantDetailModal.source : '-'}</h1>
                                        <h1>: {dataApplicantDetailModal.createdAt? moment(dataApplicantDetailModal.createdAt).format('DD-MM-YYYY') : '-'} </h1>
                                        <h1>: {dataApplicantDetailModal.recruitment? dataApplicantDetailModal.recruitment.position : '-'}</h1>
                                        <h1>: {dataApplicantDetailModal.phone? dataApplicantDetailModal.phone : '-'} </h1>
                                        <h1>: {dataApplicantDetailModal.address? dataApplicantDetailModal.address : '-'}</h1>
                                        <h1>: {dataApplicantDetailModal.address? dataApplicantDetailModal.address : '-'}</h1>
                                    </div>
                                </div>
                            </div>
                            <hr className='border-b-1 border-[#A8A8A8]' />
                            <div className='mb-[34px]'>
                                <h1 className='mb-[30px] text-[13px] font-semibold text-[#5C5C5C]'>Education</h1>
                                
                                <div className='text-[#737373] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>Universitas Gajah Mada </h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>Teknik Fisika</h3>
                                    <p className='mb-[9px] font-light text-[10px] text-[#A8A8A8]'>3,89</p>
                                    <hr />
                                </div>
                                <div className='text-[#737373] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>Universitas Gajah Mada </h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>Teknik Fisika</h3>
                                    <p className='mb-[9px] font-light text-[10px] text-[#A8A8A8]'>3,89</p>
                                </div>
                            <hr className='border-b-1 border-[#A8A8A8]' />
                            </div>
                            <div className='mb-[49px]'>
                                <h1 className='mb-[30px] text-[13px] font-semibold text-[#5C5C5C]'>Experience details</h1>
                                <div className='text-[#737373] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>Machine Learning Engineering </h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>Tokopedia - <span className='text-[#A8A8A8]'>Fulltime</span></h3>
                                    <p className='mb-[9px] font-light text-[10px] text-[#A8A8A8]'>Jan 2021 - Sep 2021 · 9 bln</p>
                                    <hr />
                                </div>
                                <div className='text-[#737373] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>Machine Learning Engineering </h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>Tokopedia - <span className='text-[#A8A8A8]'>Partime</span></h3>
                                    <p className='mb-[9px] font-light text-[10px] text-[#A8A8A8]'>Okt 2021 - Jun 2022 · 9 bln</p>
                                    <hr />
                                </div>
                                <div className='text-[#737373] mb-[21px]'>
                                    <h2 className='font-semibold mb-[7px] text-[11px]'>Data Analyst</h2>
                                    <h3 className='font-medium mb-[5px] text-[10px]'>Traveloka-<span className='text-[#A8A8A8]'>Contract</span></h3>
                                    <p className='mb-[9px] font-light text-[10px] text-[#A8A8A8]'>Jan 2022 - Saat ini · 9 bln</p>
                                    <hr />
                                </div>
                            </div>
                            <hr className='border-b-1 border-[#A8A8A8]' />
                            <div className='grid grid-cols-4 gap-y-[49px]'>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>CV Terbaru</h1>
                                    <button className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</button>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>KTP</h1>
                                    <button className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</button>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Kartu Keluarga</h1>
                                    <button className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</button>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Ijazah Terkahir</h1>
                                    <button className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</button>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Transkrip Nilai </h1>
                                    <button className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</button>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Sertifikat Vaksin </h1>
                                    <button className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</button>
                                </div>
                                <div>
                                    <h1 className='text-[#737373] text-[11px] font-semibold mb-[8px]'>Portofolio</h1>
                                    <button className='w-[78px] h-[30px] rounded-[5px] px-[8px] py-[6px] text-[12px] text-white font-semibold bg-gradient-to-r from-[#06B6D4] to-[#3B82F6]'>Download</button>
                                </div>
                            </div>
                        </div>
                        <button onClick={() => navigate('/recruitment/recruitment-stage')} className='text-[#219EBC] text-[14px] font-medium underline'>See Recruitment Stage</button>
                    </div>
                }
            />
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Archive Applicant'} LinkBack={'/recruitment/entry-application'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div>
                        <h1 className='text-[#454545] font-semibold text-[20px]'>Archive Applicant</h1>
                        <h2 className='text-[#737373] text-[10px]'>List of rejected applicant</h2>
                    </div>
                    <div className="flex gap-[20px] mt-[31px] relative">
                        <input type="date" className='py-[11.5px] px-[10px] border rounded-md text-[12px]' />
                        <button className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                            <TbFileExport className='text-[#003049]'/>
                            <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                        </button>
                        <div className="absolute right-0">
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative'>
                                    <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                    <input placeholder='Search...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Rejected Date </h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[180px] max-w-[180px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Full Name</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[180px] max-w-[180px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Position</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Apply Date </h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Phone Number</h1>
                                    </div>
                                    <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Source of vacancies</h1>
                                    </div>
                                    <div className='flex items-center justify-center gap-[6px] w-full'>
                                        <h1 className='text-[#737373] text-[11px] font-[500]'>Actions</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                </div>
                                {Object.values(dataApplicant).map((item, idx) => (
                                    <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.updatedAt? moment(item.updatedAt).format('DD-MM-YYYY') : '-'}</h1>
                                        </div>
                                        <div className='min-w-[180px] max-w-[180px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.name? item.name :'-'}</h1>
                                        </div>
                                        <div className='min-w-[180px] max-w-[180px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.recruitment? item.recruitment.position : '-'}</h1>
                                        </div>
                                        <div className='min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.createdAt? moment(item.createdAt).format('DD-MM-YYYY') : '-'}</h1>
                                        </div>
                                        <div className=' min-w-[120px] max-w-[120px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.phone? item.phone :'-'}</h1>
                                        </div>
                                        <div className=' min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.source? item.source :'-'}</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <button onClick={() => handleDetailModal(item.id)}  className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <FiEye className='text-[#003049]'/>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage} 
                            totalPages={totalPages} 
                            onPageChange={handlePageChange}
                            onPrevChange={handlePrevChange}
                            onNextChange={handleNextChange}
                        />
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default ArchiveApplicant