import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { BiPlus } from 'react-icons/bi'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { ModalDelete, Navbar, Pagination} from '../../../../../components'
import Api from '../../../../../Api'
import { toast } from 'react-hot-toast'

const Subsidiary = () => {

    const [showDetail, setShowDetail] = useState(false)
    const [dataSubsidiary, setDataSubsidiary] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [refresh, setRefresh] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [totalData, setTotalData] = useState('')

    const navigate = useNavigate()


    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const Fetch = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setCompanyId(response.data.results.data.companyId)
            console.log(response, 'fetch')
        } catch (error) {
            console.log(error)
        }
    }

    const getSubsidiary = async () => {
        try {
            const response = await Api.GetSubsidiary(localStorage.getItem('hris-token'))
            setDataSubsidiary(response.data.data)
            setCurrentPage(response.data.currentPage)
            setTotalPages(response.data.totalPages)
            setTotalData(response.data.total)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    // const getCompanyById = async () => {
    //     try {
    //         const response = await Api.GetCompanyById(localStorage.getItem('hris-token'), companyId)
    //         setCompanyName
    //     } catch (error) {
    //         console.log(response)
    //     }
    // }

    const deleteSubsidiary = async () => {
        try {
            const response = await Api.DeleteSubsidiary(localStorage.getItem('hris-token'), idSubsidiary)
            setShowModalDelete(!showModalDelete)
            setRefresh(true)
            toast.success('Success delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed delete!')
        }
    }

    const [showModalDelete, setShowModalDelete] = useState()
    const [idSubsidiary, setIdSubsidiary] = useState('')

    const deleteSubsidiaryModal = (id) =>{
        setShowModalDelete(!showModalDelete)
        setIdSubsidiary(id)
        setRefresh(true)
    }

    useEffect(() => {
        getSubsidiary()
        Fetch()
        setRefresh(false)
    }, [refresh])

    
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteSubsidiary}
            />
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Subsidiary'} LinkBack={'/dashboard'}/>
                    <div>
                        <div className='bg-[#EDEDED] px-[28px] py-[10px] rounded-t-[12px] border w-fit'>
                            <h1 className='text-sm font-bold text-[#A8A8A8]'>PT Powerkerto Wahyu Keprabon</h1>
                        </div>
                        <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-b-[12px] rounded-tr-[12px] border'>
                            <div className='flex flex-col lg:flex-row items-center justify-between mb-[36px]'>
                                <div className='w-full lg:w-auto'>
                                    <h1 className='text-[#454545] text-[16px] font-[500]'>
                                        Subsidiary
                                    </h1>
                                    <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                        list of subsidiary
                                    </h1>
                                </div>
                                <div className='flex items-center gap-[13px] mt-3 lg:mt-0 w-full lg:w-auto'>
                                    <Link to={'/subsidiary/add-subsidiary'} className='bg-[#0E5073] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                        <BiPlus className='text-white'/>
                                        <h1 className='text-white text-sm font-[500]'>Add Subsidiary</h1>
                                    </Link>
                                </div>
                            </div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[500]'>Subsidiary Name</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[500]'>Username</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[500]'>Registration Number</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#737373] text-xs font-[500]'>Status</h1>
                                        </div>
                                        <div className='flex items-center gap-[15px] justify-center w-full'>
                                            <h1 className='text-[#737373] text-xs font-[500]'>Action</h1>
                                        </div>
                                    </div>
                                    <div>
                                        {Object.values(dataSubsidiary).map((item, idx) => (
                                            <>
                                                <div className={`flex items-center gap-3 bg-white border px-[14px] py-[8px] ${showDetail ? 'rounded-t-[3px]' : 'rounded-[3px]'}`}>
                                                    <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{item.company_name?? '-'}</h1>
                                                    </div>
                                                    <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{item.user[0].fullname?? '-'}</h1>
                                                    </div>
                                                    <div className='min-w-[200px] max-w-[200px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>{item.registration_number?? '-'}</h1>
                                                    </div>
                                                    <div className='min-w-[150px] max-w-[150px]'>
                                                        <h1 className='text-[#737373] text-xs font-[500] truncate'>Enable</h1>
                                                    </div>
                                                    <div className='flex items-center justify-center w-full gap-[12px]'>
                                                        <button onClick={() => navigate('/subsidiary/detail-subsidiary',  {state: {subsidiaryId: item.id}})} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <AiOutlineEye className='text-[#003049]'/>
                                                        </button>
                                                        <button onClick={() => navigate('/subsidiary/edit-subsidiary',  {state: {subsidiaryId: item.id}})} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <HiOutlinePencil className='text-[#003049]'/>
                                                        </button>
                                                        <button onClick={ () => deleteSubsidiaryModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                            <CgTrashEmpty className='text-[#003049]'/>
                                                        </button>
                                                        <button onClick={ () => setShowDetail(!showDetail)} className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                                            <MdKeyboardArrowDown className='text-[#003049]'/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${ showDetail ? 'h-52 border' : 'h-0 border-0' }`}>
                                                    <div className='grid grid-cols-12 gap-5 px-4 py-4'>
                                                        <div className='flex flex-col gap-3 col-span-2'>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Tax ID</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Phone Number</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Fax</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Email</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Address</h1>
                                                        </div>
                                                        <div className='flex flex-col gap-3 col-span-1'>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        </div>
                                                        <div className='flex flex-col gap-3 col-span-3'>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.tax_id?? '-'}</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.phone?? '-'}</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.fax?? '-'}</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.email?? '-'}</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.address?? '-'}</h1>
                                                        </div>
                                                        <div className='flex flex-col gap-3 col-span-2'>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>City</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Province</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Postal Code</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Country</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>Notes</h1>
                                                        </div>
                                                        <div className='flex flex-col gap-3 col-span-1'>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500]'>:</h1>
                                                        </div>
                                                    
                                                        <div className='flex flex-col gap-3 col-span-3'>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.city?? '-'}</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.province?? '-'}</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.postal_code?? '-'}</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-1'>{item.country?? '-'}</h1>
                                                            <h1 className='text-[#A8A8A8] text-[10px] font-[500] line-clamp-4'>{item.notes?? '-'}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                        
                                    </div>
                                    <Pagination
                                        currentPage={currentPage} 
                                        totalPages={totalPages} 
                                        onPageChange={handlePageChange}
                                        onPrevChange={handlePrevChange}
                                        onNextChange={handleNextChange}
                                    />
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subsidiary