import React, { useState } from 'react'
import { Navbar } from '../../../../components'
import { IoMdArrowDropdown } from 'react-icons/io'

const AddEvaluation = () => {

    const [monthPeriod, setMonthPeriod] = useState('')

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Detail Evaluation'} LinkBack={'/performance/detail-evaluation'}/>
                    <h1 className="text-zinc-800 text-xl font-semibold">Evaluation</h1>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>

                        <div className='w-full lg:w-auto'>
                            <h1 className='text-[#454545] text-[20px] font-[600]'>Evaluation</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[400]'>List of evaluation employee</h1>
                        </div>

                        <div className='space-y-[4px]'>
                            <h1 className="text-zinc-700 text-base font-semibold ">Step :</h1>
                            <h2 className="text-gray-500 text-xs font-normal">1. Before filling out the assessment, select the month of the period first.</h2>
                            <h2 className="text-gray-500 text-xs font-normal">2. Uploading evidence and realizing the evidence being worked on.</h2>
                            <h2 className="text-gray-500 text-xs font-normal">3. Submit evidence and realization on the button “<span className="text-zinc-800 text-xs font-medium">Submit</span>.</h2>
                        </div>

                        <div className='space-y-[5px]'>
                            <h1 className="text-neutral-500 text-[11px] font-medium">Month of Period</h1>
                            <div className='w-2/5'>
                                <div className="mb-[12px]">
                                    <div className='relative w-full'>
                                        <select onChange={(e) => setMonthPeriod(e.target.value)} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                            <option selected value="">Select month...</option>
                                            <option value="January">January</option>
                                            <option value="February">February</option>
                                            <option value="March">March</option>
                                            <option value="April">April</option>
                                            <option value="Mei">Mei</option>
                                            <option value="June">June</option>
                                            <option value="July">July</option>
                                            <option value="August">August</option>
                                            <option value="September">September</option>
                                            <option value="October">October</option>
                                            <option value="November">November</option>
                                            <option value="December">December</option>
                                            <option value="Last Evaluation">Last Evaluation</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-[#6B7280]'/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEvaluation