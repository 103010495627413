import React from 'react'

const InboxDetailLeave = ({EmployeeName, JobPosition, StartDate, EndDate, Type, LeaveDuration, RemainingDayOff, Notes, DelegatedEmployee, DelegatedTask, EmergencyContact, EmergencyPhone, EmergencyAddress, onClickDecline, onClickApprove, buttonHide }) => {
    function getHourAndMinute(datetimeString) {
        const time = datetimeString.slice(0, 10);
        return time;
    }
  return (
    <div>
        <div  className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-full text-[#737373] text-[11px] font-semibold space-y-[21px]'>
            
            <div className='border-b-2'>
                <h1 className='text-[#2E2E2E] text-sm font-[500]'>Detail Leave</h1>
            </div>
            <h1 className=''>Full Name : {EmployeeName ? EmployeeName : 'Empty'}</h1>
            <h1 className=''>Job Position : {JobPosition ? JobPosition : 'Empty'}</h1>
            <hr />
            <h1>Start Date : {StartDate? getHourAndMinute(StartDate) : 'Empty'}</h1>
            <h1>End Date : {EndDate? getHourAndMinute(EndDate) : 'Empty'}</h1>
            <h1>Leave Duration : {LeaveDuration ? LeaveDuration : 'Empty'}</h1>
            <h1>Remaining Days Off : {RemainingDayOff ? RemainingDayOff : 'Empty'}</h1>
            <h1>Leave Type : {Type ? Type : 'Empty'}</h1>
            <h1>Notes : {Notes ? Notes : 'Empty'}</h1>
            <hr/>
            <h1>Delegated Employee : {DelegatedEmployee ? DelegatedEmployee : 'Empty'}</h1>
            <h1>Delegated Task : {DelegatedTask ? DelegatedTask : 'Empty'}</h1>
            <hr />
            <h1>Emergency Contact : {EmergencyContact ? EmergencyContact : 'Empty'}</h1>
            <h1>Emergency Phone Number : {EmergencyPhone ? EmergencyPhone : 'Empty'}</h1>
            <h1>Emergency Address : {EmergencyAddress ? EmergencyAddress : 'Empty'}</h1>
        </div>
        {buttonHide &&
                <div className='flex items-center justify-end gap-[20px] mt-10'>
                    <button onClick={onClickDecline} className='bg-[#C1121F] text-[#EDEDED] text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Decline
                    </button>
                    <button onClick={onClickApprove} className='bg-[#04BB00] text-[#EDEDED] text-[14px] font-[500] rounded-[6px] w-[100px] py-[10px] px-[25px]'>
                        Approve
                    </button>
                </div>
            }
    </div>
  )
}

export default InboxDetailLeave