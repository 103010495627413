import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination, Sidebar } from '../../../../components'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import {AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import { toast } from 'react-hot-toast'
import { useForm, Controller } from 'react-hook-form';
import { BiSearch } from 'react-icons/bi'


const LeaveSetting = () => {
  const [menu, setMenu] = useState('Leave Setting')
  const [showAddLeaveType, setShowAddLeaveType] = useState()
  const [showEditLeaveType, setShowEditLeaveType] = useState()
  const [showCustomLeave, setShowCustomLeave] = useState()
  const [data, setData] = useState('')
  const [showModalDelete, setShowModalDelete] = useState()

  //Post Data Leave Type
  const [typeName, setTypeName] = useState()
  const [maxDuration, setMaxDuration] = useState()
  const [maxSubmission, setMaxSubmission] = useState()
  const [submissionBefore, setSubmissionBefore] = useState()
  const [idLeaveType, setIdLeaveType] = useState()
  const [refresh, setRefresh] = useState(false)
  const limit = 5
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };


  const OpenEditLeaveType = async(id) => {
        setIdLeaveType(id)
        setShowEditLeaveType(!showEditLeaveType)
        try {
            const response = await Api.getLeaveTypeById(localStorage.getItem('hris-token'), id)
            setTypeName(response.data.type_name)
            setMaxDuration(response.data.max_duration)
            setMaxSubmission(response.data.max_submission)
            setSubmissionBefore(response.data.submission_before)
        } catch (error) {
            console.log(error)
        }
    }
    
    const resetForm = () => {
        setTypeName('')
        setSubmissionBefore('')
        setMaxDuration('')
        setMaxSubmission('')
    }

  const postDataLeaveType = async () => {
    try{
        const data ={
            type_name: typeName,
            submission_before: submissionBefore,
            max_duration: maxDuration,
            max_submission: maxSubmission
        }
        const response = await Api.PostLeaveType(localStorage.getItem('hris-token'), data)
        resetForm()
        setShowAddLeaveType(!showAddLeaveType)
        console.log(response)
        setRefresh(true)
        toast.success('Success Create Leave Type!')
    } catch (error){
        console.log(error)
        toast.error('Failed Create Leave Type!')
    }
  }
  
  const editDataLeaveType = async () => {
    try {
        const data ={
            type_name: typeName,
            submission_before: submissionBefore,
            max_duration: maxDuration,
            max_submission: maxSubmission
        }
        const response = await Api.UpdateLeaveType(localStorage.getItem('hris-token'), data, idLeaveType)
        resetForm()
        setShowEditLeaveType(!showEditLeaveType)
        setRefresh(true)
        toast.success('Success Edit Leave Type!')
    } catch (error) {
        console.log(error)
        toast.error('Failed Edit Leave Type!')
    }
  }
  const getDataLeaveType = async () => {
    try {
        const response = await Api.getLeaveType(localStorage.getItem('hris-token'), currentPage, limit)
        setData(response.data.leaveTypeList)
        setCurrentPage(response.data.currentPage)
        setTotalPages(response.data.totalPages)
        console.log(response)
    } catch (error) {
        console.log(error)
    }
  }

  const deleteLeaveTypeModal = (id) =>{
    setShowModalDelete(!showModalDelete)
    setIdLeaveType(id)
    setRefresh(true)
}

  const deleteLeaveType = async(id) => {
    try {
        const response = await Api.DeleteLeaveType(localStorage.getItem('hris-token'), idLeaveType)
        setShowModalDelete(!showModalDelete)
        setRefresh(true)
        toast.success('Success Delete Leave Type!')
    } catch (error) {
        console.log(error)
        toast.error('Failed Delete Leave Type!')
    }
}

  useEffect(() =>{
    getDataLeaveType()
    setRefresh(false)
  }, [refresh])

    


  return (
        <div>
             <Modal 
                activeModal={showAddLeaveType}
                title={'Add Leave Type'}
                buttonClose={ () => setShowAddLeaveType(!showAddLeaveType)}
                width={'550px'}
                content= {
                    <div className='space-y-[24px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Type Name</h1>
                            <input onChange={(e) => setTypeName(e.target.value)} value={typeName} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full' placeholder='Type Name...'/>
                        </div>

                        <div className="flex gap-[14px]">
                            <div>
                                <div>    
                                    <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Maximum Duration</h1>
                                    <div className='flex items-center'>
                                        <input onChange={(e) => setMaxDuration(e.target.value)} value={maxDuration} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[150px]'/>
                                        <h1 className='text-[12px] px-[22px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-[#737373]'>Days</h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Submission Before</h1>
                                <div className='flex items-center'>
                                        <input onChange={(e) => setSubmissionBefore(e.target.value)} value={submissionBefore} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[134px]'/>
                                        <h1 className='text-[12px] px-[12px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-[#737373] truncate'>Days Before</h1>
                                    </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Obtained when employees have worked for how long?*</h1>
                            <div className='flex items-center'>
                                <input onChange={(e) => setSubmissionBefore(e.target.value)} value={submissionBefore} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[142px]'/>
                                <h1 className='text-[12px] px-[12px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-[#737373] truncate'>Month</h1>
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowAddLeaveType(!showAddLeaveType)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={postDataLeaveType} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <Modal 
                activeModal={showEditLeaveType}
                title={'Edit Leave Type'}
                buttonClose={ () => setShowEditLeaveType(!showEditLeaveType)}
                width={'550px'}
                content= {
                    <div className='space-y-[24px] w-[full]'>
                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Type Name</h1>
                            <input onChange={(e) => setTypeName(e.target.value)} value={typeName} type="text" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'placeholder={!typeName ? 'Type Name...' : typeName}/>
                        </div>

                        <div className="flex gap-[14px]">
                            <div>
                                <div>    
                                    <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Maximum Duration</h1>
                                    <div className='flex items-center'>
                                        <input onChange={(e) => setMaxDuration(e.target.value)} value={maxDuration} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[150px]'/>
                                        <h1 className='text-[12px] px-[22px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-[#737373]'>Days</h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Submission Before</h1>
                                <div className='flex items-center'>
                                        <input onChange={(e) => setSubmissionBefore(e.target.value)} value={submissionBefore} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[134px]'/>
                                        <h1 className='text-[12px] px-[12px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-[#737373] truncate'>Days Before</h1>
                                    </div>
                            </div>
                        </div>

                        <div>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Obtained when employees have worked for how long?*</h1>
                            <div className='flex items-center'>
                                <input onChange={(e) => setSubmissionBefore(e.target.value)} value={submissionBefore} type="number" className='py-[10px] border rounded-s-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-[142px]'/>
                                <h1 className='text-[12px] px-[12px] py-[9px] rounded-e-md border bg-[#F1F1F1] text-[#737373] truncate'>Month</h1>
                            </div>
                        </div>

                        <div className='relative pb-[37px]'>
                            <div className="flex gap-[10px] absolute right-0">
                                <button onClick={() => setShowEditLeaveType(!showEditLeaveType)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                                </button>
                                <button onClick={editDataLeaveType} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={() => setShowModalDelete(!showModalDelete)}
                submitButton={deleteLeaveType}
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Time Of Setting'} LinkBack={'/time-management-setting'}/>
                    <h1 className="text-zinc-800 text-xl font-semibold">Time Off Setting</h1>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='border-b-2 flex items-center text-[16px]'>
                            <button onClick={ () => setMenu('Leave Setting') } className={`${menu === 'Leave Setting' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px]`}>Leave Setting</button>
                        </div>
                    <div className="py-5 space-y-5 bg-white">
                            <div>
                                <h1 className='text-xl font-semibold text-[#25272C]'>Leave Type</h1>
                                <h2 className='text-xs text-[#6B7280]'>List of leave type</h2>
                            </div>
                            <div className="flex items-center justify-between mb-[36px]">
                            <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-[#113B61] font-medium border rounded-lg'>
                                <CgTrashEmpty className='text-lg' />
                                <h1>Delete</h1>
                            </button>
                            <div className='flex gap-[12px] items-center'>
                                <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                                    <BiSearch className='absolute left-[12px] text-lg' />
                                    <input type="text" placeholder='Search...' className='outline-none text-[10px] w-full' />
                                </div>
                                <button onClick={() => setShowAddLeaveType(!showAddLeaveType)} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus  className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add Leave Type</h1>
                                </button>
                            </div>

                            </div>
                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[180px] max-w-[180px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Type Name</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Maximal Duration</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Submission Before</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Action </h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                    </div>
                                    {Object.values(data).map((item, idx) => (
                                        <>
                                            <div key={idx} className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[180px] max-w-[180px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.type_name}</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.max_duration} Hari</h1>
                                                </div>
                                                <div className='min-w-[150px] max-w-[150px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.submission_before} Hari</h1>
                                                </div>
                                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                                    <button onClick={() => OpenEditLeaveType(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                    </button>
                                                    <button onClick={() => deleteLeaveTypeModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]'/>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </table>
                            </div>
                            <Pagination
                                currentPage={currentPage} 
                                totalPages={totalPages} 
                                onPageChange={handlePageChange}
                                onPrevChange={handlePrevChange}
                                onNextChange={handleNextChange}
                            />
                        </div>
                       
                    </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default LeaveSetting