import React from 'react'
import { Navbar, Sidebar } from '../../../../components'
import { CashAdvance, FinanceSetting, Loan, Profile, Reimbursment } from '../../../../assets'
import { Link, useNavigate } from 'react-router-dom'

const Finance = () => {

    const navigate = useNavigate()

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Finance'} LinkBack={'/dashboard'}/>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border w-full'>
                    <div>
                        <h1 className='text-[20px] font-semibold'>Finance</h1>
                        <h2 className='text-[10px] font- text-[#737373] mb-[21.64px]'>Finance Menu</h2>
                    </div>
                    <div className='grid grid-cols-12 gap-3'>
                            <div className={`${localStorage.getItem('hris-role') === 'ADMIN' ? 'col-span-3' : 'col-span-4'} relative w-full h-[225.25px]`}>
                                <button onClick={() => navigate('/finance/reimbursement')} className='border w-full h-full rounded-[10px] bg-cover shadow-sm' style={{ backgroundImage: `url(${Reimbursment})` }}>
                                    <div className='h-full flex flex-col justify-end px-[8px] py-[10px] rounded-[10px] bg-gradient-to-r from-[#282828A6] to-[#2F2F2F99]'>
                                        <h1 className="absolute top-1/2 left-1/2 text-[22px] font-medium text-center text-white transform -translate-x-1/2 -translate-y-1/2">Reimbursement</h1>
                                    </div>
                                </button>
                            </div>
                            <div className={`${localStorage.getItem('hris-role') === 'ADMIN' ? 'col-span-3' : 'col-span-4'} relative w-full h-[225.25px]`}>
                                <button onClick={() => navigate('/finance/cash-advance')} className='border w-full h-full rounded-[10px] bg-cover shadow-sm' style={{ backgroundImage: `url(${CashAdvance})` }}>
                                    <div className='h-full flex flex-col justify-end px-[8px] py-[10px] rounded-[10px] bg-gradient-to-r from-[#282828A6] to-[#2F2F2F99]'>
                                        <h1 className="absolute top-1/2 left-1/2 text-[22px] font-medium text-center text-white transform -translate-x-1/2 -translate-y-1/2">Cash Advance</h1>
                                    </div>
                                </button>
                            </div>
                            <div className={` ${localStorage.getItem('hris-role') === 'ADMIN' ? 'col-span-3' : 'col-span-4'} relative w-full h-[225.25px]`}>
                                <button onClick={() => navigate('/finance/loan')} className='border w-full h-full rounded-[10px] bg-cover shadow-sm' style={{ backgroundImage: `url(${Loan})` }}>
                                    <div className='h-full flex flex-col justify-end px-[8px] py-[10px] rounded-[10px] bg-gradient-to-r from-[#282828A6] to-[#2F2F2F99]'>
                                        <h1 className="absolute top-1/2 left-1/2 text-[22px] font-medium text-center text-white transform -translate-x-1/2 -translate-y-1/2">Loan</h1>
                                    </div>
                                </button>
                            </div>
                            {localStorage.getItem('hris-role') === 'ADMIN' && 
                                <div className="col-span-3 relative w-full h-[225.25px]">
                                    <button onClick={() => navigate('/finance/setting')} className='border w-full h-full rounded-[10px] bg-cover shadow-sm' style={{ backgroundImage: `url(${FinanceSetting})` }}>
                                        <div className='h-full flex flex-col justify-end px-[8px] py-[10px] rounded-[10px] bg-gradient-to-r from-[#282828A6] to-[#2F2F2F99]'>
                                            <h1 className="absolute top-1/2 left-1/2 text-[22px] font-medium text-center text-white transform -translate-x-1/2 -translate-y-1/2">Finance Setting</h1>
                                        </div>
                                    </button>
                                </div>
                            }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Finance