import React, { useState } from 'react'
import { Modal, ModalDelete, Navbar } from '../../../../components'
import { CgTrashEmpty } from 'react-icons/cg'
import { BiSearch } from 'react-icons/bi'
import { AiOutlinePlus } from 'react-icons/ai'
import { MdImportExport } from 'react-icons/md'
import { HiOutlinePencil } from 'react-icons/hi'
import Api from '../../../../Api'
import { useEffect } from 'react'

const Punishment = () => {
    const [showModalAddPunishment, setShowModalAddPunishment] = useState(false)
    const [showModalEditPunishment, setShowModalEditPunishment] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [dataPunishment, setDataPunishment] = useState('')

    const getPunishment = async () => {
        try {
            const response = await Api.GetPunishment(localStorage.getItem('hris-token'))
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getPunishment()
    }, [])


  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
        <Modal
            activeModal={showModalAddPunishment}
            title={'Add Punishment'}
            buttonClose={ () => setShowModalAddPunishment(!showModalAddPunishment)}
            width={'550px'}
            content= {
                <div className='space-y-6'>
                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Punishment  Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input type={'text'}  className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Input punishment name...'/>
                    </div>
                    <div className='w-full'>
                        <div className='w-full'>
                            <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Type of Deduction<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input type={'text'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Select the type of income to deduct'/>
                        </div>
                    </div>
                    <div className='w-full'>
                        <h1 className='text-[#40444C] text-[13px] font-[500] mb-[7px]'>Amount<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input type={'number'} className='bg-white border rounded-[6px] text-[#40444C] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Rp'/>
                    </div>
                    
                    <div className='space-y-[16px]'>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio1" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio1">All Employee</label>
                        </div>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio2" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio2">Search Employee</label>
                        </div>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio3" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio3">Based on Job Grade </label>
                        </div>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio4" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio4">Based on Job Level  </label>
                        </div>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio5" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio5">Based on Job Position </label>
                        </div>
                    </div>

                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowModalAddPunishment(!showModalAddPunishment)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                    </div>
                </div>
            }
        />
        <Modal
            activeModal={showModalEditPunishment}
            title={'Add Absence Distance'}
            buttonClose={ () => setShowModalEditPunishment(!showModalEditPunishment)}
            width={'550px'}
            content= {
                <div className='space-y-6'>
                    <div className='w-full'>
                        <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Punishment  Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                        <input type={'text'}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Input punishment name...'/>
                    </div>
                    <div className='w-full'>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Type of Deduction<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input type={'text'} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Select the type of income to deduct'/>
                        </div>
                    </div>
                    <div className='space-y-[16px]'>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio1" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio1">All Employee</label>
                        </div>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio2" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio2">Search Employee</label>
                        </div>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio3" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio3">Based on Job Grade </label>
                        </div>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio4" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio4">Based on Job Level  </label>
                        </div>
                        <div className='flex items-center gap-[14px] font-medium'>
                            <input type="radio" id="radio5" name="radio-option" value="" />
                            <label className='#40444C font-medium text-xs' for="radio5">Based on Job Position </label>
                        </div>
                    </div>

                    <div className='flex items-center justify-end gap-[12px] mt-5'>
                        <button onClick={ () => setShowModalAddPunishment(!showModalAddPunishment)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                        <button className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                    </div>
                </div>
            }
        />
        {/* <ModalDelete
            activeModal={showModalDelete}
            buttonClose={ () => setShowModalDelete(!showModalDelete)}
            submitButton={deleteGeoData}
        /> */}
        <div className='flex px-[30px] py-[35px] gap-[30px]'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Structure'} LinkBack={'/dashboard'}/>
                <h1 className="text-zinc-800 text-xl font-semibold">Punishment Setting </h1>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    {/* <div className='bg-white border shadow-sm rounded-[12px]'> */}
                        <div className='w-full lg:w-auto mb-[20px]'>
                            <h1 className='text-[#454545] text-[20px] font-[600]'>Punishment</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[400]'>list of Punishment</h1>
                        </div>
                        <div className='flex lg:flex-row items-center justify-between  '>
                            <div>
                                <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-[#113B61] font-medium border rounded-lg'>
                                    <CgTrashEmpty className='text-lg' />
                                    <h1>Delete</h1>
                                </button>
                            </div>
                            <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                                    <BiSearch className='absolute left-[12px] text-lg' />
                                    <input type="text" placeholder='Search...' className='outline-none text-[10px] w-full' />
                                </div>
                                <button onClick={() => setShowModalAddPunishment(!showModalAddPunishment)}  className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                    <AiOutlinePlus className='text-xl text-white'/>
                                    <h1 className='text-[#EDEDED] text-sm font-[500]'>Add Punishment</h1>
                                </button>
                            </div>
                        </div>
                        <div className='mt-[44px] overflow-auto scrollbar-hide'>
                            <table className='w-full space-y-[10px]'>
                                <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[600]'>Punishment Name</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[600]'>Type of Deduction</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[600]'>Amount of Deduction</h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[600]'>Assign to </h1>
                                        <MdImportExport className='text-[#737373] text-xs'/>
                                    </div>
                                    <div className='flex items-center justify-center gap-[15px] w-full'>
                                        <h1 className='text-[#737373] text-xs font-[600]'>Action</h1>
                                    </div>
                                </div>
                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>Late </h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>Gaji Pokok </h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>Rp 20.000</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#015995] text-xs font-[600] line-clamp-1'>4 Jobs Position </h1>
                                    </div>
                                    <div className='flex items-center justify-center w-full gap-[12px]'>
                                        <button  className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-[#003049]'/>
                                        </button>
                                        <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]'/>
                                        </button>
                                    </div>
                                </div>
                            </table>
                        </div>
                        
                    {/* </div> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Punishment