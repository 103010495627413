import React from 'react'
import { Navbar } from '../../../../components'
import { Link } from 'react-router-dom'

const TimeManagementSetting = () => {
  return (
    <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Time Management Setting'} LinkBack={'/dashboard'}/>
                    <h1 className="text-zinc-800 text-2xl font-semibold">Time Management Setting</h1>
                    <div className='space-y-[12px]'>
                      <Link to={'/attendance-setting'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                        <h1 className="w-[550px] text-black text-xl font-medium text-start">Attendance Setting</h1>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M9.5 6L15.5 12L9.5 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </Link>
                      <Link to={'/time-management/leave-setting'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                        <h1 className="w-[550px] text-black text-xl font-medium text-start">Time Off Setting</h1>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M9.5 6L15.5 12L9.5 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </Link>
                      <Link to={'/official-work-travel'} className='bg-white px-[40px] py-[21px] rounded-md border flex items-center justify-between w-full'>
                        <h1 className="w-[550px] text-black text-xl font-medium text-start">Official work Travel</h1>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M9.5 6L15.5 12L9.5 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </Link>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default TimeManagementSetting