import React, { useEffect, useState } from 'react'
import { Modal, ModalDelete, Navbar, Pagination, Sidebar } from '../../../../components'
import { TbFileExport } from 'react-icons/tb'
import { BiSearch } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle, AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import { CgTrashEmpty } from 'react-icons/cg'
import { IoMdArrowDropdown } from 'react-icons/io'
import Api from '../../../../Api'
import { FaTimesCircle } from 'react-icons/fa'
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { debounce } from 'lodash'

const Leave = () => {
    const [showDetailLeave, setShowDetailLeave] = useState()
    const [showAddLeave, setShowAddLeave] = useState()
    const [showEditLeave, setShowEditLeave] = useState()
    const [showModalDelete, setShowModalDelete] = useState()
    const [showDetailApproval, setShowDetailApproval] = useState()
    const [data, setData] = useState('')
    const [dataById, setDataById] = useState('')
    const [detailJobPosition, setDetailJobPosition] = useState('')
    const [dataJobPosition, setDataJobPosition] = useState('')

    const [dataLeaveType, setDataLeaveType] = useState('')
    const [dataEmployee, setDataEmployee] = useState([])
    const [dataEmployeeById, setDataEmployeeById] = useState('')
    const [dataEmergencyContact, setDataEmergencyContact] = useState('')
    const [dataEmergencyContactById, setDataEmergencyContactById] = useState('')
    const [emergencyContactId, setEmergencyContactId] = useState('')

    const [userLogin, setUserLogin] = useState('')
    const [idUserLogin, setIdUserLogin] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [limit, setLimit] = useState(10)

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [dateSearch, setDateSearch] = useState('')

    const [isLoading, setIsLoading] = useState(false);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };
    
    const handlePrevChange = () => {
        if(currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };
    
    const handleNextChange = () => {
        if(currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };

    const FetchUser = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserLogin(response.data.results.user.fullname)
            setIdUserLogin(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }

    const getDataLeave = async () => {
        try {
            if(localStorage.getItem('hris-role') === 'ADMIN'){
                const response = await Api.getLeaveByAdmin(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
                setData(response.data.leaves)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            } else {
                const response = await Api.getLeave(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
                setData(response.data.leaves)
                setCurrentPage(response.data.currentPages)
                setTotalPages(response.data.totalPages)

            }
        } catch (error) {
            console.log(error)
        }
    }

    const debouncedSearchName = debounce(async(name) => {
        try {
            if(localStorage.getItem('hris-role') === 'ADMIN'){
                const response = await Api.getLeaveByAdmin(localStorage.getItem('hris-token'), dateSearch, name, currentPage, limit)
                setData(response.data.leaves)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
                // console.log('dataLeave: ', response)
            } else {
                const response = await Api.getLeave(localStorage.getItem('hris-token'), dateSearch, name, currentPage, limit)
                setData(response.data.leaves)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleSearchName = (e) => {
        const searchName = e.target.value
        debouncedSearchName(searchName)
    }
    const debouncedSearchDate = debounce(async(date) => {
        try {
            if(localStorage.getItem('hris-role') === 'ADMIN'){
                const response = await Api.getLeaveByAdmin(localStorage.getItem('hris-token'), date, nameSearch, currentPage, limit)
                setData(response.data.leaves)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            } else {
                const response = await Api.getLeave(localStorage.getItem('hris-token'), date, nameSearch, currentPage, limit)
                setData(response.data.leaves)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const debouncedLimit = debounce(async(limit) => {
        try {
            if(localStorage.getItem('hris-role') === 'ADMIN'){
                const response = await Api.getLeaveByAdmin(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
                setData(response.data.leaves)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            } else {
                const response = await Api.getLeave(localStorage.getItem('hris-token'), dateSearch, nameSearch, currentPage, limit)
                setData(response.data.leaves)
                setCurrentPage(response.data.currentPage)
                setTotalPages(response.data.totalPages)
            }
        } catch (error) {
            console.log(error)
        }
    }, 300)

    const handleLimit= (e) => {
        const limit = e.target.value
        console.log(limit)
        debouncedLimit(limit)
    }

    const handleSearchDate = (e) => {
        const searchDate = e.target.value
        debouncedSearchDate(searchDate)
    }

    const getDataLeaveType = async () => {
        try {
            const response = await Api.getLeaveType(localStorage.getItem('hris-token'), 1, 100)
            setDataLeaveType(response.data.leaveTypeList) 
        } catch (error) {
            console.log(error)
        }
    }

    const getDataEmployee = async () => {
        try {
            const response = await Api.getAllEmployee(localStorage.getItem('hris-token'))
            setDataEmployee(response.data.data.filter(item => item.role === 'USER'))
        } catch (error) {
            console.log(error)
        }
    }

    const getDataEmployeeById = async () => {
        try {
            
            const response = await Api.GetAllEmployeeById(localStorage.getItem('hris-token'), user)
            setFullname(response.data[0].fullname)
        } catch (error) {
            console.log(error)
        }
    }

    const getDataEmergencyContact = async () => {
        try {
            const response = await Api.GetEmergencyContactByLogin(localStorage.getItem('hris-token'))
            setDataEmergencyContact(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getDataEmergencyContactById = async () => {
        try {
            const response = await Api.GetEmergencyContactById(localStorage.getItem('hris-token'), emergencyContact)
            setEmergencyAddres(response.data[0].addres)
            setEmergencyPhone(response.data[0].telephone)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteLeaveModal = (id) =>{
        setShowModalDelete(!showModalDelete)
        setIdLeave(id)
        setRefresh(true)
    }

    const deleteLeave = async() => {
        try {
            const response = await Api.DeleteLeave(localStorage.getItem('hris-token'), idLeave)
            setShowModalDelete(!showModalDelete)
            setRefresh(true)
            toast.success('Success Delete')
        } catch (error) {
            console.log(error)
            toast.error('Failed Delete')
        }
    }

    const [fullname, setFullname] = useState('')
    const [jobPosition, setJobposition] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [leaveDuration, setLeaveDuration] = useState('')
    const [remainingDaysOf, setRemainingDaysOf] = useState('')
    const [notes, setNotes] = useState('')
    const [leaveTypeId, setLeaveTypeId] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [delegatedEmployee, setDelegatedEmployee] = useState('')
    const [delegatedTask, setDelegatedTask] = useState('')
    const [emergencyContact, setEmergencyContact] = useState('')
    const [emergencyPhone, setEmergencyPhone] = useState('')
    const [emergencyAddres, setEmergencyAddres] = useState('')
    const [user, setUser] = useState('')
    const [idLeave, setIdLeave] = useState('')

    const resetFormFields = () => {
        setFullname('');
        setStartDate('');
        setEndDate('');
        setLeaveDuration('');
        setRemainingDaysOf('');
        setNotes('');
        setLeaveTypeId('');
        setDelegatedEmployee('');
        setDelegatedTask('');
        setEmergencyContact('');
        setEmergencyPhone('');
        setEmergencyAddres('');
        setIdLeave('');
        setUser('')
      };

    const postLeave = async () => {
        try{
            const data = {
                fullname: localStorage.getItem('hris-role') === 'ADMIN' ? fullname : userLogin,
                // job_position: jobPosition,
                start_date: startDate,
                end_date: endDate,
                leave_duration: leaveDuration ,
                remaining_daysof: remainingDaysOf ,
                notes: notes ,
                leavetype: leaveTypeId,
                // companyId: companyId ,
                delegated_employee: delegatedEmployee,
                delegated_task: delegatedTask,
                emergency_contact: !emergencyContact ? '-' : emergencyContact,
                emergency_phone : !emergencyPhone ? '-' : emergencyPhone,
                emergency_addres: !emergencyAddres ? '-' : emergencyAddres,
                userId: localStorage.getItem('hris-role') === 'ADMIN'? user : idUserLogin,
                reportToId: idEmployee
            }
            setIsLoading(true)
            const response = await Api.PostLeave(localStorage.getItem('hris-token'), data)
            setIsLoading(false)
            resetFormFields()
            setRefresh(true)
            toast.success('Success Create Leave')
            setShowAddLeave(!showAddLeave)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
            toast.error('Failed Create Leave')
        }
    }

    const openEditLeave = async (id) => {
        setShowEditLeave(!showEditLeave)
        setIdLeave(id)
        try {
            const response = await Api.getLeaveById(localStorage.getItem('hris-token'), id)
            console.log(response, 'leavebyId')
            setFullname(response.data.response.fullname)
            setStartDate(response.data.response.start_date)
            setEndDate(response.data.response.end_date)
            setLeaveDuration(response.data.response.leave_duration)
            setRemainingDaysOf(response.data.response.remaining_daysof)
            setLeaveTypeId(response.data.response.leavetype.type_name)
            setNotes(response.data.response.notes)
            setDelegatedEmployee(response.data.response.delegated_employee)
            setDelegatedTask(response.data.response.delegated_task)
            setEmergencyContact(response.data.response.emergency_contact)
            setEmergencyPhone(response.data.response.emergency_phone)
            setEmergencyAddres(response.data.response.emergency_addres)
        } catch (error) {
            console.log(error)
        }
    }

    const openDetailLeave = async(id) => {
        setShowDetailLeave(!showDetailLeave)
        try {
            const response = await Api.getLeaveById(localStorage.getItem('hris-token'), id)
            console.log(response, 'byId')
            setDataById(response.data.response)
        } catch (error) {
            console.log(error)
        }
    }

    const editLeave = async () => {
        try {
            const data = {
                fullname: fullname,
                // job_position: jobPosition,
                start_date: startDate,
                end_date: endDate,
                leave_duration: leaveDuration ,
                remaining_daysof: remainingDaysOf ,
                notes: notes ,
                leavetype: leaveTypeId,
                // companyId: companyId ,
                delegated_employee: delegatedEmployee,
                delegated_task: delegatedTask,
                emergency_contact: emergencyContact,
                emergency_phone : emergencyPhone,
                emergency_addres: emergencyAddres,
                reportToId: idEmployee
            }
            setIsLoading(true)
            const response = await Api.UpdateLeave(localStorage.getItem('hris-token'), data, idLeave)
            setIsLoading(false)
            resetFormFields()
            setShowEditLeave(!showEditLeave)
            setRefresh(true)
            toast.success('Success Edit Leave')
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            toast.error('Failed Edit Leave')
        }
    }

    // Repeater Inputan
    const [searchTerm, setSearchTerm] = useState('');
    const [employeeName, setEmployeeName] = useState([])
    const [idEmployee, setIdEmployee] = useState([])

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleRemoveClickedEmployee = (name) => {
        const index = employeeName.indexOf(name);
        if (index !== -1) {
          const newIdEmployee = [...idEmployee];
          const newEmployeeName = [...employeeName];
      
          newIdEmployee.splice(index, 1);
          newEmployeeName.splice(index, 1);
      
          setIdEmployee(newIdEmployee);
          setEmployeeName(newEmployeeName);
        }
    };

    const handleNameClick = (id) => {
        const selectedOption = dataEmployee.find (
            (data) => data.report_to_employee[0].id === id
        );
        if (selectedOption && !idEmployee.includes(id)) {
            setIdEmployee([...idEmployee, selectedOption.id]);
            setEmployeeName([...employeeName, selectedOption.fullname]);
        }
    };

    console.log(idEmployee, 'idEmpl')
    console.log(dataEmployee, 'dataEmpl')

    useEffect(() => {
        getDataLeave()
        getDataLeaveType()
        getDataEmployee()
        FetchUser()
        setRefresh(false)
        
    }, [refresh, limit])
    
    useEffect(() =>{
        getDataEmergencyContact()
        getDataEmergencyContactById()
        getDataEmployeeById() 
    }, [emergencyContact])



  return (
    <div>
        <Modal 
            activeModal={showDetailApproval}
            title={'Edit Subordinate'}
            buttonClose={ () => setShowDetailApproval(!showDetailApproval)}
            width={'507px'}
            content= {
                <div className='space-y-[20px] w-[full]'>
                    <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[400px] max-w-[400px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Leader Name</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[15px] w-full'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Status</h1>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[400px] max-w-[400px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Muhammad Eko David Kurniawan</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <div className='rounded-[9px] flex flex-col items-center justify-center'>
                                                <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[400px] max-w-[400px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Muhammad Eko David Kurniawan</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <div className='rounded-[9px] flex flex-col items-center justify-center'>
                                                <AiFillClockCircle className='text-[#FFD900] w-[29px] h-[29px]'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='min-w-[400px] max-w-[400px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500] truncate'>Muhammad Eko David Kurniawan</h1>
                                        </div>
                                        <div className='w-full flex items-center justify-center gap-[12px]'>
                                            <div className='rounded-[9px] flex flex-col items-center justify-center'>
                                                <AiFillClockCircle className='text-[#FFD900] w-[29px] h-[29px]'/>
                                            </div>
                                        </div>
                                    </div>
                                </table>
                            </div>
                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowDetailApproval(!showDetailApproval)} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Close</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showDetailLeave}
            title={'Detail Leave'}
            buttonClose={ () => setShowDetailLeave(!showDetailLeave)}
            width={'832px'}
            content= {
                <div className='space-y-[40px] w-full'>
                          
                            <div className='bg-[#F8F8F8] rounded-[15px] px-[19px] py-[31px] w-[773px] text-[#737373] text-[11px] font-semibold'>
                                <div className="flex gap-[168px] mb-[21px]">
                                    <h1>Full Name : {dataById? dataById.fullname : '-'}</h1>
                                    <h1>Job Position :{detailJobPosition? detailJobPosition : '-'}  </h1>
                                </div>
                                
                                <hr className='mb-[21px]'/>

                                <div className="flex gap-[193px] mb-[25px]">
                                    
                                    <h1>Start Date : {dataById? moment(dataById.start_date).format('DD-MM-YYYY'): '-'}</h1>
                                    <h1>End Date : {dataById? moment(dataById.end_date).format('DD-MM-YYYY'): '-'}</h1>
                                   
                                </div>
                                <div className="flex gap-[221px] mb-[21px]">
                                    
                                    <h1>Time Off Duration : {dataById ? dataById.leave_duration : '-'}</h1>
                                    <h1>Remaining Days Off : {dataById ? dataById.remaining_daysof : '-'}</h1>
                                    
                                </div>
                                <div className="flex  mb-[21px]">
                                    <h1>Leave Type : {dataById ? dataById.leavetype.type_name : '-'}</h1>
                                </div>
                                <div className="flex  mb-[21px]">
                                    <h1>Notes : {dataById? dataById.notes : '-'}</h1>
                                </div>
                                
                                <hr className='mb-[21px]'/>

                                <div className="flex  mb-[25px]">
                                    <h1>Delegated Employee : {dataById? dataById.delegated_employee : '-'}</h1>
                                </div>
                                <div className="flex mb-[21px]">
                                    <h1>Delegated Task : {dataById? dataById.delegated_task : '-'}</h1>
                                </div>

                                <hr className='mb-[21px]'/>

                                <div className="flex gap-[175px] mb-[25px]">
                                    <h1>Emergency Contact : {dataById? dataById.emergency_contact.name : '-'}</h1>
                                    <h1>Emergency Phone Number : {dataById? dataById.emergency_contact.telephone : '-'}</h1>
                                </div>

                                <div className="flex  mb-[21px]">
                                    <h1>Emergency Address : {dataById? dataById.emergency_contact.addres : '-'}</h1>
                                </div>
                            </div>
                           
                    
                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowDetailLeave(!showDetailLeave)} className=' bg-[#0E5073] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[white] text-sm font-[500]'>Close</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showAddLeave}
            title={'Add Leave'}
            buttonClose={ () => setShowAddLeave(!showAddLeave)}
            width={'832px'}
            content= {
                <div className='space-y-[24px] w-[full]'>
                    {localStorage.getItem('hris-role') === 'ADMIN' ? (
                        <div>
                            <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Employee Name</h2>
                                <div className='relative'>
                                <select onChange={(e) => setUser(e.target.value)} value={user} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Employee</option>
                                    {Object.values(dataEmployee).map((item, index) => {
                                        if (item.role === 'USER'){
                                            return (
                                                <option value={item.id}>{item.fullname}</option>
                                            )
                                        } else{
                                            return null
                                        }
                                    })}
                                </select>
                                <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                            </div>
                        </div>
                    ) : (
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Employee<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <div className='relative'>
                                <input value={userLogin} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-[#454545] text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder={userLogin} readOnly/>
                            </div>
                        </div>
                    )}
                    
                    {/* <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Job Position</h2>
                        <div className='relative'>
                            <select onChange={(e) => setJobposition(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                            <option disabled selected={''}>Select Job Position</option>
                                {Object.values(dataJobPosition).map((item, index) =>  (
                                    <option value={item.id}>{item.position_name}</option>   
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div> */}
                    
                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Leave Type</h2>
                        <div className='relative'>
                            <select onChange={(e) => setLeaveTypeId(e.target.value)} value={leaveTypeId} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Leave Type</option>
                                {Object.values(dataLeaveType).map((item, index) => {
                                    return (
                                        <option value={item.id}>{item.type_name}</option>
                                    )
                                })}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div>

                    <div className="flex gap-[19px]">
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Start Date</h1>
                            <input onChange={(e) => setStartDate(e.target.value)} value={startDate} type="date" className='py-[10px] border rounded-md pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>End Date</h1>
                            <input onChange={(e) => setEndDate(e.target.value)} value={endDate}  type="date" className='py-[10px] border rounded-md pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                        </div>
                    </div>

                    <div className="flex gap-[19px]">
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Leave Duration</h1>
                            <input onChange={(e) => setLeaveDuration(e.target.value)} value={leaveDuration}  type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Remaining Days Off</h1>
                            <input onChange={(e) => setRemainingDaysOf(e.target.value)} value={remainingDaysOf}  type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                        </div>
                    </div>

                   

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Notes</h1>
                        <textarea onChange={(e) => setNotes(e.target.value)} value={notes} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] text-[#737373] w-full resize-none' placeholder='Notes'/>
                    </div>

                    <hr />

                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Delegeted Employee</h2>
                        <div className='relative'>
                            <select onChange={(e) => setDelegatedEmployee(e.target.value)} value={delegatedEmployee} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Employee</option>
                                {Object.values(dataEmployee).map((item, index) => {
                                    if (item.role === 'USER'){
                                        return (
                                            <option value={item.fullname}>{item.fullname}</option>
                                        )
                                    } else{
                                        return null
                                    }
                                })}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Delegated Task</h1>
                        <textarea onChange={(e) => setDelegatedTask(e.target.value)} rows={3} value={delegatedTask} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] text-[#737373] w-full resize-none' placeholder='Task list...'/>
                    </div>

                    <hr />

                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Emergency Contact</h2>
                        <div className='relative'>
                            <select onChange={(e) => setEmergencyContact(e.target.value)} value={emergencyContact}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select emergency contact</option>
                                {Object.values(dataEmergencyContact).map((item, index) =>(
                                    <option value={item.id}>{item.name}</option>
                                ))}
                                <option value={'Tidak ada Emergency Contact'}>Tidak ada Emergency Contact</option>
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Emergency Phone Number</h1>
                        <input className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] bg-[#F2F2F2] h-[35px] w-full text-[#A8A8A8]' placeholder='Auto Fill' value={emergencyPhone? emergencyPhone : 'Auto-fill'}/>
                    </div>

                    <div className='mb-[56px]'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Emergency Address</h1>
                        <input className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] bg-[#F2F2F2] h-[35px] w-full text-[#A8A8A8]' placeholder='Auto Fill' value={emergencyAddres? emergencyAddres : 'Auto-fill'}/>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Approval <span className='text-[#780000]'>*</span></h1>

                        <div className='relative py-[12px] pl-[12px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-full mb-3'>
                            <BiSearch className='absolute right-[12px] text-lg' />
                            <input value={searchTerm} onChange={handleSearchChange} type="text" placeholder='Search Employee name...' className='outline-none text-[12px] w-full' />
                        </div>

                        {searchTerm.trim() !== '' && (
                            <div className={`bg-white rounded-md mb-3 shadow overflow-auto min-h-fit max-h-[120px]`}>
                                {dataEmployee.map((item, index) => (
                                    <h1 key={index} className='flex text-[12px] font-medium items-center p-[10px]' onClick={() => handleNameClick(item.report_to_employee[0].id)}>
                                        {item.fullname}
                                    </h1>   
                                ))}
                            </div>
                        )}
                        
                        {employeeName.length > 0 && (
                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                {employeeName.map((name, index) => (
                                    <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                        <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                            <h1>{name}</h1>
                                            <button onClick={() => handleRemoveClickedEmployee(name)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                    <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowAddLeave(!showAddLeave)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={isLoading ? null : postLeave} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
        <Modal 
            activeModal={showEditLeave}
            title={'Edit Leave'}
            buttonClose={ () => setShowEditLeave(!showEditLeave)}
            width={'832px'}
            content= {
                <div className='space-y-[24px] w-[full]'>
                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Employee Name</h2>
                        <input value={fullname} type='text' className='bg-[#F2F2F2] border rounded-[6px] text-[#A8A8A8] text-[12px] px-[8px] py-[10px] w-full appearance-none' placeholder={fullname} readOnly/>
                    </div>

                    {/* <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[5px]'>Job Position</h2>
                        <div className='py-[10px] px-[12px] text-[#A8A8A8] text-[12px] bg-[#F2F2F2] rounded-md'>
                            Auto-fill
                        </div>
                    </div> */}

                    <div className="flex gap-[19px]">
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Start Date</h1>
                            <input onChange={(e) => setStartDate(e.target.value)} value={moment(startDate).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>End Date</h1>
                            <input onChange={(e) => setEndDate(e.target.value)} value={moment(endDate).format('YYYY-MM-DD')} type="date" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                        </div>
                    </div>

                    <div className="flex gap-[19px]">
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Leave Duration</h1>
                            <input onChange={(e) => setLeaveDuration(e.target.value)} value={leaveDuration} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Remaining Days Off</h1>
                            <input onChange={(e) => setRemainingDaysOf(e.target.value)} value={remainingDaysOf} type="number" className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[11px] text-[#737373] w-full'/>
                        </div>
                    </div>

                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Leave Type</h2>
                        <div className='relative'>
                            <select onChange={(e) => setLeaveTypeId(e.target.value)} value={leaveTypeId} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Leave Type</option>
                                {Object.values(dataLeaveType).map((item, index) => {
                                    return (
                                        <option value={item.id}>{item.type_name}</option>
                                    )
                                })}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Notes</h1>
                        <textarea onChange={(e) => setNotes(e.target.value)} value={notes} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] text-[#737373] w-full resize-none' placeholder='Notes'/>
                    </div>

                    <hr />

                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Delegeted Employee</h2>
                        <div className='relative'>
                            <select onChange={(e) => setDelegatedEmployee(e.target.value)} value={delegatedEmployee} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select Employee</option>
                                {Object.values(dataEmployee).map((item, index) => {
                                    if (item.role === 'USER'){
                                        return (
                                            <option value={item.fullname}>{item.fullname}</option>
                                        )
                                    } else{
                                        return null
                                    }
                                })}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Delegated Task</h1>
                        <textarea onChange={(e) => setDelegatedTask(e.target.value)} value={delegatedTask} rows={3} className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] text-[#737373] w-full resize-none' placeholder='Task list...'/>
                    </div>

                    <hr />

                    <div>
                        <h2 className='text-[#737373] text-[12px] font-medium mb-[9px]'>Emergency Contact</h2>
                        <div className='relative'>
                            <select onChange={(e) => setEmergencyContact(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[12px] pl-[21.74px] py-[10px] w-full appearance-none'>
                                <option selected value={''}>Select emergency contact</option>
                                {Object.values(dataEmergencyContact).map((item, index) =>(
                                    <option value={item.id}>{item.name}</option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#2E2E2E ] text-xl'/>
                        </div>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Emergency Phone Number</h1>
                        <input className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] bg-[#F2F2F2] h-[35px] w-full text-[#A8A8A8]' placeholder='Auto Fill' value={emergencyPhone? emergencyPhone : 'Auto-fill'}/>
                    </div>

                    <div className='mb-[56px]'>
                        <h1 className='text-[12px] text-[#737373] mb-[9px] font-medium'>Emergency Address</h1>
                        <input className='py-[10px] border rounded-md  pl-[13px] pr-2 text-[12px] bg-[#F2F2F2] h-[35px] w-full text-[#A8A8A8]' placeholder='Auto Fill' value={emergencyAddres? emergencyAddres : 'Auto-fill'}/>
                    </div>

                    <div>
                        <h1 className='text-[12px] text-[#737373] mb-[5px] font-medium'>Approval <span className='text-[#780000]'>*</span></h1>

                        <div className='relative py-[12px] pl-[12px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-full mb-3'>
                            <BiSearch className='absolute right-[12px] text-lg' />
                            <input value={searchTerm} onChange={handleSearchChange} type="text" placeholder='Search Employee name...' className='outline-none text-[12px] w-full' />
                        </div>

                        {searchTerm.trim() !== '' && (
                            <div className={`bg-white rounded-md mb-3 shadow overflow-auto min-h-fit max-h-[120px]`}>
                                {dataEmployee.map((item, index) => (
                                    <h1 key={index} className='flex text-[12px] font-medium items-center p-[10px]' onClick={() => handleNameClick(item.id)}>
                                        {item.fullname}
                                    </h1>   
                                ))}
                            </div>
                        )}
                        
                        {employeeName.length > 0 && (
                            <div className='flex items-center justify-start gap-[8px] flex-wrap '>
                                {employeeName.map((name, index) => (
                                    <div key={index} className='flex gap-[8px] items-center rounded-lg'>
                                        <div className='bg-[#8E95A2] rounded-md py-[4px] px-[10px] text-white text-xs flex gap-[8px] items-center'>
                                            <h1>{name}</h1>
                                            <button onClick={() => handleRemoveClickedEmployee(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                                    <path d="M7.82246 7.50043L10.3308 4.99793C10.4406 4.88809 10.5024 4.73911 10.5024 4.58377C10.5024 4.42842 10.4406 4.27944 10.3308 4.1696C10.221 4.05976 10.072 3.99805 9.91663 3.99805C9.76129 3.99805 9.61231 4.05976 9.50247 4.1696L6.99996 6.67793L4.49746 4.1696C4.38762 4.05976 4.23864 3.99805 4.0833 3.99805C3.92796 3.99805 3.77897 4.05976 3.66913 4.1696C3.55929 4.27944 3.49758 4.42842 3.49758 4.58377C3.49758 4.73911 3.55929 4.88809 3.66913 4.99793L6.17746 7.50043L3.66913 10.0029C3.61446 10.0572 3.57106 10.1217 3.54144 10.1928C3.51183 10.2638 3.49658 10.3401 3.49658 10.4171C3.49658 10.4941 3.51183 10.5704 3.54144 10.6414C3.57106 10.7125 3.61446 10.777 3.66913 10.8313C3.72336 10.8859 3.78788 10.9293 3.85896 10.959C3.93005 10.9886 4.00629 11.0038 4.0833 11.0038C4.1603 11.0038 4.23655 10.9886 4.30763 10.959C4.37872 10.9293 4.44324 10.8859 4.49746 10.8313L6.99996 8.32293L9.50247 10.8313C9.55669 10.8859 9.62121 10.9293 9.6923 10.959C9.76338 10.9886 9.83962 11.0038 9.91663 11.0038C9.99364 11.0038 10.0699 10.9886 10.141 10.959C10.2121 10.9293 10.2766 10.8859 10.3308 10.8313C10.3855 10.777 10.4289 10.7125 10.4585 10.6414C10.4881 10.5704 10.5033 10.4941 10.5033 10.4171C10.5033 10.3401 10.4881 10.2638 10.4585 10.1928C10.4289 10.1217 10.3855 10.0572 10.3308 10.0029L7.82246 7.50043Z" fill="white"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className='relative pb-[37px]'>
                        <div className="flex gap-[10px] absolute right-0">
                            <button onClick={() => setShowEditLeave(!showEditLeave)} className='bg-[#ECECEC] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#003049] text-sm font-[500]'>Cancel</h1>
                            </button>
                            <button onClick={isLoading ? null : editLeave} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center justify-center gap-[16px] w-[86px]'>
                                <h1 className='text-[#EDEDED] text-sm font-[500]'>Add</h1>
                            </button>
                        </div>
                    </div>
                </div>
            }
        />

        <ModalDelete
            activeModal={showModalDelete}
            buttonClose={() => setShowModalDelete(!showModalDelete)}
            submitButton={deleteLeave}
        />

        <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
            <div>
                <h1 className='text-[20px]'>Leave</h1>
                <h2 className='text-[10px] text-[#737373]'>List of  Leave Employee</h2>
            </div>

                <div className="flex gap-2 mt-[31px] relative">
                <input type="date" onChange={handleSearchDate} className='py-[11.5px] px-[10px] text-[#7E8299] text-[12px] font-medium rounded-md bg-[#F5F8FA]' />
                {localStorage.getItem('hris-role') === 'ADMIN' &&
                    <Link to='/time-management/leave-setting'>
                        <button className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px] h-12'>
                            <h1 className='text-[#003049] text-sm font-[500]'>Leave Setting</h1>
                        </button> 
                    </Link>
                }

                <select onChange={handleLimit} className='border rounded-md text-sm font-medium text-[#003049] px-[10px] py-2 appearance-none'>
                    <option value="" selected disabled>Set Limit</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                </select>
                {/* <button className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                    <TbFileExport className='text-[#003049]'/>
                    <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                </button>            */}
                <div className="absolute right-0">
                    <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                        <div className='relative'>
                            <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                            <input onChange={handleSearchName} placeholder='Search...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                        </div>
                        <button onClick={() => setShowAddLeave(!showAddLeave)} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                            <AiOutlinePlus  className='text-xl text-white'/>
                            <h1 className='text-[#EDEDED] text-sm font-[500]'>Add Leave</h1>
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <div className='overflow-auto scrollbar-hide'>
                    <table className='w-full space-y-[10px]'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Employee Name</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Job Position</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Start Date</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>End Date</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[120px] max-w-[120px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Type</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center justify-center gap-[15px] w-full'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Approved Leader</h1>
                            </div>
                            <div className='flex items-center justify-center gap-[6px] w-full'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Action </h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                        </div>
                        {Object.values(data).map((item, index) => (
                            <>
                                <div key={index} className='flex items-center gap-2  px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.fullname}</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.user.buat? item.user.buat[0].job_position.position_name : '-' } </h1>
                                    </div>
                                    <div className='min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{moment(item.start_date).format('DD-MM-YYYY')}</h1>
                                    </div>
                                    <div className='min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{moment(item.end_date).format('DD-MM-YYYY')}</h1>
                                    </div>
                                    <div className='min-w-[120px] max-w-[120px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.leavetype.type_name}</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <div className='rounded-[9px] flex flex-col items-center justify-center'>
                                            {item.status === 'pending' ? 
                                                <AiFillClockCircle className='text-[#FFD900] w-[29px] h-[29px]'/>
                                            :item.status === 'approved' ?
                                                <AiFillCheckCircle className='text-[#00AB9A] w-[29px] h-[29px]'/>
                                            :
                                                <FaTimesCircle className='text-[#C1121F] w-[29px] h-[29px]'/>
                                            }
                                        </div>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <button onClick={() => openDetailLeave(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <AiOutlineEye  className='text-[#003049]'/>
                                        </button>
                                        {item.status === 'approved' ?
                                            <button disabled onClick={() => openEditLeave(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]'/>
                                            </button>
                                            :
                                            <button onClick={() => openEditLeave(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                <HiOutlinePencil className='text-[#003049]'/>
                                            </button>
                                        }
                                        <button onClick={() => deleteLeaveModal(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]'/>
                                        </button>
                                        
                                    </div>
                                </div>
                            </>
                        ))}
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage} 
                    totalPages={totalPages} 
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                />
            </div>

        </div>
    </div>
  )
}

export default Leave