import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import { Modal, ModalDelete, Navbar } from '../../../../components'
import { MapContainer, TileLayer, Marker, Popup, Circle, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import { LogoH } from '../../../../assets';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdImportExport, MdKeyboardArrowLeft } from 'react-icons/md';
import { BiSearch, BiSearchAlt2 } from 'react-icons/bi';
import Api from '../../../../Api';
import { HiOutlinePencil } from 'react-icons/hi';
import { CgTrashEmpty } from 'react-icons/cg';
import { toast } from 'react-hot-toast';


const AttendanceSetting = () => {
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [menu, setMenu] = useState('Attendance Distance')

    const [geoid, setGeoId] = useState('')
    const [geodata, setGeoData] = useState('')
    const [geoname, setGeoName] = useState('')
    const [georadius, setGeoRadius] = useState('')
    const [markerPosition, setMarkerPosition] = useState(null);

    //State Set Time
    const [workHours, setWorkHours] = useState('')
    const [afterWorkTime, setAfterWorkTime] = useState('')
    const [lateTolerance ,setLateTolerance] = useState('')
    const [calculatedAlphaAfter, setCalculatedAlphaAfter] = useState('')

    const customIcon = L.icon({
        iconUrl: LogoH,
        iconSize: [24, 32]
    });

    const handleClick = (e) => {
        const { lat, lng } = e.latlng;
        setMarkerPosition([lat, lng]);
    }
    
    const MapClickHandler = () => {
        const map = useMapEvents({
            click: handleClick,
        });
    
        return null;
    }

    const resetForm = () => {
        setGeoName('')
        setMarkerPosition('')
        setGeoRadius('')
    }


    const postGeoData = async() => {
        try {
            const data = {
                geoname: geoname,
                geocode: JSON.stringify(markerPosition),
                georadius: georadius,
            }
            if (georadius >= 0){
                const response = await Api.PostGeoLocation(localStorage.getItem('hris-token'), data)
                resetForm()
                setRefresh(true)
                setShowModalAdd(!showModalAdd)
                toast.success('Success add absence distance')
            } else{
                toast.error('Radius cannot be less than zero')
            }
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const getGeoData = async() => {
        try {
            const response = await Api.GetGeoLocation(localStorage.getItem('hris-token'))
            setGeoData(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getGeoDataById = async(id) => {
        setShowModalEdit(!showModalEdit)
        setGeoId(id)
        try {
            const response = await Api.GetGeoLocationById(localStorage.getItem('hris-token'), id)
            const markerPositionArray = response.data[0].geocode
            const markerPositionParse = JSON.parse(markerPositionArray)
            setMarkerPosition(markerPositionParse)
            setGeoName(response.data[0].geoname)
            setGeoRadius(response.data[0].georadius)
        } catch (error) {
            console.log(error)
        }
    }

    const updateGeoData = async() => {
        try {
            const data = {
                geoname: geoname,
                geocode: JSON.stringify(markerPosition),
                georadius: georadius
            }
            const response = await Api.UpdateGeoLocation(localStorage.getItem('hris-token'), data, geoid)
            resetForm()
            setRefresh(true)
            setShowModalEdit(!showModalEdit)
            toast.success('Success update absence distance')
        } catch (error) {
            console.log(error)
            toast.error('Data cannot be empty!')
        }
    }

    const deleteGeoDataId = (id) => {
        setShowModalDelete(!showModalDelete)
        setGeoId(id)
    }

    const deleteGeoData = async() => {
        try {
            const response = await Api.DeleteGeoLocation(localStorage.getItem('hris-token'), geoid)
            setRefresh(true)
            setShowModalDelete(!showModalDelete)
            toast.success('Success delete absence distance')
        } catch (error) {
            console.log(error)
            toast.error('Failed delete!')
        }
    }

    const createSetTime = async () => {
        const data ={
            work_hours:workHours,
            late_tolerance:lateTolerance,
            after_work_time:afterWorkTime,
            calculated_alpha_after: calculatedAlphaAfter
        }
        try {
            const response = await Api.CreateSetTime(localStorage.getItem('hris-token'), data)
            setRefresh(true)
            console.log(response)
            toast.success('Success Set Time!')
        } catch (error) {
            console.log(error)
            toast.error('Failed Update')
        }
    }

    const [dataSetTime, setDataSetTime] = useState('')
    const getSetTime = async () => {
        try {
            const response = await Api.GetSetTime(localStorage.getItem('hris-token'))
            console.log(response)
            setAfterWorkTime(response.data.data.after_work_time)
            setWorkHours(response.data.data.work_hours)
            setLateTolerance(response.data.data.late_tolerance)
            setCalculatedAlphaAfter(response.data.data.calculated_alpha_after)
        } catch (error) {
            console.log(error)
        }
    }
    
    useEffect(() => {
        getGeoData()
        getSetTime()
        setRefresh(false)
    }, [refresh])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showModalAdd}
                title={'Add Absence Distance'}
                buttonClose={ () => setShowModalAdd(!showModalAdd)}
                width={'850px'}
                content= {
                    <div className='space-y-6'>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Geo Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input type={'text'} onChange={(e) => setGeoName(e.target.value)} value={geoname} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Geo Name...'/>
                        </div>
                        <hr/>
                        {/* <div className='w-full relative'>
                            <BiSearchAlt2 className='absolute left-3 top-3'/>
                            <input type={'text'} className='bg-white border rounded-[6px] pl-10 text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Search...'/>
                        </div> */}
                        <div className='w-ull'>
                            <MapContainer
                                center={[-4.508742832943697, 121.81246561664125]}
                                zoom={5}
                                style={{ height: '300px', width: '100%' }}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                />
                                <MapClickHandler />
                                {markerPosition && <Marker position={markerPosition} icon={customIcon}/>}
                                {georadius && <Circle center={markerPosition} radius={georadius}/>}
                            </MapContainer>
                        </div>
                        <div className='w-full'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Geo Location<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input type={'text'} value={markerPosition} disabled className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Geo Location...'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Geo Radius<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input type={'number'} min={0} max={50} disabled={!markerPosition ? true : false} value={georadius} onChange={(e) => setGeoRadius(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Ex : 10 Meter...'/>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalAdd(!showModalAdd)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={postGeoData} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <Modal
                activeModal={showModalEdit}
                title={'Edit Absence Distance'}
                buttonClose={ () => setShowModalEdit(!showModalEdit)}
                width={'550px'}
                content= {
                    <div className='space-y-6'>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Geo Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input type={'text'} value={geoname} onChange={(e) => setGeoName(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Geo Name...'/>
                        </div>
                        <hr/>
                        {/* <div className='w-full relative'>
                            <BiSearchAlt2 className='absolute left-3 top-3'/>
                            <input type={'text'} className='bg-white border rounded-[6px] pl-10 text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Search...'/>
                        </div> */}
                        <div className='w-ull'>
                            <MapContainer
                                center={[-4.508742832943697, 121.81246561664125]}
                                zoom={5}
                                style={{ height: '300px', width: '100%' }}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                                />
                                <MapClickHandler />
                                {markerPosition && <Marker position={markerPosition} icon={customIcon}/>}
                                {georadius && <Circle center={markerPosition} radius={georadius}/>}
                            </MapContainer>
                        </div>
                        <div className='w-full'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Geo Location<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input type={'text'} value={markerPosition} disabled className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Geo Location...'/>
                            </div>
                        </div>
                        <div className='w-full'>
                            <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Geo Radius<span className='text-[#780000] ml-[4px]'>*</span></h1>
                            <input type={'number'} min={0} max={50} disabled={!markerPosition ? true : false} value={georadius} onChange={(e) => setGeoRadius(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full outline-none' placeholder='Ex : 10 Meter...'/>
                        </div>
                        <div className='flex items-center justify-end gap-[12px] mt-5'>
                            <button onClick={ () => setShowModalEdit(!showModalEdit)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                            <button onClick={updateGeoData} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Submit</button>
                        </div>
                    </div>
                }
            />
            <ModalDelete
                activeModal={showModalDelete}
                buttonClose={ () => setShowModalDelete(!showModalDelete)}
                submitButton={deleteGeoData}
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={'Attendance Setting'} LinkBack={'/time-management-setting'}/>
                <div className='border-b-2 flex items-center text-[16px]'>
                    <button onClick={ () => setMenu('Attendance Distance') } className={`${menu === 'Attendance Distance' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[85px] py-[10px]`}>Attendance Distance</button>
                    <button onClick={ () => setMenu('SetTime') } className={`${menu === 'SetTime' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'} px-[85px] py-[10px]`}>SetTime</button>
                </div>
                {menu === 'Attendance Distance' ? (
                    <>
                        <div className='rounded-xl overflow-hidden shadow-lg border-gray-400 border'>
                            <MapContainer center={geodata.length === 0 ? [-4.289663763983576, 122.42769997219564] : geodata[0].geocode} zoom={geodata.length === 0 ? 6 : 13} style={{ height: '400px', width: '100%', zIndex: '10'}}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="Humanusia Using Leaflet"
                                />
                                {Object.values(geodata).map((data, index) => {
                                    const geocodeString = data.geocode;
                                    const geocodeArray = JSON.parse(geocodeString);
                                    return (
                                        <div key={index}>
                                            <Marker position={data.length === 0 ? [-4.289663763983576, 122.42769997219564] : geocodeArray} icon={customIcon}>
                                                <Popup>{data.geoname}</Popup>
                                            </Marker>
                                            <Circle center={data.length === 0 ? [-4.289663763983576, 122.42769997219564] : geocodeArray} radius={data.georadius}/>
                                        </div>
                                    )
                                })}
                            </MapContainer>
                        </div>
                        <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                            <div className='flex flex-col lg:flex-row items-center justify-between'>
                                <div>
                                    <button className=' gap-[10px] flex items-center py-[7px] px-[8px] text-[#113B61] font-medium border rounded-lg'>
                                        <CgTrashEmpty className='text-lg' />
                                        <h1>Delete</h1>
                                    </button>
                                </div>
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                    <div className='relative py-[12px] pl-[40px] pr-[13px] border rounded-lg flex text-[#8E95A2] font-medium w-[300px]'>
                                        <BiSearch className='absolute left-[12px] text-lg' />
                                        <input type="text" placeholder='Search...' className='outline-none text-[10px] w-full' />
                                    </div>
                                    <button onClick={ () => setShowModalAdd(!showModalAdd)} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                        <AiOutlinePlus className='text-xl text-white'/>
                                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add Absence Distance</h1>
                                    </button>
                                </div>
                            </div>
                            <div className='mt-[44px] overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[15px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-xs font-[600]'>Geo Name</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-[#737373] text-xs font-[600]'>Geo Location</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[15px] min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-[#737373] text-xs font-[600]'>Geo Radius</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[15px] w-full'>
                                            <h1 className='text-[#737373] text-xs font-[600]'>Action</h1>
                                        </div>
                                    </div>
                                    {Object.values(geodata).map((data, index) => {
                                        return (
                                            <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>{data.geoname}</h1>
                                                </div>
                                                <div className='min-w-[300px] max-w-[300px]'>
                                                    <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>{data.geocode}</h1>
                                                </div>
                                                <div className='min-w-[300px] max-w-[300px]'>
                                                    <h1 className='text-[#737373] text-xs font-[600] line-clamp-1'>{data.georadius}</h1>
                                                </div>
                                                <div className='flex items-center justify-center w-full gap-[12px]'>
                                                    <button onClick={ () => getGeoDataById(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-[#003049]'/>
                                                    </button>
                                                    <button onClick={ () => deleteGeoDataId(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <CgTrashEmpty className='text-[#003049]'/>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </table>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                        <div className='w-full lg:w-auto mb-[20px]'>
                            <h1 className='text-[#454545] text-[20px] font-[600]'>Set Time</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[400]'>list of Set Time</h1>
                        </div>

                        <div className='rounded-xl shadow p-[24px]'>
                            <div className='flex gap-[10px] items-center w-full mb-[23px]'>
                                <div className='w-full'>
                                    <h1 className='text-gray-500 text-[11px] font-medium'>Work Hours <span className='text-[#E00101]'>*</span></h1>
                                    <input type="time" onChange={(e) => setWorkHours(e.target.value)} value={workHours} className='px-[20px] py-[8px] text-[11px] outline-none border border-stone-300 rounded-lg text-gray-500 w-full' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-gray-500 text-[11px] font-medium'>After Work Time <span className='text-[#E00101]'>*</span></h1>
                                    <input type="time"  onChange={(e) => setAfterWorkTime(e.target.value)} value={afterWorkTime} className='px-[20px] py-[8px] text-[11px] outline-none border border-stone-300 rounded-lg text-gray-500 w-full' />
                                </div>
                            </div>

                            <div className='flex gap-[10px] items-center w-full mb-[23px]'>
                                <div className='w-full'>
                                    <h1 className='text-gray-500 text-[11px] font-medium'>Late Tolerance <span className='text-[#E00101]'>*</span></h1>
                                    <input type="time"  onChange={(e) => setLateTolerance(e.target.value)} value={lateTolerance} className='px-[20px] py-[8px] text-[11px] outline-none border border-stone-300 rounded-lg text-gray-500 w-full' />
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-gray-500 text-[11px] font-medium'>Calculated Alpha After <span className='text-[#E00101]'>*</span></h1>
                                    <input type="time"  onChange={(e) => setCalculatedAlphaAfter(e.target.value)} value={calculatedAlphaAfter} className='px-[20px] py-[8px] text-[11px] outline-none border border-stone-300 rounded-lg text-gray-500 w-full' />
                                </div>
                            </div>

                            <div onClick={createSetTime} className='flex items-center justify-end'>
                                <button className='py-[10px] px-[30px] bg-[#015995] text-white text-sm font-medium rounded-lg'>
                                    Set Time
                                </button>
                            </div>
                        </div>

                    </div>

                ) }
                
            </div>
        </div>
    )
}

export default AttendanceSetting