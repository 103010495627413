import React, { useEffect, useState } from "react";
import { Navbar } from "../../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../../../Api";
import { BsFillEyeFill } from "react-icons/bs";
import { toast } from "react-hot-toast";


const OpenDocument = () =>{
    const params = useLocation()
    const navigate = useNavigate()
    const [dataDocument, setDataDocument] = useState('')
    const [dataDocumentDetail, setDataDocumentDetail] = useState('')
    const [newData, setNewData] = useState([])

    const GetData = async() => {
        try {
            const response = await Api.GetDocumentById(localStorage.getItem('hris-token'), params.state.documentId)
            setDataDocument(response.data.data[0])
            setDataDocumentDetail(response.data.data[0].detailDocument)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const PostDocumentAnswer = async () => {
        const requestBody = {
            newDocumentAnswer: newData.map((input) => {
                let answer = input.answer;
                if (input.type === "multiple-choice") {
                    if (Array.isArray(answer)) {
                        answer = answer.join(","); 
                    } else if (typeof answer !== "string") {
                        answer = "";
                    }
                } else if (typeof answer !== "string") {
                    answer = "";
                }
            
                const field = {
                    answer: answer.trim(),
                    detailDocumentId: input.detailDocumentId,
                };
            
                return field;
            }),
        };
        try {
            console.log(requestBody)
            const response = await Api.CreateDocumentAnswer(localStorage.getItem('hris-token'), requestBody)
            toast.success('Success to send your document')
            setTimeout(() => {
                navigate( localStorage.getItem('hris-role') === 'ADMIN' ? '/document/answer-document' : '/document', {state : {documentId: params.state.documentId}})
            }, 3000);
        } catch (error) {
            console.log(error)
        }
    };

    const handleDataAnswer = (e, id, type) => {
        const newDataItem = {
            answer: type === 'upload-file' ? e.target.files[0] : e.target.value,
            type: type,
            detailDocumentId: id
        };
      
        let updatedData = [...newData];
      
        if (type === 'single-choice') {
            const existingDataItemIndex = updatedData.findIndex(dataItem => dataItem.detailDocumentId === id);
            if (existingDataItemIndex !== -1) {
                updatedData[existingDataItemIndex] = newDataItem;
            } else {
                updatedData.push(newDataItem);
            }
        } else if (type === 'multiple-choice') {
            if (e.target.checked) {
                    const existingDataItemIndex = updatedData.findIndex(dataItem => dataItem.detailDocumentId === id);
                    if (existingDataItemIndex !== -1) {
                        const existingDataItem = updatedData[existingDataItemIndex];
                        if (!Array.isArray(existingDataItem.answer)) {
                            existingDataItem.answer = [existingDataItem.answer];
                        }
                        existingDataItem.answer.push(e.target.value);
                    } else {
                    const newMultipleChoiceDataItem = {
                        answer: [e.target.value],
                        detailDocumentId: id,
                        type: type
                    };
                    updatedData.push(newMultipleChoiceDataItem);
                }
            } else {
                const existingDataItemIndex = updatedData.findIndex(dataItem => dataItem.detailDocumentId === id);
      
                if (existingDataItemIndex !== -1) {
                    const existingDataItem = updatedData[existingDataItemIndex];
                    if (Array.isArray(existingDataItem.answer)) {
                        existingDataItem.answer = existingDataItem.answer.filter(answer => answer !== e.target.value);
                        if (existingDataItem.answer.length === 0) {
                            updatedData.splice(existingDataItemIndex, 1);
                        }
                    }
                }
            }
        } else {
            const existingDataItemIndex = updatedData.findIndex(dataItem => dataItem.detailDocumentId === id);

            if (existingDataItemIndex !== -1) {
                updatedData.splice(existingDataItemIndex, 1);
            }

            updatedData.push(newDataItem);
        }
      
        setNewData(updatedData);
    };

    useEffect(() => {
        GetData()
    }, [])

    return(
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full h-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Detail Document'} LinkBack={'/document'}/>
                <div className="pt-[27px] flex items-center justify-between">
                    <div className="space-y-2">
                        <h1 className="text-[#454545] text-[20px] font-semibold">Detail Document</h1>
                    </div>
                    {localStorage.getItem('hris-role') === 'ADMIN' ?
                        <button onClick={ () => navigate('/document/answer-document', {state : {documentId: dataDocument.id}})} className="w-[200px] h-[40px] rounded-md px-[21px] py-[10px] flex items-center justify-center gap-[10px] border bg-white">
                            <BsFillEyeFill className="text-[#003049] font-medium"/>
                                <h1 className="text-sm text-[#003049] font-medium">See Responden</h1>
                        </button>
                    :
                        null
                    }
                </div>
                <div className='bg-white p-[38px] rounded-md shadow-lg border space-y-10'>
                    <div className='border rounded-md flex items-center shadow-md'>
                        <div className='h-[100px] min-w-[10px] rounded-l-md bg-[#780000]'/>
                        <div className='px-[15px] w-full space-y-1'>
                            <h1 name='title'className='border-b border-[#EDEDED] min-w-full outline-none text-[#454545] py-[6px] text-[20px]'>{dataDocument?.title}</h1>
                            <h1 name='description' className='border-b border-[#EDEDED] min-w-full outline-none text-[#454545] py-[6px] text-[12px]'>{dataDocument?.description}</h1>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-10">
                        {Object.values(dataDocumentDetail).map((data, index) => {
                            return (
                                <div key={index} className="col-span-6 w-full space-y-2">
                                    <h1 className="text-[#737373] text-sm font-medium">{data.field_name}</h1>
                                    {data.dropdown !== null ? 
                                        <select onChange={(e) => handleDataAnswer(e, data.id, data.field_type)} className="border border-[#CACACA] rounded-md text-[#2E2E2E] text-sm w-full px-[30px] py-[15px]">
                                            {Object.values(data.dropdown).map((item, index) => {
                                                return (
                                                    <option key={index} value={item}>{item}</option>
                                                )
                                            })}
                                        </select>
                                    :data.option_single_choice !== null ? 
                                        Object.values(data.option_single_choice).map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="flex items-center mb-4">
                                                        <input id={`default-radio-${index+1}`} onChange={(e) => handleDataAnswer(e, data.id, 'single-choice')} type="radio" value={item} name={`default-radio`} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"/>
                                                        <label htmlFor={`default-radio-${index+1}`} className="ml-2 text-sm font-medium text-gray-900">{item}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :data.option_multiple_choice !== null ? 
                                        Object.values(data.option_multiple_choice).map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="flex items-center mb-4">
                                                        <input id={`default-checkbox-${index}`} onChange={(e) => handleDataAnswer(e, data.id, 'multiple-choice')} type="checkbox" value={item} name={`default-checkbox`} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"/>
                                                        <label htmlFor={`default-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-900">{item}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :<input type={data.field_type === 'string' ? 'text' : data.field_type === 'upload-file' ? 'file' : data.field_type} onChange={(e) => handleDataAnswer(e, data.id, data.field_type)} className="border border-[#CACACA] rounded-md text-[#2E2E2E] text-sm w-full px-[30px] py-[15px]"/>
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className="flex items-center justify-end gap-4">
                        <button onClick={() => navigate(-1)} className="w-[100px] h-[38px] bg-[#ECECEC] rounded-lg font-medium text-[14px] mr-[10px]">Cancel</button>
                        <button onClick={PostDocumentAnswer} className="w-[100px] h-[38px] text-white bg-[#0E5073] rounded-lg font-medium text-[14px]">Submission</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpenDocument