import React, { useEffect, useState } from "react";
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle, AiOutlineEye, AiOutlinePlus, AiTwotoneMail } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { IoStatsChartSharp } from "react-icons/io5";
import { MdImportExport, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { Modal, Navbar, Sidebar } from "../../../../components";
import { TbFileExport } from "react-icons/tb";
import { BsFilter } from "react-icons/bs";
import Api from "../../../../Api";
import { FaPhoneSquareAlt } from "react-icons/fa";

const Answer = () => {

    const params = useLocation()
    const [dataResponden, setDataResponden] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [dataDocument, setDataDocument] = useState('')
    const [questionAnswered, setQuestionAnswered] = useState('')
    const [dataUser, setDataUser] = useState('')

    const GetData = async() => {
        try {
            const response = await Api.GetDocumentResponden(localStorage.getItem('hris-token'), params.state.documentId)
            setDataResponden(response.data.userFilledDocument)
        } catch (error) {
            console.log(error)
        }
    }

    const handleOpenModal = async(id) => {
        setShowModal(!showModal)
        console.log(id)
        try {
            const response = await Api.GetDocumentRespondenDetail(localStorage.getItem('hris-token'), id, params.state.documentId)
            setDataDocument(response.data.filledDocuments[0].document)
            setQuestionAnswered(response.data.filledDocuments[0].document.questionAnswered)
            setDataUser(response.data.user)
        } catch (error) {
            console.log(error)
        }
    }
    

    useEffect(() => {
        GetData()
    },[])

    return (
        <div>
            <Modal
                activeModal={showModal}
                buttonClose={() => setShowModal(!showModal)}
                width={'750px'}
                title={'Detail Responden'}
                content={
                    <div className="space-y-10">
                        <div className='border rounded-md flex items-center shadow-md'>
                            <div className='px-[15px] space-y-1'>
                                <div className="border-b border-[#EDEDED] flex items-center justify-between w-full">
                                    <h1 className='outline-none text-[#454545] py-[6px] text-[20px]'>{dataUser.fullname}</h1>
                                    <div className="flex items-center gap-10 ">
                                        <div className="flex items-center gap-2">
                                            <AiTwotoneMail className="text-[#454545] py-[6px] text-[30px]"/>
                                            <h1 className='text-[#454545] py-[6px] text-[12px]'>{dataUser.email}</h1>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <FaPhoneSquareAlt className="text-[#454545] py-[6px] text-[30px]"/>
                                            <h1 className='text-[#454545] py-[6px] text-[12px]'>{dataUser.phone}</h1>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='border-b border-[#EDEDED] min-w-full outline-none text-[#454545] py-[6px] text-[20px]'>{dataDocument.title}</h1>
                                <h1 className='border-b border-[#EDEDED] min-w-full outline-none text-[#454545] py-[6px] text-[12px]'>{dataDocument.description}</h1>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-12 border-b-4 border-[#EDEDED]">
                                <h1 className='col-span-6 text-[#454545] py-[6px] text-[14px] font-semibold'>Question</h1>
                                <h1 className='col-span-6 text-[#454545] py-[6px] text-[14px] font-semibold'>Answer</h1>
                            </div>
                            {Object.values(questionAnswered).map((data, index) => {
                                return (
                                    <div key={index} className="grid grid-cols-12 border-b-2 border-[#EDEDED]">
                                        <h1 className='col-span-6 text-[#454545] py-[6px] text-[12px]'>{data.detailDocument.field_name}</h1>
                                        <h1 className='col-span-6 text-[#454545] py-[6px] text-[12px]'>: {data.documentAnswer.answer}</h1>
                                    </div>
                                )
                            })}
                        </div>
                        <button onClick={ () => setShowModal(!showModal)} className="w-full bg-[#02508D] rounded-[6px] h-[50px] px-[37px] py-[13px] text-white text-center">Close</button>
                    </div>
                }
            />
            <div className='bg-[#F8F9FB] min-h-screen'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar  SubNavbar={true} NameSubNavbar={'Responden'} LinkBack={'/document'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        
                        <div>
                            <h1 className='text-[20px] font-semibold'>Employee List Filled Document</h1>
                            <h2 className='text-[10px] text-[#737373]'>Responden List</h2>
                        </div>

                        <div className="w-[203px] h-[66px] bg-white border rounded-lg mt-[23px] ">
                            <div className="pt-[14px] pl-[21px]">
                                <h1 className="text-[8px]">Total Responden</h1>
                                <h2 className="text-[15px]">{dataResponden.length}</h2>
                            </div>
                        </div>

                            <div className="flex gap-2 mt-[31px] relative">
                            <button className='bg-white border border-[#CACACA] rounded-[6px] flex items-center gap-[12px] px-[10px] py-[8px]'>
                                <TbFileExport className='text-[#003049]'/>
                                <h1 className='text-[#003049] text-sm font-[500]'>Export</h1>
                            </button>           
                            <div className="absolute right-0">
                                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                                    <div className='relative'>
                                        <BiSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg'/>
                                        <input placeholder='Search by Employee Name or Employee ID...' className='h-[38px] text-[#A8A8A8] text-[10px] font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]'/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='overflow-auto scrollbar-hide'>
                                <table className='w-full space-y-[10px]'>
                                    <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                        <div className='flex items-center gap-[6px] min-w-[300px] max-w-[300px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Employee Name</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Phone Number</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Job Grade</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Job Level</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center gap-[6px] min-w-[200px] max-w-[200px]'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Job Position</h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                        <div className='flex items-center justify-center gap-[6px] w-full'>
                                            <h1 className='text-[#737373] text-[11px] font-[500]'>Action </h1>
                                            <MdImportExport className='text-[#737373] text-xs'/>
                                        </div>
                                    </div>
                                    {Object.values(dataResponden).map((data, index) => {
                                        console.log(data)
                                        return (
                                            <div key={index} className='flex items-center gap-2 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                                <div className='min-w-[300px] max-w-[300px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{data.fullname}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.phone ? '-' : data.phone}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.jobGrade ? '-' : data.jobGrade}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.jobLevel ? '-' : data.jobLevel}</h1>
                                                </div>
                                                <div className='min-w-[200px] max-w-[200px]'>
                                                    <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{!data.jobPosiotion ? '-' : data.jobPosiotion}</h1>
                                                </div>
                                                <div className='w-full flex items-center justify-center gap-[12px]'>
                                                    <button onClick={ () => handleOpenModal(data.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                        <AiOutlineEye className='text-[#003049]'/>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </table>
                            </div>
                            <div className='flex items-center justify-between mt-[30px]'>
                                <h1 className='text-[#A098AE] text-[10px] '>Showing 1-5 from 100 data</h1>
                                <div className='flex items-center gap-[8px]'>
                                    <MdKeyboardArrowLeft className='text-[#A098AE]'/>
                                    <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                        <h1 className='text-[#780000] text-[10px]'>1</h1>
                                    </div>
                                    <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center'>
                                        <h1 className='text-white text-[10px]'>2</h1>
                                    </div>
                                    <div className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'>
                                        <h1 className='text-[#780000] text-[10px]'>3</h1>
                                    </div>
                                    <MdKeyboardArrowRight className='text-[#A098AE]'/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Answer