import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { Pagination } from '../../../../../components'
import { MdImportExport, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { CgTrashEmpty } from 'react-icons/cg'
import { HiOutlinePencil } from 'react-icons/hi'
import Api from '../../../../../Api'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import imageHandle from '../../../../../utils/imageHandle'

const Training = ({ModalAddTraining, ModalEditTraining, refreshPage}) => {
    const [Current, setCurrent] = useState(0)
    const [Active, setActive] = useState(false)
    const [dataTraining, setDataTraining] = useState('')
    const [refresh, setRefresh] = useState(false)
    const params = useLocation()


    const handleCollapse = (id) => {
        if(id){
            setCurrent(id)
            setActive(!Active)
        }
    }

    const getDataTraining = async () => {
        try {
            if(!params.state){
                const response = await Api.GetTrainingByLogin(localStorage.getItem('hris-token'))
                console.log(response)
                setDataTraining(response.data.response)
            } else {
                
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getDataTraining()
    }, [refresh, refreshPage])

  return (
    <div className='px-[19px] py-[19px] space-y-[20px]'>
        <div className='flex relative'>
            <div>
                <h1 className='text-[#000000] text-[20px] font-medium'>Training</h1>
                <h2 className='text-[#737373] text-[10px]'>List of Training</h2>
            </div>
            <div className='absolute right-0'>
                <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                    <button onClick={ModalAddTraining} className='bg-[#015995] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                        <AiOutlinePlus  className='text-xl text-white'/>
                        <h1 className='text-[#EDEDED] text-sm font-[500]'>Add Training</h1>
                    </button>
                </div>
            </div>
        </div>
        <hr  className='border border-[#B6BAC3]'/>
        <div className='rounded shadow'>
            <div className='px-2 py-4'>
                <div className='overflow-auto scrollbar-hide'>
                    <table className='w-full space-y-[10px] text-[#737373]'>
                        <div className='flex items-center gap-2 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Training  Name</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] min-w-[150px] max-w-[150px]'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Date</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center gap-[6px] w-full justify-center'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Status</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                            <div className='flex items-center justify-center gap-[6px] w-full'>
                                <h1 className='text-[#737373] text-[11px] font-[500]'>Action</h1>
                                <MdImportExport className='text-[#737373] text-xs'/>
                            </div>
                        </div>
                        {Object.values(dataTraining).map((item,idx) => (
                            <>
                                <div  className='flex items-center gap-2 px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.training_name?? '-'}</h1>
                                    </div>
                                    <div className='min-w-[150px] max-w-[150px]'>
                                        <h1 className='text-[#737373] text-[11px] font-[500] truncate'>{item.date? moment(item.date).format('DD/MM/YYYY') : '-'}</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path d="M25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C9.18479 25 6.00537 23.683 3.66117 21.3388C1.31696 18.9946 0 15.8152 0 12.5C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5ZM12.5 5.46875C12.5 5.26155 12.4177 5.06284 12.2712 4.91632C12.1247 4.76981 11.926 4.6875 11.7188 4.6875C11.5115 4.6875 11.3128 4.76981 11.1663 4.91632C11.0198 5.06284 10.9375 5.26155 10.9375 5.46875V14.0625C10.9375 14.2002 10.974 14.3355 11.0431 14.4545C11.1123 14.5736 11.2117 14.6723 11.3313 14.7406L16.8 17.8656C16.9795 17.9626 17.1898 17.9856 17.3859 17.9296C17.5821 17.8736 17.7486 17.7431 17.8498 17.5659C17.951 17.3888 17.9789 17.1791 17.9275 16.9817C17.8762 16.7843 17.7497 16.6147 17.575 16.5094L12.5 13.6094V5.46875Z" fill="#FFD900"/>
                                        </svg>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[12px]'>
                                        <button onClick={() => ModalEditTraining(item.id)} className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <HiOutlinePencil className='text-[#003049]'/>
                                        </button>
                                        <button className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                            <CgTrashEmpty className='text-[#003049]'/>
                                        </button>
                                        <button onClick={ () => handleCollapse(item.id) } className='w-[29px] h-[29px] rounded-[9px] flex items-center justify-center'>
                                            { item.id === Current && !Active ?
                                                <MdKeyboardArrowUp className='text-[#003049]'/>
                                                :
                                                <MdKeyboardArrowDown className='text-[#003049]'/>
                                            }
                                        </button>
                                    </div>
                                </div>
                                <div className={`w-full rounded-b-[3px] bg-[#F9F9F9] transition-all ease-in-out duration-500 overflow-hidden ${ item.id === Current && !Active  ? 'h-fit border' : 'h-0 border-0' }`}>
                                    <div className='px-[16px] py-[19px] space-y-3'>
                                        <div className="text-gray-500 text-xs font-medium ">Detail Training</div>
                                        <div className='flex gap-[50px]'>

                                            <div className='grid grid-cols-12 w-full'>
                                                <div className='col-span-4 text-gray-500 text-[10px] font-medium space-y-2'>
                                                    <h1>Training Name</h1>
                                                    <h1>Date</h1>
                                                    <h1>Time of Training</h1>
                                                    <h1>File</h1>
                                                </div>
                                                <div className='col-span-2 text-gray-500 text-[10px] font-medium space-y-2'>
                                                    <h1>:</h1>
                                                    <h1>:</h1>
                                                    <h1>:</h1>
                                                    <h1>:</h1>
                                                </div>
                                                <div className='col-span-6 text-gray-500 text-[10px] font-medium space-y-2'>
                                                    <h1>{item.training_name?? '-'}</h1>
                                                    <h1>{item.date? moment(item.date).format('DD/MM/YYYY') : '-'}</h1>
                                                    <h1>{item.time?? '-'}</h1>
                                                    <a href={imageHandle(item.file)} target='_blank' className='w-[102px] h-[21px] px-2 py-1 bg-gray-300 rounded justify-start items-center gap-2 inline-flex text-gray-500 text-[10px] font-normal'>
                                                        <div className="text-gray-500 text-[10px] font-normal">{item.training_name}</div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                                            <path d="M5.00033 7.16699L2.91699 5.08366L3.50033 4.47949L4.58366 5.56283V2.16699H5.41699V5.56283L6.50033 4.47949L7.08366 5.08366L5.00033 7.16699ZM2.50033 8.83366C2.27116 8.83366 2.07491 8.75199 1.91158 8.58866C1.74824 8.42533 1.66672 8.22921 1.66699 8.00033V6.75033H2.50033V8.00033H7.50033V6.75033H8.33366V8.00033C8.33366 8.22949 8.25199 8.42574 8.08866 8.58908C7.92533 8.75241 7.72922 8.83394 7.50033 8.83366H2.50033Z" fill="#6B7280"/>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className='w-full'>
                                                <div className='grid grid-cols-12 w-full mb-2'>
                                                    <div className='col-span-3 text-gray-500 text-[10px] font-medium space-y-2'>
                                                        <h1>Description</h1>
                                                    </div>
                                                    <div className='col-span-1 text-gray-500 text-[10px] font-medium'>
                                                        <h1>:</h1>
                                                    </div>
                                                    <div className='col-span-8 text-gray-500 text-[10px] font-medium space-y-2'>
                                                        <h1>{item.description?? '-'}</h1>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-12 w-full'>
                                                    <div className='col-span-3 text-gray-500 text-[10px] font-medium space-y-2'>
                                                        <h1>Status</h1>
                                                    </div>
                                                    <div className='col-span-1 text-gray-500 text-[10px] font-medium'>
                                                        <h1>:</h1>
                                                    </div>
                                                    <div className='col-span-8 text-gray-500 text-[10px] font-medium space-y-2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                            <path d="M25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C9.18479 25 6.00537 23.683 3.66117 21.3388C1.31696 18.9946 0 15.8152 0 12.5C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5ZM12.5 5.46875C12.5 5.26155 12.4177 5.06284 12.2712 4.91632C12.1247 4.76981 11.926 4.6875 11.7188 4.6875C11.5115 4.6875 11.3128 4.76981 11.1663 4.91632C11.0198 5.06284 10.9375 5.26155 10.9375 5.46875V14.0625C10.9375 14.2002 10.974 14.3355 11.0431 14.4545C11.1123 14.5736 11.2117 14.6723 11.3313 14.7406L16.8 17.8656C16.9795 17.9626 17.1898 17.9856 17.3859 17.9296C17.5821 17.8736 17.7486 17.7431 17.8498 17.5659C17.951 17.3888 17.9789 17.1791 17.9275 16.9817C17.8762 16.7843 17.7497 16.6147 17.575 16.5094L12.5 13.6094V5.46875Z" fill="#FFD900"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}

                    </table>
                </div>
                {/* <Pagination
                    currentPage={currentPage} 
                    totalPages={totalPages} 
                    onPageChange={handlePageChange}
                    onPrevChange={handlePrevChange}
                    onNextChange={handleNextChange}
                /> */}
            </div>
        </div>
    </div>
  )
}

export default Training