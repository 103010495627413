import React from "react";
import { BsFileExcelFill, BsFillFilePptFill, BsFillFileWordFill } from "react-icons/bs";
import { MdImage, MdPictureAsPdf } from "react-icons/md";
import { Sidebar, Navbar } from "../../../../components";
import BarChart from "../../../../components/Bar/BarChart";
import PieChart from "../../../../components/Bar/PieChart";

const StatisticAnswer = () =>{
    return(
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar  SubNavbar={true} NameSubNavbar={'Statistic Answer'} LinkBack={'/document'}/>
                <div>
                    <h1 className='text-[20px]'>Statistic Answer</h1>
                    <h2 className='text-[10px] text-[#737373]'>Percentage of overall answers</h2>
                </div>

                <div className=' bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <h1 className='text-[20px]'>Multiple Choice Answer</h1>
                    <h2 className='text-[10px] text-[#737373]'>10 Answer</h2>
                    <div className="ml-[104px] mt-2">
                        <PieChart />
                    </div>
                </div>

                <div className=' bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <h1 className='text-[20px]'>Dropdown Answer</h1>
                    <h2 className='text-[10px] text-[#737373]'>10 Answer</h2>
                    <div className="ml-[104px] mt-2">
                        <PieChart />
                    </div>
                </div>

                <div className=' bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <h1 className='text-[20px]'>Checkbox Answerr</h1>
                    <h2 className='text-[10px] text-[#737373]'>10 Answer</h2>
                    <div className="mt-2">
                        <BarChart />
                    </div>
                </div>

                <div className=' bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <h1 className='text-[20px]'>Short Answer</h1>
                    <h2 className='text-[10px] text-[#737373]'>10 Answer</h2>
                    <div className="mt-[24px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is example for answer number one </h1>
                    </div>
                    <div className="mt-[16px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is loongg example for answer number two </h1>
                    </div>
                    <div className="mt-[16px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is little long example for answer number three </h1>
                    </div>
                    <div className="mt-[16px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is longer than previous example for answer number three</h1>
                    </div>
                    <div className="mt-[16px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is longest than previous example for answer number Four</h1>
                    </div>
                    <div className="mt-[16px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is most long than previous example for answer number fives.</h1>
                    </div>
                </div>

                <div className=' bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <h1 className='text-[20px]'>Paragraf Answer</h1>
                    <h2 className='text-[10px] text-[#737373]'>10 Answer</h2>
                    <div className="mt-[24px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is example for answer number one </h1>
                    </div>
                    <div className="mt-[16px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is loongg example for answer number two </h1>
                    </div>
                    <div className="mt-[16px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is little long example for answer number three </h1>
                    </div>
                    <div className="mt-[16px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is longer than previous example for answer number three</h1>
                    </div>
                    <div className="mt-[16px] w-full h-[30px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">This is longest than previous example for answer number Four</h1>
                    </div>
                    <div className="mt-[16px] w-full h-[40px] bg-[#F3F3F3] rounded-lg text-[10px]">
                        <h1 className="py-[8px] pl-[14px] text-[#5C5C5C]">Lorem ipsum dolor sit amet consectetur. Convallis pellentesque pretium ornare turpis dolor purus. Commodo odio dignissim aliquam at. Risus montes aliquam enim dolor sit elit odio. Augue ut at arcu hac lacinia nec purus id. Neque quisque elementum id nibh mattis rhoncus tellus.</h1>
                    </div>
                </div>

                <div className=' bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <h1 className='text-[20px]'>Upload File Answer</h1>
                    <h2 className='text-[10px] text-[#737373]'>10 Answer</h2>

                    <div className="bg-[#EFF7FD] rounded-lg mt-[24px] h-[50px] w-full">
                        <div className="flex items-center py-[10px] pl-[22px]">
                            <div className="bg-[#A5C6DB] p-[6px] w-[30px] h-[30px] flex items-center justify-center rounded-lg">
                                <MdImage className="text-xl"/>
                            </div>
                            <h1 className="text-[10px] text-[#5C5C5C] ml-[12px]">Image.png</h1>
                        </div>
                    </div>
                    <div className="bg-[#EFF7FD] rounded-lg mt-[9px] h-[50px] w-full">
                        <div className="flex items-center py-[10px] pl-[22px]">
                            <div className="bg-[#A5C6DB] p-[6px] w-[30px] h-[30px] flex items-center justify-center rounded-lg">
                                <BsFileExcelFill className="text-xl"/>
                            </div>
                            <h1 className="text-[10px] text-[#5C5C5C] ml-[12px]">Upload File.xlxs</h1>
                        </div>
                    </div>
                    <div className="bg-[#EFF7FD] rounded-lg mt-[9px] h-[50px] w-full">
                        <div className="flex items-center py-[10px] pl-[22px]">
                            <div className="bg-[#A5C6DB] p-[6px] w-[30px] h-[30px] flex items-center justify-center rounded-lg">
                                <BsFillFileWordFill className="text-xl"/>
                            </div>
                            <h1 className="text-[10px] text-[#5C5C5C] ml-[12px]">Lorem Ipsum Dolor Set Amet.doc</h1>
                        </div>
                    </div>
                    <div className="bg-[#EFF7FD] rounded-lg mt-[9px] h-[50px] w-full">
                        <div className="flex items-center py-[10px] pl-[22px]">
                            <div className="bg-[#A5C6DB] p-[6px] w-[30px] h-[30px] flex items-center justify-center rounded-lg">
                                <BsFillFilePptFill className="text-xl"/>
                            </div>
                            <h1 className="text-[10px] text-[#5C5C5C] ml-[12px]">Human Resources Integrated System.pptx</h1>
                        </div>
                    </div>
                    <div className="bg-[#EFF7FD] rounded-lg mt-[9px] h-[50px] w-full">
                        <div className="flex items-center py-[10px] pl-[22px]">
                            <div className="bg-[#A5C6DB] p-[6px] w-[30px] h-[30px] flex items-center justify-center rounded-lg">
                                <MdPictureAsPdf className="text-xl"/>
                            </div>
                            <h1 className="text-[10px] text-[#5C5C5C] ml-[12px]">Image Picture.pdf</h1>
                        </div>
                    </div>
                </div>

                <div className=' bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <h1 className='text-[20px]'>Date Answer</h1>
                    <h2 className='text-[10px] text-[#737373] mb-[21px]'>10 Answer</h2>

                    <div className="grid grid-cols-2 text-[#454545] text-[10px] ml-[50px] w-1/3 gap-x-[11px] ">
                        <div className="font-medium border-r-2 border-[#737373] py-2">
                            January
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">14</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">21</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">30</div>
                        </div>
                        <div className="font-medium border-r-2 border-[#737373] py-2">
                            February
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">14</div>
                        </div>
                        <div className="font-medium border-r-2 border-[#737373] py-2">
                            March
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">14</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">21</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">30</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">41</div>
                        </div>
                        <div className="font-medium border-r-2 border-[#737373] py-2">
                            April
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">14</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">21</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">30</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">11</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">31</div>
                        </div>
                        <div className="font-medium border-r-2 border-[#737373] py-2">
                            May
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">14</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">21</div>
                            <div className="w-[25px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">30</div>
                        </div>
                    </div>
                </div>
                <div className=' bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <h1 className='text-[20px]'>Time Answer</h1>
                    <h2 className='text-[10px] text-[#737373] mb-[21px]'>10 Answer</h2>

                    <div className="grid grid-cols-2 text-[#454545] text-[10px] ml-[50px] gap-x-[11px] w-1/2">
                        <div className="font-medium border-r-2 border-[#737373] py-2 w-">
                            03.--
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">03.15</div>
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">03.30</div>
                        </div>
                        <div className="font-medium border-r-2 border-[#737373] py-2">
                            04.__
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">04.02</div>
                        </div>
                        <div className="font-medium border-r-2 border-[#737373] py-2">
                            12.__
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">12.09</div>
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">12.15</div>
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">12.18</div>
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">12.45</div>
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">12.55</div>
                        </div>
                        <div className="font-medium border-r-2 border-[#737373] py-2">
                            16.__
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">16.34</div>
                        </div>
                        <div className="font-medium border-r-2 border-[#737373] py-2">
                            22.__
                        </div>
                        <div className="flex gap-[12px] ">
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">22:22</div>
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">22:34</div>
                            <div className="w-[44px] h-[25px] bg-[#003049] text-white rounded-md flex items-center justify-center">22:57</div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default StatisticAnswer