import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { BsImage } from 'react-icons/bs'
import { IoMdArrowDropdown } from 'react-icons/io'
import { MdModeEditOutline } from 'react-icons/md'
import { Navbar } from '../../../../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../../Api'
import imageHandle from '../../../../../utils/imageHandle'
import { toast } from 'react-hot-toast'

const EditSubsidiary = () => {

    const [image, setImage] = useState('')
    const [typePassword, setTypePassword] = useState('password')
    const [companyId, setCompanyId] = useState('')
    const [dataCompany, setDataCompany] = useState({

    })
    const [dataUser, setDataUser] = useState('')
    const params = useLocation()

    const [subsidiaryName, setSubsidiaryName] = useState('')
    const [regNumber, setRegNumber] = useState('')
    const [taxId, setTaxId] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [fax, setFax] = useState('')
    const [address, setAddress] = useState('')
    const [notes, setNotes] = useState('')
    const [country, setCountry] = useState('')
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()

    const Fetch = async() => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setCompanyId(response.data.results.data.companyId)
            console.log(response, 'fetch')
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {
        const maxSize = 2 * 1024 * 1024
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = e.target.files[0]
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (file && file.size > maxSize){
            toast.error('gambar harus < 2MB')
        } else if (!allowedExtensions.includes(fileExtension)){
            toast.error('file harus jpg, jpeg, atau png')
        } else {
            const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                setImage(reader.result)
            };
        }
    }

    const getSubsidiaryById = async () => {
        try {
            const response = await Api.GetSubsidiaryById(localStorage.getItem('hris-token'), params.state.subsidiaryId)
            setDataCompany(response.data.company)
            setDataUser(response.data.company.user[0])
            setSubsidiaryName(response.data.company.user[0].fullname)
            setRegNumber(response.data.company.registration_number)
            setTaxId(response.data.company.tax_id)
            setPhoneNumber(response.data.company.phone)
            setFax(response.data.company.fax)
            setAddress(response.data.company.address)
            setNotes(response.data.company.notes)
            setCountry(response.data.company.country)
            setProvince(response.data.company.province)
            setCity(response.data.company.city)
            setPostalCode(response.data.company.postal_code)
            setUserName(response.data.company.user[0].fullname)
            setEmail(response.data.company.user[0].email)
            setPassword(response.data.company.user[0].password)
            console.log(response, 'byId')
        } catch (error) {
            
        }
    }

    const updateSubsidiary = async () => {
        try {
            const data = {
                ...companyId && { adminId: companyId },
                ...subsidiaryName && { subsidiary_name: subsidiaryName },
                ...regNumber && { registration_number: regNumber },
                ...taxId && { tax_id: taxId },
                ...phoneNumber && { phone_number: phoneNumber },
                ...fax && { fax: JSON.stringify(fax) },
                ...address && { addres: address },
                ...notes && { notes: notes },
                ...country && { country: country },
                ...province && { province: province },
                ...city && { city: city },
                ...postalCode && { postal_code: JSON.stringify(postalCode) },
                ...userName && { user_name: userName },
                ...email && { email: email },
                ...password && { password: password }, 
                status: true,
                ...image && { image: image },
            };
            console.log(data)
            const response = await Api.UpdateSubsidiary(localStorage.getItem('hris-token'), data, params.state.subsidiaryId)   
            console.log(response)  
            toast.success('Success Update!')   
            navigate('/subsidiary')
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }

    const handleTypePassword = () => {
        if(typePassword === 'password') {
            setTypePassword('text')
        }else {
            setTypePassword('password')
        }
    }

    useEffect(() => {
        getSubsidiaryById()
        Fetch()
    }, [])

    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <div className='flex px-[30px] py-[35px] gap-[30px]'>
                <div className='w-full space-y-[24px] overflow-hidden'>
                    <Navbar SubNavbar={true} NameSubNavbar={'Edit Subsidiary'} LinkBack={'/subsidiary'}/>
                    <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-[#454545] text-[16px] font-[500]'>
                                Subsidiary Data
                            </h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>
                                Input the data of your subsidiary
                            </h1>
                        </div>
                        <div className='space-y-[25px]'>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Subsidiary Logo</h1>
                                <label htmlFor='upload-image' className='cursor-pointer'>
                                    <div className='w-[105px] h-[105px] border rounded-[12px] bg-[#C6C6C6] flex flex-col items-center justify-center bg-cover relative' style={{ backgroundImage: `url(${!image ? imageHandle(dataCompany.image) : image })` }}>
                                        <BsImage className='text-2xl text-[#EDEDED]'/>
                                        <div className='absolute bg-[#F9F9F9] border border-[#EBEBEB] w-[38px] h-[38px] rounded-full -bottom-3 -right-4 flex items-center justify-center'>
                                            <MdModeEditOutline/>
                                        </div>
                                    </div>
                                    <input type='file' id='upload-image' onChange={(e) => handleChange(e)}  className='rounded-[12px] outline-none border border-[#E3E8F1] w-full px-[20px] py-[15px] hidden' required/>
                                </label>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Subsidiary Name<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input type={'text'} onChange={(e) => setSubsidiaryName(e.target.value)}  value={subsidiaryName} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Subsidiary Name...'/>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Registration Number<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <input type={'text'} value={regNumber} onChange={(e) => setRegNumber(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Registration Number...'/>
                            </div>
                            <div className='flex items-center gap-[30px]'>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Tax ID</h1>
                                    <input type={'text'} value={taxId} onChange={(e) => setTaxId(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='123-345-5678'/>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Phone Number<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input type={'text'} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='0281-xxx-xxx'/>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Fax</h1>
                                    <input type={'text'} value={fax} onChange={(e) => setFax(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='0224203450'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Address<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <textarea type='text' rows={4} value={address} onChange={(e) => setAddress(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Your Address...'/>
                            </div>
                            <div className='flex items-center gap-[30px]'>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Country</h1>
                                    <div className='relative'>
                                        <select value={country} onChange={(e) => setCountry(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Country</option>
                                            <option value={'Indonesia'}>Indonesia</option>
                                            <option value={'United State'}>United State</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Province<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <select value={province} onChange={(e) => setProvince(e.target.value)}  className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Province</option>
                                            <option value={'Sulawesi Selatan'}>Sulawesi Selatan</option>
                                            <option value={'Jawa Tengah'}>Jawa Tengah</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center gap-[30px]'>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>City<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <select value={city} onChange={(e) => setCity(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select City</option>
                                            <option value={'Makassar'}>Makassar</option>
                                            <option value={'Banyumas'}>Banyumas</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Postal Code<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input type={'text'} value={postalCode} onChange={(e) => setPostalCode(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Postal Code...'/>
                                </div>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Notes<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <textarea type='text' rows={4} value={notes} onChange={(e) => setNotes(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Notes...'/>
                            </div>
                            <div className='border-b-2 w-full'/>
                            <div className='flex items-center gap-[30px]'>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Username<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input type={'text'} value={userName} onChange={(e) => setUserName(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Your Username...'/>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Status<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'>
                                        <select value={''} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full appearance-none'>
                                            <option selected value={''}>Select Status</option>
                                            <option value={'Enable'}>Enable</option>
                                            <option value={'Disable'}>Disable</option>
                                        </select>
                                        <IoMdArrowDropdown className='absolute top-[10px] right-3 text-[#A8A8A8] text-xl'/>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center gap-[30px]'>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Email<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <input type={'text'} value={email} onChange={(e) => setEmail(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Humanusia@example.com...'/>
                                </div>
                                <div className='w-full'>
                                    <h1 className='text-[#737373] text-[13px] font-[500] mb-[7px]'>Password<span className='text-[#780000] ml-[4px]'>*</span></h1>
                                    <div className='relative'> 
                                        <input type={typePassword} onChange={(e) => setPassword(e.target.value)} className='bg-white border rounded-[6px] text-[#A8A8A8] text-[13px] px-[8px] py-[10px] w-full' placeholder='Your Password...'/>
                                        <button onClick={handleTypePassword} className='absolute top-[10px] right-5 '>
                                            <AiOutlineEye className='text-[#A8A8A8] text-xl'/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Cancel</button>
                                <button onClick={updateSubsidiary} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditSubsidiary