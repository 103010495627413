import React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const Pagination = ({ currentPage, totalPages, onPageChange, onPrevChange, onNextChange, lengthData, limitData }) => {
  const maxVisiblePages = 3;
  const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
  
  let startPage = Math.max(1, currentPage - halfMaxVisiblePages);
  let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
  
  if (endPage - startPage < maxVisiblePages - 1) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  const pageRange = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  
  return (
    <div>
      <div className='flex items-center justify-between mt-[30px]'>
      <h1 className='text-[#A098AE] text-[10px] '>Showing 1-{limitData} from {lengthData} data</h1>
        <div className='flex items-center gap-[8px]'>
          <button onClick={onPrevChange} disabled={currentPage === 1}>
            <MdKeyboardArrowLeft className='text-[#A098AE]' />
          </button>
          {startPage > 1 && (
            <>
              <button className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center' onClick={() => onPageChange(1)}>
                <h1 className='text-[#780000] text-[10px]'>1</h1>
              </button>
              {startPage > 2 && (
                <span className='text-[#A098AE]'>...</span>
              )}
            </>
          )}
          {pageRange.map((page) => (
            <button
              key={page}
              className={currentPage === page ? 'bg-[#780000] rounded-[6px] w-[24px] h-[24px] flex items-center justify-center' : 'bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center'}
              onClick={() => onPageChange(page)}
            >
              <h1 className={currentPage === page ? 'text-white text-[10px]' : 'text-[#780000] text-[10px]'}>{page}</h1>
            </button>
          ))}
          {endPage < totalPages && (
            <>
              {endPage < totalPages - 1 && (
                <span className='text-[#A098AE]'>...</span>
              )}
              <button className='bg-[#780000] rounded-[6px] w-[24px] h-[24px] bg-opacity-10 flex items-center justify-center' onClick={() => onPageChange(totalPages)}>
                <h1 className='text-[#780000] text-[10px]'>{totalPages}</h1>
              </button>
            </>
          )}
          <button onClick={onNextChange} disabled={currentPage === totalPages}>
            <MdKeyboardArrowRight className='text-[#A098AE]' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
